import React, { Component } from "react";
import Button from "@mui/material/Button";
import { Autocomplete, Container, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TableComponent from "./datatable";
import { withRouter } from "./withRouter";
import Snackbar from "@mui/material/Snackbar";
import Popper from "@mui/material/Popper";
import MuiAlert from "@mui/material/Alert";

import DeleteIcon from "@mui/icons-material/Delete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";

import S3bucketComponent from "./S3bucketComponent";
import LinearProgressLabel from "./LinearProgressLabel";
import axios, { CancelToken, isCancel } from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const application_code = "ZN001"; // for csv
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const dbtype = [
  {
    value: "CSV",
    label: "CSV",
  },
  {
    value: "SQLite",
    label: "SQLite",
  },
  {
    value: "PostgreSQL",
    label: "PostgreSQL",
  },
  {
    value: "MYSQL",
    label: "MYSQL",
  },
  {
    value: "RedShift",
    label: "RedShift",
  },
  {
    value: "S3 Bucket",
    label: "S3 Bucket",
  },
  {
    value: "Oracle",
    label: "Oracle",
  },
];
const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};
const selectFieldSx = {
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
  },
  "& .MuiMenu-paper": {
    maxHeight: "200px", // Set the maximum height for the dropdown
    maxWidth: "100px",
  },
};
const icon = (
  <span
    style={{
      display: "inline-flex",
      color: "#6001FF",
    }}
  >
    <CheckBoxOutlineBlankIcon fontSize="small" />
  </span>
);

const checkedIcon = (
  <span
    style={{
      display: "inline-flex",
      color: "#6001FF",
    }}
  >
    <CheckBoxIcon fontSize="small" />
  </span>
);

class FileUpload extends Component {
  constructor(props) {
    super(props);
    // Create a ref
    this.cancelFileUpload = React.createRef();
    this.state = {
      selectedFiles: [],
      message: "",
      showAddMoreButton: false,
      dbType: props.addType,
      userName: "",
      password: "",
      hostName: "",
      databasename: "",
      access_user_list: [],
      alreadyExistingFiles: [],
      connecting: false,
      value: 0,
      databaseInfo: null,
      showLoader: false,
      users: ["Loading..."],
      selectedUserNames: [],
      selectedUserEmails: [],
      fileInfo: null,

      columns: [
        { field: "id", headerName: "ID", width: 70 },
        { field: "tablename", headerName: "Table Name", width: 130 },
        {
          field: "rows",
          headerName: "Rows",
          type: "number",
          width: 90,
        },
      ],
      rows: null,
      showsnack: false,
      snackmessage: "",
      severity: "",
      progress: 0, //file uploading tracker
      loaded_1: 0,
      target_1: 0,
    };
  }
  componentDidMount() {
    if (this.props.addType === "MYSQL") {
      // Retrieve databaseInfo from localStorage when the component mounts
      const storedDatabaseInfo = localStorage.getItem("databaseInfo");

      if (storedDatabaseInfo) {
        // Parse the stored JSON string and update the component's state
        this.setState({ databaseInfo: JSON.parse(storedDatabaseInfo) }, () => {
          // Once the state is updated, set the form field values
          const {
            hostName,
            userName,
            password,
            databasename,
            table_count,
            access_user_list,
          } = this.state.databaseInfo;
          const dbType = this.state.databaseInfo["databasetype"];
          this.setState({
            dbType,
            hostName,
            userName,
            password,
            databasename,
            table_count,
            access_user_list,
          });
          this.getUserDetails();
        });
      } else {
        this.getUserDetails();
      }
    } else if (this.props.addType === "CSV") {
      if (this.props.isEditCsv) {
        this.setState(
          {
            fileInfo: this.props.fileDetails,
          },
          () => {
            this.getUserDetails();
          }
        );
      } else {
        this.getUserDetails();
      }
    }
  }

  handleChatClickInUpload = () => {
    this.props.handleChatClick({
      showUpload: false,
    });
  };
  handleSnackbarOpen = (severity, message) => {
    this.setState({
      showsnack: true,
      severity: severity,
      snackmessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({ showsnack: false });
  };

  handleNextClick = () => {
    this.props.handleCloseFileUpload();
    this.setState(() => {
      window.location.reload();
    });
  };

  // To back from upload to tabs
  // handleBackClick = () =>{
  //   this.props.handleTabClick({
  //     showTab:true,
  //     showUpload:false
  //   })
  // }

  handleFileChange = (e) => {
    e.preventDefault();
    const files = Array.from(e.target.files);

    // Check for valid file formats (e.g., allow only CSV files)
    const validFormats = ["csv", "xlsx", "tsv"]; // Add valid file formats here
    const invalidFiles = files.filter((file) => {
      const fileFormat = file.name.split(".").pop().toLowerCase();
      return !validFormats.includes(fileFormat);
    });

    if (invalidFiles.length > 0) {
      this.setState({
        showsnack: true,
        severity: "error",
        snackmessage: `Invalid file format: ${invalidFiles
          .map((file) => file.name)
          .join(", ")}`,
      });
      return;
    }

    this.setState((prevState) => ({
      selectedFiles: [...prevState.selectedFiles, ...files],
      showAddMoreButton: true,
    }));
  };
  handleUpdateFile = async () => {
    try {
      const formData = new FormData();
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");
      const userOrganization = JSON.parse(
        localStorage.getItem("currentLoggedInUserDetails")
      )["organization"];
      formData.append("file_id", this.props.fileDetails.id);
      formData.append(
        "updatedUserAccessList",
        JSON.stringify(this.getSelectedUsers())
      );
      formData.append("updatedByUserEmail", email);
      formData.append("userOrganization", userOrganization);
      const response = await fetch(`${BASE_URL}/admin/zn001/admin_files`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (response.ok) {
        console.log("Updated the file details successfully");
      } else {
        console.log("Failed to update the user details");
      }
    } catch (error) {
      console.log(error);
    } finally {
      // this.setState(()=>{window.location.reload()})
      this.props.fetchCSVData();
      this.props.handleCloseFileUpload();
    }
  };
  handleDeleteFile = (index) => {
    this.setState((prevState) => {
      const newSelectedFiles = [...prevState.selectedFiles];
      newSelectedFiles.splice(index, 1);
      return { selectedFiles: newSelectedFiles };
    });
  };
  showSkeletonVisible = () => {
    this.setState({ showSkeleton: true });
  };
  showSkeletonHide = () => {
    this.setState({ showSkeleton: false });
  };

  hiderows = (event) => {
    this.setState({ rows: null });
  };

  handleFetchTables = async () => {
    const { userName, password, hostName, databasename, dbType } = this.state;
    if (
      userName === "" ||
      password === "" ||
      hostName === "" ||
      databasename === ""
    ) {
      this.setState({
        showsnack: true,
        severity: "error",
        snackmessage: "Fill all the fields",
        // connecting: false, // closing loader
      });
      return;
    }

    this.setState({ connecting: true });
    this.hiderows();
    this.showSkeletonVisible();

    try {
      const formData = new FormData();
      const token = localStorage.getItem("token");

      formData.append("userName", userName);
      formData.append("password", password);
      formData.append("hostName", hostName);
      formData.append("databasename", databasename);
      formData.append("dbType", dbType);

      let endpoint;
      if (dbType === "MYSQL") {
        endpoint = `${BASE_URL}/zn001/user/select_tables`;
      } else if (dbType === "PostgreSQL") {
        endpoint = `${BASE_URL}/zn001/user/select_tables_postgres`;
      } else if (dbType === "Oracle") {
        endpoint = `${BASE_URL}/zn001/user/select_tables_oracle`;
      } else {
        console.error("Unsupported database type");
        return;
      }
      const responseSelectTables = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const resultFetchTables = await responseSelectTables.json();
      if (responseSelectTables.ok) {
        if (resultFetchTables.status) {
          // Handle error response from the server
          this.setState({
            showSkeleton: false,
            showsnack: true,
            severity: "error",
            snackmessage: resultFetchTables.message,
          });
        } else {
          // Handle success response from the server
          const { table_count } = resultFetchTables; // Extract table_count from the response

          this.setState({
            showSkeleton: false,
            showsnack: true,
            severity: "success",
            snackmessage: "Connection successful",
          });
          // Get user emails from selected users to access database
          const selectedUserEmails = this.getSelectedUsers();
          const userOrganization =
            JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
              ?.organization ?? "";
          // Only call the database endpoint if there were no errors during table selection to create
          const responseStoreData = await fetch(
            `${BASE_URL}/admin/zn001/admindatabases`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                userName: userName,
                hostName: hostName,
                password: password,
                databasename: databasename,
                dbType: dbType,
                table_count: table_count, // Include table_count in the request payload
                userAccessList: selectedUserEmails,
                is_active: true,
                organization: userOrganization,
              }),
            }
          );

          const result = await responseStoreData.json();

          if (responseStoreData.ok) {
            if (result.status) {
              // Handle error response from the server

              this.setState({
                showSkeleton: false,
                showsnack: true,
                severity: "error",
                snackmessage: result.message,
              });
            } else {
              // Handle success response from the server
              this.setState({
                showSkeleton: false,
                showsnack: true,
                severity: "success",
                snackmessage: "Connection successful",
              });
              this.props.handleCloseFileUpload();
              this.props.fetchDBData();
            }
          } else if (responseStoreData.status === 401) {
            // Handle a 403 (Forbidden) response
            this.props.navigate("/");
          } else {
            // Handle other error responses from the server
            // const errorData = await responseStoreData.json();
            this.setState({
              showSkeleton: false,
              showsnack: true,
              severity: "error",
              snackmessage: result.detail,
            });
            console.error("DB connect error:", result.detail);
          }
        }
      } else if (responseSelectTables.status === 403) {
        // Handle a 403 (Forbidden) response for table selection
        this.props.navigate("/");
      } else {
        // Handle other error responses from table selection
        const errorData = await responseSelectTables.json();
        this.setState({
          showSkeleton: false,
          showsnack: true,
          severity: "error",
          snackmessage: "Error occurred while selecting tables",
        });
        console.error("Table selection error:", errorData);
      }
    } catch (error) {
      // Handle network errors or unexpected errors
      this.setState({
        message: "Network error occurred",
      });
      console.error("Error:", error);
    } finally {
      // Set the 'connecting' state to false when connection completes (success or failure)
      this.setState({ connecting: false });
      //  this.props.fetchDBData();
    }
  };
  handletable = async () => {
    const { userName, password, hostName, databasename, dbType } = this.state;

    if (
      userName === "" ||
      password === "" ||
      hostName === "" ||
      databasename === ""
    ) {
      this.setState({
        showsnack: true,
        severity: "error",
        snackmessage: "Fill all the fields",
      });
      return;
    }

    this.hiderows();
    this.showSkeletonVisible();

    try {
      const formData = new FormData();
      const token = localStorage.getItem("token");

      formData.append("userName", userName);
      formData.append("password", password);
      formData.append("hostName", hostName);
      formData.append("databasename", databasename);
      formData.append("dbType", dbType);

      let endpoint;
      if (dbType === "MYSQL") {
        endpoint = `${BASE_URL}/zn001/user/select_tables`;
      } else if (dbType === "PostgreSQL") {
        endpoint = `${BASE_URL}/zn001/user/select_tables_postgres`;
      } else if (dbType === "Oracle") {
        endpoint = `${BASE_URL}/zn001/user/select_tables_oracle`;
      } else {
        console.error("Unsupported database type");
        return;
      }
      const responseSelectTables = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (responseSelectTables.ok) {
        const resultFetchTables = await responseSelectTables.json();

        if (resultFetchTables.status) {
          // Handle error response from the server

          this.setState({
            showSkeleton: false,
            showsnack: true,
            severity: "error",
            snackmessage: resultFetchTables.message,
          });
        } else {
          // Handle success response from the server
          const tableCount = resultFetchTables.table_count;

          this.setState({
            showSkeleton: false,
            showsnack: true,
            severity: "success",
            snackmessage: "Connection successful",
            table_count: tableCount, // Update the table_count in your UI state
          });
          this.props.handleCloseFileUpload();
          return tableCount; // Return the table_count value
        }
      } else {
        // Handle non-OK response (e.g., network error)
        this.setState({
          showSkeleton: false,
          showsnack: true,
          severity: "error",
          snackmessage: "Failed to fetch tables",
        });
      }
    } catch (error) {
      // Handle unexpected errors
      this.setState({
        showSkeleton: false,
        showsnack: true,
        severity: "error",
        snackmessage: "An error occurred while fetching tables",
      });
      console.error("Error:", error);
    } finally {
      this.setState({
        connecting: false,
      });
    }
  };
  // handleReceiveMessage = (event) => {
  //   // this.setState({ receivedMessage: event.data });
  //   console.log(event.data);
  // };
  enableLoader = () => {
    this.setState({ showLoader: true });
  };
  disableLoader = () => {
    this.setState({ showLoader: false });
  };

  handleUpload = async (e) => {
    e.preventDefault();
    this.setState({ connecting: true });

    const { selectedFiles, alreadyExistingFiles } = this.state;
    const largeFiles = selectedFiles.filter(
      (file) => file.size > MAX_FILE_SIZE
    );
    const existingFiles = selectedFiles.filter((file) =>
      alreadyExistingFiles.includes(file.name)
    );

    if (largeFiles.length > 0) {
      this.setState({
        showsnack: true,
        snackmessage: "One or more files exceed the size limit of 10MB.",
      });
      return;
    }

    if (existingFiles.length > 0) {
      this.setState({
        showsnack: true,
        severity: "error",
        snackmessage: `The following files already exist: ${existingFiles
          .map((file) => file.name)
          .join(", ")}`,
      });
      return;
    }

    try {
      const formData = new FormData();
      const selectedUserEmails = this.getSelectedUsers();
      selectedFiles.forEach((file) => formData.append("files", file));
      formData.append("selectedEmails", JSON.stringify(selectedUserEmails));
      const userOrganization = JSON.parse(
        localStorage.getItem("currentLoggedInUserDetails")
      )["organization"];
      formData.append("userOrganization", userOrganization);
      formData.append("application_code", application_code);
      const token = localStorage.getItem("token");

      const url = `${BASE_URL}/admin/zn001/admin_csvfileupload`;

      const responseData = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // Add a progress event listener to track upload progress
        onUploadProgress: (data) => {
          let uploadProgress = Math.round((data.loaded * 100) / data.total);

          if (uploadProgress < 100) {
            this.setState({
              progress: uploadProgress,
              loaded_1: data.loaded,
              target_1: data.total,
            });
          }
          // else{
          //   this.setState({progress :uploadProgress,loaded_1:data.loaded,target_1:data.total,connecting:false})
          // }
        },
        cancelToken: new CancelToken(
          (cancel) => (this.cancelFileUpload.current = cancel)
        ),
      });
      // connecting:false
      this.setState({ progress: 100, loaded_1: 100, target_1: 100 });

      if (responseData.statusText) {
        this.setState({
          message: responseData.data.message,
          selectedFiles: [],
          showAddMoreButton: false,
          severity: "success",
          snackmessage: responseData.data.message,
          showsnack: true,
          // Perform further actions after successful file upload
        });
        //console.log('Files uploaded:', responseData.uploaded_files);
      } else {
        this.setState({
          message: responseData.data.detail,
          severity: "error",
          snackmessage: responseData.data.detail,
          showsnack: true,
        });
        console.error("File upload error:", responseData.detail);
      }
    } catch (error) {
      this.setState({
        message: error.response?.data?.detail || error.message,
        severity: "error",
        snackmessage: error.response?.data?.detail || error.message,
        showsnack: true,
        connecting: false,
      });
      if (isCancel(error)) {
        // alert(error.message)
        console.error("Error:", error.message);
      }
      console.error("Error:", error);
    } finally {
      // Set the 'connecting' state to false when connection completes (success or failure)
      this.setState({ connecting: false });
    }
  };
  handleSQliteUpload = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      this.state.selectedFiles.forEach((file) =>
        formData.append("files", file)
      );

      const response = await fetch(`${BASE_URL}/sqliteupload`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        this.setState({
          message: data.message,
          selectedFiles: [],
          showAddMoreButton: false,
        });
        // Perform further actions after successful file upload
      } else {
        const errorData = await response.json();
        this.setState({
          message: errorData.detail,
        });
        console.error("File upload error:", errorData);
      }
    } catch (error) {
      this.setState({
        message: "Network error occurred",
      });
      console.error("Error:", error);
    }
  };
  handleUpdateDatabase = async () => {
    const { dbType, hostName, userName, password, databasename, databaseInfo } =
      this.state;
    this.setState({ connecting: true });
    const selectedUserEmails = this.getSelectedUsers();
    // Check if the form is dirty (changes have been made)
    const isFormDirty =
      dbType !== databaseInfo.databasetype ||
      hostName !== databaseInfo.hostName ||
      userName !== databaseInfo.userName ||
      password !== databaseInfo.password ||
      databasename !== databaseInfo.databasename ||
      JSON.stringify(selectedUserEmails) !==
        JSON.stringify(databaseInfo.userAccessList);
    try {
      // Perform the update operation only if the form is dirty
      let tableCount;
      if (isFormDirty) {
        // Update the database information including the ID
        tableCount = await this.handletable();
      } else {
        this.setState({
          showsnack: true,
          severity: "info",
          snackmessage: "Database details are same. Checking for new tables",
        });
        tableCount = await this.handletable();
      }
      if (typeof tableCount === "undefined") {
        // Exit the function
        return;
      }

      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");
      const userOrganization =
        JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
          ?.organization ?? "";
      const response = await fetch(
        `${BASE_URL}/admin/zn001/admindatabases/${
          databaseInfo.id
        }?updatedByUserEmail=${encodeURIComponent(email)}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: databaseInfo.id, // Include the ID here
            dbType,
            hostName,
            userName,
            password,
            databasename,
            table_count: tableCount, // Use the updated table_count value obtained from handletable
            userAccessList: selectedUserEmails,
            organization: userOrganization,
          }),
        }
      );

      if (response.ok) {
        // Handle success
        const result = await response.json();
        this.setState({
          showsnack: true,
          severity: "success",
          snackmessage: result.message,
        });
        this.props.handleCloseFileUpload();
        this.props.fetchDBData();
      } else {
        // Handle error response
        const errorData = await response.json();
        this.setState({
          showsnack: true,
          severity: "error",
          snackmessage: errorData.detail,
        });
      }

      // Rest of your code
    } catch (error) {
      // Handle network errors or unexpected errors
      this.setState({
        showsnack: true,
        severity: "error",
        snackmessage: "An error occurred while updating the database.",
      });
      console.error("Error:", error);
    } finally {
      // Set the 'connecting' state to false when connection completes (success or failure)
      this.setState({ connecting: false });
    }
  };

  handleAddMoreFiles = () => {
    this.fileInputRef.value = null;
    this.fileInputRef.click();
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getUserDetails = () => {
    try {
      const addType = this.props.addType;
      const userDetails = this.props.userDetails;
      let access_user_list = [];
      // const access_user_list = this.state.databaseInfo ? this.state.databaseInfo.access_user_list : []
      if (addType === "MYSQL") {
        access_user_list = this.state.databaseInfo
          ? this.state.databaseInfo.userAccessList
          : [];
      } else if (addType === "CSV") {
        access_user_list = this.state.fileInfo
          ? this.state.fileInfo.userAccessList
          : [];
      }
      const users = userDetails.map((item) => {
        const username = item.username;
        const email = item.email;
        const accessible = access_user_list.includes(email);
        return { username: username, email: email, accessible: accessible };
      });
      const initiallyAccessibleUsers = users.filter((user) => user.accessible);

      this.setState({
        users: users,
        selectedUserNames: initiallyAccessibleUsers.map(
          (user) => user.username
        ),
        selectedUserEmails: initiallyAccessibleUsers.map((user) => user.email),
      });

      return users;
    } catch (error) {
      console.log(error);
    }
  };
  handleCheckBoxChange = (username) => {
    const { selectedUserNames, users } = this.state;
    const updatedUsers = [...users]; // Create a copy of the users array

    // Find the user object in the updatedUsers array by username
    const user = updatedUsers.find((user) => user.username === username);

    if (user) {
      // Toggle the 'accessible' property of the user based on whether it was selected or deselected
      user.accessible = !selectedUserNames.includes(username);
    }
    const index = selectedUserNames.indexOf(username);

    if (index !== -1) {
      // Remove the user from the selected Users list
      selectedUserNames.splice(index, 1);
    } else {
      // Add the user to the selected Users list
      selectedUserNames.push(username);
    }
    // Update the selectedUserNames in the state
    this.setState({ selectedUserNames });

    // Update the users in the state
    this.setState({ users: updatedUsers });
  };
  getSelectedUsers = () => {
    const selectedUserEmails = this.state.users
      .filter((user) => this.state.selectedUserNames.includes(user.username))
      .map((user) => user.email);
    return selectedUserEmails;
  };
  cancelUpload = () => {
    if (this.cancelFileUpload.current) {
      this.cancelFileUpload.current("User has cancelled the file upload");
    }
  };
  render() {
    const {
      databaseInfo,
      connecting,
      selectedFiles,
      message,
      showAddMoreButton,
      dbType,
      userName,
      password,
      hostName,
      databasename,
      rows,
      showsnack,
      severity,
      snackmessage,
      progress,
    } = this.state;
    const hasDatabaseInfo = databaseInfo !== null;
    const { users } = this.state;
    return (
      <Container>
        <Paper
          elevation={0}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "20px",
            backgroundColor: "#FFFFFF",
            height: "fit-content",
            padding: "2rem",
            width: "31%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
              flexDirection: "column",
            }}
          >
            <div style={{ width: "100%" }}>
              {!connecting && (
                <div>
                  {!this.props.isEditDatabase && !this.props.isEditCsv && (
                    <TextField
                      id="dbType"
                      select
                      label="Database Type"
                      name="dbType"
                      value={dbType}
                      onChange={this.handleInputChange}
                      defaultValue="SQLite"
                      helperText="Please select your Database Type"
                      style={{ width: "100%" }}
                      sx={selectFieldSx}
                      SelectProps={{
                        menuprops: {
                          style: { maxHeight: "12.5rem" },
                        },
                      }}
                    >
                      {dbtype.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>
              )}
            </div>
            {dbType === "CSV" &&
              (!this.props.isEditCsv ? (
                <div style={{ width: "30rem" }}>
                  <h2>{!connecting ? "File Upload" : "Uploading Files"}</h2>
                  <form onSubmit={this.handleUpload}>
                    <input
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      onChange={this.handleFileChange}
                      ref={(ref) => (this.fileInputRef = ref)}
                    />
                    {selectedFiles.length >= 0 && !connecting && (
                      <Button onClick={this.handleAddMoreFiles}>
                        {showAddMoreButton ? "Add More Files" : "Add File"}
                      </Button>
                    )}
                    &nbsp;&nbsp;
                    {connecting && (
                      <div style={{ margin: "5px" }}>
                        {" "}
                        <LinearProgressLabel value={progress} />
                      </div>
                    )}
                    {connecting && (
                      <div>
                        {/* <p>{message}</p> onClick={this.props.handleCloseFileUpload} */}
                        <Button
                          variant="contained"
                          color="success"
                          onClick={this.cancelUpload}
                          style={{
                            backgroundColor: "#6001FF",
                            color: "#ffffff",
                            marginRight: "10%",
                            marginTop: "5%",
                            textTransform: "none",
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                    {!connecting && (
                      <div>
                        {" "}
                        <h3>Selected Files:</h3>
                        {selectedFiles.length > 4 ? (
                          <List>
                            <div style={{ height: "260px", overflow: "auto" }}>
                              {selectedFiles.map((file, index) => (
                                <ListItem key={index} disablePadding>
                                  <ListItemButton>
                                    <ListItemIcon>
                                      <InsertDriveFileOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={file.name} />
                                    <ListItemIcon
                                      onClick={() =>
                                        this.handleDeleteFile(index)
                                      }
                                    >
                                      <DeleteIcon sx={{ color: "#6001FF" }} />
                                    </ListItemIcon>
                                  </ListItemButton>
                                </ListItem>
                              ))}
                            </div>
                          </List>
                        ) : (
                          <List>
                            {selectedFiles.map((file, index) => (
                              <ListItem key={index} disablePadding>
                                <ListItemButton>
                                  <ListItemIcon>
                                    <InsertDriveFileOutlinedIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={file.name} />
                                  <ListItemIcon
                                    onClick={() => this.handleDeleteFile(index)}
                                  >
                                    <DeleteIcon sx={{ color: "#6001FF" }} />
                                  </ListItemIcon>
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        )}
                        <div
                          style={{ marginBottom: "10px", marginTop: "10px" }}
                        >
                          <Autocomplete
                            multiple
                            style={{ width: "100%" }}
                            sx={selectFieldSx}
                            options={users}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.username}
                            value={this.state.selectedUserNames.map((name) =>
                              users.find((user) => user.username === name)
                            )}
                            renderOption={(props, option, { selected }) => {
                              return (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                    onChange={() =>
                                      this.handleCheckBoxChange(option.username)
                                    }
                                    onDelete={() =>
                                      this.handleCheckBoxChange(option.username)
                                    }
                                  />
                                  {option.username}
                                </li>
                              );
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  label={option.username}
                                  {...getTagProps({ index })}
                                  style={{
                                    background: "#6001FF",
                                    color: "white",
                                    marginRight: 8,
                                  }}
                                  deleteIcon={
                                    <CancelIcon style={{ color: "white" }} />
                                  }
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Users"
                                placeholder="Users"
                              />
                            )}
                            onChange={(event, newValue) => {
                              // Handle the change in Autocomplete value
                              // This can include any logic needed to update your component state
                              this.setState({
                                selectedUserNames: newValue.map(
                                  (user) => user.username
                                ),
                              });
                            }}
                            PopperComponent={(props) => (
                              <Popper {...props} placement="bottom-start" />
                            )}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {!message && (
                            <React.Fragment>
                              {/* <p>{message}</p> */}
                              <Typography
                                variant="p"
                                style={{
                                  color: "#6001ff",
                                  textTransform: "none",
                                  cursor: "pointer",
                                  alignContent: "center",
                                }}
                                onClick={this.props.handleCloseFileUpload}
                              >
                                Cancel
                              </Typography>
                            </React.Fragment>
                          )}
                          <Button
                            variant="contained"
                            color="error"
                            onClick={this.handleUpload}
                            disabled={selectedFiles.length === 0}
                            style={{
                              backgroundColor:
                                selectedFiles.length === 0
                                  ? "initial"
                                  : "#6001FF",
                              color:
                                selectedFiles.length === 0
                                  ? "#6001FF"
                                  : "#ffffff",
                              marginLeft: "5%",
                              textTransform: "none",
                            }}
                          >
                            {connecting ? (
                              <CircularProgress
                                size={25}
                                sx={{
                                  color: "#FFFFFF",
                                }}
                              />
                            ) : (
                              "Upload Files"
                            )}
                          </Button>
                        </div>
                      </div>
                    )}
                    {message && (
                      <React.Fragment>
                        <p>{message}</p>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => {
                            this.props.fetchCSVData();
                            this.props.handleCloseFileUpload();
                          }}
                          style={{
                            backgroundColor: "#6001FF",
                            color: "#ffffff",
                            textTransform: "none",
                          }}
                        >
                          Next
                        </Button>
                      </React.Fragment>
                    )}
                  </form>
                </div>
              ) : (
                <div style={{ width: "30rem" }}>
                  <h2>Edit File</h2>
                  <List>
                    <ListItemText primary={this.props.fileDetails.filename} />
                  </List>
                  <form>
                    <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                      <Autocomplete
                        multiple
                        style={{ width: "100%" }}
                        sx={selectFieldSx}
                        options={users}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.username}
                        value={this.state.selectedUserNames.map((name) =>
                          users.find((user) => user.username === name)
                        )}
                        renderOption={(props, option, { selected }) => {
                          return (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                onChange={() =>
                                  this.handleCheckBoxChange(option.username)
                                }
                                onDelete={() =>
                                  this.handleCheckBoxChange(option.username)
                                }
                              />
                              {option.username}
                            </li>
                          );
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              label={option.username}
                              {...getTagProps({ index })}
                              style={{
                                background: "#6001FF",
                                color: "white",
                                marginRight: 8,
                              }}
                              deleteIcon={
                                <CancelIcon style={{ color: "white" }} />
                              }
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Users"
                            placeholder="Users"
                          />
                        )}
                        onChange={(event, newValue) => {
                          // Handle the change in Autocomplete value
                          // This can include any logic needed to update your component state
                          this.setState({
                            selectedUserNames: newValue.map(
                              (user) => user.username
                            ),
                          });
                        }}
                        PopperComponent={(props) => (
                          <Popper {...props} placement="bottom-start" />
                        )}
                      />
                    </div>
                    <Box
                      style={{
                        display: "flex",
                        gap: "1.25rem",
                        width: "50%",
                        marginLeft: "58%",
                      }}
                    >
                      {/* <p>{message}</p> */}
                      <Typography
                        variant="p"
                        style={{
                          color: "#6001ff",
                          textTransform: "none",
                          cursor: "pointer",
                          alignContent: "center",
                        }}
                        onClick={this.props.handleCloseFileUpload}
                      >
                        Cancel
                      </Typography>

                      <Button
                        style={{
                          backgroundColor: "#6001ff",
                          color: "#ffffff",
                          textTransform: "none",
                        }}
                        variant="contained"
                        onClick={this.handleUpdateFile}
                      >
                        Apply Changes
                      </Button>
                    </Box>
                  </form>
                </div>
              ))}

            {dbType === "MYSQL" && (
              // <form onSubmit={this.handledbconnect}>
              <form>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <div>
                    <TextField
                      id="hostName"
                      label="Host Name"
                      name="hostName"
                      value={hostName}
                      onChange={this.handleInputChange}
                      style={{ width: "100%" }}
                      sx={textFieldSx}
                      required
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      id="userName"
                      label="User Name"
                      name="userName"
                      value={userName}
                      onChange={this.handleInputChange}
                      style={{ width: "50%" }}
                      sx={textFieldSx}
                      required
                    />{" "}
                    &nbsp;&nbsp;
                    <TextField
                      id="password"
                      label="Password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={this.handleInputChange}
                      style={{ width: "50%" }}
                      sx={textFieldSx}
                      required
                    />
                  </div>
                  <div>
                    <TextField
                      id="databasename"
                      label="databasename "
                      name="databasename"
                      value={databasename}
                      onChange={this.handleInputChange}
                      style={{ width: "100%" }}
                      sx={textFieldSx}
                      required
                    />
                  </div>
                  <div>
                    <Autocomplete
                      multiple
                      style={{ width: "100%" }}
                      sx={selectFieldSx}
                      options={users}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.username}
                      value={this.state.selectedUserNames.map((name) =>
                        users.find((user) => user.username === name)
                      )}
                      renderOption={(props, option, { selected }) => {
                        return (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                              onChange={() =>
                                this.handleCheckBoxChange(option.username)
                              }
                              onDelete={() =>
                                this.handleCheckBoxChange(option.username)
                              }
                            />
                            {option.username}
                          </li>
                        );
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option.username}
                            {...getTagProps({ index })}
                            style={{
                              background: "#6001FF",
                              color: "white",
                              marginRight: 8,
                            }}
                            deleteIcon={
                              <CancelIcon style={{ color: "white" }} />
                            }
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Users"
                          placeholder="Users"
                        />
                      )}
                      onChange={(event, newValue) => {
                        // Handle the change in Autocomplete value
                        // This can include any logic needed to update your component state
                        this.setState({
                          selectedUserNames: newValue.map(
                            (user) => user.username
                          ),
                        });
                      }}
                    />
                  </div>
                </div>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop="1.25rem"
                >
                  {/* Conditionally render CircularProgress based on the 'connecting' state */}
                  {hasDatabaseInfo ? (
                    <div>
                      {connecting ? (
                        <CircularProgress
                          size={25}
                          style={{ color: "#6001ff", marginLeft: "58%" }}
                        />
                      ) : (
                        <Box
                          style={{
                            display: "flex",
                            gap: "1.25rem",
                            width: "100%",
                            marginLeft: "58%",
                          }}
                        >
                          <Typography
                            variant="p"
                            style={{
                              color: "#6001ff",
                              textTransform: "none",
                              cursor: "pointer",
                              alignContent: "center",
                            }}
                            onClick={this.props.handleCloseFileUpload}
                          >
                            Cancel
                          </Typography>
                          <Button
                            style={{
                              backgroundColor: "#6001ff",
                              color: "#ffffff",
                              textTransform: "none",
                            }}
                            variant="contained"
                            onClick={this.handleUpdateDatabase}
                          >
                            Apply Changes
                          </Button>
                        </Box>
                      )}
                    </div>
                  ) : (
                    <div>
                      {connecting ? (
                        <CircularProgress
                          size={25}
                          style={{ color: "#6001ff", marginLeft: "109%" }}
                        />
                      ) : (
                        <Box
                          style={{
                            display: "flex",
                            gap: "1.25rem",
                            width: "100%",
                            marginLeft: "109%",
                          }}
                        >
                          <Typography
                            variant="p"
                            style={{
                              color: "#6001ff",
                              textTransform: "none",
                              alignContent: "center",
                              cursor: "pointer",
                            }}
                            onClick={this.props.handleCloseFileUpload}
                          >
                            Cancel
                          </Typography>

                          <Button
                            style={{
                              backgroundColor: "#6001ff",
                              textTransform: "none",
                            }}
                            variant="contained"
                            onClick={this.handleFetchTables}
                          >
                            Save
                          </Button>
                        </Box>
                      )}
                    </div>
                  )}
                </Box>
              </form>
            )}
            {dbType === "PostgreSQL" && (
              // <form onSubmit={this.handledbconnect}>
              <form>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <div>
                    <TextField
                      id="hostName"
                      label="Host Name"
                      name="hostName"
                      value={hostName}
                      onChange={this.handleInputChange}
                      style={{ width: "100%" }}
                      sx={textFieldSx}
                      required
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      id="userName"
                      label="User Name"
                      name="userName"
                      value={userName}
                      onChange={this.handleInputChange}
                      style={{ width: "50%" }}
                      sx={textFieldSx}
                      required
                    />{" "}
                    &nbsp;&nbsp;
                    <TextField
                      id="password"
                      label="Password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={this.handleInputChange}
                      style={{ width: "50%" }}
                      sx={textFieldSx}
                      required
                    />
                  </div>
                  <div>
                    <TextField
                      id="databasename"
                      label="databasename "
                      name="databasename"
                      value={databasename}
                      onChange={this.handleInputChange}
                      style={{ width: "100%" }}
                      sx={textFieldSx}
                      required
                    />
                  </div>
                  <div>
                    <Autocomplete
                      multiple
                      style={{ width: "100%" }}
                      sx={selectFieldSx}
                      options={users}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.username}
                      value={this.state.selectedUserNames.map((name) =>
                        users.find((user) => user.username === name)
                      )}
                      renderOption={(props, option, { selected }) => {
                        return (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                              onChange={() =>
                                this.handleCheckBoxChange(option.username)
                              }
                              onDelete={() =>
                                this.handleCheckBoxChange(option.username)
                              }
                            />
                            {option.username}
                          </li>
                        );
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option.username}
                            {...getTagProps({ index })}
                            style={{
                              background: "#6001FF",
                              color: "white",
                              marginRight: 8,
                            }}
                            deleteIcon={
                              <CancelIcon style={{ color: "white" }} />
                            }
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Users"
                          placeholder="Users"
                        />
                      )}
                      onChange={(event, newValue) => {
                        // Handle the change in Autocomplete value
                        // This can include any logic needed to update your component state
                        this.setState({
                          selectedUserNames: newValue.map(
                            (user) => user.username
                          ),
                        });
                      }}
                      PopperComponent={(props) => (
                        <Popper {...props} placement="bottom-start" />
                      )}
                    />
                  </div>
                </div>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop="1.25rem"
                >
                  {/* Conditionally render CircularProgress based on the 'connecting' state */}
                  {hasDatabaseInfo ? (
                    <div>
                      {connecting ? (
                        <CircularProgress
                          size={25}
                          style={{ color: "#6001ff", marginLeft: "57%" }}
                        />
                      ) : (
                        <Box
                          style={{
                            display: "flex",
                            gap: "1.25rem",
                            width: "100%",
                            marginLeft: "57%",
                          }}
                        >
                          <Typography
                            variant="p"
                            style={{
                              color: "#6001ff",
                              textTransform: "none",
                              cursor: "pointer",
                              alignContent: "center",
                            }}
                            onClick={this.props.handleCloseFileUpload}
                          >
                            Cancel
                          </Typography>
                          <Button
                            style={{
                              backgroundColor: "#6001ff",
                              color: "#ffffff",
                              textTransform: "none",
                            }}
                            variant="contained"
                            onClick={this.handleUpdateDatabase}
                          >
                            Apply Changes
                          </Button>
                        </Box>
                      )}
                    </div>
                  ) : (
                    <div>
                      {connecting ? (
                        <CircularProgress
                          size={25}
                          style={{ color: "#6001ff", marginLeft: "109%" }}
                        />
                      ) : (
                        <Box
                          style={{
                            display: "flex",
                            gap: "1.25rem",
                            width: "100%",
                            marginLeft: "109%",
                          }}
                        >
                          <Typography
                            variant="p"
                            style={{
                              color: "#6001ff",
                              textTransform: "none",
                              alignContent: "center",
                              cursor: "pointer",
                            }}
                            onClick={this.props.handleCloseFileUpload}
                          >
                            Cancel
                          </Typography>

                          <Button
                            style={{
                              backgroundColor: "#6001ff",
                              textTransform: "none",
                            }}
                            variant="contained"
                            onClick={this.handleFetchTables}
                          >
                            Save
                          </Button>
                        </Box>
                      )}
                    </div>
                  )}
                </Box>
              </form>
            )}
            {dbType === "SQLite" && (
              <div>
                <h2>File Upload</h2>
                <form onSubmit={this.handleSQliteUpload}>
                  <input
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    onChange={this.handleFileChange}
                    ref={(ref) => (this.fileInputRef = ref)}
                  />
                  {selectedFiles.length >= 0 && (
                    <Button onClick={this.handleAddMoreFiles}>
                      {showAddMoreButton ? "Add More Files" : "Add File"}
                    </Button>
                  )}
                  &nbsp;&nbsp;
                  <Button
                    variant="contained"
                    color="error"
                    style={{ textTransform: "none" }}
                    onClick={this.handleSQliteUpload}
                    disabled={selectedFiles.length === 0}
                  >
                    Upload Files
                  </Button>
                </form>

                <h3>Selected Files:</h3>
                <List>
                  {selectedFiles.map((file, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <InsertDriveFileOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={file.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>

                {message && (
                  <div>
                    <p>{message}</p>
                    <Button
                      variant="contained"
                      color="success"
                      style={{ textTransform: "none" }}
                      onClick={this.handleChatClickInUpload}
                    >
                      Next
                    </Button>
                  </div>
                )}
              </div>
            )}

            {dbType === "S3 Bucket" && (
              <S3bucketComponent
                dbType={dbType}
                users={users}
                handleCloseFileUpload={this.props.handleCloseFileUpload}
                handleSnackbarOpen={this.handleSnackbarOpen}
                handleSnackbarClose={this.handleSnackbarClose}
                fetchCSVData={this.props.fetchCSVData}
              />
            )}

            {dbType === "Oracle" && (
              // <form onSubmit={this.handledbconnect}>
              <form>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <div>
                    <TextField
                      id="hostName"
                      label="Host Name"
                      name="hostName"
                      value={hostName}
                      onChange={this.handleInputChange}
                      style={{ width: "100%" }}
                      sx={textFieldSx}
                      required
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      id="userName"
                      label="User Name"
                      name="userName"
                      value={userName}
                      onChange={this.handleInputChange}
                      style={{ width: "50%" }}
                      sx={textFieldSx}
                      required
                    />{" "}
                    &nbsp;&nbsp;
                    <TextField
                      id="password"
                      label="Password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={this.handleInputChange}
                      style={{ width: "50%" }}
                      sx={textFieldSx}
                      required
                    />
                  </div>
                  <div>
                    <TextField
                      id="databasename"
                      label="databasename "
                      name="databasename"
                      value={databasename}
                      onChange={this.handleInputChange}
                      style={{ width: "100%" }}
                      sx={textFieldSx}
                      placeholder="Example Oracle PDB's (XE,XEPDB1)"
                      required
                    />
                  </div>
                  <div>
                    <Autocomplete
                      multiple
                      style={{ width: "100%" }}
                      sx={selectFieldSx}
                      options={users}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.username}
                      value={this.state.selectedUserNames.map((name) =>
                        users.find((user) => user.username === name)
                      )}
                      renderOption={(props, option, { selected }) => {
                        return (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                              onChange={() =>
                                this.handleCheckBoxChange(option.username)
                              }
                              onDelete={() =>
                                this.handleCheckBoxChange(option.username)
                              }
                            />
                            {option.username}
                          </li>
                        );
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option.username}
                            {...getTagProps({ index })}
                            style={{
                              background: "#6001FF",
                              color: "white",
                              marginRight: 8,
                            }}
                            deleteIcon={
                              <CancelIcon style={{ color: "white" }} />
                            }
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Users"
                          placeholder="Users"
                        />
                      )}
                      onChange={(event, newValue) => {
                        // Handle the change in Autocomplete value
                        // This can include any logic needed to update your component state
                        this.setState({
                          selectedUserNames: newValue.map(
                            (user) => user.username
                          ),
                        });
                      }}
                      PopperComponent={(props) => (
                        <Popper {...props} placement="bottom-start" />
                      )}
                    />
                  </div>
                </div>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop="1.25rem"
                >
                  {/* Conditionally render CircularProgress based on the 'connecting' state */}
                  {hasDatabaseInfo ? (
                    <div>
                      {connecting ? (
                        <CircularProgress
                          size={25}
                          style={{ color: "#6001ff", marginLeft: "57%" }}
                        />
                      ) : (
                        <Box
                          style={{
                            display: "flex",
                            gap: "1.25rem",
                            width: "100%",
                            marginLeft: "57%",
                          }}
                        >
                          <Typography
                            variant="p"
                            style={{
                              color: "#6001ff",
                              textTransform: "none",
                              cursor: "pointer",
                              alignContent: "center",
                            }}
                            onClick={this.props.handleCloseFileUpload}
                          >
                            Cancel
                          </Typography>
                          <Button
                            style={{
                              backgroundColor: "#6001ff",
                              color: "#ffffff",
                              textTransform: "none",
                            }}
                            variant="contained"
                            onClick={this.handleUpdateDatabase}
                          >
                            Apply Changes
                          </Button>
                        </Box>
                      )}
                    </div>
                  ) : (
                    <div>
                      {connecting ? (
                        <CircularProgress
                          size={25}
                          style={{ color: "#6001ff", marginLeft: "109%" }}
                        />
                      ) : (
                        <Box
                          style={{
                            display: "flex",
                            gap: "1.25rem",
                            width: "100%",
                            marginLeft: "109%",
                          }}
                        >
                          <Typography
                            variant="p"
                            style={{
                              color: "#6001ff",
                              textTransform: "none",
                              alignContent: "center",
                              cursor: "pointer",
                            }}
                            onClick={this.props.handleCloseFileUpload}
                          >
                            Cancel
                          </Typography>

                          <Button
                            style={{
                              backgroundColor: "#6001ff",
                              textTransform: "none",
                            }}
                            variant="contained"
                            onClick={this.handleFetchTables}
                          >
                            Save
                          </Button>
                        </Box>
                      )}
                    </div>
                  )}
                </Box>
              </form>
            )}
          </div>
          {/* <div >
            
          </div> */}
          {/* <Select></Select> */}
        </Paper>
        <br />
        <br />
        {rows !== null ? (
          <div>
            <div style={{ height: 400, width: "100%" }}>
              <TableComponent
                data={rows}
                username={userName}
                password={password}
                host={hostName}
                databasename={databasename}
                handleChatClick={this.handleChatClickInUpload}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <Snackbar
          open={showsnack}
          autoHideDuration={6000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            this.setState({ showsnack: false });
          }}
        >
          <Alert
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }

              this.setState({ showsnack: false });
            }}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {snackmessage}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export default withRouter(FileUpload);
