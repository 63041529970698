import React, { Component } from "react";
import { CardContent, Grid } from "@mui/material";
import CardsLogs from "./Cards_logs"; // Corrected the component name
import { Paper, Typography, Box, Tooltip, TableContainer } from "@mui/material";
import Menu from "./Menu";
import User from "./Useract";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress for the loader
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import LogsDropDown from "./LogsDropDown";
import LogsSearch from "./LogsSearch";
import LogsDateRange from "./LogsDateRange";
import { withRouter } from "./withRouter";
import Utils from "../userComponents/Utils";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
const BASE_URL = process.env.REACT_APP_BASE_URL;

// Custom styling using sx prop
const customStyles = {
  "& .header-bold": {
    fontWeight: "bold",
    margin: "0 10px",
    padding: "5px 0",
  },
  "& .cell-padding": {
    margin: "0 10px",
    padding: "5px 0",
  },
};

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 70,
    sortable: true,
    disableColumnMenu: true,
    type: "number",
  },
  {
    field: "query",
    headerName: "Query",
    width: 200,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "application",
    headerName: "Application",
    width: 200,
    sortable: false,
    disableColumnMenu: true,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "response",
    headerName: "Response",
    width: 200,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "token_consumed",
    headerName: "Tokens Consumed",
    align: "left",
    width: 142,
    sortable: true,
    disableColumnMenu: true,
    type: "number",
    renderCell: (params) => (
      <Box style={{ paddingLeft: "20px" }}>{params.value}</Box>
    ),
  },
  {
    field: "username",
    headerName: "Username",
    headerAlign: "center",
    align: "center",
    width: 190,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "department",
    headerName: "Department",
    width: 175,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "date",
    headerName: "Date",
    width: 180,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <Box style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
        {params.value}
      </Box>
    ),
  },
];
function CustomFooter() {
  return <GridFooterContainer />;
}
class Logs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      data: [],
      csv: [],
      userIdCounter: 1,
      currentPagelogs: 1,
      itemsPerPagelogs: 50,
      selected: "Query",
      startDate: null,
      endDate: null,
      cummulative_token_usage: 0,
      order: "desc",
      orderBy: "id",
      totalLogs: 0,
      isLoading: true,
      paginationModel: {
        page: 0,
        pageSize: 25,
      },
    };

    // Bind the handlers to this
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
  }

  componentDidMount() {
    const { paginationModel } = this.state;
    this.fetchLogs(paginationModel.page + 1, paginationModel.pageSize); // Fetch first page data
  }

  fetchLogs = async (
    page,
    pageSize,
    searchQuery = this.state.searchQuery,
    selected = this.state.selected,
    startDate = this.state.startDate,
    endDate = this.state.endDate,
    sortField = this.state.orderBy,
    sortOrder = this.state.order
  ) => {
    this.setState({ isLoading: true });
    const token = localStorage.getItem("token");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";

    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
      return;
    }
    try {
      let url = `${BASE_URL}/admin/query_logs/${userOrganization}?page=${page}&page_size=${pageSize}&sort_field=${sortField}&sort_order=${sortOrder}`;
      if (searchQuery) {
        url += `&search_filter=${selected}&search_query=${searchQuery}`;
      }
      if (startDate) {
        url += `&start_date=${startDate}`;
      }
      if (endDate) {
        url += `&end_date=${endDate}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
        return;
      }

      if (response.ok) {
        const data = await response.json();

        this.setState({
          data: data["query_logs"],
          isLoading: false,
          totalLogs: data["total_logs"],
        });
      } else {
        console.error("Failed to fetch data:", response.statusText);
        this.setState({ isLoading: false });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.error("An error occurred while fetching data:", error);
    }
  };

  reset = () => {
    window.location.reload();
  };

  handleSearchQuery = (value) => {
    this.setState({ searchQuery: value, data: [] }, () => {
      this.fetchLogs(
        1,
        this.state.paginationModel.pageSize,
        value,
        this.state.selected,
        this.state.startDate,
        this.state.endDate
      );
    });
  };

  handleDropDown = (value) => {
    this.setState({ selected: value, data: [] }, () => {
      this.fetchLogs(
        1,
        this.state.paginationModel.pageSize,
        this.state.searchQuery,
        value,
        this.state.startDate,
        this.state.endDate
      );
    });
  };

  handleStartDate = (value) => {
    this.setState({ startDate: value, data: [] }, () => {
      this.fetchLogs(
        1,
        this.state.paginationModel.pageSize,
        this.state.searchQuery,
        this.state.selected,
        value,
        this.state.endDate
      );
    });
  };

  handleEndDate = (value) => {
    this.setState({ endDate: value, data: [] }, () => {
      this.fetchLogs(
        1,
        this.state.paginationModel.pageSize,
        this.state.searchQuery,
        this.state.selected,
        this.state.startDate,
        value
      );
    });
  };

  handleSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0];
      this.setState(
        {
          order: sort,
          orderBy: field,
          data: [],
        },
        () => {
          this.fetchLogs(
            this.state.paginationModel.page + 1,
            this.state.paginationModel.pageSize,
            this.state.searchQuery,
            this.state.selected,
            this.state.startDate,
            this.state.endDate,
            field,
            sort
          );
        }
      );
    }
  };

  handlePageChange = (newPage) => {
    this.setState(
      (prevState) => ({
        paginationModel: { ...prevState.paginationModel, page: newPage },
      }),
      () => {
        this.fetchLogs(
          newPage + 1,
          this.state.paginationModel.pageSize,
          this.state.searchQuery,
          this.state.selected,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  handlePageSizeChange = (newPageSize) => {
    this.setState(
      (prevState) => ({
        paginationModel: {
          ...prevState.paginationModel,
          pageSize: newPageSize,
          page: 0,
        },
      }),
      () => {
        this.fetchLogs(
          1,
          newPageSize,
          this.state.searchQuery,
          this.state.selected,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };
  render() {
    let {
      searchQuery,
      selected,
      startDate,
      endDate,
      order,
      orderBy,
      data,
      totalLogs,
      isLoading,
      paginationModel,
    } = this.state;

    if ((startDate && endDate) || searchQuery) {
      if (startDate && endDate) {
        startDate = new Date(startDate).setHours(0, 0, 0, 0);
        endDate = new Date(endDate).setHours(23, 59, 59, 999);

        data = data.filter(
          (item) =>
            new Date(item["date"]) >= startDate &&
            new Date(item["date"]) <= endDate
        );
      }
      if (searchQuery) {
        data = data.filter(
          (item) =>
            item[selected.toLowerCase()] &&
            item[selected.toLowerCase()]
              .toLowerCase()
              .includes(searchQuery.trim().toLowerCase())
        );
      }
    }

    return (
      <Box style={{ margin: "2%" }}>
        <Box
          style={{ position: "absolute", top: 0, right: "3%", margin: "1rem" }}
        >
          <User />
        </Box>
        <Menu selectedButton="Query Logs" />
        <Typography
          variant="h1"
          component="div"
          sx={{
            flexGrow: 1,
            color: "#6001FF",
            fontSize: "2rem",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 600,
            display: "flex",
            justifyContent: "center",
          }}
        >
          Admin Logs Monitoring
        </Typography>
        <Box style={{ margin: "3%" }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <CardsLogs />
            </Grid>
            <Grid item xs={12} md={12}>
              <Paper elevation={2}>
                <TableContainer>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      minHeight: "95px",
                    }}
                  >
                    <Box
                      style={{
                        marginLeft: "10px",
                        minHeight: "95px",
                        alignItems: "center",
                        width: "410px",
                      }}
                    >
                      <LogsDateRange
                        handleStartDate={this.handleStartDate}
                        handleEndDate={this.handleEndDate}
                      />
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        marginLeft: "430px",
                        minHeight: "95px",
                      }}
                    >
                      <LogsDropDown
                        selected={selected}
                        handleDropDown={this.handleDropDown}
                      />

                      <LogsSearch
                        searchQuery={searchQuery}
                        handleSearchQuery={this.handleSearchQuery}
                      />

                      <Tooltip
                        title="Refresh Logs"
                        sx={{ color: "#000", pt: 1, ml: 2, mb: 1 }}
                      >
                        <RestartAltOutlinedIcon
                          sx={{
                            color: "#6001FF",
                            width: "45px",
                            height: "35px",
                            "&:hover": {
                              backgroundColor: "#EFEFEF",
                              borderRadius: "50%",
                            },
                          }}
                          onClick={this.reset}
                        />
                      </Tooltip>
                      {/* <Tooltip title="Sort">
                          <IconButton sx={{ mr: 5 }}>
                            <FilterListRoundedIcon
                              fontSize="large"
                              sx={{ color: "#6001FF" }}
                            />
                          </IconButton>
                        </Tooltip> */}
                    </Box>
                  </Box>
                  {isLoading ? (
                    <CardContent
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        borderRadius: "5px",
                      }}
                    >
                      <CircularProgress style={{ color: "#335" }} />
                    </CardContent>
                  ) : (
                    <DataGrid
                      rows={data}
                      columns={columns}
                      rowCount={totalLogs}
                      loading={isLoading}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 25,
                          },
                        },
                      }}
                      pageSizeOptions={[25, 50, 100]}
                      paginationModel={paginationModel}
                      paginationMode="server"
                      onPaginationModelChange={(model) => {
                        const { page, pageSize } = model;
                        if (pageSize !== paginationModel.pageSize) {
                          this.handlePageSizeChange(pageSize);
                        } else {
                          this.handlePageChange(page);
                        }
                      }}
                      sortingOrder={["asc", "desc"]}
                      sortModel={[{ field: orderBy, sort: order }]}
                      onSortModelChange={this.handleSortModelChange}
                      autoHeight
                      sx={{ ...customStyles }}
                      // components={{
                      //   footer: CustomFooter,
                      // }}
                    />
                  )}
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
}

export default withRouter(Logs);
