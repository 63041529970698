import React, { Component } from "react";
import {
  Container,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
// import { ListItemIcon, IconButton, Menu, MenuItem, Button, Box,Checkbox, InputBase, TablePagination  } from '@mui/material';
import {
  ListItemIcon,
  Button,
  Box,
  Checkbox,
  InputBase,
  TablePagination,
  IconButton,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
//import CircularProgress from '@mui/material/CircularProgress';
import { withRouter } from "./withRouter";

import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
// import AddIcon from '@mui/icons-material/Add';
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
// import MoreVertIcon from '@mui/icons-material/MoreVert';

import PdfIcon from "../assets/icons/pdf.webp";
import DocIcon from "../assets/icons/doc.webp";
import TextIcon from "../assets/icons/txt.webp";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress for the loader

const BASE_URL = process.env.REACT_APP_BASE_URL;
const application_code = "ZN003";
class DatasourceQA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedIndex: -1,
      searchQuery: "",
      selectAll: false,
      documentFiles: [],
      selectedFiles: [],
      page: 0,
      rowsPerPage: 10,
      showLoader: false,
      username: localStorage.getItem("username"),
      isLoading: false, // Add isLoading state to track data loading
    };
  }

  handleClick = (event, index) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedIndex: index,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      selectedIndex: -1,
    });
  };

  fetchDocumentFiles = async () => {
    this.setState({ isLoading: true });
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    try {
      const response = await fetch(
        `${BASE_URL}/zn003/user/selected_files/${userOrganization}?application_code=${encodeURIComponent(
          application_code
        )}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json(); // Parse the response as JSON

        if (Array.isArray(responseData.selected_files)) {
          this.setState({
            documentFiles: responseData.selected_files,
            isLoading: false, // Set isLoading to false once data is fetched
          });
        } else {
          console.error(
            "Data is not an array in the server response:",
            responseData
          );
        }
      } else {
        console.error("Server response was not OK. Status:", response.status);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.error("Error fetching data:", error);
    }
  };

  componentDidMount() {
    this.fetchDocumentFiles(); // Call the function when the component mounts
  }

  truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  handleDeleteFile = async (fileId) => {
    const { selectedFiles, documentFiles } = this.state;
    const token = localStorage.getItem("token");
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }
    const selectedFileIds = documentFiles
      .filter((file) => selectedFiles.includes(file.filename))
      .map((file) => file.fileid);

    const formData = new FormData();

    formData.append("userOrganization", userOrganization);
    formData.append("selected_file_ids", selectedFileIds);
    formData.append("application_code", application_code);
    try {
      const response = await fetch(`${BASE_URL}/zn003/user/selected_files`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        // File was successfully deleted, you can update your state or perform other actions here
        // For example, you can refresh the list of files to reflect the changes
        this.fetchDocumentFiles();
        this.setState({
          selectedFiles: [],
        });
      } else {
        console.error("Error deleting file. Status:", response.status);
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;

    if (newRowsPerPage === -1) {
      // If "All" is selected, set rowsPerPage to the total number of rows.
      this.setState({
        rowsPerPage: this.state.documentFiles.length,
        page: 0, // Reset the page to the first page
      });
    } else {
      // For other values, set rowsPerPage to the selected value.
      this.setState({
        rowsPerPage: parseInt(newRowsPerPage, 10),
        page: 0, // Reset the page to the first page
      });
    }
  };

  handleSelectAllClick = () => {
    const { selectAll, documentFiles } = this.state;
    let selectedFiles;

    if (selectAll) {
      // If "Select All" is already checked, unselect all files
      selectedFiles = [];
    } else {
      // If "Select All" is not checked, select all files by fileId
      selectedFiles = documentFiles.map((file) => file.filename);
    }

    this.setState({
      selectAll: !selectAll,
      selectedFiles,
    });
  };

  handleCheckboxChange = (filename) => {
    const { selectedFiles } = this.state;

    if (selectedFiles.includes(filename)) {
      // File is already selected, remove it from the selected files
      this.setState((prevState) => ({
        selectedFiles: prevState.selectedFiles.filter(
          (name) => name !== filename
        ),
      }));
    } else {
      // File is not selected, add it to the selected files
      this.setState((prevState) => ({
        selectedFiles: [...prevState.selectedFiles, filename],
      }));
    }
  };

  handleUpdateKnowledge = async () => {
    // Get the selected files and user token
    // const { selectedFiles } = this.state;
    const token = localStorage.getItem("token");
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    // Create a FormData object to send data as a multipart/form-data request
    const formData = new FormData();

    // Append selected files and user information to the FormData
    // this.state.selectedFiles.forEach((file) => formData.append('files', file));
    formData.append("username", localStorage.getItem("username"));
    formData.append("organization", userOrganization);
    const filenames = this.state.selectedFiles;

    const filteredData = this.state.documentFiles.filter((obj) =>
      filenames.includes(obj.filename)
    );

    formData.append("filedetails", JSON.stringify(filteredData));
    // Check if a user token is available
    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    try {
      // Show the loader while the update is in progress
      this.setState({ showLoader: true });

      // Send a POST request to the server to analyze the documents
      const response = await fetch(`${BASE_URL}/zn003/bot/analyseDocument`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Include the user's token in the request headers
        },
        body: formData, // Include the FormData with files and user information in the request body
      });

      // Check if the response status is OK (HTTP 200)
      if (response.ok) {
        // Parse the response JSON data if needed
        // const responseData = await response.json();

        // Call the chat click handler to navigate to the chat page
        this.props.handleChatClick({
          showUpload: false, // Hide the upload interface
          showChat: true, // Show the chat interface
          ShowKnowledge: false, // Hide the knowledge interface
        });
      } else {
        // Log an error message if the response status is not OK
        console.error("Error updating knowledge. Status:", response.status);
      }
    } catch (error) {
      // Log an error message if there is an exception during the request
      console.error("Error updating knowledge:", error);
    } finally {
      // Hide the loader when the update is complete (whether it succeeds or fails)
      this.setState({ showLoader: false });
    }
  };
  getUsername = (username, uploaded_by_admin) => {
    if (uploaded_by_admin) {
      return "Admin";
    } else {
      return username;
    }
  };

  render() {
    // const { anchorEl, selectedIndex , searchQuery, disableUploadButton, showLoader, selectAll,  documentFiles, page, rowsPerPage} = this.state;
    const {
      searchQuery,
      disableUploadButton,
      showLoader,
      selectAll,
      documentFiles,
      page,
      rowsPerPage,
    } = this.state;

    return (
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5%",
            padding: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F3F6FD",
              borderRadius: "25px",
              padding: "5px",
              marginRight: "-2%",
            }}
          >
            <SearchIcon
              sx={{ color: "#6001FF", marginLeft: "10px", marginRight: "5px" }}
            />
            <InputBase
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => this.setState({ searchQuery: e.target.value })}
              sx={{
                color: "#6001FF",
                width: "40%",
                backgroundColor: "#F3F6FD",
                borderRadius: "25px",
              }}
            />
          </Box>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        >
          {this.state.selectedFiles.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                {`${this.state.selectedFiles.length} ${
                  this.state.selectedFiles.length === 1 ? "file" : "files"
                } selected`}
              </Typography>
              <IconButton
                aria-label="Delete"
                onClick={() =>
                  this.handleDeleteFile(this.state.selected_files_by_id)
                }
              >
                <Tooltip title="Remove" arrow>
                  <DeleteIcon style={{ marginLeft: "10%" }} />
                </Tooltip>{" "}
              </IconButton>
            </div>
          )}
        </div>
        <Paper
          elevation={5}
          style={{
            width: "100%",
            borderRadius: "10px",
            backgroundColor: "#FFF",
          }}
        >
          {/* Conditional rendering for loader */}
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {this.state.isLoading && (
              <CircularProgress style={{ color: "#335" }} />
            )}{" "}
          </CardContent>
          {/* Display stats if data is available */}
          {!this.state.isLoading && (
            <>
              <div>
                <List component="nav" aria-label="file list">
                  <ListItem>
                    <ListItemText style={{ width: "3%" }}></ListItemText>
                    <ListItemText style={{ width: "10%" }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "1.1rem",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 400,
                        }}
                      >
                        File Name
                      </Typography>
                    </ListItemText>
                    <ListItemText style={{ width: "15%" }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "1.1rem",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 400,
                        }}
                      >
                        Upload Date
                      </Typography>
                    </ListItemText>
                    <ListItemText style={{ width: "9%" }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "1.1rem",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 400,
                        }}
                      >
                        Size
                      </Typography>
                    </ListItemText>
                    <ListItemText style={{ width: "8%" }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "1.1rem",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 400,
                        }}
                      >
                        User
                      </Typography>
                    </ListItemText>
                    <ListItemText style={{ width: "10%" }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "1.1rem",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 400,
                        }}
                      >
                        Select{" "}
                        <Checkbox
                          sx={{
                            color: "#6001FF",
                            "&.Mui-checked": { color: "#6001FF" },
                          }}
                          id="selectAll"
                          checked={selectAll}
                          onChange={this.handleSelectAllClick}
                          size="small"
                        />
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  {documentFiles
                    .filter((file) =>
                      file.filename
                        .toLowerCase()
                        .includes(searchQuery.trim().toLowerCase())
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((file, index) => (
                      <React.Fragment key={index}>
                        <ListItemButton
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <ListItemIcon
                            style={{ width: "9.5%", marginLeft: "1%" }}
                          >
                            {/* Display the logo based on the databasetype */}
                            {file.filetype === "application/pdf" && (
                              <img
                                src={PdfIcon}
                                alt="application/pdf"
                                style={{ width: "40%", height: "30%" }}
                              />
                            )}
                            {file.filetype ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                              <img
                                src={DocIcon}
                                alt="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                style={{ width: "38%", height: "40%" }}
                              />
                            )}
                            {file.filetype === "application/doc" && (
                              <img
                                src={DocIcon}
                                alt="application/doc"
                                style={{ width: "38%", height: "40%" }}
                              />
                            )}
                            {file.filetype === "text/plain" && (
                              <img
                                src={TextIcon}
                                alt="text/plain"
                                style={{ width: "38%", height: "40%" }}
                              />
                            )}
                            {/* Add more conditions for other database types */}
                          </ListItemIcon>
                          <ListItemText style={{ width: "7%" }}>
                            <Tooltip
                              title={file.filename}
                              placement="top-start"
                              arrow
                            >
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{
                                  fontSize: "1rem",
                                  fontFamily: "Poppins, sans-serif",
                                  fontWeight: 400,
                                }}
                              >
                                {this.truncateText(file.filename, 10)}
                              </Typography>
                            </Tooltip>
                          </ListItemText>
                          <ListItemText style={{ width: "12%" }}>
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              sx={{
                                fontSize: "1rem",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 400,
                              }}
                            >
                              {file.uploaded_date}
                            </Typography>
                          </ListItemText>
                          <ListItemText style={{ width: "6%" }}>
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              sx={{
                                fontSize: "1rem",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 400,
                              }}
                            >
                              {file.filesize}
                            </Typography>
                          </ListItemText>
                          <ListItemText style={{ width: "10%" }}>
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              sx={{
                                fontSize: "1rem",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 400,
                              }}
                            >
                              {this.getUsername(
                                file.username,
                                file.uploaded_by_admin
                              )}
                            </Typography>
                          </ListItemText>
                          <ListItemText style={{ width: "2%" }}>
                            <Checkbox
                              size="small"
                              sx={{
                                color: "#6001FF",
                                "&.Mui-checked": { color: "#6001FF" },
                              }}
                              checked={this.state.selectedFiles.includes(
                                file.filename
                              )}
                              onChange={() =>
                                this.handleCheckboxChange(file.filename)
                              }
                            />
                          </ListItemText>
                          {/*  <ListItemIcon style={{ width: '0%' }}>
                   <IconButton
                        aria-label="More options"
                        aria-controls="options-menu"
                        aria-haspopup="true"
                        onClick={(event) => this.handleClick(event, index)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                       sx={{ marginLeft:'-8%' }}
                        id="options-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl) && selectedIndex === index}
                        onClose={this.handleClose}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        PaperProps={{
                          style: {
                            width: '9%', 
                          },
                        }}
                      >
                        <MenuItem onClick={() => { this.handleClose(); this.handleDeleteFile(file.id); }}>
                        <ListItemIcon>
                        <DeleteIcon />
                        </ListItemIcon>
                        Delete
                        </MenuItem>
                      </Menu>
                    </ListItemIcon> */}
                        </ListItemButton>
                        {index !== file.length - 1 && <Divider />}
                      </React.Fragment>
                    ))}
                </List>
              </div>
            </>
          )}
        </Paper>
        <div
          style={{
            display: "flex",
            marginTop: "1%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TablePagination
            style={{ marginLeft: "1%" }} // Adjust the margin as needed
            rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
            component="div"
            count={documentFiles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
          <Button
            variant="contained"
            color="primary"
            style={{
              marginRight: "2%",
              backgroundColor: "#6001FF",
              color: "#FFFFFF",
            }}
            disabled={
              documentFiles.length === 0 || disableUploadButton === true
            }
            onClick={this.handleUpdateKnowledge}
            endIcon={<ArrowCircleRightOutlinedIcon style={{ fontSize: 20 }} />}
          >
            {showLoader ? (
              <CircularProgress
                size={25}
                sx={{
                  color: "#FFFFFF",
                  marginLeft: "-7%",
                }}
              />
            ) : (
              "Query"
            )}
          </Button>
        </div>
      </Container>
    );
  }
}

export default withRouter(DatasourceQA);
