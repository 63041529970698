import React from "react";
import Grid from "@mui/material/Grid";
import FileUploadWeb from "./fileUploadWeb";
import SideNavWeb from "./sidenavWeb";
import ChatWeb from "./ChatWeb";
import BookmarkWeb from "./BookmarkWeb";
import Datasource from "./Datasource";
import Listdata from "./Listdata";
import PromptTemplate from "./promptTemplate";

class AdminWeb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpload: false,
      showChat: false,
      showListdata: true,
      showBook: false,
      showData: false,
      folder_name: "",
      folder_id: "",
      current_webs: null,
      showPromptTemplate: false,

    };
  }
  handleDatasourceClick = () => {
    this.setState({
      showUpload: false,
      showChat: false,
      showListdata: false,
      showBook: false,
      showData: true,
      showPromptTemplate: false,

    });
  };

  handleUploadClick = () => {
    this.setState(
      {
        showUpload: true,
        showChat: false,
        showListdata: false,
        showBook: false,
        showData: false,
        showPromptTemplate: false,

      },
    );
  };

  handleListdataClick = () => {
    this.setState({
      showUpload: false,
      showChat: false,
      showListdata: true,
      showBook: false,
      showData: false,
      showPromptTemplate: false,

    });
  };

  handleChatClick = (current_webs) => {
    this.setState({
      showUpload: false,
      showChat: true,
      showListdata: false,
      showBook: false,
      showData: false,
      current_webs:current_webs,
      showPromptTemplate: false,

    });
  };

  handleBookClick = () => {
    this.setState({
      showUpload: false,
      showChat: false,
      showListdata: false,
      showBook: true,
      showData: false,
      showPromptTemplate: false,

    });
  };

  handlePromptTemplateClick = () => {
    this.setState({
      showUpload: false,
      showChat: false,
      showListdata: false,
      ShowBook: false,
      showData: false,
      showPromptTemplate: true,
    });
  };

  render() {
    const {
      showUpload,
      showChat,
      showListdata,
      showBook,
      showData,
      current_webs,
      showPromptTemplate,
    } = this.state;
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={2} style={{ marginTop: "5%" }}>
            <SideNavWeb
              handleListdataClick={this.handleDatasourceClick}
              handleChatClick={this.handleChatClick}
              handleUploadClick={this.handleUploadClick}
              handleDatasourceClick={this.handleListdataClick}
              handleBookClick={this.handleBookClick}
              handlePromptTemplateClick={this.handlePromptTemplateClick}
              showUpload={showUpload}
              showChat={showChat}
              showListdata={showData}
              showData={showListdata}
            />
          </Grid>
          <Grid item xs={10}>
            {showUpload && (
              <FileUploadWeb
                handleListdataClick={this.handleListdataClick}
              />
            )}
            {showChat && (
              <ChatWeb current_webs={current_webs || []} />
            )}
            {showListdata && (
              <Listdata
                handleChatClick={this.handleChatClick}
                handleUploadClick={this.handleUploadClick}
                handleDatasourceClick={this.handleDatasourceClick}
              />
            )}
            {showBook && <BookmarkWeb />}
            {showData && <Datasource handleChatClick={this.handleChatClick} />}
            {showPromptTemplate && <PromptTemplate />}

          </Grid>
        </Grid>
      </>
    );
  }
}

export default AdminWeb;
