import Utils from "../userComponents/Utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const handleFetchTokenUsage = async () => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    try {
      const response = await fetch(
        `${BASE_URL}/admin/adminview_token_usage/${email}?userOrganization=${encodeURIComponent(
          userOrganization
        )}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status===401) {
         console.error("Unauthorized: You are not logged in");
         Utils.removeLocalStorage()
          this.props.navigate("/")        
      }
      if (response.status === 200) {
        const result = await response.json();
        const token_usage = result["total_token_usage"];
        const total_token_limit = result["total_token_limit"];
        const tokenDetails={
            token_usage:token_usage,
            total_token_limit:total_token_limit,
          }
          localStorage.setItem("tokenDetails",JSON.stringify(tokenDetails));
      } else {
        throw new Error("Failed to fetch token usage");
      }
    } catch (error) {
      console.error("Error fetching token usage:", error);
    }
  };
 export {handleFetchTokenUsage}