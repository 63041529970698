import React, { Component } from "react";
import { Container, InputBase } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import ListItemIcon from "@mui/material/ListItemIcon";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
// import Files from "./Files";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import Listdata from "./Listdata";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import { withRouter } from "./withRouter";
import Utils from "../userComponents/Utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const EMBEDDING_SERVICE_URL = process.env.REACT_APP_EMBEDDING_SERVICE_URL;
const application_code = "ZN003";

const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};
class ListFolder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      folderName: "",
      userName: localStorage.getItem("username"),
      folders: [],
      showModal: false,
      // selectedFolders: new Set(),
      selectedFolders: [],
      selectAll: false,
      showFiles: false,
      folderIndex: 0,
      searchQuery: "",
      page: 0,
      rowsPerPage: 10,
      showLoader: false,
      selectedFoldersArray: [],
      snackbarOpen: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
      responseOk: false,
    };
  }
  componentDidMount() {
    this.fetchFolders();
  }
  fetchFolders = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    const email = localStorage.getItem("email");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    try {
      const response = await fetch(
        `${EMBEDDING_SERVICE_URL}/common/folderapi/get_user_folders/${email}?userOrganization=${encodeURIComponent(
          userOrganization
        )}&application_code=${encodeURIComponent(application_code)}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("responseHR",response);
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const responseData = await response.json(); // Parse the response as JSON
        this.setState({ folders: responseData.folders });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  async folderCreate() {
    const { folderName } = this.state;
    const foldername = folderName.trim();

    if (foldername.length === 0) {
      this.handleSnackbarOpen(
        "error",
        "folder name should contain some characters"
      );
      return;
    } else if (foldername.length >= 20) {
      this.handleSnackbarOpen(
        "error",
        "folder name should be less than 20 characters"
      );
      return;
    } else if (this.hasSpecialFirstCharacter(foldername)) {
      this.handleSnackbarOpen(
        "error",
        `${foldername} name should start with alphabetic character`
      );
      return;
    } else if (this.hasSpecialCharacters(foldername)) {
      this.handleSnackbarOpen(
        "error",
        `${foldername} name contains special character`
      );
      return;
    }
    this.setState({ showModal: false });
    const email = localStorage.getItem("email");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    const token = localStorage.getItem("token");
    if (folderName) {
      const formData = new FormData();
      formData.append("folder_name", foldername);
      formData.append("organization", userOrganization);
      formData.append("application_code", application_code);
      formData.append("useremail", email);

      try {
        const response = await fetch(
          `${EMBEDDING_SERVICE_URL}/common/folderapi/user_create_folder`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        this.setState({ responseOk: response.ok });
        if (!response.ok) {
          const folderExist = await response.json();
          if (folderExist.detail) {
            this.handleSnackbarOpen("error", folderExist.detail);
            this.setState({ folderName: "" });
          }
          throw new Error("Network response was not ok");
        } else {
          this.setState({ folderName: "" });
          this.fetchFolders();
        }

        // If you need the response data, you can access it here
      } catch (error) {
        console.error("Error:", error);
      }
    }
  }

  handleSnackbarOpen = (severity, message) => {
    this.setState({
      snackbarOpen: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  toggleSelectAll = () => {
    this.setState((prevState) => {
      const { selectAll, folders } = prevState;
      const updatedSelectedFolders = selectAll ? [] : [...folders];
      return {
        selectedFolders: updatedSelectedFolders,
        selectAll: !selectAll,
      };
    });
  };

  // Function to toggle selected folders
  toggleFolderSelection = (id, item) => {
    const { selectedFolders, folders } = this.state;
    if (selectedFolders.some((selectedFolder) => selectedFolder.id === id)) {
      // File is already selected,  it from the selected files

      this.setState(
        (prevState) => ({
          selectedFolders: prevState.selectedFolders.filter(
            (selectedFolder) => selectedFolder.id !== id
          ),
        }),
        () => {
          if (this.state.selectedFolders.length === folders.length) {
            this.setState({ selectAll: true });
          }

          if (this.state.selectedFolders.length ===folders.length-1) {
            this.setState({ selectAll: false });
          }
        }
      );
    } else {
      // File is not selected, add it to the selected files
      this.setState(
        (prevState) => ({
          selectedFolders: [
            ...prevState.selectedFolders,
            { id: id, folder_name: item.folder_name },
          ],
        }),
        () => {
          if (this.state.selectedFolders.length === folders.length) {
            this.setState({ selectAll: true });
          }

          if (this.state.selectedFolders.length ===folders.length-1) {
            this.setState({ selectAll: false });
          }
        }
      );
    }
  };
  openFiles(index) {
    this.setState({ showFiles: true, folderIndex: index });
  }
  backFolder = (value) => {
    this.setState({ showFiles: value });
  };
  reset = () => {
    window.location.reload();
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;

    if (newRowsPerPage === -1) {
      // If "All" is selected, set rowsPerPage to the total number of rows.
      this.setState({
        rowsPerPage: this.state.folders.length,
        page: 0, // Reset the page to the first page
      });
    } else {
      // For other values, set rowsPerPage to the selected value.
      this.setState({
        rowsPerPage: parseInt(newRowsPerPage, 10),
        page: 0, // Reset the page to the first page
      });
    }
  };

  handleUpdateKnowledge = async () => {
    const { selectedFolders } = this.state;
    const token = localStorage.getItem("token");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";

    const selectedFoldersArray = selectedFolders.map(
      (folder) => folder.folder_name
    );
    if(localStorage.getItem('filenameAndSummaryHR')){
      localStorage.removeItem('filenameAndSummaryHR');
    }
    const formData = new FormData();
    // formData.append("username", localStorage.getItem("username"));
    formData.append("organization", userOrganization);
    formData.append("folder_list", JSON.stringify(selectedFoldersArray));
    formData.append("application_code", application_code);
    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }
    try {
      // Show the loader while the update is in progress
      this.setState({ showLoader: true });

      // Send a POST request to the server to analyze the documents
      const response = await fetch(`${BASE_URL}/zn003/bot/analyseFolders`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Include the user's token in the request headers
        },
        body: formData, // Include the FormData with files and user information in the request body
      });

      if(response.status===500){
        this.handleSnackbarOpen(
          "error",
          "Something went wrong."
        );
        return;
      }
      
      // Check if the response status is OK (HTTP 200)
      if (response.ok) {
        // Call the chat click handler to navigate to the chat page
        const res = await response.json();
        const files_path = res["files_path"];
        const filenames=res["filenames"];
        const file_summary=res["summary_response"];
        const filenameAndSummaryHR = {};
        filenames.forEach((filename, index) => {
          if (index === 0) {
            filenameAndSummaryHR[filename] = file_summary;
        } else {
            filenameAndSummaryHR[filename] = "";
        }
      });
        localStorage.setItem("zn003_files_path", JSON.stringify(files_path));
        localStorage.setItem("filenameAndSummaryHR", JSON.stringify(filenameAndSummaryHR));

        this.props.handleChatClick(files_path, {
          showUpload: false, // Hide the upload interface
          showChat: true, // Show the chat interface
          ShowKnowledge: false, // Hide the knowledge interface
        });
      } else {
        // Log an error message if the response status is not OK
        const fileNotFound = await response.json();
        console.error("Error updating knowledge. Status:", fileNotFound.detail);
        this.handleSnackbarOpen("error", fileNotFound.detail);
      }
    } catch (error) {
      // Log an error message if there is an exception during the request
      console.error("Error updating knowledge:", error);
    } finally {
      // Hide the loader when the update is complete (whether it succeeds or fails)
      this.setState({ showLoader: false });
    }
  };
  handleDeleteFolder = async () => {
    const token = localStorage.getItem("token");
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    const formData = new FormData();
    const { selectedFolders, folders } = this.state;

    // const selectedFoldersArray = Array.from(selectedFolders);
    const selectedFoldersArray = selectedFolders.map(
      (folder) => folder.folder_name
    );
    const selectedFoldersIds = selectedFolders.map((folder) => folder.id);
    let adminFolders = folders.filter(
      (folder) =>
        selectedFoldersArray.includes(folder.folder_name) &&
        selectedFoldersIds.includes(folder.id) &&
        folder.isAdmin
    );
    let foldersToDelete = folders
      .filter(
        (folder) =>
          selectedFoldersArray.includes(folder.folder_name) &&
          selectedFoldersIds.includes(folder.id) &&
          !folder.isAdmin
      )
      .map((folder) => folder.folder_name);
    if (adminFolders.length > 0) {
      if (adminFolders.length === 1) {
        this.handleSnackbarOpen(
          "error",
          `${adminFolders.length} folder cannot be deleted that is added by admin`
        );
      } else if (adminFolders.length > 1) {
        this.handleSnackbarOpen(
          "error",
          `${adminFolders.length} folders cannot be deleted that are added by admin`
        );
      }
    }

    formData.append("folder_list", JSON.stringify(foldersToDelete));
    formData.append("application_code", application_code);
    formData.append("organization", userOrganization);

    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/zn003/user/deletefolders`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (response.ok) {
        this.setState({ selectedFolders: [], selectAll: false });
        this.fetchFolders();
      } else {
        const errorResponse = await response.json();
        this.handleSnackbarOpen("error", errorResponse.detail);
        console.error("Failed to delete folders");
      }
    } catch (error) {
      console.error("Error deleting folders:", error);
    }
  };

  getUsername = (username, isadmin) => {
    if (isadmin) {
      return "Admin";
    } else {
      return username;
    }
  };
  truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  hasSpecialCharacters = (folderName) => {
    // Define a regular expression to match special characters
    const regex = /[^a-zA-Z0-9_.-\s]+/;
    // returns boolean value from test()
    return regex.test(folderName);
  };
  hasSpecialFirstCharacter = (folderName) => {
    // Check if the first character is a special character
    const firstCharSpecial = /^[^a-zA-Z\s]/.test(folderName.charAt(0));
    return firstCharSpecial;
  };
  render() {
    const {
      folderName,
      showModal,
      showFiles,
      folderIndex,
      selectedFolders,
      selectAll,
      searchQuery,
      showLoader,
      rowsPerPage,
      page,
      snackbarOpen,
      snackbarSeverity,
      snackbarMessage,
    } = this.state;
    let { folders } = this.state;
    const { handleChatClick, handleUploadClick, handleDatasourceClick } =
      this.props;
    if (searchQuery) {
      folders = folders.filter((folder) => {
        return folder.folder_name
          .toLowerCase()
          .includes(searchQuery.trim().toLowerCase());
      });
    }
    return (
      <>
        {!showFiles && (
          <Container>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "10px",
                marginTop: "75px",
                marginBottom: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#F3F6FD",
                  borderRadius: "25px",
                  padding: "5px",
                  marginRight: "3%",
                }}
              >
                <SearchIcon
                  sx={{
                    color: "#6001FF",
                    marginLeft: "10px",
                    marginRight: "5px",
                  }}
                />
                <InputBase
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) =>
                    this.setState({ searchQuery: e.target.value })
                  }
                  sx={{
                    color: "#6001FF",
                    width: "40%",
                    backgroundColor: "#F3F6FD",
                    borderRadius: "25px",
                  }}
                />
              </Box>
              <ListItemIcon sx={{ color: "#000", pt: 1, pr: 1 }}>
                <RestartAltOutlinedIcon
                  sx={{
                    color: "#6001FF",
                    width: "45px",
                    height: "35px",
                    "&:hover": {
                      backgroundColor: "#EFEFEF",
                      borderRadius: "50%", // Making background circular
                    },
                  }}
                  onClick={this.reset}
                />
              </ListItemIcon>
              <Button
                sx={{
                  backgroundColor: "#6001ff",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#6001ff", // Change to the hover color if needed
                  },
                }}
                variant="contained"
                onClick={() => this.setState({ showModal: true })}
              >
                New Folder
              </Button>
            </div>
            <div style={{ display: "flex" }}>
              {selectedFolders.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "15px",
                  }}
                >
                  <Typography variant="body1" color="textSecondary">
                    {`${selectedFolders.length} ${
                      selectedFolders.length === 1 ? "folder" : "folders"
                    } selected`}
                  </Typography>
                  <IconButton
                    aria-label="Delete"
                    onClick={() => this.handleDeleteFolder()}
                  >
                    <Tooltip title="Remove" arrow>
                      <DeleteIcon style={{ marginLeft: "10%" }} />
                    </Tooltip>
                  </IconButton>
                </div>
              )}
            </div>
            <Modal
              open={showModal}
              onClose={() => this.setState({ showModal: false })}
            >
              <Paper
                elevation={5}
                style={{
                  marginTop: "15%",
                  marginLeft: "30%",
                  borderRadius: "20px",
                  width: "35%",
                  minHeight: "170px",
                  backgroundColor: "#FFFFFF",
                  // paddingTop: "15px",
                }}
              >
                <DialogTitle>New Folder</DialogTitle>
                <TextField
                  label="Folder Name"
                  value={folderName}
                  onChange={(e) =>
                    this.setState({ folderName: e.target.value })
                  }
                  sx={{
                    width: "92%",
                    marginTop: "0.5%",
                    marginLeft: "3.5%",
                    ...textFieldSx,
                  }}
                />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "15px",
                  }}
                >
                  <p
                    style={{
                      marginRight: "8%",
                      color: "#6001FF",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.setState({ showModal: false, folderName: "" });
                    }}
                  >
                    Cancel
                  </p>
                  <Button
                    style={{
                      color: "#fff",
                      cursor: "pointer",
                      backgroundColor: "#6001FF",
                      textTransform: "none",
                      marginTop: "1.4%",
                      marginRight: "5%",
                      height: "1%",
                    }}
                    onClick={() => {
                      this.folderCreate();
                    }}
                  >
                    Create
                  </Button>
                </Box>
              </Paper>
            </Modal>

            <Paper
              elevation={5}
              sx={{
                width: "1150px",
                ml: "10px",
                mt: "10px",
                borderRadius: "10px",
              }}
            >
              <div style={{ marginBottom: "1.5%", color: "white" }}>.</div>
              <Table
                style={{ width: "100%" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead sx={{}}>
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "1px",
                        paddingLeft: "60px",
                        fontSize: "1.1em",
                        fontWeight: "inherit",
                      }}
                    >
                      Folder
                    </TableCell>
                    <TableCell
                      style={{
                        width: "80px",
                        fontSize: "1.1em",
                        fontWeight: "inherit",
                      }}
                    >
                      Folder Name
                    </TableCell>
                    <TableCell
                      style={{
                        width: "60px",
                        fontSize: "1.1em",
                        fontWeight: "inherit",
                      }}
                    >
                      Created Date
                    </TableCell>
                    <TableCell
                      style={{
                        width: "20px",
                        paddingLeft: "70px",
                        fontSize: "1.1em",
                        fontWeight: "inherit",
                      }}
                    >
                      User
                    </TableCell>
                    <TableCell
                      style={{
                        width: "75px",
                        fontSize: "1.1em",
                        fontWeight: "inherit",
                      }}
                    >
                      Number of Files
                    </TableCell>
                    <TableCell
                      style={{
                        width: "60px",
                        fontSize: "1.1em",
                        fontWeight: "inherit",
                      }}
                    >
                      <Checkbox
                        sx={{
                          color: "#6001FF",
                          "&.Mui-checked": { color: "#6001FF" },
                        }}
                        checked={selectAll}
                        onChange={this.toggleSelectAll}
                      />
                      Select
                    </TableCell>
                    <TableCell
                      style={{
                        width: "50px",
                        paddingLeft: "40px",
                        fontSize: "1.1em",
                        fontWeight: "inherit",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                {/* <Divider /> */}
                {folders
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableBody key={item.id}>
                      <TableRow>
                        <TableCell
                          style={{
                            paddingLeft: "60px",
                          }}
                        >
                          <ListItemIcon>
                            <FolderRoundedIcon />
                          </ListItemIcon>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={{
                              fontSize: "1rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                              ml: 2,
                            }}
                          >
                            {this.truncateText(item.folder_name, 10)}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={{
                              fontSize: "1rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                              ml: 1,
                            }}
                          >
                            {`${item.created_date.substring(0, 10)}`}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={{
                              fontSize: "1rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                              textAlign: "center",
                            }}
                          >
                            {this.getUsername(item.username, item.isAdmin)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={{
                              fontSize: "1rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                              textAlign: "center",
                            }}
                          >
                            {item.total_files_count}
                          </Typography>
                        </TableCell>

                        <TableCell sx={{ width: "2px" }}>
                          <Checkbox
                            sx={{
                              color: "#6001FF",
                              "&.Mui-checked": { color: "#6001FF" },
                            }}
                            checked={selectedFolders.some(
                              (selectedFolder) =>
                                selectedFolder.id === item.id &&
                                selectedFolder.folder_name === item.folder_name
                            )} // Check if folder is selected
                            onChange={() =>
                              this.toggleFolderSelection(item.id, item)
                            } // Toggle selection on checkbox change
                          />
                        </TableCell>

                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "1rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                            }}
                          >
                            <Button
                              sx={{
                                cursor: "pointer",
                                color: "#6001FF",
                                textTransform: "none",
                                "&:hover": {
                                  backgroundColor: "#EFEFEF",
                                },
                              }}
                              onClick={() => {
                                this.openFiles(index);
                              }}
                            >
                              Open Folder
                            </Button>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
            </Paper>

            <div
              style={{
                display: "flex",
                marginTop: "10px",
                marginLeft: "0px",
                alignItems: "center",
                justifyContent: "space-between",
                width: "1150px",
              }}
            >
              <TablePagination
                style={{ marginLeft: "30px" }} // Adjust the margin as needed
                rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
                component="div"
                count={folders.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />

              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#6001FF",
                  color: "#FFFFFF",
                  marginRight: "10px",
                  textTransform: "none",
                }}
                disabled={
                  folders.length === 0 ||
                  // disableUploadButton === true ||
                  this.state.selectedFolders.length === 0
                }
                onClick={this.handleUpdateKnowledge}
                endIcon={
                  <ArrowCircleRightOutlinedIcon style={{ fontSize: 30 }} />
                }
              >
                {showLoader ? (
                  <CircularProgress
                    size={25}
                    sx={{
                      color: "#FFFFFF",
                      marginLeft: "-7%",
                    }}
                  />
                ) : (
                  "Query"
                )}
              </Button>
            </div>
            <Snackbar
              open={snackbarOpen} // Use the correct state name here (snackbarOpen)
              autoHideDuration={3000}
              onClose={this.handleSnackbarClose}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                onClose={this.handleSnackbarClose}
                severity={snackbarSeverity}
              >
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
          </Container>
        )}
        {showFiles && (
          <Listdata
            folders={folders}
            folderIndex={folderIndex}
            folder_name={folders[folderIndex]["folder_name"] || ""}
            folder_id={folders[folderIndex]["id"] || ""}
            is_Admin_folder={folders[folderIndex]["isAdmin"]}
            backFolder={this.backFolder}
            handleChatClick={handleChatClick}
            handleUploadClick={handleUploadClick}
            handleDatasourceClick={handleDatasourceClick}
          />
        )}
      </>
    );
  }
}

export default withRouter(ListFolder);
