import React, { Component } from "react";
import { Grid, Typography } from "@mui/material";
import Tab from "./Tab";
import Menu from "./Menu";
import User from "./Useract";
import { withRouter } from "./withRouter";
import Utils from "../userComponents/Utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;
class DataManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("token"),
      userDetails: JSON.parse(localStorage.getItem("userDetails"))
        ? JSON.parse(localStorage.getItem("userDetails"))
        : [],
      weekly_token_usage: JSON.parse(localStorage.getItem("weekly_token_usage"))
        ? JSON.parse(localStorage.getItem("weekly_token_usage"))
        : [],
      analytics_data: JSON.parse(localStorage.getItem("analytics_data"))
        ? JSON.parse(localStorage.getItem("analytics_data"))
        : [],
      cummulative_token_usage: 0,
      customTabValue: 2,
      showTab: true,
      showUpload: false,
      average_cost_per_query: 0,
      average_cost_per_day: 0,
      xAxisGranularity: "day",
      yAxisMetric: "Active Users",
      pie_chart_data: [],
      isLoading: false, // Add isLoading state to track data loading
    };
  }

  async componentDidMount() {
    this.getUserdetails()
  }
  componentDidUpdate(){
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
        Utils.removeLocalStorage()
        this.props.navigate("/");
    }
  }
  handleUploadClick = () => {
    this.setState({
      showTab: false,
      showUpload: true,
    });
  };

  handleTabClick = (customTabValue) => {
    this.setState({
      showTab: true,
      showUpload: false,
      customTabValue: customTabValue,
    });
  };

  updateUserDetails = (updatedDetails) => {
    localStorage.setItem("userDetails", JSON.stringify(updatedDetails));
    this.setState({ userDetails: updatedDetails });
  };

  handleXAxisChange = (event) => {
    let bar_chart_data = [];
    if (event.target.value === "year") {
      bar_chart_data =
        this.state.aggregated_organization_data["merged_data_by_year"];
    }
    if (event.target.value === "quarter") {
      bar_chart_data =
        this.state.aggregated_organization_data["merged_data_by_quarter"];
    }
    if (event.target.value === "month") {
      bar_chart_data =
        this.state.aggregated_organization_data["merged_data_by_month"];
    }
    if (event.target.value === "day") {
      bar_chart_data =
        this.state.aggregated_organization_data["merged_data_by_date"];
    }
    this.setState({
      xAxisGranularity: event.target.value,
      data: bar_chart_data,
    });
  };

  handleYAxisChange = (event) => {
    this.setState({ yAxisMetric: event.target.value });
  };
  getColumnsBasedOnMetric = () => {
    const { yAxisMetric } = this.state;

    switch (yAxisMetric) {
      case "Active Users":
        return [
          { id: "name", label: "Name" },
          { id: "email", label: "Email" },
          { id: "organization", label: "Organization" },
          { id: "active_days", label: "Active Days" },
        ];
      case "Queries":
        return [
          { id: "name", label: "Name" },
          { id: "email", label: "Email" },
          { id: "organization", label: "Organization" },
          { id: "total_messages", label: "Total Queries" },
        ];
      case "Tokens Consumed":
        return [
          { id: "name", label: "Name" },
          { id: "email", label: "Email" },
          { id: "organization", label: "Organization" },
          { id: "token_used", label: "Tokens Used" },
        ];
      case "Cost":
        return [
          { id: "name", label: "Name" },
          { id: "email", label: "Email" },
          { id: "organization", label: "Organization" },
          { id: "total_token_cost", label: "Cost" },
        ];
      default:
        return [];
    }
  };
  getUserdetails=async()=>{
    const token = localStorage.getItem("token");
  
    if (!token) {
      console.error("Token not found in localStorage");
        Utils.removeLocalStorage()
        this.props.navigate("/");
    }
    try {
      const response = await fetch(
        `${BASE_URL}/admin/fetch_user_details`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        console.error("Unauthorized: You are not logged in");
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.status === 200) {
        const users_data = await response.json();
        this.setState({
          userDetails: users_data.userDetails,
        });
      } else {
        throw new Error("Failed to fetch  users Data");
      }
    } catch (error) {
      console.error("Error fetching  users Data:", error);
    }
}
  
  render() {
    const {
      showUpload,
      showTab,
    } = this.state;
    return (
      <>
        <div
          style={{
            margin: "2%",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              right: "3%",
              margin: "1rem",
            }}
          >
            <User />
          </div>
          <Menu selectedButton="Data Management" />

          <Typography
            variant="h1"
            component="div"
            sx={{
              flexGrow: 1,
              color: "#6001FF",
              fontSize: "2rem",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
            }}
          >
            Data Management
          </Typography>
          {/* <Paper elevation={5} style={{ borderRadius: '20px', backgroundColor: '#F3F6FD',marginTop:'5%' }}> */}
          {/* Conditional rendering for loader */}
          {/* <CardContent sx={{ display: 'flex', alignItems: 'center',flexDirection: 'column'}}>{this.state.isLoading && <CircularProgress />} </CardContent>  */}

          <div style={{ margin: "2%" }}>
            {/* Conditional rendering for loader */}

            <Grid container spacing={6}>
              {/* <BasicModal /> */}
              <Grid item xs={12} md={12}>
                {showTab && !showUpload && (
                  <Tab
                    handleTabClick={this.handleTabClick}
                    handleUploadClick={this.handleUploadClick}
                    userDetails={this.state.userDetails}
                  />
                )}

                {/* {showUpload && (
              <FileUpload
                customTabValue={this.state.customTabValue}
                showUpload={showUpload}
                handleTabClick={this.handleTabClick}
                userDetails={this.state.userDetails}
              />
            )}  */}
              </Grid>
            </Grid>
          </div>

          {/* </Paper> */}
        </div>
      </>
    );
  }
}

export default withRouter(DataManagement);
