import React, { Component } from 'react';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

export class FormFileUpload extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         
      }
    }
  render() {
    const {
        hostName,
        userName,
        password,
        databasename,
        hasDatabaseInfo,
        connecting,
        textFieldSx,
        handleInputChange,
        handleUpdateDatabase,
        handleFetchTables,
    }=this.props
    return (
    <>
          <form>
                <div style={{ marginTop: "10px" }}>
                  <div>
                    <TextField
                      id="hostName"
                      label="Host Name"
                      name="hostName"
                      value={hostName}
                      onChange={handleInputChange}
                      style={{ width: "60.5%", marginBottom: "10px" }}
                      sx={textFieldSx}
                      required
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      id="userName"
                      label="User Name"
                      name="userName"
                      value={userName}
                      onChange={handleInputChange}
                      style={{ width: "30%", marginBottom: "10px" }}
                      sx={textFieldSx}
                      required
                    />{" "}
                    &nbsp;&nbsp;
                    <TextField
                      id="password"
                      label="Password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={handleInputChange}
                      style={{ width: "30%", marginBottom: "10px" }}
                      sx={textFieldSx}
                      required
                    />
                  </div>
                  <div>
                    <TextField
                      id="databasename"
                      label="databasename "
                      name="databasename"
                      value={databasename}
                      onChange={handleInputChange}
                      style={{ width: "60.5%", marginBottom: "10px" }}
                      sx={textFieldSx}
                      required
                    />
                  </div>
                </div>
                <Box
                  m={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* Conditionally render CircularProgress based on the 'connecting' state */}
                  {hasDatabaseInfo ? (
                    <div>
                      {connecting ? (
                        <CircularProgress
                          size={25}
                          style={{ color: "#6001ff",  marginLeft:"26%",}}
                        />
                      ) : (
                        <Button
                          style={{
                            backgroundColor: "#6001ff",
                            marginBottom: "20px",
                            color: "#ffffff",
                            marginLeft:"26%",
                            width:"100%",
                          }}
                          variant="contained"
                          onClick={handleUpdateDatabase}
                        >
                          Apply Changes
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div>
                      {connecting ? (
                        <CircularProgress
                          size={25}
                          style={{ color: "#6001ff", marginLeft: "115%",}}
                        />
                      ) : (
                        <Button
                          style={{
                            backgroundColor: "#6001ff",
                            marginLeft: "115%",
                            
                           
                          }}
                          variant="contained"
                          onClick={handleFetchTables}
                        >
                          Save
                        </Button>
                      )}
                    </div>
                  )}
                </Box>
              </form>
    </>
    )
  }
}

export default FormFileUpload