import React, { Component } from "react";
import {
  Container,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import {
  ListItemIcon,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Box,
  InputBase,
  TablePagination,
  Checkbox,
  ListSubheader,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import CircleIcon from "@mui/icons-material/Circle";

import MYSQLLogo from "../assets/icons/mysql.webp";
import PostLogo from "../assets/icons/postgresql.webp";
import Oracle from "../assets/icons/orcale.webp";
import SBucket from "../assets/icons/bucket.webp";
import Snowflake from "../assets/icons/snow.webp";
import MongoDB from "../assets/icons/m.webp";
import Spark from "../assets/icons/spark.webp";
import Hive from "../assets/icons/hive.webp";
import CSV from "../assets/icons/csv.webp";
import XLSX from "../assets/icons/xlsx.webp";
import TSV from "../assets/icons/tsv.webp";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

import { withRouter } from "./withRouter";
import Utils from "../userComponents/Utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

class DataTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedIndex: -1,
      databases: [],
      table_count: [],
      searchQuery: "", // Initialize the searchQuery state
      csvSearchQuery: "",
      activeDatabaseIndex: -1, // Initialize with -1 (no active connection)
      selectedTables: [], // Initialize as an empty array
      selectAllTables: false, //select all for tables
      selectAllCSV: false, // select all for CSV
      loading: false,
      rowdata: [],
      file: [],
      value: 0,
      selectedItemIndex: null,
      showContinueButton: false, // Add this state variable
      table_data: [],
      currentPageFiles: 1,
      itemsPerPageFiles: 10,
      currentPageDatabases: 1,
      itemsPerPageDatabases: 10,
      currentPageTables: 1,
      itemsPerPageTables: 10,
      selectedDatabase: null, // This will hold the selected database information
      isContinueButtonVisible: false, // Initialize as false
      showLoader: false,
      selectedRows: [],
      connecting: false,
      showBackdrop: false,
      databaseInfo: null, // Initialize the state variable to null
      selectedFiles: [], // Initialize selectedFiles as an empty array

      isEditMode: false,
      showSnackbar: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
    };
  }
  async componentDidMount() {
    await this.fetchData(); // Fetch and set the initial data
    await this.fetchUploadedFiles(); // Fetch uploaded files
    this.setState({ showContinueButton: true }); // Show the button after fetching data
  }
  fetchData = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    const userEmail = localStorage.getItem("email");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    try {
      const response = await fetch(
        `${BASE_URL}/zn001/user/fetch_databases/${userEmail}?userOrganization=${encodeURIComponent(
          userOrganization
        )}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response.json();

      if (Array.isArray(responseData.databases)) {
        responseData.databases.sort((a, b) => {
          if (a.isActive && !b.isActive) {
            return -1;
          } else if (!a.isActive && b.isActive) {
            return 1;
          } else {
            return 0;
          }
        });
        const databasesWithLoading = responseData.databases.map((database) => ({
          ...database,
          loading: false,
          connecting: false,
        }));
        this.setState({ databases: databasesWithLoading });
      } else {
        console.error(
          "Data is not an array in the server response:",
          responseData
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  handleDeleteUserData = async (id) => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
      return;
    }
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    try {
      const response = await fetch(
        `${BASE_URL}/zn001/user/databases/${id}?userOrganization=${encodeURIComponent(
          userOrganization
        )}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        // Fetch updated data after deletion
        this.fetchData();
      } else {
        console.error("Failed to delete user data");
      }
    } catch (error) {
      console.error("Error deleting user data:", error);
    } finally {
      this.handleClose();
    }
  };
  fetchUploadedFiles = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
      return;
    }
    const userEmail = localStorage.getItem("email");
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    try {
      const response = await fetch(
        `${BASE_URL}/zn001/user/user_files/${userEmail}?userOrganization=${encodeURIComponent(
          userOrganization
        )}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const files = await response.json();
        this.setState({ file: files.user_files }); // Update the 'file' state with the fetched files
      } else {
        console.error("Failed to fetch uploaded files");
      }
    } catch (error) {
      console.error("Error fetching uploaded files:", error);
    }
  };
  handleDeleteFile = async (fileIds) => {
    const token = localStorage.getItem("token");
    const { selectedFiles } = this.state;
    // const updatedFiles = this.state.selectedFiles.filter(file => !fileIds.includes(file.id));
    // this.setState({ selectedFiles: updatedFiles });
    const filteredFileIds = [];

    // let fileId = undefined;
    let adminFileCount = 0;
    for (let fileId of fileIds) {
      const fileInfo = selectedFiles.find((file) => file.id === fileId);

      if (fileInfo && !fileInfo.isAdmin) {
        filteredFileIds.push(fileId);
      } else {
        adminFileCount = adminFileCount + 1;
      }
    }
    if (adminFileCount > 0) {
      if (adminFileCount === 1) {
        this.handleSnackbarOpen(
          "error",
          `${adminFileCount} file cannot be deleted that is added by admin`
        );
      } else if (adminFileCount > 1) {
        this.handleSnackbarOpen(
          "error",
          `${adminFileCount} files cannot be deleted that are added by admin`
        );
      }
    }
    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/zn001/user/files`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filteredFileIds),
      });

      if (response.ok) {
        // Fetch updated file list after deletion
        this.setState({
          selectedFiles: [],
          selectAllCSV: false,
        });
        this.fetchUploadedFiles();
      } else {
        console.error("Failed to delete files");
      }
    } catch (error) {
      console.error("Error deleting files:", error);
    } finally {
      this.handleClose();
    }
  };

  handleClick = (event, index) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedIndex: index,
    });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue }, () => {
      if (newValue === 2) {
        // You can replace this with the actual logic to navigate to the "Files" tab
      }
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      selectedIndex: -1,
    });
  };

  handleAddDatabase = () => {
    localStorage.removeItem("databaseInfo");

    this.props.handleUploadClick({
      showTab: false,
      showUpload: true,
      showChat: false,
      ShowBook: false,
      databaseInfo: this.state.databaseInfo, // Include the databaseInfo prop
    });
  };

  handleChatClickInDataTable = () => {
    this.props.handleChatClick({
      showTab: false,
      showUpload: false,
      showChat: true,
      ShowBook: false,
      showCSVChat: false,
    });
  };
  handleCSVChatClickInUpload = () => {
    this.props.handleCSVChatClick({
      showTab: false,
      showUpload: false,
      showChat: false,
      ShowBook: false,
      showCSVChat: true,
    });
  };

  handleConnect = async (database, index) => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }

    const { Databasename, hostName, password, userName, dbType, user, id } =
      database;
    try {
      // Set connecting to true when connecting
      this.setState((prevState) => ({
        databases: prevState.databases.map((db) =>
          db === database ? { ...db, connecting: true } : db
        ),
        showBackdrop: true,
      }));

      const formData = new FormData();

      formData.append("userName", userName);
      formData.append("password", password);
      formData.append("hostName", hostName);
      formData.append("databasename", Databasename);
      formData.append("dbType", dbType);
      let endpoint;
      if (dbType === "PostgreSQL") {
        endpoint = `${BASE_URL}/zn001/user/select_tables_postgres`;
      } else if (dbType === "MYSQL") {
        endpoint = `${BASE_URL}/zn001/user/select_tables`;
      } else if (dbType === "Oracle") {
        endpoint = `${BASE_URL}/zn001/user/select_tables_oracle`;
      } else {
        console.error("Unsupported database type");
        return;
      }
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
    
      if (response.ok) {
        const data = await response.json();
        // Store the selected database information in the state
        this.setState({
          selectedDatabase: {
            userName,
            password,
            hostName,
            Databasename,
            database,
            dbType,
            user,
            id,
          },
          rowdata: data.message,
          value: 1,
          connectionSuccessful: true,
        });
      } else {
        const errorData = await response.json();
        this.setState({
          message: errorData.detail,
          connectionSuccessful: false,
        });
        console.error("DB connect error:", errorData);
      }
    } catch (error) {
      this.setState({
        message: "Network error occurred",
        connectionSuccessful: false,
      });
      console.error("Error:", error);
    } finally {
      // Set connecting to false after connection attempt
      this.setState((prevState) => ({
        databases: prevState.databases.map((db) =>
          db === database ? { ...db, connecting: false } : db
        ),
        showBackdrop: false,
      }));
    }
  };

  handleEditDatabase = async (databaseId) => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("Token not found in localStorage");
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }

      const response = await fetch(
        `${BASE_URL}/zn001/user/databases/${databaseId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      const data = await response.json();
      localStorage.setItem("databaseInfo", JSON.stringify(data));

      this.setState(
        {
          databaseInfo: data,
          isEditMode: true, // Set isEditMode to true here
        },
        () => {
          this.props.handleUploadClick({
            showTab: false,
            showUpload: true,
            showChat: false,
            ShowBook: false,
          });
        }
      );
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };
  handleSelectAllClick = () => {
    const { selectAllTables } = this.state;
    const { rowdata } = this.state;

    if (selectAllTables) {
      // If all checkboxes are currently selected, unselect all
      this.setState({
        selectAllTables: false,
        selectedRows: [],
        isContinueButtonVisible: false, // No checkboxes are selected
      });
    } else {
      // If not all checkboxes are selected, select all
      this.setState({
        selectAllTables: true,
        selectedRows: rowdata,
        isContinueButtonVisible: true, // At least one checkbox is selected
      });
    }
  };

  handleCheckboxChange = (id, row) => {
    // const { selectedRows } = this.state;
    const { selectedRows, rowdata } = this.state;
    if (selectedRows.some((selectedRow) => selectedRow.id === id)) {
      this.setState(
        (prevState) => ({
          selectedRows: prevState.selectedRows.filter(
            (selectedRows) => selectedRows.id !== id
          ),
        }),
        () => {
          if (this.state.selectedRows.length === rowdata.length) {
            this.setState({ selectAllTables: true });
          }

          if (this.state.selectedRows.length === rowdata.length - 1) {
            this.setState({
              selectAllTables: false,
              isContinueButtonVisible: false,
            });
          }
          if (this.state.selectedRows.length > 0) {
            this.setState({ isContinueButtonVisible: true });
          }
        }
      );
    } else {
      // Table is not selected, add it to the selected table
      this.setState(
        (prevState) => ({
          selectedRows: [...prevState.selectedRows, { id: id, ...row }],
        }),
        () => {
          if (this.state.selectedRows.length === rowdata.length) {
            this.setState({ selectAllTables: true });
          }
          if (this.state.selectedRows.length === rowdata.length - 1) {
            this.setState({
              selectAllTables: false,
              isContinueButtonVisible: false,
            });
          }
          if (this.state.selectedRows.length > 0) {
            this.setState({ isContinueButtonVisible: true });
          }
        }
      );
    }
    // const { checked } = event.target;
    // let updatedSelectedRows;
    // if (checked) {
    //   this.setState((prevState) => ({
    //     selectedRows: [...prevState.selectedRows, row],
    //     isContinueButtonVisible: true, // Show the "Continue" button
    //   }));
    //   if (this.state.selectedRows.length === rowdata.length-1 ) {
    //     this.setState({ selectAllTables: true });
    //   }
    //   if (this.state.selectedRows.length === rowdata.length -1) {
    //     this.setState({ selectAllTables: false });
    //   }
    // } else {
    //   this.setState((prevState) => {
    //     updatedSelectedRows = prevState.selectedRows.filter(
    //       (selectedRow) => selectedRow.id !== row.id
    //     );
    //     const isAnySelected = updatedSelectedRows.length > 0; // Check if any checkboxes are selected
    //     return {
    //       selectedRows: updatedSelectedRows,
    //       isContinueButtonVisible: isAnySelected, // Update the isContinueButtonVisible state
    //     };
    //   });
    //   if (this.state.selectedRows.length === rowdata.length-1 ) {
    //     this.setState({ selectAllTables: true });
    //   }
    //   if (this.state.selectedRows.length ===  rowdata.length-1 ) {
    //     this.setState({ selectAllTables: false });
    //   }
    // }
  };
  handleSelectAllCSVClick = () => {
    this.setState((prevState) => {
      const { selectAllCSV, file } = prevState;
      const updatedSelectedFiles = selectAllCSV ? [] : [...file];
      return {
        selectedFiles: updatedSelectedFiles,
        selectAllCSV: !selectAllCSV,
        isContinueButtonVisible: updatedSelectedFiles.length > 0,
      };
    });
  };

  handleCheckboxChange_csv = (id, files) => {
    const { selectedFiles, file } = this.state;
    if (selectedFiles.some((selectedFile) => selectedFile.id === id)) {
      this.setState(
        (prevState) => ({
          selectedFiles: prevState.selectedFiles.filter(
            (selectedFile) => selectedFile.id !== id
          ),
        }),
        () => {
          if (this.state.selectedFiles.length === file.length) {
            this.setState({ selectAllCSV: true });
          }

          if (this.state.selectedFiles.length === file.length - 1) {
            this.setState({
              selectAllCSV: false,
              isContinueButtonVisible: false,
            });
          }
          if (this.state.selectedFiles.length > 0) {
            this.setState({ isContinueButtonVisible: true });
          }
        }
      );
    } else {
      // File is not selected, add it to the selected files
      this.setState(
        (prevState) => ({
          selectedFiles: [...prevState.selectedFiles, { id: id, ...files }],
        }),
        () => {
          // console.log("selectedFiles", this.state.selectedFiles);
          if (this.state.selectedFiles.length === file.length) {
            this.setState({ selectAllCSV: true });
          }
          if (this.state.selectedFiles.length === file.length - 1) {
            this.setState({
              selectAllCSV: false,
              isContinueButtonVisible: false,
            });
          }
          if (this.state.selectedFiles.length > 0) {
            this.setState({ isContinueButtonVisible: true });
          }
        }
      );
    }
    // this.setState((prevState) => {
    //   const selectedFiles = prevState.selectedFiles.includes(files)
    //     ? prevState.selectedFiles.filter(
    //         (selectedFile) => selectedFile !== files
    //       )
    //     : [...prevState.selectedFiles, files];

    //   return {
    //     selectedFiles,
    //     isContinueButtonVisible: selectedFiles.length > 0,
    //     // selectAllCSV:selectedFiles.length===0?false:null
    //   };
    // });

    // if (selectedFiles.length === file.length - 1) {
    //   this.setState({ selectAllCSV: true });
    // }
    // if (selectedFiles.length === 1) {
    //   this.setState({ selectAllCSV: false });
    // }
  };

  handleDBConnect = async () => {
    const { selectedDatabase } = this.state;
    const token = localStorage.getItem("token");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";

    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    if(localStorage.getItem("filenameAndSummaryData")){
      localStorage.removeItem("filenameAndSummaryData")
    }
    if(localStorage.getItem("databasenameAndSummary")){
      localStorage.removeItem("databasenameAndSummary")
    }
    this.setState({ showLoader: true });
    try {
      const formData = new FormData();

      // Use the selected database information from state
      formData.append("userName", selectedDatabase.userName);
      formData.append("password", selectedDatabase.password);
      formData.append("hostName", selectedDatabase.hostName);
      formData.append("databasename", selectedDatabase.Databasename);
      formData.append("dbType", selectedDatabase.dbType);
      formData.append("userOrganization", userOrganization);

      const tablenames = this.state.selectedRows.map(
        (entry) => entry.tablename
      );
      formData.append("selectedTables", tablenames.join(","));

      let endpoint;
      if (selectedDatabase.dbType === "PostgreSQL") {
        endpoint = `${BASE_URL}/zn001/bot/db_register_postgres`;
      } else if (selectedDatabase.dbType === "MYSQL") {
        endpoint = `${BASE_URL}/zn001/bot/db_register`;
      } else if (selectedDatabase.dbType === "Oracle") {
        endpoint = `${BASE_URL}/zn001/bot/db_register_oracle`;
      } else {
        console.error("Unsupported database type");
        return;
      }

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if(response.status===500){
        this.handleSnackbarOpen(
          "error",
          "Something went wrong."
        );
      }
      const result = await response.json();
      // console.log(result);

      // Handle response based on status codes or other conditions as needed
      if (response.status === 401) {
        this.props.navigate("/");
      } else if (response.ok) {
        const databasename=result["databasename"];
        const table_summary=result["tables_summary"];
        const databasenameAndSummary = {
          databasename:databasename,
          table_summary:table_summary,
        };
      localStorage.setItem("databasenameAndSummary", JSON.stringify(databasenameAndSummary));

        this.handleChatClickInDataTable();
      } else if (response.status === 403) {
        this.props.navigate("/");
      } else {
        const errorData = result;
        this.setState({
          message: errorData.detail,
        });

        console.error("DB connect error:", errorData);
      }
    } catch (error) {
      this.setState({
        message: "Network error occurred",
      });
      console.error("Error:", error);
    } finally {
      // Hide the loader when the update is complete (whether it succeeds or fails)
      // setTimeout(() => {
        this.setState({ showLoader: false });
      // }, 6000);
    }
  };

  truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  handleAnalyseCSV = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
      return;
    }

    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    if(localStorage.getItem("databasenameAndSummary")){
        localStorage.removeItem("databasenameAndSummary")
    }
    if(localStorage.getItem("filenameAndSummaryData")){
      localStorage.removeItem("filenameAndSummaryData")
    }
    const formData = new FormData();
    this.setState({ showLoader: true });

    try {
      const isAdminTrue = this.state.selectedFiles.some((file) => file.isAdmin);
      const isAdminFalse = this.state.selectedFiles.some(
        (file) => !file.isAdmin
      );

      if (isAdminTrue && isAdminFalse) {
        this.handleSnackbarOpen(
          "error",
          "At a time you can query either admin files or user files."
        );
        return;
      }
      formData.append("filenames", JSON.stringify(this.state.selectedFiles)); // Convert the array to a JSON string
      formData.append("userOrganization", userOrganization);
      const response = await fetch(`${BASE_URL}/zn001/bot/analyse_csv`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData, // Send formData directly as the body
      });
      console.log("response",response);
      if(response.status===500){
        this.handleSnackbarOpen(
          "error",
          "Something went wrong."
        );
      }
      if (response.ok) {
        const res = await response.json();
        const filenames=res["filenames"];
        const file_summary=res["summary_response"];
        // console.log("filenames",filenames);
        // console.log("file_summary",file_summary);
        const filenameAndSummaryData = {};
        filenames.forEach((filename, index) => {
          if (index === 0) {
            filenameAndSummaryData[filename] = file_summary;
        } else {
          filenameAndSummaryData[filename] = "";
        }
      });
      localStorage.setItem("filenameAndSummaryData", JSON.stringify(filenameAndSummaryData));
        this.props.handleCSVChatClick({
          showTab: false,
          showUpload: false,
          showChat: false,
          ShowBook: false,
          showCSVChat: true,
        });
      } else {
        console.error("Error occurred while analyzing CSV");
      }
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    } finally {
      // Hide the loader when the update is complete (whether it succeeds or fails)
      this.setState({ showLoader: false });
    }
  };
  handleToggleSelect = (fileId) => {
    // Logic to toggle selection state of the file with fileId
    // Update the state to re-render the component
    this.setState((prevState) => ({
      selectedFiles: prevState.selectedFiles.includes(fileId)
        ? prevState.selectedFiles.filter((file) => file !== fileId)
        : [...prevState.selectedFiles, fileId],
    }));
  };
  handleSnackbarOpen = (severity, message) => {
    this.setState({
      snackbarOpen: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };
  getusername = (username, isAdmin) => {
    if (isAdmin) {
      return "Admin";
    } else {
      return username;
    }
  };

  render() {
    const {
      anchorEl,
      loading,
      showLoader,
      selectedIndex,
      selectedRows,
      searchQuery,
      csvSearchQuery,
      showBackdrop,
      currentPageDatabases,
      itemsPerPageDatabases,
      value,
      // selectAll,
      selectedDatabase,
      databases,
      rowdata,
      file,
      currentPageFiles,
      currentPageTables,
      itemsPerPageFiles,
      itemsPerPageTables,
      snackbarOpen,
      snackbarSeverity,
      snackbarMessage,
      selectAllCSV,
      selectAllTables,
    } = this.state;
    const filteredDatabases = databases.filter((database) =>
      database.Databasename.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const startIndexDatabases =
      (currentPageDatabases - 1) * itemsPerPageDatabases;
    const endIndexDatabases = startIndexDatabases + itemsPerPageDatabases;

    const startIndexTables = (currentPageTables - 1) * itemsPerPageTables;
    const endIndexTables = startIndexTables + itemsPerPageTables;

    const startIndexFiles = (currentPageFiles - 1) * itemsPerPageFiles;
    const endIndexFiles = startIndexFiles + itemsPerPageFiles;
    const dbTypeToDatabases = {};

    filteredDatabases.forEach((database) => {
      const { dbType } = database;
      if (!dbTypeToDatabases[dbType]) {
        dbTypeToDatabases[dbType] = [];
      }
      dbTypeToDatabases[dbType].push(database);
    });

    // Extract unique dbTypes
    const uniqueDbTypes = Object.keys(dbTypeToDatabases);
    return (
      <Container>
        <Paper
          elevation={5}
          style={{
            marginTop: "5%",
            borderRadius: "20px",
            width: "100%",
            minHeight: "370px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={this.handleChange}
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#6001FF",
                  },
                }}
              >
                <Tab
                  label="Databases"
                  {...a11yProps(0)}
                  sx={{
                    color: value === 0 ? "#6001FF" : "inherit",
                    "&.Mui-selected": {
                      color: "#6001FF",
                    },
                  }}
                />
                <Tab
                  label="tables"
                  {...a11yProps(1)}
                  sx={{
                    color: value === 1 ? "#6001FF" : "inherit",
                    "&.Mui-selected": {
                      color: "#6001FF",
                    },
                  }}
                />
                <Tab
                  label="Files"
                  {...a11yProps(2)}
                  sx={{
                    color: value === 2 ? "#6001FF" : "inherit",
                    "&.Mui-selected": {
                      color: "#6001FF",
                    },
                  }}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              {value === 0 && (
                <Container>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "0px 0px 20px 0px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F3F6FD",
                        borderRadius: "25px",
                        padding: "5px",
                        marginRight: "3%",
                      }}
                    >
                      <SearchIcon
                        sx={{
                          color: "#6001FF",
                          marginLeft: "10px",
                          marginRight: "5px",
                        }}
                      />
                      <InputBase
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) =>
                          this.setState({ searchQuery: e.target.value })
                        }
                        sx={{
                          color: "#6001FF",
                          width: "40%",
                          backgroundColor: "#F3F6FD",
                          borderRadius: "25px",
                        }}
                      />
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      style={{ backgroundColor: "#6001FF", color: "#fff" }}
                      onClick={this.handleAddDatabase}
                    >
                      Add Database
                    </Button>
                  </div>
                  <Paper
                    elevation={0}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      backgroundColor: "#FFF",
                    }}
                  >
                    <div>
                      <List component="nav" aria-label="database list">
                        <ListItem>
                          <ListItemText style={{ width: "3%" }}></ListItemText>
                          <ListItemText style={{ width: "15%" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontSize: "1.1rem",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 400,
                              }}
                            >
                              Database Name
                            </Typography>
                          </ListItemText>
                          <ListItemText style={{ width: "15%" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontSize: "1.1rem",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 400,
                              }}
                            >
                              Last Connected
                            </Typography>
                          </ListItemText>
                          <ListItemText style={{ width: "11%" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontSize: "1.1rem",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 400,
                              }}
                            >
                              Tables
                            </Typography>
                          </ListItemText>
                          <ListItemText style={{ width: "15%" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontSize: "1.1rem",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 400,
                              }}
                            >
                              Users
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <Divider />
                        {databases
                          .filter((database) =>
                            database.Databasename.toLowerCase().includes(
                              searchQuery.trim().toLowerCase()
                            )
                          )
                          .slice(startIndexDatabases, endIndexDatabases)
                          .map((database, index) => (
                            <React.Fragment key={index}>
                              <ListItemButton
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <ListItemIcon
                                  style={{ width: "11%", marginLeft: "1%" }}
                                >
                                  {/* Display the logo based on the databasetype */}
                                  {database.dbType === "MYSQL" && (
                                    <img
                                      src={MYSQLLogo}
                                      alt="MSQL"
                                      style={{ width: "40%", height: "30%" }}
                                    />
                                  )}
                                  {database.dbType === "PostgreSQL" && (
                                    <img
                                      src={PostLogo}
                                      alt="PostgreSQL"
                                      style={{ width: "40%", height: "40%" }}
                                    />
                                  )}
                                  {database.dbType === "Oracle" && (
                                    <img
                                      src={Oracle}
                                      alt="Oracle"
                                      style={{ width: "40%", height: "70%" }}
                                    />
                                  )}
                                  {database.dbType === "S3 Bucket" && (
                                    <img
                                      src={SBucket}
                                      alt="S3 Bucket"
                                      style={{ width: "40%", height: "70%" }}
                                    />
                                  )}
                                  {database.dbType === "Snowflake" && (
                                    <img
                                      src={Snowflake}
                                      alt="Snowflake"
                                      style={{ width: "40%", height: "70%" }}
                                    />
                                  )}
                                  {database.dbType === "Mongo DB" && (
                                    <img
                                      src={MongoDB}
                                      alt="Mongo DB"
                                      style={{ width: "40%", height: "70%" }}
                                    />
                                  )}
                                  {database.dbType === "Spark" && (
                                    <img
                                      src={Spark}
                                      alt="Spark"
                                      style={{ width: "40%", height: "70%" }}
                                    />
                                  )}
                                  {database.dbType === "Hive" && (
                                    <img
                                      src={Hive}
                                      alt="Hive"
                                      style={{ width: "40%", height: "70%" }}
                                    />
                                  )}
                                  {/* Add more conditions for other database types */}
                                </ListItemIcon>
                                <ListItemText style={{ width: "15%" }}>
                                  <Tooltip title={database.Databasename}>
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                      sx={{
                                        fontSize: "1rem",
                                        fontFamily: "Poppins, sans-serif",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {database.Databasename.length > 15
                                        ? `${database.Databasename.substring(
                                            0,
                                            15
                                          )}...`
                                        : database.Databasename}

                                      {database.isActive && (
                                        <span style={{ marginLeft: "5px" }}>
                                          <CircleIcon
                                            style={{
                                              color: "#228B22",
                                              fontSize: "1rem",
                                              verticalAlign: "middle",
                                            }}
                                          />
                                        </span>
                                      )}
                                    </Typography>
                                  </Tooltip>
                                </ListItemText>
                                <ListItemText style={{ width: "15%" }}>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{
                                      fontSize: "1rem",
                                      fontFamily: "Poppins, sans-serif",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {database.last_connected}
                                  </Typography>
                                </ListItemText>
                                <ListItemText style={{ width: "10%" }}>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{
                                      fontSize: "1rem",
                                      fontFamily: "Poppins, sans-serif",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {database.table_count}{" "}
                                    {/* Display the count for the corresponding database */}
                                  </Typography>
                                </ListItemText>
                                <ListItemText style={{ width: "10%" }}>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{
                                      fontSize: "1rem",
                                      fontFamily: "Poppins, sans-serif",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {this.getusername(
                                      database.user,
                                      database.isAdmin
                                    )}
                                  </Typography>
                                </ListItemText>
                                <ListItemIcon style={{ width: "0%" }}>
                                  {!database.isAdmin && (
                                    <IconButton
                                      aria-label="More options"
                                      aria-controls="options-menu"
                                      aria-haspopup="true"
                                      onClick={(event) =>
                                        this.handleClick(event, index)
                                      }
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  )}
                                  <Menu
                                    sx={{ marginLeft: "-8%" }}
                                    id="options-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={
                                      Boolean(anchorEl) &&
                                      selectedIndex === index
                                    }
                                    onClose={this.handleClose}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    PaperProps={{
                                      style: {
                                        width: "9%",
                                      },
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() => {
                                        this.handleConnect(database);
                                        this.handleClose(); // Close the menu when this item is clicked
                                      }}
                                    >
                                      <ListItemIcon>
                                        <LinkIcon />
                                      </ListItemIcon>
                                      Connect
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        this.handleEditDatabase(database.id)
                                      }
                                    >
                                      <ListItemIcon>
                                        <EditIcon />
                                      </ListItemIcon>
                                      Edit
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        this.handleDeleteUserData(database.id)
                                      }
                                    >
                                      <ListItemIcon>
                                        <DeleteIcon />
                                      </ListItemIcon>
                                      Remove
                                    </MenuItem>
                                  </Menu>
                                </ListItemIcon>
                              </ListItemButton>
                              {index !== databases.length - 1 && <Divider />}
                            </React.Fragment>
                          ))}
                      </List>
                    </div>
                    {databases.length > 0 && (
                      <TablePagination
                        component="div"
                        count={databases.length}
                        page={currentPageDatabases - 1}
                        onPageChange={(_, newPage) =>
                          this.setState({ currentPageDatabases: newPage + 1 })
                        }
                        rowsPerPage={itemsPerPageDatabases}
                        onRowsPerPageChange={(event) => {
                          const newRowsPerPage = parseInt(
                            event.target.value,
                            10
                          );
                          this.setState({
                            itemsPerPageDatabases: newRowsPerPage,
                            currentPageDatabases: 1,
                          });
                        }}
                      />
                    )}
                  </Paper>
                </Container>
              )}
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={showBackdrop}
                onClick={this.handleClose} // Close the backdrop when clicked
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              {value === 1 && (
                <Paper
                  elevation={0}
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    backgroundColor: "#FFF",
                    display: "flex",
                  }}
                >
                  <Paper
                    elevation={0}
                    style={{
                      width: "25%",
                      borderRadius: "10px 0px 0px 10px ",
                      backgroundColor: "#FFF",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#F3F6FD",
                          borderRadius: "25px",
                          padding: "5px",
                          marginRight: "3%",
                        }}
                      >
                        <SearchIcon
                          sx={{
                            color: "#6001FF",
                            marginLeft: "10px",
                            marginRight: "5px",
                          }}
                        />
                        <InputBase
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={(e) =>
                            this.setState({ searchQuery: e.target.value })
                          }
                          sx={{
                            color: "#6001FF",
                            width: "60%",
                            backgroundColor: "#F3F6FD",
                            borderRadius: "25px",
                          }}
                        />
                      </Box>
                      {filteredDatabases.length > 0 ? (
                        <List
                          sx={{
                            width: "100%",
                            overflow: "auto",
                            maxHeight: "250px",
                            "& ul": { padding: 0 },
                            scrollbarWidth: "thin",
                            scrollbarColor: "#6001FF #F5F5F5",
                            "&::-webkit-scrollbar": {
                              width: "8px",
                              backgroundColor: "#F5F5F5",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              borderRadius: "4px",
                              backgroundColor: "#6b6f73",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                              backgroundColor: "#555",
                            },
                            "& .scrollbar": {
                              scrollbarWidth: "thin",
                              scrollbarColor: "#6001FF #F5F5F5",
                              overflowY: "auto",
                            },
                          }}
                          subheader={<li />}
                          dense
                        >
                          {uniqueDbTypes.map((dbType, index) => (
                            <li key={`section-${index}`}>
                              <ul>
                                <ListSubheader sx={{ color: "black" }}>
                                  {dbType}
                                </ListSubheader>
                                {dbTypeToDatabases[dbType].map((database) => (
                                  <ListItemButton
                                    key={`item-${database.id}-${database.user}`}
                                    onClick={() => {
                                      this.handleConnect(database, index);
                                    }}
                                    sx={{
                                      borderRadius: "10px",
                                      backgroundColor:
                                        selectedDatabase &&
                                        selectedDatabase.Databasename ===
                                          database.Databasename &&
                                        selectedDatabase.id === database.id
                                          ? "#6001FF"
                                          : "transparent",
                                      "&:hover": {
                                        backgroundColor: "#EFEFEF",
                                      },
                                    }}
                                  >
                                    <ListItemText
                                      primary={database.Databasename}
                                      primaryTypographyProps={{
                                        style: {
                                          color:
                                            selectedDatabase &&
                                            selectedDatabase.Databasename ===
                                              database.Databasename &&
                                            selectedDatabase.id === database.id
                                              ? "#FFF"
                                              : "#6b6f73",
                                        },
                                      }}
                                    />
                                    <Backdrop
                                      sx={{
                                        color: "#fff",
                                        zIndex: (theme) =>
                                          theme.zIndex.drawer + 1,
                                      }}
                                      open={showBackdrop}
                                      onClick={this.handleClose} // Close the backdrop when clicked
                                    >
                                      <CircularProgress color="inherit" />
                                    </Backdrop>
                                  </ListItemButton>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </List>
                      ) : (
                        <p
                          style={{
                            fontSize: "0.9rem",
                            color: "#6b6f73",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 400,
                          }}
                        >
                          No database name found
                        </p>
                      )}
                    </Box>
                  </Paper>
                  <Paper
                    elevation={0}
                    style={{
                      width: "75%",
                      borderRadius: "10px",
                      backgroundColor: "#FFF",
                    }}
                  >
                    <div>
                      <List component="nav" aria-label="datatable list">
                        <ListItem>
                          <ListItemText
                            style={{ width: "5%", marginLeft: "5%" }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontSize: "1.1rem",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 400,
                              }}
                            >
                              ID
                            </Typography>
                          </ListItemText>
                          <ListItemText style={{ width: "15%" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontSize: "1.1rem",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 400,
                              }}
                            >
                              Table Name
                            </Typography>
                          </ListItemText>
                          <ListItemText style={{ width: "11%" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontSize: "1.1rem",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 400,
                              }}
                            >
                              Rows
                            </Typography>
                          </ListItemText>
                          <ListItemText style={{ width: "15%" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontSize: "1.1rem",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 400,
                                ml: 3.5,
                              }}
                            >
                              <Checkbox
                                id="selectAll"
                                sx={{
                                  color: "#6001FF",
                                  "&.Mui-checked": { color: "#6001FF" },
                                }}
                                checked={selectAllTables}
                                onChange={this.handleSelectAllClick} // Call the function on checkbox change
                                size="small"
                              />
                              Select
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <Divider />

                        {Array.isArray(rowdata) &&
                          rowdata
                            .slice(startIndexTables, endIndexTables)
                            .map((rowDataItem, index) => (
                              <React.Fragment key={index}>
                                <ListItemButton
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <ListItemText
                                    style={{ width: "3%", marginLeft: "5%" }}
                                  >
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                      sx={{
                                        fontSize: "1rem",
                                        fontFamily: "Poppins, sans-serif",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {rowDataItem.id}
                                    </Typography>
                                  </ListItemText>
                                  <ListItemText style={{ width: "11%" }}>
                                    <Tooltip
                                      style={{ width: "10%" }}
                                      title={rowDataItem.tablename}
                                      arrow
                                    >
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        sx={{
                                          fontSize: "1rem",
                                          fontFamily: "Poppins, sans-serif",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {this.truncateText(
                                          rowDataItem.tablename,
                                          10
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  </ListItemText>
                                  <ListItemText style={{ width: "10%" }}>
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                      sx={{
                                        fontSize: "1rem",
                                        fontFamily: "Poppins, sans-serif",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {rowDataItem.rows}
                                    </Typography>
                                  </ListItemText>
                                  <ListItemText
                                    style={{ width: "2%", marginRight: "6%" }}
                                  >
                                    <Checkbox
                                      size="small"
                                      checked={this.state.selectedRows.some(
                                        (selectedRow) =>
                                          selectedRow.id === rowDataItem.id &&
                                          selectedRow.tablename ===
                                            rowDataItem.tablename
                                      )}
                                      sx={{
                                        color: "#6001FF",
                                        "&.Mui-checked": { color: "#6001FF" },
                                      }}
                                      onChange={() =>
                                        this.handleCheckboxChange(
                                          rowDataItem.id,
                                          rowDataItem
                                        )
                                      }
                                    />
                                  </ListItemText>
                                </ListItemButton>
                                {index !== rowdata.length - 1 && <Divider />}
                              </React.Fragment>
                            ))}
                      </List>
                    </div>
                    {rowdata.length > 0 &&
                      Array.isArray(rowdata) && ( // Check if there are tables to show
                        <TablePagination
                          component="div"
                          count={rowdata.length}
                          page={currentPageTables - 1}
                          onPageChange={(_, newPage) =>
                            this.setState({ currentPageTables: newPage + 1 })
                          }
                          rowsPerPage={itemsPerPageTables}
                          onRowsPerPageChange={(event) => {
                            const newRowsPerPage = parseInt(
                              event.target.value,
                              10
                            );
                            this.setState({
                              itemsPerPageTables: newRowsPerPage,
                              currentPageTables: 1,
                            });
                          }}
                        />
                      )}
                  </Paper>
                </Paper>
              )}
              {rowdata.length > 0 && (
                <Box
                  m={1}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  {loading ? (
                    <CircularProgress style={{ marginTop: "4%" }} />
                  ) : (
                    this.state.isContinueButtonVisible && (
                      <Button
                        style={{
                          backgroundColor: "#6001ff",
                          marginRight: "1%",
                          textTransform: "none",
                        }}
                        variant="contained"
                        onClick={() =>
                          this.handleDBConnect(this.state.databases)
                        }
                        endIcon={
                          <ArrowCircleRightOutlinedIcon
                            style={{ fontSize: 30 }}
                          />
                        }
                      >
                        {showLoader ? (
                          <CircularProgress
                            size={25}
                            sx={{
                              color: "#FFFFFF",
                              marginLeft: "-7%",
                            }}
                          />
                        ) : (
                          "Query"
                        )}
                      </Button>
                    )
                  )}
                </Box>
              )}
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0px 0px 20px 0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#F3F6FD",
                    borderRadius: "25px",
                    padding: "5px",
                    marginRight: "3%",
                  }}
                >
                  <SearchIcon
                    sx={{
                      color: "#6001FF",
                      marginLeft: "10px",
                      marginRight: "5px",
                    }}
                  />
                  <InputBase
                    placeholder="Search..."
                    value={csvSearchQuery}
                    onChange={(e) =>
                      this.setState({ csvSearchQuery: e.target.value })
                    }
                    sx={{
                      color: "#6001FF",
                      width: "40%",
                      backgroundColor: "#F3F6FD",
                      borderRadius: "25px",
                    }}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  style={{
                    backgroundColor: "#6001FF",
                    color: "#fff",
                    marginRight: "1%",
                  }}
                  onClick={this.handleAddDatabase}
                >
                  Add File
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                {this.state.selectedFiles.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    <Typography variant="body1" color="textSecondary">
                      {`${this.state.selectedFiles.length} ${
                        this.state.selectedFiles.length === 1 ? "file" : "files"
                      } selected`}
                    </Typography>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => {
                        const fileIds = this.state.selectedFiles.map((file) => {
                          return file.id;
                        });
                        this.handleDeleteFile(fileIds);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )}
              </div>

              <Paper
                elevation={0}
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#FFF",
                }}
              >
                <div>
                  <List component="nav" aria-label="file list">
                    <ListItem>
                      <ListItemText style={{ width: "3%" }}></ListItemText>
                      <ListItemText style={{ width: "10%" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: "1.1rem",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 400,
                          }}
                        >
                          File Name
                        </Typography>
                      </ListItemText>
                      <ListItemText style={{ width: "15%" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: "1.1rem",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 400,
                          }}
                        >
                          Upload Date
                        </Typography>
                      </ListItemText>
                      <ListItemText style={{ width: "9%" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: "1.1rem",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 400,
                          }}
                        >
                          Size
                        </Typography>
                      </ListItemText>
                      <ListItemText style={{ width: "8%" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: "1.1rem",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 400,
                          }}
                        >
                          User
                        </Typography>
                      </ListItemText>
                      <ListItemText style={{ width: "8%" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: "1.1rem",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 400,
                            ml: 4,
                          }}
                        >
                          <Checkbox
                            sx={{
                              color: "#6001FF",
                              "&.Mui-checked": { color: "#6001FF" },
                            }}
                            id="selectAll"
                            checked={selectAllCSV}
                            onChange={this.handleSelectAllCSVClick} // Call the function on checkbox change
                            size="small"
                          />
                          Select
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider />

                    {Array.isArray(file) &&
                      file
                        .filter((file) =>
                          file.filename
                            .toLowerCase()
                            .includes(csvSearchQuery.trim().toLowerCase())
                        )
                        .slice(startIndexFiles, endIndexFiles)
                        .map((file, index) => (
                          <React.Fragment key={index}>
                            <ListItemButton
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <ListItemIcon
                                style={{ width: "9.5%", marginLeft: "1%" }}
                              >
                                {/* Display the logo based on the databasetype */}
                                {file.filetype ===
                                  "application/octet-stream" && (
                                  <img
                                    src={TSV}
                                    alt="tsv"
                                    style={{
                                      width: "30%",
                                      height: "30%",
                                      marginLeft: "6%",
                                    }}
                                  />
                                )}
                                {file.filetype ===
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && (
                                  <img
                                    src={XLSX}
                                    alt="xlsx"
                                    style={{ width: "40%", height: "30%" }}
                                  />
                                )}
                                {file.filetype === "text/csv" && (
                                  <img
                                    src={CSV}
                                    alt="text/csv"
                                    style={{ width: "40%", height: "30%" }}
                                  />
                                )}
                                {/* Add more conditions for other database types */}
                              </ListItemIcon>
                              <ListItemText style={{ width: "7%" }}>
                                <Tooltip title={file.filename} placement="top">
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{
                                      fontSize: "1rem",
                                      fontFamily: "Poppins, sans-serif",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {file.filename.length > 10
                                      ? `${file.filename.substring(0, 10)}...`
                                      : file.filename}
                                  </Typography>
                                </Tooltip>
                              </ListItemText>

                              <ListItemText style={{ width: "12%" }}>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                  sx={{
                                    fontSize: "1rem",
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: 400,
                                  }}
                                >
                                  {file.uploaded_date}
                                </Typography>
                              </ListItemText>
                              <ListItemText style={{ width: "6%" }}>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                  sx={{
                                    fontSize: "1rem",
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: 400,
                                  }}
                                >
                                  {file.filesize}
                                </Typography>
                              </ListItemText>
                              <ListItemText style={{ width: "6%" }}>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                  sx={{
                                    fontSize: "1rem",
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: 400,
                                  }}
                                >
                                  {this.getusername(
                                    file.username,
                                    file.isAdmin
                                  )}
                                </Typography>
                              </ListItemText>

                              <ListItemText
                                style={{ width: "2%", marginLeft: "2%" }}
                              >
                                <Checkbox
                                  size="small"
                                  checked={this.state.selectedFiles.some(
                                    (selectedFile) =>
                                      selectedFile.id === file.id &&
                                      selectedFile.filename === file.filename
                                  )}
                                  sx={{
                                    color: "#6001FF",
                                    "&.Mui-checked": { color: "#6001FF" },
                                  }}
                                  onChange={() =>
                                    this.handleCheckboxChange_csv(file.id, file)
                                  }
                                />
                              </ListItemText>
                            </ListItemButton>
                            {index !== file.length - 1 && <Divider />}
                          </React.Fragment>
                        ))}
                  </List>
                </div>
                {file.length > 0 && (
                  <TablePagination
                    component="div"
                    count={file.length}
                    page={currentPageFiles - 1}
                    onPageChange={(_, newPage) =>
                      this.setState({ currentPageFiles: newPage + 1 })
                    }
                    rowsPerPage={itemsPerPageFiles}
                    onRowsPerPageChange={(event) => {
                      const newRowsPerPage = parseInt(event.target.value, 10);
                      this.setState({
                        itemsPerPageFiles: newRowsPerPage,
                        currentPageFiles: 1,
                      });
                    }}
                  />
                )}
              </Paper>
              {file.length > 0 && (
                <Box
                  m={1}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  {loading ? (
                    <CircularProgress style={{ marginTop: "4%" }} />
                  ) : (
                    this.state.isContinueButtonVisible && (
                      <Button
                        style={{
                          backgroundColor: "#6001ff",
                          marginRight: "1%",
                          textTransform: "none",
                        }}
                        variant="contained"
                        onClick={this.handleAnalyseCSV} // Call handleAnalyseCSV function on button click
                        endIcon={
                          <ArrowCircleRightOutlinedIcon
                            style={{ fontSize: 30 }}
                          />
                        }
                        disabled={showLoader} // Disable the button if showLoader is true
                      >
                        {showLoader ? (
                          <CircularProgress
                            size={25}
                            sx={{
                              color: "#FFFFFF",
                              marginLeft: "-7%",
                            }}
                          />
                        ) : (
                          "Query"
                        )}
                      </Button>
                    )
                  )}
                </Box>
              )}
            </CustomTabPanel>
          </Box>
        </Paper>
        <Snackbar
          open={snackbarOpen} // Use the correct state name here (snackbarOpen)
          autoHideDuration={3000}
          onClose={this.handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.handleSnackbarClose}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Container>
    );
  }
}

export default withRouter(DataTabs);
