import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import { Link } from "react-router-dom";
//import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
//import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

import tenzai_logo from "../assets/images/png/tenzai-logo.png";
import { Helmet } from "react-helmet";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';

// const BASE_URL = process.env.REACT_APP_BASE_URL;
class SideNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedButton: localStorage.getItem("selectedButton") || "Data Sources",
    };
  }
  componentDidMount() {
    if (localStorage.getItem("selectedButton") === "chat") {
      const files_path =
        JSON.parse(localStorage.getItem("zn003_files_path")) || [];
      this.props.handleChatClick(
        files_path,
        {
          showTab: false,
          showUpload: false,
          showChat: true,
          ShowBook: false,
          showData: false,
        },
        localStorage.removeItem("selectedButton")
      );
    } else {
      this.handleDatasourceClickInNav();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.showData !== prevProps.showData) {
      // Update the selected button when showChat prop changes
      if (this.props.showData) {
        this.setState({ selectedButton: "Data Sources" });
      }
    }

    if (this.props.showChat !== prevProps.showChat) {
      // Update the selected button when showChat prop changes
      if (this.props.showChat) {
        this.setState({ selectedButton: "chat" });
      }
    }

    if (this.props.showListdata !== prevProps.showListdata) {
      // Update the selected button when showListdata prop changes
      if (this.props.showListdata) {
        this.setState({ selectedButton: "Knowledge" });
      }
    }
  }

  resetmessages = () => {
    this.props.handleChatClick({
      showTab: false,
      showUpload: false,
      showChat: true,
      ShowBook: false,
      showData: false,
    });
    localStorage.setItem("messagesHR", null);
    localStorage.setItem("selectedButtons", null);
    localStorage.setItem("selectedButton", "chat");
    this.setState({ selectedButton: "chat" }, () => {
      window.location.reload();
    });
  };
  handleChatClickInNav = (event) => {
    event.preventDefault();
    this.props.handleChatClick({
      showUpload: false,
      showChat: true,
      showListdata: false,
      showData: false,
    });
    this.setState({ selectedButton: "chat" });
  };

  handlerecentClickInNav = (event) => {
    event.preventDefault();
    this.props.handleChatClick({
      showUpload: false,
      showChat: true,
      showListdata: false,
      showData: false,
    });
    this.setState({ selectedButton: "recent" });
  };

  handleDatasourceClickInNav = () => {
    this.props.handleDatasourceClick({
      showUpload: true,
      showChat: false,
      showListdata: false,
      showData: true,
    });
    this.setState({ selectedButton: "Data Sources" });
  };

  handleListdataClickInNav = () => {
    this.props.handleListdataClick({
      showUpload: false,
      showChat: false,
      showListdata: true,
      showData: false,
    });
    this.setState({ selectedButton: "Knowledge" });
  };

  handleBookClickInNav = () => {
    this.props.handleBookClick({
      showUpload: false,
      showChat: false,
      ShowBook: true,
      showData: false,
    });
    this.setState({ selectedButton: "Bookmark" });
  };
  handlePromptTemplateClickInNav = () => {
    this.props.handlePromptTemplateClick({
     showUpload: false,
      showChat: false,
      ShowBook: false,
      showData: false,
      showListdata: false,
      showPromptTemplate: true,
    });
    this.setState({ selectedButton: "Prompt Template" });
  };

  render() {
    const { selectedButton } = this.state;
    return (
      <>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <nav aria-label="main mailbox folders" sx={{ position: "fixed" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <Link to="/home">
              <img
                src={tenzai_logo}
                alt="logo"
                style={{
                  cursor: "pointer",
                  width: "175px",
                  height: "100px",
                  position: "absolute",
                  top: "10px",
                  left: "20px",
                }}
              />
            </Link>
          </div>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                href="#"
                onClick={this.handleChatClickInNav}
                sx={{
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  backgroundColor:
                    selectedButton === "chat" ? "#6001FF" : "transparent",
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
              >
                <ListItemIcon
                  sx={{ color: selectedButton === "chat" ? "#FFF" : "#000" }}
                >
                  <ChatBubbleOutlineIcon
                    sx={{
                      color: selectedButton === "chat" ? "#FFF" : "#6001FF",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Chat"
                  sx={{
                    color: selectedButton === "chat" ? "#FFF" : "#000",
                    fontSize: "1.3rem",
                  }}
                  disableTypography
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                href="#"
                onClick={this.handleBookClickInNav}
                sx={{
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  backgroundColor:
                    selectedButton === "Bookmark" ? "#6001FF" : "transparent",
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color: selectedButton === "Bookmark" ? "#FFF" : "#000",
                  }}
                >
                  <BookmarkBorderOutlinedIcon
                    sx={{
                      color: selectedButton === "Bookmark" ? "#FFF" : "#6001FF",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Bookmark"
                  sx={{
                    color: selectedButton === "Bookmark" ? "#FFF" : "#000",
                    fontSize: "1.3rem",
                  }}
                  disableTypography
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                href="#"
                sx={{
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  backgroundColor:
                    selectedButton === "reset chat" ? "#6001FF" : "transparent",
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
                onClick={this.resetmessages}
              >
                <ListItemIcon
                  sx={{
                    color: selectedButton === "reset chat" ? "#FFF" : "#000",
                  }}
                >
                  <RestartAltOutlinedIcon
                    sx={{
                      color:
                        selectedButton === "reset chat" ? "#FFF" : "#6001FF",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Reset Chat"
                  sx={{
                    color: selectedButton === "reset chat" ? "#FFF" : "#000",
                    fontSize: "1.3rem",
                  }}
                  disableTypography
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                href="#"
                onClick={this.handleDatasourceClickInNav}
                sx={{
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  backgroundColor:
                    selectedButton === "Data Sources"
                      ? "#6001FF"
                      : "transparent",
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color: selectedButton === "Data Sources" ? "#FFF" : "#000",
                  }}
                >
                  <UploadFileOutlinedIcon
                    sx={{
                      color:
                        selectedButton === "Data Sources" ? "#FFF" : "#6001FF",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Data Sources"
                  sx={{
                    color: selectedButton === "Data Sources" ? "#FFF" : "#000",
                    fontSize: "1.3rem",
                  }}
                  disableTypography
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
                            <ListItemButton href='#' onClick={this.handlePromptTemplateClickInNav}
                                sx={{
                                    borderTopRightRadius: '30px', borderBottomRightRadius: '30px',
                                    backgroundColor: selectedButton === "Prompt Template" ? '#6001FF' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: '#EFEFEF'
                                    },
                                }}
                            >
                                <ListItemIcon sx={{ color: selectedButton === "Prompt Template" ? '#FFF' : '#000' }}>
                                    <StickyNote2Icon sx={{ color: selectedButton === "Prompt Template" ? '#FFF' : '#6001FF' }} />
                                </ListItemIcon>
                                <ListItemText primary="Prompt Template" sx={{ color: selectedButton === "Prompt Template" ? '#FFF' : '#000', fontSize: "1.3rem" }} disableTypography />
                            </ListItemButton>
                        </ListItem>

          </List>
        </nav>
      </>
    );
  }
}

export default SideNav;
