import React, { Component } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Card,
  Paper,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  FormGroup,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import CustomMenu from "@mui/material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import Menu from "../../Menu";
import { withRouter } from "../withRouter";
import Utils from "../../../userComponents/Utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};
const selectFieldSx = {
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
  },
  "& .MuiMenu-paper": {
    maxHeight: "200px", // Set the maximum height for the dropdown
    maxWidth: "100px",
  },
};
const LLMModel = [
  {
    value: "gpt-3.5-turbo",
    label: "gpt-3.5-turbo",
  },
  {
    value: "gpt-3.5-turbo-16k",
    label: "gpt-3.5-turbo-16k",
  },
  {
    value: "gpt-3.5-turbo-0613",
    label: "gpt-3.5-turbo-0613",
  },
  {
    value: "gpt-3.5-turbo-16k-0613",
    label: "gpt-3.5-turbo-16k-0613",
  },
  {
    value: "gpt-4o-mini",
    label: "gpt-4o-mini",
  }
  // {
  //   value: 'gpt-3.5-turbo-1106',
  //   label: 'gpt-3.5-turbo-1106',
  // },
];

const llamaindexChunkingstrategies = [
  {
    value: "Recursive Chunking",
    label: "Recursive Chunking",
  },
  {
    value: "Markdown",
    label: "Markdown",
  },
  {
    value: "Token Splitting",
    label: "Token Splitting",
  },
  {
    value: "NLTK",
    label: "NLTK",
  },
  {
    value: "Spacy",
    label: "Spacy",
  },
];

const langchainChunkingStrategies = [
  ...llamaindexChunkingstrategies,
  {
    value: "Parent Document Retriver",
    label: "Parent Document Retriver",
  },
];

const Chunksize = [
  {
    value: "512",
    label: "512",
  },
  {
    value: "1024",
    label: "1024",
  },
  {
    value: "2048",
    label: "2048",
  },
];

const Chunkoverlap = [
  {
    value: "0",
    label: "0",
  },
  {
    value: "100",
    label: "100",
  },
  {
    value: "200",
    label: "200",
  },
  {
    value: "300",
    label: "300",
  },
  {
    value: "400",
    label: "400",
  },
];

const Embeddings = [
  {
    value: "HuggingFace",
    label: "HuggingFace",
  },
  {
    value: "OpenAI",
    label: "OpenAI",
  },
  {
    value: "HYDE",
    label: "HYDE",
  },
];

const LLamaIndexEmbeddings = [
  {
    value: "HuggingFace",
    label: "HuggingFace",
  },

  {
    value: "HYDE",
    label: "HYDE",
  },
];

const IndexType = [
  {
    value: "HNSW",
    label: "HNSW",
  },
  {
    value: "IVF_PQ",
    label: "IVF_PQ",
  },
  {
    value: "AUTOINDEX",
    label: "AUTOINDEX",
  },
];

const llamaSimilarityMatrix = [
  {
    value: "L2",
    label: "L2",
  },
  {
    value: "IP",
    label: "IP",
  },
];

const langchainSimilarityMatrix = [
  {
    value: "L2",
    label: "L2",
  },
  {
    value: "IP",
    label: "IP",
  },
  {
    value: "COSINE",
    label: "COSINE",
  },
];
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 4 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

class CustomizePromptsDocs extends Component {
  constructor(props) {
    super(props);
    const defaultValue1 = Embeddings.find(
      (option) => option.value === "HuggingFace"
    );
    const defaultValue2 = IndexType.find(
      (option) => option.value === "AUTOINDEX"
    );
    let url = window.location.href;
    let params = new URL(url).searchParams;
    let page = params.get("p");
    this.state = {
      value: parseInt(page), // Set the default tab index to 0
      profilePic: null,
      assistantName: "",
      tasks: "",
      outputPresentation: "",
      responseLength: "",
      avoidActions: "",
      selectedCheckboxes: {
        dataAnalysis: false,
        informationExtraction: false,
        accessLLMCorpus: false,
      },
      checkboxArray: [],
      showSnackbar: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
      EmbeddingllamaValue: defaultValue1,
      IndexTypellamaValue: defaultValue2,
      dropdown1Value: "",
      dropdown2Value: "",
      isLangchainChecked: true,
      isLlamaIndexChecked: false,
      languageModel: "gpt-3.5-turbo-16k",
      modelTemperature: 0.4,
      langchainChunkingStrategy: {
        value: "Recursive Chunking",
        label: "Recursive Chunking",
      },
      llamaindexChunkingStrategy: {
        value: "Recursive Chunking",
        label: "Recursive Chunking",
      },
      langchainChunkSize: {
        value: "1024",
        label: "1024",
      },
      llamaindexChunksize: {
        value: "1024",
        label: "1024",
      },
      langchainChunkOverlap: {
        value: "100",
        label: "100",
      },
      llamaindexChunkOverlap: {
        value: "100",
        label: "100",
      },
      defualtlangchainSimilarityMatrix: {
        value: "L2",
        label: "L2",
      },
      llamaindexSimilarityMatrix: {
        value: "L2",
        label: "L2",
      },
      langchainEmbedding: {
        value: "HuggingFace",
        label: "HuggingFace",
      },
      llamaindexEmbedding: {
        value: "HuggingFace",
        label: "HuggingFace",
      },
      langchainIndex: {
        value: "HNSW",
        label: "HNSW",
      },
      langchainTopK: 4,
      llamaindexTopK: 4,
      langchainCacheReuse: true,
      llamaindexCacheReuse: true,
      anchorEl: "",
      methodsCalled: localStorage.getItem("methodsCalled")
        ? JSON.parse(localStorage.getItem("methodsCalled"))
        : {},
      botImages: localStorage.getItem("botImages")
        ? JSON.parse(localStorage.getItem("botImages"))
        : {},

      isChatMemoryEnabled: false,
      customizePromptAlert: false,
    };
    // we need to store previous data for alert pop up after clicking on Menu Icon
    this.prevState = {};
  }

  async componentDidMount() {
    await this.fetchPromtDetails();
    await this.fetchLLmConfigDetails();
    //we need to store previous data for alert pop up after clicking on Menu Icon
    // this.prevState={...this.state}
  }
  async componentDidUpdate() {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
  }

  resetState = () => {
    const defaultValue1 = Embeddings.find(
      (option) => option.value === "HuggingFace"
    );
    const defaultValue2 = IndexType.find(
      (option) => option.value === "AUTOINDEX"
    );

    this.setState(
      {
        showSnackbar: false,
        snackbarSeverity: "success",
        snackbarMessage: "",
        EmbeddingllamaValue: defaultValue1,
        IndexTypellamaValue: defaultValue2,
        dropdown1Value: "",
        dropdown2Value: "",
        languageModel: "gpt-3.5-turbo-16k",
        modelTemperature: 0.4,
        langchainChunkingStrategy: {
          value: "Recursive Chunking",
          label: "Recursive Chunking",
        },
        llamaindexChunkingStrategy: {
          value: "Recursive Chunking",
          label: "Recursive Chunking",
        },
        langchainChunkSize: {
          value: "1024",
          label: "1024",
        },
        llamaindexChunkSize: {
          value: "1024",
          label: "1024",
        },
        langchainChunkOverlap: {
          value: "100",
          label: "100",
        },
        llamaindexChunkOverlap: {
          value: "100",
          label: "100",
        },
        defualtlangchainSimilarityMatrix: {
          value: "L2",
          label: "L2",
        },
        llamaindexSimilarityMatrix: {
          value: "L2",
          label: "L2",
        },
        langchainEmbedding: {
          value: "HuggingFace",
          label: "HuggingFace",
        },
        llamaindexEmbedding: {
          value: "HuggingFace",
          label: "HuggingFace",
        },
        langchainIndex: {
          value: "HNSW",
          label: "HNSW",
        },
        langchainTopK: 4,
        llamaindexTopK: 4,
        langchainCacheReuse: true,
        llamaindexCacheReuse: true,
      },
      this.handleSaveLLMConfigChanges
    );
  };
  handleResetCustomizedPrompts = () => {
    this.setState(
      {
        profilePic: null,
        assistantName: "",
        tasks: "",
        outputPresentation: "",
        responseLength: "",
        avoidActions: "",
        selectedCheckboxes: {
          dataAnalysis: false,
          informationExtraction: false,
          accessLLMCorpus: false,
        },
        checkboxArray: [],
      },
      this.handleSaveChanges
    );
  };

  fetchPromtDetails = async () => {
    const token = localStorage.getItem("token");

    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const response = await fetch(
        `${BASE_URL}/admin/customize_prompts/${userOrganization}?applicationType=${encodeURIComponent(
          "zunofordocs"
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const result = await response.json();
        if (result.message) {
          // here the user is creating the prompt for first time
          return;
        } else {
          const equipAssistant = result.equip_assistant;
          const checkboxArray = Object.keys(equipAssistant).filter(
            (key) => equipAssistant[key]
          );
          this.setState(
            {
              profilePic: result.profilePic,
              assistantName: result.assistantName,
              tasks: result.tasks,
              outputPresentation: result.outputPresentation,
              responseLength: result.responseLength,
              avoidActions: result.avoidActions,
              selectedCheckboxes: equipAssistant,
              checkboxArray: checkboxArray,
            },
            () => {
              this.prevState = { ...this.state };
            }
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  fetchLLmConfigDetails = async () => {
    const token = localStorage.getItem("token");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const response = await fetch(
        `${BASE_URL}/admin/llm_configuration/${userOrganization}?applicationType=${encodeURIComponent(
          "zunofordocs"
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const result = await response.json();

        if (result.message) {
          // here the user is creating the prompt for the first time
          return;
        } else {
          const langchain_configuration = result.llm_configuration["Langchain"];
          const llamaindex_configuration =
            result.llm_configuration["Llamaindex"];
          this.setState(
            {
              isLangchainChecked: result.selected_models["Langchain"],
              isLlamaIndexChecked: result.selected_models["Llamaindex"],
              languageModel: langchain_configuration.llm_model,
              modelTemperature: langchain_configuration.temperature,
              isChatMemoryEnabled: langchain_configuration.isChatMemoryEnabled,
              langchainChunkingStrategy: {
                value: langchain_configuration.chunking_strategies,
                label: langchain_configuration.chunking_strategies,
              },
              llamaindexChunkingStrategy: {
                value: llamaindex_configuration.chunking_strategies,
                label: llamaindex_configuration.chunking_strategies,
              },
              langchainChunkSize: {
                value: langchain_configuration.chunk_size,
                label: langchain_configuration.chunk_size,
              },
              llamaindexChunksize: {
                value: llamaindex_configuration.chunk_size,
                label: llamaindex_configuration.chunk_size,
              },
              langchainChunkOverlap: {
                value: langchain_configuration.chunk_overlap,
                label: langchain_configuration.chunk_overlap,
              },
              llamaindexChunkOverlap: {
                value: llamaindex_configuration.chunk_overlap,
                label: llamaindex_configuration.chunk_overlap,
              },
              defualtlangchainSimilarityMatrix: {
                value: langchain_configuration.similarity_metric,
                label: langchain_configuration.similarity_metric,
              },
              llamaindexSimilarityMatrix: {
                value: llamaindex_configuration.similarity_metric,
                label: llamaindex_configuration.similarity_metric,
              },
              langchainEmbedding: {
                value: langchain_configuration.embedding_type,
                label: langchain_configuration.embedding_type,
              },
              llamaindexEmbedding: {
                value: llamaindex_configuration.embedding_type,
                label: llamaindex_configuration.embedding_type,
              },
              langchainIndex: {
                value: langchain_configuration.index_type,
                label: langchain_configuration.index_type,
              },
              langchainTopK: langchain_configuration.top_k,
              llamaindexTopK: llamaindex_configuration.top_k,
              langchainCacheReuse: langchain_configuration.cache_reuse,
              llamaindexCacheReuse: llamaindex_configuration.cache_reuse,
            },
            () => {
              this.prevState = { ...this.state };
            }
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  handleCheckboxChange = (checkboxName) => {
    this.setState({ customizePromptAlert: true });

    this.setState((prev) => {
      const updatedCheckboxes = {
        ...prev.selectedCheckboxes,
        [checkboxName]: !prev.selectedCheckboxes[checkboxName],
      };
      const updatedCheckboxArray = Object.keys(updatedCheckboxes).filter(
        (checkbox) => updatedCheckboxes[checkbox]
      );

      return {
        selectedCheckboxes: updatedCheckboxes,
        checkboxArray: updatedCheckboxArray,
      };
    });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue, customizePromptAlert: true });
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      customizePromptAlert: true,
    });
  };
  handleEnableButton = (event) => {
    const { name, checked } = event.target;
    this.setState({
      [name]: checked,
      customizePromptAlert: true,
    });
  };

  handleSaveChanges = async () => {
    this.prevState = { ...this.state }; //we need to store previous data for alert pop up after clicking on Menu Icon
    this.setState({ customizePromptAlert: false });
    const token = localStorage.getItem("token");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    const formData = {
      applicationType: "zunofordocs",
      organization: userOrganization,
      equip_assistant: this.state.selectedCheckboxes, // Corrected variable name
      profilePic: this.state.profilePic,
      assistantName: this.state.assistantName,
      tasks: this.state.tasks,
      outputPresentation: this.state.outputPresentation,
      responseLength: this.state.responseLength,
      avoidActions: this.state.avoidActions,
    };

    try {
      const response = await fetch(`${BASE_URL}/admin/customize_prompts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        this.handleSnackbarOpen(
          "success",
          "Successfully saved prompt template"
        );
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error("Error saving data:", error);
      // Handle errors, show an error message, etc.
    }
    const methodCalled = { ...this.state.methodsCalled };
    const botImage = { ...this.state.botImages };
    botImage["profilePicDocs"] = this.state.profilePic;
    methodCalled["DocsgetProfileData"] = true;
    this.setState({ methodsCalled: methodCalled, botImages: botImage }, () => {
      localStorage.setItem(
        "methodsCalled",
        JSON.stringify(this.state.methodsCalled)
      );
      localStorage.setItem("botImages", JSON.stringify(this.state.botImages));
    });
  };

  handleSaveLLMConfigChanges = async () => {
    this.prevState = { ...this.state }; //we need to store previous data for alert pop up after clicking on Menu Icon
    this.setState({ customizePromptAlert: false });

    const token = localStorage.getItem("token");

    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    let finallmConfig = {};
    let langchainConfig;
    let llamaindexConfig;

    if (this.state.isLangchainChecked) {
      langchainConfig = {
        llm_model: this.state.languageModel,
        chunking_strategies: this.state.langchainChunkingStrategy.value,
        chunk_size: this.state.langchainChunkSize.value,
        chunk_overlap: this.state.langchainChunkOverlap.value,
        embedding_type: this.state.langchainEmbedding.value,
        index_type: this.state.langchainIndex.value,
        similarity_metric: this.state.defualtlangchainSimilarityMatrix.value,
        temperature: this.state.modelTemperature,
        top_k: this.state.langchainTopK,
        cache_reuse: this.state.langchainCacheReuse,
        isChatMemoryEnabled: this.state.isChatMemoryEnabled,
      };
      finallmConfig["Langchain"] = langchainConfig;
    }
    if (this.state.isLlamaIndexChecked) {
      llamaindexConfig = {
        llm_model: this.state.languageModel,
        chunking_strategies: this.state.llamaindexChunkingStrategy.value,
        chunk_size: this.state.llamaindexChunksize.value,
        chunk_overlap: this.state.llamaindexChunkOverlap.value,
        embedding_type: this.state.llamaindexEmbedding.value,
        index_type: "AUTOINDEX",
        similarity_metric: this.state.llamaindexSimilarityMatrix.value,
        temperature: this.state.modelTemperature,
        top_k: this.state.llamaindexTopK,
        cache_reuse: this.state.llamaindexCacheReuse,
        isChatMemoryEnabled: this.state.isChatMemoryEnabled,
      };
      finallmConfig["Llamaindex"] = llamaindexConfig;
    }

    const formData = {
      organization: userOrganization,
      applicationType: "zunofordocs",
      llm_config: finallmConfig,
      selected_models: {
        Langchain: this.state.isLangchainChecked,
        Llamaindex: this.state.isLlamaIndexChecked,
      },
    };

    try {
      const response = await fetch(`${BASE_URL}/admin/llm_configuration_docs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        this.handleSnackbarOpen(
          "success",
          "Successfully saved LLM Configuration"
        );
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      this.handleSnackbarOpen("error", error);
    }
  };

  handleProfilePicChange = (event) => {
    // Handle profile picture change
    const file = event.target.files[0];

    if (file) {
      if (file.size > 1048576) {
        // toast.error("File size should be less than 1MB");
        this.handleSnackbarOpen("error", "File size should be less than 1MB");
        return;
      }
      const reader = new FileReader();

      reader.onloadend = () => {
        this.setState({
          profilePic: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  handleSnackbarOpen = (severity, message) => {
    this.setState({
      showSnackbar: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({ showSnackbar: false });
  };
  handleDropdownChange = (listIndex, selectedValue) => {
    if (listIndex === 1) {
      this.setState({ dropdown1Value: selectedValue });
    } else if (listIndex === 2) {
      this.setState({ dropdown2Value: selectedValue });
    }
  };

  handleLangchainCheckboxChange = (event) => {
    this.setState({
      isLangchainChecked: event.target.checked,
      customizePromptAlert: true,
    });
  };
  handleLlamaIndexCheckboxChange = (event) => {
    this.setState({
      isLlamaIndexChecked: event.target.checked,
      customizePromptAlert: true,
    });
  };
  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleRemovePicture = () => {
    this.setState({ profilePic: null, anchorEl: null });
  };
  handleUploadPicture = () => {
    this.fileInput.click();
    this.handleMenuClose();
  };

  handleSideMenuClick = (event) => {
    if (this.state.customizePromptAlert) {
      // Show an alert or confirmation dialog
      const userConfirmed = window.confirm(
        "You have unsaved changes. Are you sure you want to leave this page?"
      );
      // console.log("entered handleSideMenuClick",userConfirmed);
      if (!userConfirmed) {
        // If user cancels, prevent navigation
        // console.log("!userConfirmed");
        return true;
      } else {
        // console.log("userConfirmed");
        this.setState({ customizePromptAlert: false });
        this.fetchPromtDetails();
        this.fetchLLmConfigDetails();
        return false;
      }
    }
  };

  checkAllStates = async () => {
    if (this.state.profilePic !== this.prevState.profilePic) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.assistantName !== this.prevState.assistantName) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.tasks !== this.prevState.tasks) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.outputPresentation !== this.prevState.outputPresentation) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.responseLength !== this.prevState.responseLength) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.avoidActions !== this.prevState.avoidActions) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (
      this.state.selectedCheckboxes.dataAnalysis !==
      this.prevState.selectedCheckboxes.dataAnalysis
    ) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (
      this.state.selectedCheckboxes.informationExtraction !==
      this.prevState.selectedCheckboxes.informationExtraction
    ) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (
      this.state.selectedCheckboxes.accessLLMCorpus !==
      this.prevState.selectedCheckboxes.accessLLMCorpus
    ) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.languageModel !== this.prevState.languageModel) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.modelTemperature !== this.prevState.modelTemperature) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.langchainCacheReuse !== this.prevState.langchainCacheReuse) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (
      this.state.llamaindexCacheReuse !== this.prevState.llamaindexCacheReuse
    ) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.isChatMemoryEnabled !== this.prevState.isChatMemoryEnabled) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if(this.state.langchainChunkingStrategy.value !== this.prevState.langchainChunkingStrategy.value){
      this.setState({ customizePromptAlert: true });
      return;
    }
    if(this.state.llamaindexChunkingStrategy.value !== this.prevState.llamaindexChunkingStrategy.value){
      this.setState({ customizePromptAlert: true });
      return;
    }
    if(this.state.langchainChunkSize.value !== this.prevState.langchainChunkSize.value){
      this.setState({ customizePromptAlert: true });
      return;
    }
    if(this.state.llamaindexChunksize.value !== this.prevState.llamaindexChunksize.value){
      this.setState({ customizePromptAlert: true });
      return;
    }
    if(this.state.langchainChunkOverlap.value !== this.prevState.langchainChunkOverlap.value){
      this.setState({ customizePromptAlert: true });
      return;
    }
    if(this.state.llamaindexChunkOverlap.value !== this.prevState.llamaindexChunkOverlap.value){
      this.setState({ customizePromptAlert: true });
      return;
    }
    if(this.state.defualtlangchainSimilarityMatrix.value !== this.prevState.defualtlangchainSimilarityMatrix.value){
      this.setState({ customizePromptAlert: true });
      return;
    }
    if(this.state.langchainEmbedding.value !== this.prevState.langchainEmbedding.value){
      this.setState({ customizePromptAlert: true });
      return;
    }
    if(this.state.llamaindexEmbedding.value !== this.prevState.llamaindexEmbedding.value){
      this.setState({ customizePromptAlert: true });
      return;
    }
    if(this.state.langchainIndex.value !== this.prevState.langchainIndex.value){
      this.setState({ customizePromptAlert: true });
      return;
    }
    if(this.state.langchainIndex.value !== this.prevState.langchainIndex.value){
      this.setState({ customizePromptAlert: true });
      return;
    }
    if(this.state.langchainTopK !== this.prevState.langchainTopK){
      this.setState({ customizePromptAlert: true });
      return;
    }
    if(this.state.llamaindexTopK !== this.prevState.llamaindexTopK){
      this.setState({ customizePromptAlert: true });
      return;
    }

    this.setState({ customizePromptAlert: false });
  };

  render() {
    const {
      value,
      profilePic,
      assistantName,
      tasks,
      avoidActions,
      outputPresentation,
      responseLength,
      showSnackbar,
      snackbarSeverity,
      snackbarMessage,
      isLangchainChecked,
      isLlamaIndexChecked,
      EmbeddingllamaValue,
      IndexTypellamaValue,
      modelTemperature,
      llamaindexChunkingStrategy,
      llamaindexChunksize,
      llamaindexChunkOverlap,
      llamaindexEmbedding,
      llamaindexSimilarityMatrix,
      llamaindexTopK,
      llamaindexCacheReuse,
      anchorEl,
      customizePromptAlert,
    } = this.state;
    const open = Boolean(anchorEl);
    const {
      langchainChunkingStrategy,
      langchainChunkSize,
      langchainChunkOverlap,
      langchainEmbedding,
      langchainIndex,
      defualtlangchainSimilarityMatrix,
      langchainTopK,
      langchainCacheReuse,
    } = this.state;

    const { languageModel, isChatMemoryEnabled } = this.state;
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "2%",
        }}
      >
        <Menu
          selectedButton="App Management"
          customizePromptAlert={customizePromptAlert}
          onMenuClick={this.handleSideMenuClick}
          checkAllStates={this.checkAllStates}
        />
        <Typography
          variant="h1"
          component="div"
          sx={{
            flexGrow: 1,
            color: "#6001FF",
            fontSize: "2rem",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 600,
          }}
        >
          Zuno For Docs
        </Typography>
        <Card
          elevation={2}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "20px",
            marginTop: "4%",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              textColor="#6001FF"
              onChange={this.handleChange}
              aria-label="basic tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#6001FF",
                },
              }}
            >
              <Tab
                label="LLM Configurations"
                {...a11yProps(0)}
                sx={{
                  color: value === 0 ? "#6001FF" : "inherit",
                  "&.Mui-selected": {
                    color: "#6001FF",
                  },
                }}
              />
              <Tab
                label="Customize Prompts"
                {...a11yProps(1)}
                sx={{
                  color: value === 1 ? "#6001FF" : "inherit",
                  "&.Mui-selected": {
                    color: "#6001FF",
                  },
                }}
              />
              <Tab
                label="Manage Data Resources"
                {...a11yProps(2)}
                sx={{
                  color: value === 2 ? "#6001FF" : "inherit",
                  "&.Mui-selected": {
                    color: "#6001FF",
                  },
                }}
              />
              <Tab
                label="Deployment Settings"
                {...a11yProps(3)}
                sx={{
                  color: value === 3 ? "#6001FF" : "inherit",
                  "&.Mui-selected": {
                    color: "#6001FF",
                  },
                }}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "50ch" },
                display: "flex",
                alignItems: "center",
              }}
              noValidate
              autoComplete="off"
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                style={{
                  fontSize: "1.2rem",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Choose your language model
              </Typography>
              <TextField
                id="LanguageModel"
                select
                label="Language Model"
                value={languageModel}
                style={{ marginLeft: "10%" }}
                name="languageModel"
                onChange={this.handleInputChange}
                sx={textFieldSx}
              >
                {LLMModel.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box
              component="form"
              sx={{
                marginTop: "2%",
                "& .MuiSlider-root": { m: 2, marginTop: "2%", width: "50ch" },
                "& .MuiSlider-valueLabel": {
                  lineHeight: 1.2,
                  fontSize: 12,
                  background: "unset",
                  padding: 0,
                  width: 32,
                  height: 32,
                  borderRadius: "50% 50% 50% 0",
                  backgroundColor: "#6001FF",
                  transformOrigin: "bottom left",
                  transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
                  "&:before": { display: "none" },
                  "&.MuiSlider-valueLabelOpen": {
                    transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
                  },
                  "& > *": {
                    transform: "rotate(45deg)",
                  },
                },

                display: "flex",
                alignItems: "center",
              }}
              noValidate
              autoComplete="off"
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                style={{
                  fontSize: "1.2rem",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Set the temperature for your model
              </Typography>
              <Slider
                valueLabelDisplay="auto"
                aria-labelledby="discrete-slider"
                marks={[
                  { value: 0, label: "0" },
                  { value: 1, label: "1" },
                ]}
                value={modelTemperature}
                step={0.1}
                min={0}
                max={1}
                slotProps={{
                  markLabel: {
                    style: {
                      transform: "translateY(-10px) translateX(-3.3px)", // Adjust this value as needed
                      fontFamily: "Poppins, sans-serif", // Optional: Keep consistent with your typography style
                      fontSize: "1.2rem", // Optional: Keep consistent with your typography style
                    },
                  },
                }}
                style={{
                  color: "#6001FF",
                  marginLeft: "6.7%",
                  marginBottom: "2%",
                }}
                name="modelTemperature"
                onChange={this.handleInputChange}
              />
            </Box>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{ display: "flex", flexDirection: "row", marginTop: "2%" }}
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                style={{
                  fontSize: "1.2rem",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Enable chat memory
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={isChatMemoryEnabled}
                    inputProps={{
                      "aria-label": "Enable model chat memory",
                      style: {
                        "&.MuiChecked": {
                          color: "#6001FF", // Color when checked
                        },
                        "&.MuiDisabled": {
                          color: "#818589", // Color when disabled
                        },
                      },
                    }}
                    onChange={this.handleEnableButton}
                    name="isChatMemoryEnabled"
                  />
                }
                // label="Enable chat memory"
                labelPlacement="start"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "14%",
                }}
              />
            </Box>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{ display: "flex", flexDirection: "row", marginTop: "4%" }}
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                style={{
                  fontSize: "1.2rem",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Choose your LLM Framework
              </Typography>
              <FormGroup
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "10%",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isLlamaIndexChecked}
                      onChange={this.handleLlamaIndexCheckboxChange}
                    />
                  }
                  label="Llama Index"
                  style={{ "&.MuiChecked": { color: "#6001FF" } }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isLangchainChecked}
                      onChange={this.handleLangchainCheckboxChange}
                    />
                  }
                  label="Langchain"
                  style={{ "&.MuiChecked": { color: "#6001FF" } }}
                />
              </FormGroup>
            </Box>

            <Box
              style={{ display: "flex", flexDirection: "row", marginTop: "5%" }}
            >
              <Box>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ textAlign: "center" }}
                ></Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "8%", fontSize: "1rem" }}
                >
                  How would you like the text to be broken down for processing?
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    color: "#818589",
                    fontSize: "0.8rem",
                    marginTop: "-1%",
                  }}
                >
                  Chunking is the process of breaking down large pieces of text
                  into smaller segments. <br />
                  Select it based on type of text content and your use case.
                </Typography>

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "8%", fontSize: "1rem" }}
                >
                  How much text should be included in each chunk?
                </Typography>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  style={{
                    color: "#818589",
                    fontSize: "0.8rem",
                    marginTop: "-1%",
                  }}
                >
                  If your use case is question answering, you may need shorter
                  specific chunks,
                  <br />
                  but if your use case is summarization, you may need longer
                  chunks.
                </Typography>

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "8%", fontSize: "1rem" }}
                >
                  How much overlap should be there between consecutive chunks?
                </Typography>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  style={{
                    color: "#818589",
                    fontSize: "0.8rem",
                    marginTop: "-1%",
                  }}
                >
                  It allows for continuity and context between chunks.High
                  overlap maximizes context but can be
                  <br />
                  slower and resource-intensive while low overlap maintains some
                  context while remaining efficient.
                </Typography>

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "8%", fontSize: "1rem" }}
                >
                  What type of word representation would you like the model to
                  use?
                </Typography>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  style={{
                    color: "#818589",
                    fontSize: "0.8rem",
                    marginTop: "-1%",
                  }}
                >
                  OpenAI Embedding is high performance option but charges
                  $0.0001 per 1000 tokens whereas
                  <br />
                  HuggingFace Embedding is completely free to use.
                </Typography>

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "9%", fontSize: "1rem" }}
                >
                  How should the model efficiently access and compare similar
                  text content?
                </Typography>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  style={{
                    color: "#818589",
                    fontSize: "0.8rem",
                    marginTop: "-1%",
                  }}
                >
                  Efficiently navigates large amounts of text, INV_PQ: Faster
                  than HNSW for specific types
                  <br /> of similarity, but less accurate, AUTOINDEX:
                  Automatically selects the best index
                  <br /> based on your data and query types.
                </Typography>

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "4%", fontSize: "1rem" }}
                >
                  How should the model determine the similarity between
                  different text content?
                </Typography>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  style={{
                    color: "#818589",
                    fontSize: "0.8rem",
                    marginTop: "-1%",
                  }}
                >
                  Consider the nature of your data. For text data, cosine
                  distance or IP might be more suitable,
                  <br /> while L2 distance might be better for numerical data.
                </Typography>

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "8%", fontSize: "1rem" }}
                >
                  How many most similar passages should the model return?
                </Typography>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  style={{
                    color: "#818589",
                    fontSize: "0.8rem",
                    marginTop: "-1%",
                  }}
                >
                  Very low value may miss relevant results whereas higher value
                  can be
                  <br />
                  more comprehensive, but potentially slower and overwhelming.
                </Typography>

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "4%", fontSize: "1rem" }}
                >
                  Should the model utilize previously processed results to save
                  token consumption?
                </Typography>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  style={{
                    color: "#818589",
                    fontSize: "0.8rem",
                    marginTop: "-1%",
                  }}
                >
                  It helps to give response without consuming tokens when asked
                  exact same query again.
                  <br />
                  Enable or disable the cache storing as per your requirements.
                </Typography>
              </Box>
              {isLlamaIndexChecked && (
                <Box style={{ marginLeft: "2%" }}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ textAlign: "center" }}
                  >
                    Llama Index
                  </Typography>
                  <Autocomplete
                    id="chunkstrategies"
                    name="llamaindexChunkingStrategy"
                    onChange={(event, value) =>
                      this.handleInputChange({
                        target: {
                          name: "llamaindexChunkingStrategy",
                          value: value,
                        },
                      })
                    }
                    options={llamaindexChunkingstrategies}
                    // getOptionSelected={(option, value) => option.value === value.value}
                    value={llamaindexChunkingStrategy}
                    getOptionLabel={(option) => option.label}
                    style={{ width: 300, marginTop: "8%" }}
                    sx={selectFieldSx}
                    renderInput={(params) => (
                      <TextField {...params} label="Chunking Strategies" />
                    )}
                  />
                  {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10%' }}> */}
                  <Autocomplete
                    id="Chunksize"
                    options={Chunksize}
                    //getOptionSelected={(option, value) => option.value === value.value}
                    value={llamaindexChunksize}
                    name="llamaindexChunksize"
                    onChange={(event, value) =>
                      this.handleInputChange({
                        target: {
                          name: "llamaindexChunksize",
                          value: value,
                        },
                      })
                    }
                    getOptionLabel={(option) => option.label}
                    style={{ marginTop: "20%" }}
                    sx={selectFieldSx}
                    renderInput={(params) => (
                      <TextField {...params} label="Chunk Size" />
                    )}
                  />

                  <Autocomplete
                    id="Chunkoverlap"
                    options={Chunkoverlap}
                    //getOptionSelected={(option, value) => option.value === value.value}
                    value={llamaindexChunkOverlap}
                    name="llamaindexChunkOverlap"
                    onChange={(event, value) =>
                      this.handleInputChange({
                        target: {
                          name: "llamaindexChunkOverlap",
                          value: value,
                        },
                      })
                    }
                    getOptionLabel={(option) => option.label}
                    style={{ marginTop: "20%" }}
                    sx={selectFieldSx}
                    renderInput={(params) => (
                      <TextField {...params} label="Chunk Overlap" />
                    )}
                  />
                  {/* </div> */}

                  <Autocomplete
                    id="Embeddings"
                    name="llamaindexEmbedding"
                    // disabled
                    options={LLamaIndexEmbeddings}
                    onChange={(event, value) =>
                      this.handleInputChange({
                        target: {
                          name: "llamaindexEmbedding",
                          value: value,
                        },
                      })
                    }
                    getOptionLabel={(option) => option.label}
                    style={{ marginTop: "20%" }}
                    sx={selectFieldSx}
                    renderInput={(params) => (
                      <TextField {...params} label="Embeddings" />
                    )}
                    value={llamaindexEmbedding}
                  />

                  <Autocomplete
                    id="IndexType"
                    disabled
                    options={IndexType}
                    getOptionLabel={(option) => option.label}
                    style={{ marginTop: "20%" }}
                    sx={selectFieldSx}
                    renderInput={(params) => (
                      <TextField {...params} label="Index Type" />
                    )}
                    value={IndexTypellamaValue}
                  />

                  <Autocomplete
                    id="llamaSimilarityMatrix"
                    options={llamaSimilarityMatrix}
                    name="llamaindexSimilarityMatrix"
                    onChange={(event, value) =>
                      this.handleInputChange({
                        target: {
                          name: "llamaindexSimilarityMatrix",
                          value: value,
                        },
                      })
                    }
                    // getOptionSelected={(option, value) => option.value === value.value}
                    value={llamaindexSimilarityMatrix}
                    getOptionLabel={(option) => option.label}
                    style={{ width: 300, marginTop: "15%" }}
                    sx={selectFieldSx}
                    renderInput={(params) => (
                      <TextField {...params} label="Similarity Matrix" />
                    )}
                  />
                  <Typography
                    gutterBottom
                    style={{ fontSize: "1rem", marginTop: "13%" }}
                  >
                    Top K
                  </Typography>
                  <Slider
                    valueLabelDisplay="auto"
                    aria-labelledby="discrete-slider"
                    marks
                    value={llamaindexTopK}
                    step={1}
                    min={2}
                    max={8}
                    style={{ color: "#6001FF" }}
                    name="llamaindexTopK"
                    onChange={this.handleInputChange}
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={llamaindexCacheReuse}
                        inputProps={{
                          "aria-label": "Cache Reuse",
                          style: {
                            "&.MuiChecked": {
                              color: "#6001FF", // Color when checked
                            },
                            "&.MuiDisabled": {
                              color: "#818589", // Color when disabled
                            },
                          },
                        }}
                        onChange={this.handleEnableButton}
                        name="llamaindexCacheReuse"
                      />
                    }
                    label="Cache reuse"
                    labelPlacement="start"
                    sx={{ marginTop: "10%", marginLeft: "-1%" }}
                  />
                </Box>
              )}

              {isLangchainChecked && (
                <Box style={{ marginLeft: "2%" }}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ textAlign: "center" }}
                  >
                    Langchain
                  </Typography>
                  <Autocomplete
                    id="chunkstrategies"
                    name="langchainChunkingStrategy"
                    onChange={(event, value) =>
                      this.handleInputChange({
                        target: {
                          name: "langchainChunkingStrategy",
                          value: value,
                        },
                      })
                    }
                    options={langchainChunkingStrategies}
                    // getOptionSelected={(option, value) => option.value === value.value}
                    value={langchainChunkingStrategy}
                    getOptionLabel={(option) => option.label}
                    style={{ width: 300, marginTop: "8%" }}
                    sx={selectFieldSx}
                    renderInput={(params) => (
                      <TextField {...params} label="Chunking Strategies" />
                    )}
                  />

                  {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5%' }}> */}
                  <Autocomplete
                    id="Chunksize"
                    options={Chunksize}
                    name="langchainChunkSize"
                    onChange={(event, value) =>
                      this.handleInputChange({
                        target: {
                          name: "langchainChunkSize",
                          value: value,
                        },
                      })
                    }
                    // getOptionSelected={(option, value) => option.value === value.value}
                    value={langchainChunkSize}
                    getOptionLabel={(option) => option.label}
                    style={{ marginTop: "20%" }}
                    sx={selectFieldSx}
                    renderInput={(params) => (
                      <TextField {...params} label="Chunk Size" />
                    )}
                  />

                  <Autocomplete
                    id="Chunkoverlap"
                    options={Chunkoverlap}
                    name="langchainChunkOverlap"
                    onChange={(event, value) =>
                      this.handleInputChange({
                        target: {
                          name: "langchainChunkOverlap",
                          value: value,
                        },
                      })
                    }
                    //getOptionSelected={(option, value) => option.value === value.value}
                    value={langchainChunkOverlap}
                    getOptionLabel={(option) => option.label}
                    style={{ marginTop: "20%" }}
                    sx={selectFieldSx}
                    renderInput={(params) => (
                      <TextField {...params} label="Chunk Overlap" />
                    )}
                  />
                  {/* </div> */}

                  <Autocomplete
                    id="Embeddings"
                    name="langchainEmbedding"
                    options={Embeddings}
                    onChange={(event, value) =>
                      this.handleInputChange({
                        target: {
                          name: "langchainEmbedding",
                          value: value,
                        },
                      })
                    }
                    //getOptionSelected={(option, value) => option.value === value.value}
                    value={langchainEmbedding}
                    getOptionLabel={(option) => option.label}
                    style={{ marginTop: "20%" }}
                    sx={selectFieldSx}
                    renderInput={(params) => (
                      <TextField {...params} label="Embeddings" />
                    )}
                  />

                  <Autocomplete
                    id="IndexType"
                    name="langchainIndex"
                    options={IndexType}
                    onChange={(event, value) =>
                      this.handleInputChange({
                        target: {
                          name: "langchainIndex",
                          value: value,
                        },
                      })
                    }
                    //getOptionSelected={(option, value) => option.value === value.value}
                    value={langchainIndex}
                    getOptionLabel={(option) => option.label}
                    style={{ marginTop: "20%" }}
                    sx={selectFieldSx}
                    renderInput={(params) => (
                      <TextField {...params} label="Index Type" />
                    )}
                  />

                  <Autocomplete
                    id="langchainSimilarityMatrix"
                    options={langchainSimilarityMatrix}
                    // getOptionSelected={(option, value) => option.value === value.value}
                    name="defualtlangchainSimilarityMatrix"
                    value={defualtlangchainSimilarityMatrix}
                    onChange={(event, value) =>
                      this.handleInputChange({
                        target: {
                          name: "defualtlangchainSimilarityMatrix",
                          value: value,
                        },
                      })
                    }
                    getOptionLabel={(option) => option.label}
                    style={{ width: 300, marginTop: "15%" }}
                    sx={selectFieldSx}
                    renderInput={(params) => (
                      <TextField {...params} label="Similarity Matrix" />
                    )}
                  />

                  <Typography
                    gutterBottom
                    style={{ fontSize: "1rem", marginTop: "13%" }}
                  >
                    Top K
                  </Typography>
                  <Slider
                    valueLabelDisplay="auto"
                    aria-labelledby="discrete-slider"
                    marks
                    value={langchainTopK}
                    step={1}
                    min={2}
                    max={8}
                    style={{ color: "#6001FF" }}
                    name="langchainTopK"
                    onChange={this.handleInputChange}
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={langchainCacheReuse}
                        inputProps={{
                          "aria-label": "Cache Reuse",
                          style: {
                            "&.MuiChecked": {
                              color: "#6001FF", // Color when checked
                            },
                            "&.MuiDisabled": {
                              color: "#818589", // Color when disabled
                            },
                          },
                        }}
                        onChange={this.handleEnableButton}
                        name="langchainCacheReuse"
                      />
                    }
                    label="Cache reuse"
                    labelPlacement="start"
                    sx={{ marginTop: "10%", marginLeft: "-1%" }}
                  />
                </Box>
              )}
            </Box>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#6001FF",
                color: "#fff",
                marginLeft:
                  isLlamaIndexChecked && isLangchainChecked ? "68.5%" : "45%",
              }}
              onClick={this.resetState}
            >
              Reset Changes{" "}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#6001FF",
                color: "#fff",
                marginLeft:
                  isLlamaIndexChecked && isLangchainChecked ? "1%" : "1%",
              }}
              onClick={this.handleSaveLLMConfigChanges}
            >
              Save Changes
            </Button>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <Box style={{ display: "flex" }}>
              <Paper elevation={0} sx={{ width: "100%" }}>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  {/* Empty Avatar */}
                  <Box
                    // onClick={() => this.fileInput.click()} // Trigger file input on avatar click
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      backgroundColor: "#E0E0E0",
                      cursor: "pointer",
                      overflow: "hidden",
                      marginRight: "20px",
                      position: "relative",
                    }}
                  >
                    {profilePic ? (
                      <img
                        src={profilePic}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <Box
                        style={{
                          position: "absolute",
                          top: "45%",
                          left: "45%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        Profile Picture
                      </Box>
                    )}
                    <Box
                      onClick={(event) => this.handleMenuOpen(event)}
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        backgroundColor: "white",
                        borderRadius: "50%",
                        padding: "2px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <EditIcon style={{ fontSize: "20px", color: "#000" }} />
                    </Box>
                  </Box>

                  {/* Hidden file input */}
                  <input
                    type="file"
                    accept="image/*"
                    ref={(input) => (this.fileInput = input)}
                    style={{ display: "none" }}
                    onChange={this.handleProfilePicChange}
                  />
                  <CustomMenu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={(event) => this.handleMenuClose(event)}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        this.setState({ customizePromptAlert: true });
                        this.handleUploadPicture();
                      }}
                    >
                      Upload Picture
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        this.setState({ customizePromptAlert: true });
                        this.handleRemovePicture();
                      }}
                    >
                      Remove Picture
                    </MenuItem>
                  </CustomMenu>

                  <Box style={{ marginLeft: "0.4%" }}>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        flexGrow: 1,
                        fontSize: "1.2rem",
                        fontFamily: "Poppins, sans-serif",
                        // marginTop: "5%",
                      }}
                    >
                      Name your AI Assistant
                    </Typography>
                    <TextField
                      id="standard-basic"
                      name="assistantName"
                      type="assistantName"
                      value={assistantName}
                      onChange={this.handleInputChange} // Update the onChange handler
                      fullWidth
                      sx={textFieldSx}
                      style={{
                        width: "604%",
                      }}
                    />
                  </Box>
                </Box>
                {/* TextField */}

                <Box style={{ marginLeft: "9%" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      fontSize: "1.2rem",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: "5%",
                    }}
                  >
                    What should your AI Assistant do?
                  </Typography>

                  <TextField
                    id="outlined-multiline-static"
                    name="tasks"
                    type="tasks"
                    value={tasks}
                    onChange={this.handleInputChange} // Update the onChange handler
                    fullWidth
                    multiline
                    rows={8}
                    sx={textFieldSx}
                  />
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      fontSize: "1.2rem",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: "6%",
                    }}
                  >
                    Equip your Assistant
                  </Typography>
                </Box>
                <Box style={{ display: "flex", gap: "20px", marginLeft: "9%" }}>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.selectedCheckboxes.dataAnalysis}
                          onChange={() =>
                            this.handleCheckboxChange("dataAnalysis")
                          }
                          style={{
                            color: this.state.selectedCheckboxes.dataAnalysis
                              ? "#6001FF"
                              : "",
                          }}
                        />
                      }
                      label="Data Analysis"
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            this.state.selectedCheckboxes.informationExtraction
                          }
                          onChange={() =>
                            this.handleCheckboxChange("informationExtraction")
                          }
                          style={{
                            color: this.state.selectedCheckboxes
                              .informationExtraction
                              ? "#6001FF"
                              : "",
                          }}
                        />
                      }
                      label="Information Extraction"
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            this.state.selectedCheckboxes.accessLLMCorpus
                          }
                          onChange={() =>
                            this.handleCheckboxChange("accessLLMCorpus")
                          }
                          style={{
                            color: this.state.selectedCheckboxes
                              .informationExtraction
                              ? "#6001FF"
                              : "",
                          }}
                        />
                      }
                      label="Access LLM Corpus"
                    />
                  </Box>
                </Box>

                <Box style={{ marginLeft: "9%" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      fontSize: "1.2rem",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: "5%",
                    }}
                  >
                    How do you want the output to be presented ?
                  </Typography>

                  <TextField
                    id="outlined-multiline-static"
                    name="outputPresentation"
                    type="outputPresentation"
                    value={outputPresentation}
                    onChange={this.handleInputChange} // Update the onChange handler
                    multiline
                    rows={3}
                    style={{ width: "100%" }}
                    sx={textFieldSx}
                  />
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      fontSize: "1.2rem",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: "5%",
                    }}
                  >
                    What is the preferrable response length?
                  </Typography>

                  <TextField
                    id="outlined-multiline-static"
                    name="responseLength"
                    type="responseLength"
                    value={responseLength}
                    placeholder="e.g. 1000 characters"
                    onChange={this.handleInputChange}
                    multiline
                    rows={3}
                    style={{ width: "100%" }}
                    sx={textFieldSx}
                  />
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      fontSize: "1.2rem",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: "5%",
                    }}
                  >
                    Anything the AI assistant should avoid doing?
                  </Typography>
                  <TextField
                    id="outlined-multiline-static"
                    name="avoidActions"
                    type="avoidActions"
                    value={avoidActions}
                    onChange={this.handleInputChange}
                    multiline
                    rows={3}
                    style={{ width: "100%" }}
                    sx={textFieldSx}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#6001FF",
                      color: "#fff",
                      marginLeft: "75%",
                      marginTop: "20px",
                    }}
                    onClick={this.handleResetCustomizedPrompts}
                  >
                    Reset Changes
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#6001FF",
                      color: "#fff",
                      marginLeft: "2.5%",
                      marginTop: "20px",
                    }}
                    onClick={this.handleSaveChanges}
                  >
                    Save Changes
                  </Button>
                </Box>
              </Paper>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}></CustomTabPanel>
          <CustomTabPanel value={value} index={3}></CustomTabPanel>
        </Card>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
        >
          <Alert
            onClose={this.handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

export default withRouter(CustomizePromptsDocs);
