import React, { Component } from "react";
import { Container } from "@mui/material";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { withRouter } from "./withRouter";
import axios, { CancelToken, isCancel } from "axios";
import FileUploadQAFile from "../chatComponents/FileUploadQAFile";

const EMBEDDING_SERVICE_URL = process.env.REACT_APP_EMBEDDING_SERVICE_URL;
const application_code = "ZN003";
const MAX_FILE_SIZE_BYTES = 50 * 1024 * 1024;
const VALID_FILE_EXTENSIONS = [".pdf", ".docx", ".doc", ".txt"];
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
class FileUploadQA extends Component {
  constructor(props) {
    super(props);
    //this.removeSpecialCharFiles = this.removeSpecialCharFiles.bind(this);
    // Create a ref
    this.cancelFileUpload = React.createRef();
    this.state = {
      selectedFiles: [],
      selectedLinks: [],
      message: "",
      value: 0,
      webCrawlClicked: false,
      filesUploaded: false,
      disableUploadButton: false,
      showLoader: false,
      showsnack: false,
      snackmessage: "",
      severity: "",
      progress: 0,
      loaded_1: 0,
      target: 0,
      check: false,
      next: false,
    };
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  handleSnackbarOpen = (severity, message) => {
    this.setState({
      snackmessage: message,
      severity: "error",
      showsnack: true,
    });
  };
  handleSnackbarClose = () => {
    this.setState({ showsnack: false });
  };

  handleChatClickInUpload = () => {
    this.props.handleListdataClick({
      showUpload: false,
      showChat: false,
      ShowKnowledge: true,
    });
  };

  handleFileChange = (e) => {
    e.preventDefault();

    const files = Array.from(e.target.files);

    const filesWithSpecialCharacters =
      this.filterFilesBySpecialCharacters(files);
    this.handleSpecialCharacterErrors(filesWithSpecialCharacters);

    // Check for files with Invalid Extension
    const filesWithInvalidExtensions =
      this.filterFilesWithInvalidExtensions(files);
    this.handleInvalidExtensionErrors(filesWithInvalidExtensions);

    // Check for files with filename length greater than 200
    const filesWithLongNames = this.filterFilesByLength(files, 200);
    this.handleFileNameLengthErrors(filesWithLongNames);

    // Check for files exceeding size limit
    const filesExceedingSizeLimit = this.filterFilesBySizeLimit(
      files,
      MAX_FILE_SIZE_BYTES
    );
    this.handleSizeLimitErrors(filesExceedingSizeLimit);

    const validFiles = files.filter(
      (file) =>
        !this.hasSpecialCharacters(file.name) &&
        !this.hasInvalidExtension(file.name) &&
        !this.fileExceedsSizeLimit(file, MAX_FILE_SIZE_BYTES) &&
        !this.isFileLengthExceeding(file.name, 200)
    );

    if (validFiles.length === 0) {
      // If no valid files are selected, return without updating the state
      return;
    }

    this.setState(
      (prevState) => ({
        selectedFiles: [...prevState.selectedFiles, ...validFiles],
        showAddMoreButton: true,
      }),
      () => {
        // Save selected files to local storage after updating the state
        const fileData = this.state.selectedFiles.map((file) => ({
          name: file.name,
          lastModified: file.lastModified,
        }));
        localStorage.setItem("filePaths", JSON.stringify(fileData));
      }
    );
  };

  filterFilesBySpecialCharacters = (files) => {
    return files.filter((file) => this.hasSpecialCharacters(file.name));
  };

  handleSpecialCharacterErrors = (filesWithSpecialCharacters) => {
    if (filesWithSpecialCharacters.length > 0) {
      const message =
        filesWithSpecialCharacters.length > 1
          ? `${filesWithSpecialCharacters.length} files with special characters cannot be uploaded.`
          : `${filesWithSpecialCharacters.length} file with special characters cannot be uploaded.`;
      this.handleSnackbarOpen("error", message);
    }
  };
  hasSpecialCharacters = (fileName) => {
    // Define a regular expression to match special characters
    const regex = /[^a-zA-Z0-9_.-\s]+/;
    // returns boolean value from test()
    return regex.test(fileName);
  };

  filterFilesBySizeLimit = (files, maxSize) => {
    return files.filter((file) => this.fileExceedsSizeLimit(file, maxSize));
  };

  fileExceedsSizeLimit = (file, maxSize) => {
    return file.size > maxSize;
  };
  handleSizeLimitErrors = (filesExceedingSizeLimit) => {
    if (filesExceedingSizeLimit.length > 0) {
      const message =
        filesExceedingSizeLimit.length > 1
          ? `${filesExceedingSizeLimit.length} files have exceeded 50MB cannot be uploaded.`
          : `${filesExceedingSizeLimit.length} file have exceeded 50MB cannot be uploaded.`;
      this.handleSnackbarOpen("error", message);
    }
  };

  filterFilesWithInvalidExtensions = (files) => {
    return files.filter((file) => this.hasInvalidExtension(file.name));
  };

  hasInvalidExtension = (fileName) => {
    const fileExtension = fileName
      .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2)
      .toLowerCase();
    return !VALID_FILE_EXTENSIONS.includes(`.${fileExtension}`);
  };
  handleInvalidExtensionErrors = (filesWithInvalidExtensions) => {
    if (filesWithInvalidExtensions.length > 0) {
      const message =
        filesWithInvalidExtensions.length > 1
          ? `${filesWithInvalidExtensions.length} files have invalid extensions.`
          : `${filesWithInvalidExtensions.length} file has an invalid extension.`;
      this.handleSnackbarOpen("error", message);
    }
  };
  filterFilesByLength = (files, maxLength) => {
    return files.filter((file) =>
      this.isFileLengthExceeding(file.name, maxLength)
    );
  };

  isFileLengthExceeding = (fileName, maxLength) => {
    return fileName.length > maxLength;
  };

  handleFileNameLengthErrors = (filesWithLongNames) => {
    if (filesWithLongNames.length > 0) {
      const message =
        filesWithLongNames.length > 1
          ? `${filesWithLongNames.length} files have names longer than 200 characters.`
          : `${filesWithLongNames.length} file has a name longer than 200 characters.`;
      this.handleSnackbarOpen("error", message);
    }
  };

  disableButton = () => {
    this.setState({ disableUploadButton: true });
  };
  enableButton = () => {
    this.setState({ disableUploadButton: false });
  };

  enableLoader = () => {
    this.setState({ showLoader: true });
  };
  disableLoader = () => {
    this.setState({ showLoader: false });
  };

  handleUpload = async (e) => {
    e.preventDefault();
    const { folder_name, folder_id } = this.props;
    try {
      const formData = new FormData();
      const token = localStorage.getItem("token");
      const userOrganization = JSON.parse(
        localStorage.getItem("currentLoggedInUserDetails")
      )["organization"];

      //check here for special character files
      // await this.removeSpecialCharFiles(this.state.selectedFiles);

      this.state.selectedFiles.forEach((file) =>
        formData.append("files", file)
      );

      formData.append("username", localStorage.getItem("username"));
      formData.append("organization", userOrganization);
      formData.append("application_code", application_code);
      formData.append("useremail", localStorage.getItem("email"));
      formData.append("folder_name", folder_name);
      formData.append("folder_id", folder_id);
      this.disableButton();
      this.enableLoader();

      if (!this.state.showsnack) {
        const url = `${EMBEDDING_SERVICE_URL}/zn002/embedding/document_upload`;
        const responseData = await axios.post(url, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: (data) => {
            const uploadProgress = Math.round((data.loaded * 100) / data.total);
            if (uploadProgress < 100) {
              this.setState({
                progress: uploadProgress,
                loaded_1: data.loaded,
                target_1: data.total,
              });
            }
          },
          cancelToken: new CancelToken(
            (cancel) => (this.cancelFileUpload.current = cancel)
          ),
        });

        if (responseData.statusText) {
          this.setState({
            message: responseData.data.message,
            next: true,
            selectedFiles: [],
            showAddMoreButton: false,
            filesUploaded: true,
            severity: "success",
            snackmessage: responseData.data.message,
            showsnack: true,
          });
        } else {
          this.setState({
            severity: "error",
            snackmessage: responseData.data.detail,
            showsnack: true,
          });
          console.error("File upload error:", responseData.data.detail);
        }
      }
    } catch (error) {
      this.setState({
        message: error.response?.data?.detail || error.message,
        severity: "error",
        snackmessage: error.response?.data?.detail || error.message,
        showsnack: true,
      });
      if (error.response?.data?.detail) {
        this.setState({
          next: false,
        });
      }
      if (isCancel(error)) {
        console.error("Error:", error.message);
      }
      console.error("Error:", error.response?.data?.detail || error.message);
      console.log("catch block error", error);
      console.log("catch block", error.response?.data?.detail);
    } finally {
      this.enableButton();
      this.disableLoader();
    }
  };

  handleAddMoreFiles = () => {
    this.fileInputRef.value = null;
    this.fileInputRef.click();
  };

  handleDeleteFile = (index) => {
    this.setState((prevState) => {
      const newSelectedFiles = [...prevState.selectedFiles];
      newSelectedFiles.splice(index, 1);
      return { selectedFiles: newSelectedFiles };
    });
  };

  handleWebCrawl = () => {
    const { webLink, selectedLinks } = this.state;
    if (webLink.trim() !== "") {
      this.setState({
        selectedLinks: [...selectedLinks, webLink.trim()],
        webLink: "",
        webCrawlClicked: true,
      });
    }
  };

  handleDeleteLink = (index) => {
    this.setState((prevState) => {
      const newSelectedLinks = [...prevState.selectedLinks];
      newSelectedLinks.splice(index, 1);
      return { selectedLinks: newSelectedLinks };
    });
  };

  componentDidUpdate() {
    // Save data to local storage whenever state changes
    localStorage.setItem(
      "selectedFiles",
      JSON.stringify(this.state.selectedFiles)
    );
    localStorage.setItem(
      "selectedLinks",
      JSON.stringify(this.state.selectedLinks)
    );
  }
  cancelUpload = () => {
    if (this.cancelFileUpload.current) {
      this.cancelFileUpload.current("User has cancelled the file upload");
    }
  };
  onChangeWebLink = (value) => {
    this.setState({ webLink: value });
  };
  render() {
    const {
      showLoader,
      disableUploadButton,
      selectedFiles,
      message,
      showAddMoreButton,
      value,
      severity,
      showsnack,
      snackmessage,
      progress,
      next,
    } = this.state;

    return (
      <Container>
        <Paper
          elevation={5}
          style={{
            marginTop: "5%",
            borderRadius: "20px",
            width: "100%",
            minHeight: "370px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={this.handleChange}
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#6001FF",
                  },
                }}
              >
                <Tab
                  label="File"
                  {...a11yProps(0)}
                  sx={{
                    color: value === 0 ? "#6001FF" : "inherit",
                    "&.Mui-selected": {
                      color: "#6001FF",
                    },
                  }}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Box style={{ marginLeft: "5%", padding: "10px" }}>
              <FileUploadQAFile
                  showLoader={showLoader}
                  selectedFiles={selectedFiles}
                  showAddMoreButton={showAddMoreButton}
                  disableUploadButton={disableUploadButton}
                  progress={progress}
                  next={next}
                  message={message}
                  handleUpload={this.handleUpload}
                  handleFileChange={this.handleFileChange}
                  handleDeleteFile={this.handleDeleteFile}
                  cancelUpload={this.cancelUpload}
                  handleChatClickInUpload={this.handleChatClickInUpload}
                />
              </Box>
            </CustomTabPanel>
           
          </Box>
        </Paper>
        <Snackbar
          open={showsnack}
          autoHideDuration={4000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            this.setState({ showsnack: false });
          }}
        >
          <Alert
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }

              this.setState({ showsnack: false });
            }}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {snackmessage}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export default withRouter(FileUploadQA);
