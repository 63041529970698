import React, { Component } from "react";
import { InputBase } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import Tooltip from "@mui/material/Tooltip";
import ListItemIcon from "@mui/material/ListItemIcon";
import TablePagination from "@mui/material/TablePagination";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

import PdfIcon from "../assets/icons/pdf.webp";
import DocIcon from "../assets/icons/doc.webp";
import TextIcon from "../assets/icons/txt.webp";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Notification from "./Notification";
import FileUploadQA from "./fileUploadQA";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const application_code = "ZN002";

class DocumentQA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedIndex: -1,
      documentSeachQuery: "",
      currentLoggedInUserRole:
        JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))[
          "role"
        ] || "",
      currentLoggedInUserPermissions:
        JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))[
          "userPermissions"
        ] || {},
      folder_name: this.props.folder_name,
      folder_id: this.props.folder_id,
      isDocumentupload: false,
      currentDocument: null,
      isEditDocument: false,
      documentFiles: [],
      page: 0,
      rowsPerPage: 10,
      selectAllDocuments: false,
      selectedDocumentFiles: [], // Initialize selectedFiles as an empty array for documents
    };
  }
  async componentDidMount() {
    await this.fetchDocumentFiles(); // Fetch Upload documents
  }

  fetchDocumentFiles = async () => {
    const { folder_id } = this.state;
    this.setState({ isLoading: true });
    const token = localStorage.getItem("token");

    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];

    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }
    const email = localStorage.getItem("email");
    try {
      const response = await fetch(
        `${BASE_URL}/admin/zn002/admin_document_files/${email}?userOrganization=${encodeURIComponent(
          userOrganization
        )}&folder_id=${encodeURIComponent(folder_id)}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json(); // Parse the response as JSON
        if (Array.isArray(responseData.user_files)) {
          this.setState({
            documentFiles: responseData.user_files,
            isLoading: false,
          });
        } else {
          console.error(
            "Data is not an array in the server response:",
            responseData
          );
        }
      } else {
        console.error("Server response was not OK. Status:", response.status);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.error("Error fetching data:", error);
    }
  };

  handleDeleteDocument = async (filename) => {
    const { folder_name } = this.state;
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }
    const email = localStorage.getItem("email");
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];

    const formData = new FormData();
    formData.append("filename", filename);
    formData.append("deleted_by_user_email", email);
    formData.append("user_organization", userOrganization);
    formData.append("application_code", application_code);
    formData.append("folder_name", folder_name);
    try {
      const response = await fetch(
        `${BASE_URL}/admin/zn002/admin_document_files`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        // File was successfully deleted, you can update your state or perform other actions here
        // For example, you can refresh the list of files to reflect the changes
        // this.fetchDocumentFiles();
        this.reset();
        this.setState({
          selected_files_by_id: [],
          selectedDocumentFiles: [],
          selectAllDocuments: false,
        });
      } else {
        console.error("Error deleting file. Status:", response.status);
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };
  truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  handleDocumentSelectAllClick = () => {
    const { selectAllDocuments, documentFiles } = this.state;
    let selectedDocumentFiles, selected_files_by_id;

    if (selectAllDocuments) {
      // If "Select All" is already checked, unselect all files
      selectedDocumentFiles = [];
      selected_files_by_id = [];
    } else {
      // If "Select All" is not checked, select all files by fileId
      const availableFiles = documentFiles.filter(
        (file) => file.embedding_status === "Available"
      );
      selectedDocumentFiles = availableFiles.map((file) => file.filename);
      selected_files_by_id = availableFiles.map((file) => file.id);
    }

    this.setState({
      selectAllDocuments: !selectAllDocuments,
      selectedDocumentFiles,
      selected_files_by_id,
    });
  };
  handleDocumentCheckboxChange = (filename) => {
    const { selectedDocumentFiles, documentFiles } = this.state;

    if (selectedDocumentFiles.includes(filename)) {
      // File is already selected, remove it from the selected files
      this.setState(
        (prevState) => ({
          selectedDocumentFiles: prevState.selectedDocumentFiles.filter(
            (name) => name !== filename
          ),
        }),
        () => {
          if (
            this.state.selectedDocumentFiles.length === documentFiles.length
          ) {
            this.setState({ selectAllDocuments: true });
          }
          if (this.state.selectedDocumentFiles.length ===documentFiles.length-1) {
            this.setState({ selectAllDocuments: false });
          }
        }
      );
    } else {
      // File is not selected, add it to the selected files
      this.setState(
        (prevState) => ({
          selectedDocumentFiles: [...prevState.selectedDocumentFiles, filename],
        }),
        () => {
          if (
            this.state.selectedDocumentFiles.length === documentFiles.length
          ) {
            this.setState({ selectAllDocuments: true });
          }
          if (this.state.selectedDocumentFiles.length ===documentFiles.length-1) {
            this.setState({ selectAllDocuments: false });
          }
        }
      );
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      selectedIndex: -1,
    });
  };
  handleClick = (event, index, accessingAppData) => {
    const { currentLoggedInUserPermissions } = this.state;
    if (
      currentLoggedInUserPermissions["Add or Delete Databases"] &&
      accessingAppData === "database"
    ) {
      this.setState({
        anchorEl: event.currentTarget,
        selectedIndex: index,
      });
    } else if (
      currentLoggedInUserPermissions["Add or Delete Files"] &&
      accessingAppData === "csv"
    ) {
      this.setState({
        anchorEl: event.currentTarget,
        selectedIndex: index,
      });
    } else if (
      currentLoggedInUserPermissions["Add or Delete Files"] &&
      accessingAppData === "documents"
    ) {
      this.setState({
        anchorEl: event.currentTarget,
        selectedIndex: index,
      });
    } else {
      toast.error(`You cannot have access to edit ${accessingAppData}`);
    }
  };
  handleAddDocument = () => {
    this.setState({
      isDocumentupload: true,
    });
  };
  handleCloseDocument = () => {
    this.setState({
      isDocumentupload: false,
      isEditDocument: false,
      currentDocument: null,
    });
    this.handleClose();
  };
  handleEditDocument = (item) => {
    this.setState({
      currentDocument: item,
      isEditDocument: true,
    });
    this.handleClose();
  };

  backToFolder = () => {
    this.props.backFolder(false);
  };
  reset = () => {
    window.location.reload();
  };

  render() {
    const {
      documentSeachQuery,
      currentLoggedInUserRole,
      currentLoggedInUserPermissions,
      selectedDocumentFiles,
      selectAllDocuments,
      documentFiles,
      page,
      rowsPerPage,
      folder_name,
      folder_id,
    } = this.state;

    return (
      <React.Fragment>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px",
          }}
        >
          <Button
            style={{
              backgroundColor: "#6001FF",
              color: "#ffffff",
              marginRight: "52%",
              textTransform: "none",
            }}
            variant="contained"
            onClick={this.backToFolder}
          >
            Back To Folder
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F3F6FD",
              borderRadius: "25px",
              padding: "5px",
              marginRight: "3%",
            }}
          >
            <SearchIcon
              sx={{
                color: "#6001FF",
                marginLeft: "10px",
                marginRight: "5px",
              }}
            />
            <InputBase
              placeholder="Search..."
              value={documentSeachQuery}
              onChange={(e) =>
                this.setState({
                  documentSeachQuery: e.target.value,
                })
              }
              sx={{
                color: "#6001FF",
                width: "40%",
                backgroundColor: "#F3F6FD",
                borderRadius: "25px",
              }}
            />
          </Box>
          <ListItemIcon sx={{ color: "#000", pt: 1 }}>
            <RestartAltOutlinedIcon
              sx={{
                color: "#6001FF",
                width: "45px",
                height: "35px",

                "&:hover": {
                  backgroundColor: "#EFEFEF",
                  borderRadius: "50%", // Making background circular
                },
              }}
              onClick={this.reset}
            />
          </ListItemIcon>
          {(currentLoggedInUserPermissions["Add or Delete Files"] ||
            currentLoggedInUserRole === "superadmin") && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              style={{
                backgroundColor: "#6001FF",
                color: "#fff",
                marginLeft: "20px",
              }}
              onClick={this.handleAddDocument}
            >
              Add File
            </Button>
          )}
          <Modal
            open={this.state.isDocumentupload}
            onClose={this.handleCloseDocument}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <FileUploadQA
              folder_name={folder_name}
              folder_id={folder_id}
              userDetails={this.props.userDetails}
              isEditDocument={false}
              handleCloseDocument={this.handleCloseDocument}
              fetchDocumentFilesData={this.fetchDocumentFiles}
            />
          </Modal>
        </Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          {this.state.selectedDocumentFiles.length > 0 && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "6%",
              }}
            >
              <Typography variant="body1" color="textSecondary">
                {`${this.state.selectedDocumentFiles.length} ${
                  this.state.selectedDocumentFiles.length === 1
                    ? "file"
                    : "files"
                } selected`}
              </Typography>
              <IconButton
                aria-label="Delete"
                onClick={() => this.handleDeleteDocument(selectedDocumentFiles)}
              >
                <Tooltip title="Remove" arrow>
                  <DeleteIcon style={{ marginLeft: "10%" }} />
                </Tooltip>
              </IconButton>
            </Box>
          )}
        </Box>

        <Paper
          elevation={0}
          style={{
            width: "100%",
            borderRadius: "10px",
            backgroundColor: "#FFF",
          }}
        >
          <Box style={{ marginLeft: "0.5%", width: "100%" }}>
            <List component="nav" aria-label="file list">
              <ListItem>
                <ListItemText style={{ width: "2%" }}></ListItemText>
                <ListItemText style={{ width: "10%" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: "1.1rem",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                    }}
                  >
                    File Type
                  </Typography>
                </ListItemText>
                <ListItemText style={{ width: "10%" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: "1.1rem",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                    }}
                  >
                    File Name
                  </Typography>
                </ListItemText>
                <ListItemText style={{ width: "10%" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: "1.1rem",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                    }}
                  >
                    Upload Date
                  </Typography>
                </ListItemText>
                <ListItemText style={{ width: "10%" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: "1.1rem",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                    }}
                  >
                    Size
                  </Typography>
                </ListItemText>
                <ListItemText style={{ width: "10%" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: "1.1rem",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                      marginLeft: "10%",
                    }}
                  >
                    User
                  </Typography>
                </ListItemText>
                <ListItemText style={{ width: "10%" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: "1.1rem",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                      marginLeft: "22%",
                    }}
                  >
                    Status
                  </Typography>
                </ListItemText>
                <ListItemText style={{ width: "10.5%" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: "1.1rem",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                      marginLeft: "22%",
                    }}
                  >
                    <Checkbox
                      sx={{
                        color: "#6001FF",
                        "&.Mui-checked": { color: "#6001FF" },
                      }}
                      id="selectAll"
                      checked={selectAllDocuments}
                      onChange={this.handleDocumentSelectAllClick}
                      size="small"
                    />
                    Select{" "}
                  </Typography>
                </ListItemText>
              </ListItem>
              <Divider />
              {documentFiles
                .filter((file) =>
                  file.filename
                    .toLowerCase()
                    .includes(documentSeachQuery.trim().toLowerCase())
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((file, index) => (
                  <React.Fragment key={index}>
                    <ListItemButton
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      disableRipple
                      disableTouchRipple
                      disableGutters
                    >
                      <ListItemText style={{ width: "2%" }}></ListItemText>
                      <ListItemIcon style={{ width: "14%", marginLeft: "1%" }}>
                        {/* Display the logo based on the databasetype */}
                        {file.filetype === "application/pdf" && (
                          <img
                            src={PdfIcon}
                            alt="application/pdf"
                            style={{ width: "40%", height: "30%" }}
                          />
                        )}
                        {file.filetype ===
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                          <img
                            src={DocIcon}
                            alt="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            style={{ width: "38%", height: "40%" }}
                          />
                        )}
                        {file.filetype === "application/doc" && (
                          <img
                            src={DocIcon}
                            alt="application/doc"
                            style={{ width: "38%", height: "40%" }}
                          />
                        )}
                        {file.filetype === "text/plain" && (
                          <img
                            src={TextIcon}
                            alt="text/plain"
                            style={{ width: "38%", height: "40%" }}
                          />
                        )}
                        {/* Add more conditions for other database types */}
                      </ListItemIcon>
                      <ListItemText style={{ width: "10%" }}>
                        <Tooltip
                          title={file.filename}
                          placement="top-start"
                          arrow
                        >
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={{
                              fontSize: "1rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                            }}
                          >
                            {this.truncateText(file.filename, 10)}
                          </Typography>
                        </Tooltip>
                      </ListItemText>
                      <ListItemText style={{ width: "10%" }}>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          sx={{
                            fontSize: "1rem",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 400,
                          }}
                        >
                          {file.uploaded_date}
                        </Typography>
                      </ListItemText>
                      <ListItemText style={{ width: "10%" }}>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          sx={{
                            fontSize: "1rem",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 400,
                          }}
                        >
                          {file.filesize}
                        </Typography>
                      </ListItemText>
                      <ListItemText style={{ width: "8%", marginRight: "3%" }}>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          sx={{
                            fontSize: "1rem",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 400,
                            marginLeft: "30px",
                          }}
                        >
                          {file.username}
                        </Typography>
                      </ListItemText>
                      <ListItemText style={{ width: "8%", marginRight: "5%" }}>
                        {file.embedding_status === "Processing" && (
                          <Chip
                            label={file.embedding_status}
                            variant="outlined"
                            sx={{ ml: 3 }}
                          />
                        )}
                        {file.embedding_status === "Unavailable" && (
                          <Chip
                            label={file.embedding_status}
                            variant="outlined"
                            color="error"
                            sx={{ ml: 3 }}
                          />
                        )}
                        {file.embedding_status === "Failed" && (
                          <Chip
                            label={file.embedding_status}
                            variant="outlined"
                            color="error"
                            sx={{ ml: 3 }}
                          />
                        )}
                        {file.embedding_status === "Available" && (
                          <Chip
                            label={file.embedding_status}
                            variant="outlined"
                            color="success"
                            sx={{ ml: 3 }}
                          />
                        )}
                      </ListItemText>
                      <ListItemText style={{ width: "1%", marginRight: "8%" }}>
                        {file.embedding_status === "Processing" ||
                        file.embedding_status === "Unavailable" ? (
                          <Checkbox
                            size="small"
                            sx={{
                              color: "#6001FF",
                              "&.Mui-checked": { color: "#6001FF" },
                            }}
                            checked={this.state.selectedDocumentFiles.includes(
                              file.filename
                            )}
                            onChange={() =>
                              this.handleDocumentCheckboxChange(file.filename)
                            }
                            disabled
                          />
                        ) : (
                          <Checkbox
                            size="small"
                            sx={{
                              color: "#6001FF",
                              "&.Mui-checked": { color: "#6001FF" },
                            }}
                            checked={this.state.selectedDocumentFiles.includes(
                              file.filename
                            )}
                            onChange={() =>
                              this.handleDocumentCheckboxChange(file.filename)
                            }
                          />
                        )}
                      </ListItemText>
                    </ListItemButton>
                    {index !== file.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
            </List>
          </Box>
          <Notification />
        </Paper>

        {documentFiles.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
            component="div"
            count={documentFiles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        )}
      </React.Fragment>
    );
  }
}

export default DocumentQA;
