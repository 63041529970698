import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
} from "@mui/material/";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Chip from "@mui/material/Chip";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { InputAdornment } from "@mui/material";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};
const selectFieldSx = {
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
  },
  "& .MuiMenu-paper": {
    maxHeight: "200px", // Set the maximum height for the dropdown
    maxWidth: "100px",
  },
};
class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      password: "",
      department: "",
      designation: "",
      tokenlimit: "",
      status: "",
      usergroup: "",
      isEmailVerified:"",
      assignedApplications: {
        "Zuno for Data": false,
        "Zuno for Docs": false,
        "Zuno for Web": false,
        "Analytical Bot": false,
        "Sales Co-Pilot": false,
        "Automated Insights": false,
        "Zuno for HR": false,
        "Knowledge Bots": false,
        "Contract Intelligence": false,
      },

      token: localStorage.getItem("token"),
      currentLoggedInUserRole: JSON.parse(
        localStorage.getItem("currentLoggedInUserDetails")
      )["role"]
        ? JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))["role"]
        : "",
      currentLoggedInUserDetails: JSON.parse(
        localStorage.getItem("currentLoggedInUserDetails")
      )["userPermissions"]
        ? JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))[
            "userPermissions"
          ]
        : {},
      userGroupPermissions: {
        "Add or Delete User Groups": false,
        "Add or Delete Admins": false,
        "Add or Delete Users": false,
        "Add or Delete Databases": false,
        "Add or Delete Files": false,
        "Assign Token Limits": false,
        "View Dashboards": false,
        "View Cost Report": false,
        "View Query Logs": false,
        "Create and Reset Password": false,
      },
      errors: {
        email: "",
        password: "",
        tokenLimit: "",
        username: "",
      },
      // isValidForm: this.props.isEditMode,
      // isValidForm:false,
      isValidEmail: false,
      isValidPassword: false,
      isValidToken: false,
      isValidUsername: false,
      showPassword: false,
      isPasswordInputNotEmpty: false,
      isUsernameInputNotEmpty: false,
      snackbarOpen: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
    };
  }
  componentDidMount() {
    if (this.props.isEditMode) {
      this.setState({
        username: this.props.initialName,
        email: this.props.initialEmail,
        department: this.props.initialDepartment,
        designation: this.props.initialDesignation,
        tokenlimit: this.props.initialTokenLimit,
        role: this.props.initialRole,
        usergroup: this.props.initialRole,
        status: this.props.initialStatus,
        assignedApplications: this.props.initialAssignedApplications,
        userGroupPermissions: this.props.userGroupPermissions,
        isEmailVerified: this.props.isEmailVerified
      });
    }
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    // const inputText=value.trim()
    this.setState({ [name]: value });
  };
  handleCheckBoxChange = (appName) => {
    this.setState((prevState) => {
      const assignedApplications = { ...prevState.assignedApplications };
      assignedApplications[appName] = !assignedApplications[appName];
      return { assignedApplications };
    });
  };
  handleUserPermissionsCheckBoxChange = (permissionName) => {
    this.setState((prevState) => {
      const userGroupPermissions = { ...prevState.userGroupPermissions };
      userGroupPermissions[permissionName] =
        !userGroupPermissions[permissionName];
      return { userGroupPermissions };
    });
  };
  resetFormState = () => {
    this.setState({
      username: "",
      email: "",
      password: "",
      department: "",
      designation: "",
      tokenlimit: "",
      status: "",
      usergroup: "",
      assignedApplications: {
        "Zuno for Data": false,
        "Zuno for Docs": false,
        "Zuno for Web": false,
        "Analytical Bot": false,
        "Sales Co-Pilot": false,
        "Automated Insights": false,
        "Zuno for HR": false,
        "Knowledge Bots": false,
        "Contract Intelligence": false,
      },
      userGroupPermissions: {
        "Add or Delete User Groups": false,
        "Add or Delete Admins": false,
        "Add or Delete Users": false,
        "Add or Delete Databases": false,
        "Add or Delete Files": false,
        "Assign Token Limits": false,
        "View Dashboards": false,
        "View Cost Report": false,
        "View Query Logs": false,
        "Create and Reset Password": false,
      },
    });
    this.props.handleClose();
  };
  getAccessibleUserGroups = () => {
    const { currentLoggedInUserRole, currentLoggedInUserDetails } = this.state;
    if (currentLoggedInUserRole === "superadmin")
      return ["superadmin", "admin", "user"];
    else if (currentLoggedInUserRole === "admin") {
      if (currentLoggedInUserDetails["Add or Delete Admins"]) {
        return ["admin", "user"];
      } else {
        return ["user"];
      }
    }
  };
  getUserPermissions = (usergroup) => {
    if (usergroup === "superadmin") {
      return {
        "Add or Delete User Groups": true,
        "Add or Delete Admins": true,
        "Add or Delete Users": true,
        "Add or Delete Databases": true,
        "Add or Delete Files": true,
        "Assign Token Limits": true,
        "View Dashboards": true,
        "View Cost Report": true,
        "View Query Logs": true,
        "Create and Reset Password": true,
      };
    } else if (usergroup === "user") {
      return {
        "Add or Delete User Groups": false,
        "Add or Delete Admins": false,
        "Add or Delete Users": false,
        "Add or Delete Databases": false,
        "Add or Delete Files": false,
        "Assign Token Limits": false,
        "View Dashboards": false,
        "View Cost Report": false,
        "View Query Logs": false,
        "Create and Reset Password": false,
      };
    } else if (usergroup === "admin") {
      return this.state.userGroupPermissions;
    }
  };

  // adding and editing users from admin page
  handleFormSubmit = async (event) => {
    event.preventDefault();

    const { currentEmail, userDetails, isEditMode } = this.props;
    const { isValidEmail, isValidPassword, tokenlimit, isValidUsername } =
      this.state;
    const formData = new FormData(event.currentTarget);
    formData.append("assignedApplications", this.state.assignedApplications);

    const assignedApplicationsString = JSON.stringify(
      this.state.assignedApplications
    );
    const userPermissions = this.getUserPermissions(formData.get("usergroup"));
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    if (!isValidUsername && !isEditMode) {
      return;
    } else if (!isValidEmail && !isEditMode) {
      return;
    } else if (!isValidPassword && !isEditMode) {
      return;
    } else if (tokenlimit < 5000) {
      return;
    }
    try {
      if (!this.props.isEditMode) {
        const response = await fetch(`${BASE_URL}/admin/admin_create_user`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: formData.get("username").trim(),
            email: formData.get("email"),
            password: formData.get("password"),
            department: formData.get("department"),
            designation: formData.get("designation"),
            organization: userOrganization, // The organization would be set as current admin organization
            role: formData.get("usergroup"),
            userPermissions: userPermissions,
            tokenlimit: formData.get("tokenlimit"),
            assignedApplications: JSON.parse(assignedApplicationsString),
          }),
        });
        console.log("response.status",response.status);
        if(response.status===401){
          this.handleSnackbarOpen(
            "error",
            "UserName or Email Already Exist"
          );
          return;
      }
        if (response.status === 201) {
          const result = await response.json();

          const newUser = result.newuser;
          newUser["last_active_date"] = "No Login Activity";
          newUser["token_used"] = 0;
          newUser["total_messages"] = 0;
          this.props.userDetails.push(newUser);
          this.props.updateUserDetails(
            this.props.userDetails,
            this.props.isEditMode
          );
          this.resetFormState();
        } else {
          console.error("Adding User failed");
        }
      } else {
        const response = await fetch(`${BASE_URL}/admin/edit_user_details`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currentEmail: this.props.currentEmail,
            // username: formData.get("username"),
            // email: formData.get("email"),
            designation: formData.get("designation"),
            department: formData.get("department"),
            role: formData.get("usergroup"),
            userPermissions: userPermissions,
            tokenlimit: formData.get("tokenlimit"),
            status: formData.get("status"),
            assignedApplications: JSON.parse(assignedApplicationsString),
          }),
        });

        if (response.status === 200) {
          const updatedUserDetails = userDetails.map((row) => {
            if (row.email === currentEmail) {
              return {
                ...row,
                // username: formData.get("username"),
                // email: formData.get("email"),
                designation: formData.get("designation"),
                department: formData.get("department"),
                token_limit: formData.get("tokenlimit"),
                role: formData.get("usergroup"),
                status: formData.get("status"),
                assigned_applications: JSON.parse(assignedApplicationsString),
                user_permissions: userPermissions,
              };
            }
            return row;
          });
          // console.log(updatedUserDetails);
          this.props.updateUserDetails(
            updatedUserDetails,
            this.props.isEditMode
          ); // Update userDetails in parent component
        } else {
          console.error("Editing User failed");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }

    this.props.handleClose(); // Close the dialog
  };

  handleEmailChange = (event) => {
    const { name, value } = event.target;
    const emailRegex = /[a-zA-Z0-9]+@[a-z]+\.[a-z]{2,3}/;

    if (!emailRegex.test(value)) {
      this.setState((prevState) => ({
        isValidEmail: false,
        [name]: value,
        errors: { ...prevState.errors, email: "Please enter a valid email" },
      }));
    } else {
      this.setState((prevState) => ({
        isValidEmail: true,
        [name]: value,
        errors: { ...prevState.errors, email: "" },
      }));
    }
  };
  handlePasswordChange = (event) => {
    const { name, value } = event.target;
    const password_regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,}$/;

    if (!password_regex.test(value)) {
      this.setState((prevState) => ({
        isPasswordInputNotEmpty: value.length > 0,
        isValidPassword: false,
        [name]: value,
        errors: {
          ...prevState.errors,
          password:
            "Password must be at least 10 characters and it should contain at least one uppercase letter, one lowercase letter, one digit, and one special character.",
        },
      }));
    } else {
      this.setState((prevState) => ({
        isPasswordInputNotEmpty: value.length > 0,
        isValidPassword: true,
        [name]: value,
        errors: { ...prevState.errors, password: "" },
      }));
    }
  };
  handlePasswordVisibilityToggle = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleTokenChange = (event) => {
    const { name, value } = event.target;

    // Regular expression to match integers greater than 5000
    const integerGreaterThan5000Regex = /^(?:[5-9]\d{3,}|\d{5,})$/;

    // Check if the value matches the regex for integers greater than 5000
    if (!integerGreaterThan5000Regex.test(value)) {
      this.setState((prevState) => ({
        isValidToken: false,
        [name]: value,
        errors: {
          ...prevState.errors,
          tokenLimit: "Token limit must be an integer greater than 5000",
        },
      }));
    } else {
      this.setState((prevState) => ({
        isValidToken: true,
        [name]: value,
        errors: { ...prevState.errors, tokenLimit: "" },
      }));
    }
  };
  handleUsernameChange = (event) => {
    const { name, value } = event.target;
    if (this.hasSpecialFirstCharacter(value)) {
      this.setState((prevState) => ({
        [name]: value,
        isValidUsername: false,
        errors: {
          ...prevState.errors,
          username:
            "Username can contain lettes, numbers and underscore(_). Must start with a letter..",
        },
      }));
    } else if (this.hasSpecialCharacters(value.replace(/\s+/g, " "))) {
      this.setState((prevState) => ({
        [name]: value,
        isValidUsername: false,
        errors: {
          ...prevState.errors,
          username: "Username should not contain special characters.",
        },
      }));
    } else if (value.length === 0) {
      this.setState((prevState) => ({
        [name]: value,
        isValidUsername: false,
        errors: {
          ...prevState.errors,
          username: "Username cannot be empty.",
        },
      }));
    } else {
      this.setState((prevState) => ({
        isValidUsername: true,
        [name]: value,
        errors: { ...prevState.errors, username: "" },
      }));
    }
  };

  hasSpecialCharacters = (userName) => {
    // Define a regular expression to match special characters
    const regex = /[^a-zA-Z0-9_\s]+/;
    // returns boolean value from test()
    return regex.test(userName);
  };
  hasSpecialFirstCharacter = (userName) => {
    // Check if the first character is a special character
    const firstCharSpecial = /^[^a-zA-Z]/.test(userName.charAt(0));
    return firstCharSpecial;
  };
  handleSnackbarOpen = (severity, message) => {
    this.setState({
      snackbarOpen: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  render() {
    const { open, handleClose, isEditMode } = this.props;
    const {
      username,
      email,
      password,
      department,
      designation,
      status,
      assignedApplications,
      tokenlimit,
      usergroup,
      showPassword,
      isPasswordInputNotEmpty,
      snackbarOpen,
      snackbarSeverity,
      snackbarMessage,
      isEmailVerified
    } = this.state;
    const userGroups = this.getAccessibleUserGroups(); // this is for to get applicable user groups
    const adminPermissionList = Object.keys(this.state.userGroupPermissions); // this gives the list of permissions
    const applications = Object.keys(assignedApplications);
    const departmentOptions = [
      "Development",
      "Quality Assurance (QA)",
      "Product Management",
      "User Experience (UX) Design",
      "DevOps",
      "Data Science",
      "Information Security",
      "Technical Support",
      "Research and Development",
      "IT Operations",
    ];
    const designationOptions = [
      "Software Engineer",
      "Senior Software Engineer",
      "Software Developer",
      "Frontend Developer",
      "Backend Developer",
      "Full Stack Developer",
      "QA Engineer",
      "Product Manager",
      "UX Designer",
      "DevOps Engineer",
    ];
    const icon = (
      <span
        style={{
          display: "inline-flex",
          color: "#6001FF",
        }}
      >
        <CheckBoxOutlineBlankIcon fontSize="small" />
      </span>
    );

    const checkedIcon = (
      <span
        style={{
          display: "inline-flex",
          color: "#6001FF",
        }}
      >
        <CheckBoxIcon fontSize="small" />
      </span>
    );
    return (
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={(event) => this.handleFormSubmit(event)}>
          <DialogTitle>{isEditMode ? "Edit User" : "Add User"}</DialogTitle>
          <DialogContent>
            {!isEditMode && (
              <TextField
                label="Username"
                name="username"
                value={username}
                onChange={this.handleUsernameChange}
                fullWidth
                margin="normal"
                sx={textFieldSx}
                required
                error={!!this.state.errors.username} // Check if there's an error
                helperText={this.state.errors.username} // Display the error message
              />
            )}
            {!isEditMode && (
              <TextField
                label="Email"
                name="email"
                value={email.trim()}
                fullWidth
                margin="normal"
                sx={textFieldSx}
                required
                onChange={this.handleEmailChange}
                error={!!this.state.errors.email} // Check if there's an error
                helperText={this.state.errors.email} // Display the error message
              />
            )}
            {!isEditMode && (
              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                fullWidth
                margin="normal"
                sx={textFieldSx}
                autoComplete="current-password"
                required
                onChange={this.handlePasswordChange}
                error={!!this.state.errors.password} // Check if there's an error
                helperText={this.state.errors.password} // Display the error message
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isPasswordInputNotEmpty && (
                        <Button
                          onClick={this.handlePasswordVisibilityToggle}
                          style={{
                            minWidth: "unset",
                            padding: 0,
                            color: "#6001FF",
                          }} // Remove button padding
                        >
                          {showPassword ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <FormControl fullWidth sx={{ marginTop: "10px" }}>
              <InputLabel
                id="demo-simple-select-helper-label"
                sx={{
                  color: "grey", // Set the color to grey
                  "&.Mui-focused": {
                    color: "grey", // Set the color to grey when in focus
                  },
                }}
              >
                Designation
              </InputLabel>
              <Select
                id="designation"
                name="designation"
                label="Designation"
                autoComplete="designation"
                margin="normal"
                required
                value={designation}
                onChange={this.handleInputChange}
                sx={selectFieldSx}
                menuprops={{
                  PaperProps: {
                    style: {
                      maxHeight: "200px", // Set the maximum height for the dropdown
                    },
                  },
                }}
              >
                {designationOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
                required
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: "15px" }}>
              <InputLabel
                id="demo-simple-select-helper-label"
                sx={{
                  color: "grey", // Set the color to grey
                  "&.Mui-focused": {
                    color: "grey", // Set the color to grey when in focus
                  },
                }}
              >
                Department
              </InputLabel>
              <Select
                id="department"
                name="department"
                label="Department"
                autoComplete="department"
                margin="normal"
                required
                value={department}
                onChange={this.handleInputChange}
                sx={selectFieldSx}
                menuprops={{
                  PaperProps: {
                    style: {
                      maxHeight: "200px", // Set the maximum height for the dropdown
                    },
                  },
                }}
              >
                {departmentOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
                required
              </Select>
            </FormControl>
            <TextField
              label="tokenlimit"
              type="tokenlimit"
              name="tokenlimit"
              value={tokenlimit}
              fullWidth
              margin="normal"
              sx={textFieldSx}
              required
              onChange={this.handleTokenChange}
              error={!!this.state.errors.tokenLimit} // Check if there's an error
              helperText={this.state.errors.tokenLimit}
            />
            <FormControl
              fullWidth
              style={{ marginTop: "15px", marginBottom: "15px" }}
            >
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  color: "grey", // Set the color to grey
                  "&.Mui-focused": {
                    color: "grey", // Set the color to grey when in focus
                  },
                }}
              >
                Assigned User Group to user
              </InputLabel>
              <Select
                id="demo-simple-select"
                name="usergroup"
                value={usergroup}
                label="Assigned User Group to user"
                sx={selectFieldSx}
                onChange={this.handleInputChange}
                fullWidth
                required
              >
                {userGroups.map((group) => (
                  <MenuItem key={group} value={group}>
                    {group}
                  </MenuItem>
                ))}
                required
              </Select>
            </FormControl>
            {isEditMode && isEmailVerified &&
(            <FormControl
              fullWidth
              style={{ marginTop: "15px", marginBottom: "15px" }}
            >
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  color: "grey", // Set the color to grey
                  "&.Mui-focused": {
                    color: "grey", // Set the color to grey when in focus
                  },
                }}
              >
                Status
              </InputLabel>
              <Select
                id="demo-simple-select"
                name="status"
                value={status}
                label="status"
                onChange={this.handleInputChange}
                fullWidth
                required
                sx={selectFieldSx}
                menuprops={{
                  PaperProps: {
                    style: {
                      maxHeight: "200px", // Set the maximum height for the dropdown
                    },
                  },
                }}
              >
                <MenuItem value={"active"}>Active</MenuItem>
                <MenuItem value={"inactive"}>In Active</MenuItem>
                required
              </Select>
            </FormControl>)
  }
            <Autocomplete
              multiple
              fullWidth
              style={{ marginTop: "15px" }}
              sx={selectFieldSx}
              menuprops={{
                PaperProps: {
                  style: {
                    maxHeight: "200px", // Set the maximum height for the dropdown
                  },
                },
              }}
              id="checkboxes-tags-demo"
              options={applications}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              value={Object.keys(this.state.assignedApplications).filter(
                (app) => this.state.assignedApplications[app]
              )}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      onChange={() => this.handleCheckBoxChange(option)}
                      onDelete={() => this.handleCheckBoxChange(option)}
                    />
                    {option}
                  </li>
                );
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option}
                    {...getTagProps({ index })}
                    style={{
                      background: "#6001FF",
                      color: "white",
                      marginRight: 8,
                    }}
                    deleteIcon={<CancelIcon style={{ color: "white" }} />}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Assigned Applications to User"
                  placeholder="Applications"
                />
              )}
            />
            {usergroup === "admin" && (
              <Autocomplete
                multiple
                fullWidth
                style={{ marginTop: "15px" }}
                sx={selectFieldSx}
                id="checkboxes-tags-demo"
                options={adminPermissionList}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                value={Object.keys(this.state.userGroupPermissions).filter(
                  (permissionName) =>
                    this.state.userGroupPermissions[permissionName]
                )}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        onChange={() =>
                          this.handleUserPermissionsCheckBoxChange(option)
                        }
                        onDelete={() =>
                          this.handleUserPermissionsCheckBoxChange(option)
                        }
                      />
                      {option}
                    </li>
                  );
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      style={{
                        background: "#6001FF",
                        color: "white",
                        marginRight: 8,
                      }}
                      deleteIcon={<CancelIcon style={{ color: "white" }} />}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Assigned Permissions to admin"
                    placeholder="Permissions"
                  />
                )}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Typography
              variant="p"
              color="primary"
              style={{ color: "#6001FF", cursor: "pointer", marginRight: "2%" }}
              onClick={() => this.resetFormState()}
            >
              Cancel
            </Typography>
            <Button
              variant="contained"
              color="primary"
              // startIcon={isEditMode && <AddIcon />}
              style={{
                backgroundColor: "#6001FF",
                color: "#fff",
                textTransform: "none",
                marginRight: "3.5%",
              }}
              type="submit"
            >
              {isEditMode ? "Save Changes" : "Add"}
            </Button>
          </DialogActions>
        </form>
        <Snackbar
          open={snackbarOpen} // Use the correct state name here (snackbarOpen)
          autoHideDuration={3000}
          onClose={this.handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.handleSnackbarClose}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Dialog>
    );
  }
}

export default UserForm;
