import React, { Component } from "react";
import SendIcon from "@mui/icons-material/Send";
import InputBase from "@mui/material/InputBase";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";

export class ChatInputBase extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const {
      newMessage,
      loader,
      handleSendButton,
      handleInputChange,
      handleKeyDown,
    } = this.props;
    return (
      <>
        <Grid container sx={{ position: "fixed", bottom: 70 }}>
          <Grid item sx={{ width: "80%" }}>
            <Grid container alignItems="center">
              <InputBase
                className="input-field"
                sx={{
                  ml: 20,
                  flex: 1,
                  marginRight: 20,
                  borderRadius: 10,
                  border: "1px solid #000000",
                  "&:hover": {
                    borderColor: "#6001FF",
                  },
                  backgroundColor: "#FFFFFF",
                  padding: "5px 7% 5px 5%",
                  minHeight: "70px",
                  height: "auto",
                  maxHeight: "130px",
                  color: "#6b6f73",
                  fontSize: "1.25rem",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 400,
                  overflowY: "hidden",
                  overflowX:"hidden",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#6001FF #F5F5F5",
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    backgroundColor: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "4px",
                    backgroundColor: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "transparent",
                  },
                  "& .scrollbar": {
                    scrollbarWidth: "thin",
                    scrollbarColor: "#6001FF #F5F5F5",
                    overflowY: "hidden",
                    overflowX:"hidden",
                  },
                }}
                multiline
                maxrows={5}
                type="text"
                placeholder="Type a message..."
                value={newMessage}
                onChange={handleInputChange}
                onFocus={this.handleInputFocus}
                onBlur={this.handleInputBlur}
                onKeyDown={handleKeyDown}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      style={{
                        marginLeft: "130%",
                        color: "#6001FF",
                        transform: "rotate(340deg)",
                        marginTop: "-5%",
                      }}
                      //onClick={this.handleLoadAndSendMessage.bind(this)}
                      onClick={() => {
                        !loader && handleSendButton();
                      }}
                    >
                      <SendIcon style={{ fontSize: 40 }} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default ChatInputBase;

// import React, { Component } from "react";
// import SendIcon from "@mui/icons-material/Send";
// import InputBase from "@mui/material/InputBase";
// import { InputAdornment } from "@mui/material";
// import IconButton from "@mui/material/IconButton";
// import Grid from "@mui/material/Grid";

// export class ChatInputBase extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   render() {
//     const {
//       newMessage,
//       loader,
//       handleSendButton,
//       handleInputChange,
//       handleKeyDown,
//     } = this.props;

//     return (
//       <>
//         <Grid container sx={{ position: "fixed", bottom: { xs: 50, sm: 70 }, width: "100%", paddingX: { xs: 2, sm: 5 } }}>
//           <Grid item xs={4} sm={6} md={8} lg={10} sx={{ margin: "0 auto" }}>
//             <Grid container alignItems="center">
//               <InputBase
//                 className="input-field"
//                 sx={{
//                   ml: { xs: 0, sm: 10 },
//                   flex: 1,
//                   marginRight: { xs: 1, sm: 20 },
//                   borderRadius: 10,
//                   border: "1px solid #000000",
//                   "&:hover": {
//                     borderColor: "#6001FF",
//                   },
//                   backgroundColor: "#FFFFFF",
//                   padding: "5px 5%",
//                   minHeight: "70px",
//                   height: "auto",
//                   maxHeight: "130px",
//                   color: "#6b6f73",
//                   fontSize: { xs: "1rem", sm: "1.25rem" },
//                   fontFamily: "Poppins, sans-serif",
//                   fontWeight: 400,
//                   // overflowY: "auto",
//                   // scrollbarWidth: "thin",
//                   // scrollbarColor: "#6001FF #F5F5F5",
//                   // "&::-webkit-scrollbar": {
//                   //   width: "6px",
//                   //   backgroundColor: "transparent",
//                   // },
//                   // "&::-webkit-scrollbar-thumb": {
//                   //   borderRadius: "4px",
//                   //   backgroundColor: "transparent",
//                   // },
//                   // "&::-webkit-scrollbar-thumb:hover": {
//                   //   backgroundColor: "transparent",
//                   // },
//                   // "& .scrollbar": {
//                   //   scrollbarWidth: "thin",
//                   //   scrollbarColor: "#6001FF #F5F5F5",
//                   //   overflowY: "auto",
//                   // },
//                 }}
//                 multiline
//                 maxRows={5}
//                 type="text"
//                 placeholder="Type a message..."
//                 value={newMessage}
//                 onChange={handleInputChange}
//                 onKeyDown={handleKeyDown}
//                 endAdornment={
//                   <InputAdornment position="end">
//                     <IconButton
//                       color="primary"
//                       sx={{
//                         marginLeft: { xs: "30%", sm: "130%" },
//                         color: "#6001FF",
//                         transform: "rotate(340deg)",
//                         marginTop: { xs: "0%", sm: "-5%" },
//                       }}
//                       onClick={() => {
//                         !loader && handleSendButton();
//                       }}
//                     >
//                       <SendIcon sx={{ fontSize: { xs: 30, sm: 40 } }} />
//                     </IconButton>
//                   </InputAdornment>
//                 }
//               />
//             </Grid>
//           </Grid>
//         </Grid>
//       </>
//     );
//   }
// }

// export default ChatInputBase;

