import React, { Component } from "react";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import { withRouter } from "./withRouter";
import LinearProgressLabel from "./LinearProgressLabel";
import axios, { CancelToken, isCancel } from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const BASE_URL = process.env.REACT_APP_BASE_URL;
const EMBEDDING_SERVICE_URL = process.env.REACT_APP_EMBEDDING_SERVICE_URL;
// const application_code = "ZN002";
const MAX_FILE_SIZE_BYTES = 50 * 1024 * 1024;
const VALID_FILE_EXTENSIONS = [".pdf", ".docx", ".doc", ".txt"];
// const APPLICATIONS = ["Zuno for Docs", "Employee Engagement"];
const APPLICATION_MAPPING = {
  "Zuno for Docs": "ZN002",
  "Employee Engagement": "ZN003",
};
const REVERSE_APPLICATION_MAPPING = {
  ZN002: "Zuno for Docs",
  ZN003: "Employee Engagement",
};

const application_mapper = (application) => {
  return APPLICATION_MAPPING[application];
};
class FileUploadQA extends Component {
  constructor(props) {
    super(props);
    // Create a ref
    this.cancelFileUpload = React.createRef();
    this.state = {
      selectedFiles: [],
      selectedLinks: [],
      message: "",
      value: 0,
      webCrawlClicked: false,
      filesUploaded: false,
      disableUploadButton: false,
      showLoader: false,
      selectedUserNames: [],
      selectedUserEmails: [],
      users: ["Loading..."],
      documentInfo: null,
      progress: 0,
      loaded_1: 0,
      target: 0,
      showsnack: false,
      snackmessage: "",
      severity: "",
      selectedApplications: [],
      folder_name: this.props.folder_name,
      folder_id: this.props.folder_id,
    };
  }
  componentDidMount() {
    if (this.props.isEditDocument) {
      this.setState(
        {
          documentInfo: this.props.fileDetails,
        },
        () => {
          this.getUserDetails();
          this.getApplicationDetails();
        }
      );
    } else {
      this.getUserDetails();
    }
  }

  componentDidUpdate() {
    // Save data to local storage whenever state changes
    localStorage.setItem(
      "selectedFiles",
      JSON.stringify(this.state.selectedFiles)
    );
    localStorage.setItem(
      "selectedLinks",
      JSON.stringify(this.state.selectedLinks)
    );
  }
  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  handleSnackbarOpen = (severity, message) => {
    this.setState({
      snackmessage: message,
      severity: "error",
      showsnack: true,
    });
  };
  handleSnackbarClose = () => {
    this.setState({ showsnack: false });
  };

  handleFileChange = (e) => {
    e.preventDefault();

    const files = Array.from(e.target.files);

    const filesWithSpecialCharacters =
      this.filterFilesBySpecialCharacters(files);
    this.handleSpecialCharacterErrors(filesWithSpecialCharacters);

    // Check for files with Invalid Extension
    const filesWithInvalidExtensions =
      this.filterFilesWithInvalidExtensions(files);
    this.handleInvalidExtensionErrors(filesWithInvalidExtensions);

    // Check for files with filename length greater than 200
    const filesWithLongNames = this.filterFilesByLength(files, 200);
    this.handleFileNameLengthErrors(filesWithLongNames);

    // Check for files exceeding size limit
    const filesExceedingSizeLimit = this.filterFilesBySizeLimit(
      files,
      MAX_FILE_SIZE_BYTES
    );
    this.handleSizeLimitErrors(filesExceedingSizeLimit);

    const validFiles = files.filter(
      (file) =>
        !this.hasSpecialCharacters(file.name) &&
        !this.hasInvalidExtension(file.name) &&
        !this.fileExceedsSizeLimit(file, MAX_FILE_SIZE_BYTES) &&
        !this.isFileLengthExceeding(file.name, 200)
    );

    if (validFiles.length === 0) {
      // If no valid files are selected, return without updating the state
      return;
    }

    this.setState(
      (prevState) => ({
        selectedFiles: [...prevState.selectedFiles, ...validFiles],
        showAddMoreButton: true,
      }),
      () => {
        // Save selected files to local storage after updating the state
        const fileData = this.state.selectedFiles.map((file) => ({
          name: file.name,
          lastModified: file.lastModified,
        }));
        localStorage.setItem("filePaths", JSON.stringify(fileData));
      }
    );
  };

  filterFilesBySpecialCharacters = (files) => {
    return files.filter((file) => this.hasSpecialCharacters(file.name));
  };

  handleSpecialCharacterErrors = (filesWithSpecialCharacters) => {
    if (filesWithSpecialCharacters.length > 0) {
      const message =
        filesWithSpecialCharacters.length > 1
          ? `${filesWithSpecialCharacters.length} files with special characters cannot be uploaded.`
          : `${filesWithSpecialCharacters.length} file with special characters cannot be uploaded.`;
      this.handleSnackbarOpen("error", message);
    }
  };
  hasSpecialCharacters = (fileName) => {
    // Define a regular expression to match special characters
    const regex = /[^a-zA-Z0-9_.-\s]+/;
    // returns boolean value from test()
    return regex.test(fileName);
  };

  filterFilesBySizeLimit = (files, maxSize) => {
    return files.filter((file) => this.fileExceedsSizeLimit(file, maxSize));
  };

  fileExceedsSizeLimit = (file, maxSize) => {
    return file.size > maxSize;
  };
  handleSizeLimitErrors = (filesExceedingSizeLimit) => {
    if (filesExceedingSizeLimit.length > 0) {
      const message =
        filesExceedingSizeLimit.length > 1
          ? `${filesExceedingSizeLimit.length} files have exceeded 50MB cannot be uploaded.`
          : `${filesExceedingSizeLimit.length} file have exceeded 50MB cannot be uploaded.`;
      this.handleSnackbarOpen("error", message);
    }
  };

  filterFilesWithInvalidExtensions = (files) => {
    return files.filter((file) => this.hasInvalidExtension(file.name));
  };

  hasInvalidExtension = (fileName) => {
    const fileExtension = fileName
      .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2)
      .toLowerCase();
    return !VALID_FILE_EXTENSIONS.includes(`.${fileExtension}`);
  };
  handleInvalidExtensionErrors = (filesWithInvalidExtensions) => {
    if (filesWithInvalidExtensions.length > 0) {
      const message =
        filesWithInvalidExtensions.length > 1
          ? `${filesWithInvalidExtensions.length} files have invalid extensions.`
          : `${filesWithInvalidExtensions.length} file has an invalid extension.`;
      this.handleSnackbarOpen("error", message);
    }
  };
  filterFilesByLength = (files, maxLength) => {
    return files.filter((file) =>
      this.isFileLengthExceeding(file.name, maxLength)
    );
  };

  isFileLengthExceeding = (fileName, maxLength) => {
    return fileName.length > maxLength;
  };

  handleFileNameLengthErrors = (filesWithLongNames) => {
    if (filesWithLongNames.length > 0) {
      const message =
        filesWithLongNames.length > 1
          ? `${filesWithLongNames.length} files have names longer than 200 characters.`
          : `${filesWithLongNames.length} file has a name longer than 200 characters.`;
      this.handleSnackbarOpen("error", message);
    }
  };

  disableButton = () => {
    this.setState({ disableUploadButton: true });
  };
  enableButton = () => {
    this.setState({ disableUploadButton: false });
  };

  enableLoader = () => {
    this.setState({ showLoader: true });
  };
  disableLoader = () => {
    this.setState({ showLoader: false });
  };
  handleUpload = async (e) => {
    e.preventDefault();
    const { folder_name, folder_id } = this.state;
    try {
      const formData = new FormData();
      const token = localStorage.getItem("token");
      const userOrganization = JSON.parse(
        localStorage.getItem("currentLoggedInUserDetails")
      )["organization"];

      // const selectedUserEmails = this.getSelectedUsers();
      // const selectedApplicationCodes = this.state.selectedApplications.map(
      //   (app) => application_mapper(app)
      // );
      // console.log(selectedApplicationCodes);
      // formData.append("selectedEmails", JSON.stringify(selectedUserEmails));
      // formData.append(
      //   "application_codes",
      //   JSON.stringify(selectedApplicationCodes)
      // );
      // formData.append("application_code", application_code);
      // formData.append("username", localStorage.getItem("username"));

      this.state.selectedFiles.forEach((file) =>
        formData.append("files", file)
      );

      formData.append("organization", userOrganization);
      formData.append("folder_name", folder_name);
      formData.append("folder_id", folder_id);

      this.disableButton();
      this.enableLoader();

      const url = `${EMBEDDING_SERVICE_URL}/zn002/embedding/admin_document_upload`;
      const responseData = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (data) => {
          const uploadProgress = Math.round((data.loaded * 100) / data.total);
          if (uploadProgress < 100) {
            this.setState({
              progress: uploadProgress,
              loaded_1: data.loaded,
              target_1: data.total,
            });
          }
        },
        cancelToken: new CancelToken(
          (cancel) => (this.cancelFileUpload.current = cancel)
        ),
      });

      if (responseData.statusText) {
        this.setState({
          message: responseData.data.message,
          selectedFiles: [],
          showAddMoreButton: false,
          filesUploaded: true,
        });
      } else {
        this.setState({
          message: responseData.data.detail,
        });
        console.error("File upload error:", responseData.data.detail);
      }
    } catch (error) {
      this.setState({
        message: error.response?.data?.detail || error.message,
      });
      if (isCancel(error)) {
        console.error("Error:", error.message);
      }
      console.error("Error:", error.response?.data?.detail || error.message);
    } finally {
      this.enableButton();
      this.disableLoader();
    }
  };

  handleAddMoreFiles = () => {
    this.fileInputRef.value = null;
    this.fileInputRef.click();
  };

  handleDeleteFile = (index) => {
    this.setState((prevState) => {
      const newSelectedFiles = [...prevState.selectedFiles];
      newSelectedFiles.splice(index, 1);
      return { selectedFiles: newSelectedFiles };
    });
  };

  handleWebCrawl = () => {
    const { webLink, selectedLinks } = this.state;
    if (webLink.trim() !== "") {
      this.setState({
        selectedLinks: [...selectedLinks, webLink.trim()],
        webLink: "",
        webCrawlClicked: true,
      });
    }
  };
  handleUpdateDocument = async (fileid) => {
    try {
      const formData = new FormData();
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");
      const userOrganization = JSON.parse(
        localStorage.getItem("currentLoggedInUserDetails")
      )["organization"];
      formData.append("file_id", this.props.fileDetails.id);
      formData.append("updatedByUserEmail", email);
      formData.append(
        "updatedUserAccessList",
        JSON.stringify(this.getSelectedUsers())
      );
      const selectedApplicationCodes = this.state.selectedApplications.map(
        (app) => application_mapper(app)
      );
      console.log(selectedApplicationCodes);
      formData.append(
        "updatedApplicationCodes",
        JSON.stringify(selectedApplicationCodes)
      );
      formData.append("userOrganization", userOrganization);
      const response = await fetch(
        `${BASE_URL}/admin/zn002/admin_document_files`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      if (response.ok) {
        console.log("Updated the file details successfully");
      } else {
        console.log("Failed to update the user details");
      }
    } catch (error) {
      console.log(error);
    } finally {
      // this.setState(()=>{window.location.reload()})
      this.props.fetchDocumentFilesData();
      this.props.handleCloseDocument();
    }
  };
  handleDeleteLink = (index) => {
    this.setState((prevState) => {
      const newSelectedLinks = [...prevState.selectedLinks];
      newSelectedLinks.splice(index, 1);
      return { selectedLinks: newSelectedLinks };
    });
  };
  getUserDetails = () => {
    try {
      const userDetails = this.props.userDetails;
      const access_user_list = this.state.documentInfo
        ? this.state.documentInfo.userAccessList
        : [];
      const users = userDetails.map((item) => {
        const username = item.username;
        const email = item.email;
        const accessible = access_user_list.includes(email);
        return { username: username, email: email, accessible: accessible };
      });
      const initiallyAccessibleUsers = users.filter((user) => user.accessible);

      this.setState({
        users: users,
        selectedUserNames: initiallyAccessibleUsers.map(
          (user) => user.username
        ),
        selectedUserEmails: initiallyAccessibleUsers.map((user) => user.email),
      });

      return users;
    } catch (error) {
      console.log(error);
    }
  };

  getApplicationDetails = () => {
    try {
      const applicationCodes = this.state.documentInfo
        ? this.state.documentInfo.application_code
        : [];
      const initialApplicationNames = applicationCodes.map(
        (code) => REVERSE_APPLICATION_MAPPING[code]
      );
      this.setState({
        selectedApplications: initialApplicationNames,
      });
    } catch (error) {
      console.log(error);
    }
  };
  handleCheckBoxChange = (username) => {
    const { selectedUserNames, users } = this.state;
    const updatedUsers = [...users]; // Create a copy of the users array

    // Find the user object in the updatedUsers array by username
    const user = updatedUsers.find((user) => user.username === username);

    if (user) {
      // Toggle the 'accessible' property of the user based on whether it was selected or deselected
      user.accessible = !selectedUserNames.includes(username);
    }
    const index = selectedUserNames.indexOf(username);

    if (index !== -1) {
      // Remove the user from the selected Users list
      selectedUserNames.splice(index, 1);
    } else {
      // Add the user to the selected Users list
      selectedUserNames.push(username);
    }
    // Update the selectedUserNames in the state
    this.setState({ selectedUserNames });

    // Update the users in the state
    this.setState({ users: updatedUsers });
  };
  handleApplicationCheckBoxChange = (applicationName) => {
    const { selectedApplications } = this.state;
    const updatedApplications = [...selectedApplications]; // Create a copy of the selectedApplications array

    const index = updatedApplications.indexOf(applicationName);

    if (index !== -1) {
      // If the application is already selected, remove it
      updatedApplications.splice(index, 1);
    } else {
      // If the application is not selected, add it
      updatedApplications.push(applicationName);
    }

    // Update the selectedApplications in the state
    this.setState({ selectedApplications: updatedApplications });
  };
  getSelectedUsers = () => {
    const selectedUserEmails = this.state.users
      .filter((user) => this.state.selectedUserNames.includes(user.username))
      .map((user) => user.email);
    return selectedUserEmails;
  };

  cancelUpload = () => {
    if (this.cancelFileUpload.current) {
      this.cancelFileUpload.current("User has cancelled the file upload");
    }
  };

  render() {
    const {
      showLoader,
      disableUploadButton,
      selectedFiles,
      message,
      showAddMoreButton,
      // value,
      // webCrawlClicked,
      progress,
      showsnack,
      snackmessage,
      severity,
      // selectedApplications,
    } = this.state;
    return (
      <Container>
        <Paper
          elevation={5}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "20px",
            backgroundColor: "#FFFFFF",
            height: "fit-content",
            padding: "2rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#FFF",
              borderRadius: "25px",
              padding: "5px",
              marginRight: "3%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                flexDirection: "column",
              }}
            >
              {!this.props.isEditDocument ? (
                <div style={{ width: "30rem" }}>
                  {!showLoader && (
                    <div>
                      <h2>My Files</h2>
                      <form onSubmit={this.handleUpload}>
                        <input
                          type="file"
                          multiple
                          style={{ display: "none" }}
                          onChange={this.handleFileChange}
                          ref={(ref) => (this.fileInputRef = ref)}
                        />
                        {selectedFiles.length >= 0 && (
                          <Button
                            onClick={this.handleAddMoreFiles}
                            style={{ color: "#6001FF" }}
                          >
                            {showAddMoreButton ? "Add More Files" : "Add File"}
                          </Button>
                        )}
                        &nbsp;&nbsp;
                        <Button
                          variant="contained"
                          color="error"
                          onClick={this.handleUpload}
                          disabled={
                            selectedFiles.length === 0 ||
                            disableUploadButton === true
                          }
                          style={{
                            backgroundColor:
                              selectedFiles.length === 0
                                ? "initial"
                                : "#6001FF",
                            color:
                              selectedFiles.length === 0
                                ? "#6001FF"
                                : "#ffffff",
                          }}
                        >
                          {showLoader ? (
                            <CircularProgress
                              size={25}
                              sx={{
                                color: "#FFFFFF",
                                marginLeft: "-7%",
                              }}
                            />
                          ) : (
                            "Upload Files"
                          )}
                        </Button>
                      </form>
                    </div>
                  )}
                  {showLoader && (
                    <h2 style={{ marginBottom: "5%" }}>Uploading files</h2>
                  )}
                  {showLoader && (
                    <div style={{ margin: "5px" }}>
                      {" "}
                      <LinearProgressLabel value={progress} />
                    </div>
                  )}
                  {!showLoader && (
                    <div>
                      <h3>Selected Files:</h3>

                      {selectedFiles.length > 4 ? (
                        <List>
                          <div style={{ height: "260px", overflow: "auto" }}>
                            {selectedFiles.map((file, index) => (
                              <ListItem key={index} disablePadding>
                                <ListItemButton>
                                  <ListItemIcon>
                                    <InsertDriveFileOutlinedIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={file.name} />
                                  <ListItemIcon
                                    onClick={() => this.handleDeleteFile(index)}
                                  >
                                    <DeleteIcon sx={{ color: "#6001FF" }} />
                                  </ListItemIcon>
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </div>
                        </List>
                      ) : (
                        <List>
                          {selectedFiles.map((file, index) => (
                            <ListItem key={index} disablePadding>
                              <ListItemButton>
                                <ListItemIcon>
                                  <InsertDriveFileOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={file.name} />
                                <ListItemIcon
                                  onClick={() => this.handleDeleteFile(index)}
                                >
                                  <DeleteIcon sx={{ color: "#6001FF" }} />
                                </ListItemIcon>
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      )}
                      {/* <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                        <Autocomplete
                          multiple
                          style={{ width: "100%" }}
                          sx={selectFieldSx}
                          options={users}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.username}
                          value={this.state.selectedUserNames.map((name) =>
                            users.find((user) => user.username === name)
                          )}
                          renderOption={(props, option, { selected }) => {
                            return (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                  onChange={() =>
                                    this.handleCheckBoxChange(option.username)
                                  }
                                  onDelete={() =>
                                    this.handleCheckBoxChange(option.username)
                                  }
                                />
                                {option.username}
                              </li>
                            );
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                label={option.username}
                                {...getTagProps({ index })}
                                style={{
                                  background: "#6001FF",
                                  color: "white",
                                  marginRight: 8,
                                }}
                                deleteIcon={
                                  <CancelIcon style={{ color: "white" }} />
                                }
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Users"
                              placeholder="Users"
                            />
                          )}
                          onChange={(event, newValue) => {
                            this.setState({
                              selectedUserNames: newValue.map(
                                (user) => user.username
                              ),
                            });
                          }}
                        />
                        <Autocomplete
                          multiple
                          style={{ width: "100%", marginTop: "2%" }}
                          sx={selectFieldSx}
                          options={APPLICATIONS.map(
                            (app) => APPLICATION_MAPPING[app]
                          )} // Map application names to codes
                          disableCloseOnSelect
                          getOptionLabel={(option) =>
                            APPLICATIONS.find(
                              (app) => APPLICATION_MAPPING[app] === option
                            )
                          } // Use application code as label
                          value={this.state.selectedApplications.map(
                            (app) => APPLICATION_MAPPING[app]
                          )} // Map selected application names to codes
                          renderOption={(props, option, { selected }) => {
                            const appName = Object.keys(
                              APPLICATION_MAPPING
                            ).find(
                              (key) => APPLICATION_MAPPING[key] === option
                            );
                            return (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                  onChange={
                                    () =>
                                      this.handleApplicationCheckBoxChange(
                                        appName
                                      ) // Use application name
                                  }
                                  onDelete={
                                    () =>
                                      this.handleApplicationCheckBoxChange(
                                        appName
                                      ) // Use application name
                                  }
                                />
                                {appName} 
                              </li>
                            );
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                label={Object.keys(APPLICATION_MAPPING).find(
                                  (key) => APPLICATION_MAPPING[key] === option
                                )} // Display application name
                                {...getTagProps({ index })}
                                style={{
                                  background: "#6001FF",
                                  color: "white",
                                  marginRight: 8,
                                }}
                                deleteIcon={
                                  <CancelIcon style={{ color: "white" }} />
                                }
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Selected Applications"
                              placeholder="Applications"
                            />
                          )}
                          onChange={(event, newValue) => {
                            // Handle the change in Autocomplete value
                            // This can include any logic needed to update your component state
                            this.setState({
                              selectedApplications: newValue.map((app) =>
                                Object.keys(APPLICATION_MAPPING).find(
                                  (key) => APPLICATION_MAPPING[key] === app
                                )
                              ),
                            });
                          }}
                        />
                      </div> */}
                      {!message && (
                        <div>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={this.props.handleCloseDocument}
                            style={{
                              backgroundColor: "#6001FF",
                              color: "#ffffff",
                            }}
                          >
                            Back
                          </Button>
                        </div>
                      )}
                    </div>
                  )}

                  {showLoader && (
                    <div>
                      {/* <p>{message}</p> */}
                      <Button
                        variant="contained"
                        color="success"
                        onClick={this.cancelUpload}
                        style={{
                          backgroundColor: "#6001FF",
                          color: "#ffffff",
                          marginTop: "10%",
                          textTransform:"none",
                        }}
                      >
                        {/* {showAddMoreButton ? "Add More Files" : "Add File"} */}
                        Cancel
                      </Button>
                    </div>
                  )}
                  {message && !showLoader && (
                    <div>
                      <p>{message}</p>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                          this.props.handleCloseDocument();
                          this.props.fetchDocumentFilesData();
                        }}
                        style={{ backgroundColor: "#6001FF", color: "#ffffff", textTransform:"none", }}
                      >
                        Next
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <div style={{ width: "30rem" }}>
                  <h2>Edit File</h2>
                  <List>
                    <ListItemText primary={this.props.fileDetails.filename} />
                  </List>
           
                </div>
              )}
            </div>
          </Box>
        </Paper>
        <Snackbar
          open={showsnack}
          autoHideDuration={4000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            this.setState({ showsnack: false });
          }}
        >
          <Alert
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }

              this.setState({ showsnack: false });
            }}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {snackmessage}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export default withRouter(FileUploadQA);
