import React, { Component } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import HeaderText from "./HeaderText";
import ChatButtonGroup from "./ChatButtonGroup";
import ChatRetryLangChain from "./ChatRetryLangChain";
import ChatRetryLlama from "./ChatRetryLlama";
import LoaderMessages from "./LoaderMessages";
import PdfChatInputBase from "./PdfChatInputBase";
import Botimageicon from "./botimageicon";
import WaterLoader from "./waterLoader";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import PersonIcon from "@mui/icons-material/Person";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
export class ChatMap extends Component {
  constructor(props) {
    super(props);
    const profileImage = localStorage.getItem("profileImage");
    let parsedProfileImage = null;

    if (profileImage && profileImage !== "undefined") {
      try {
        parsedProfileImage = JSON.parse(profileImage);
      } catch (error) {
        console.error("Error parsing profile image from localStorage", error);
      }
    }
    this.state = {
      profileImage: parsedProfileImage,
    };
    this.arrowRef = React.createRef();
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    // Check if new messages have been added and scroll to bottom if they have
    if (this.props.messagesQA.length !== prevProps.messagesQA.length) {
      this.scrollToBottom();
      this.props.handleSetTimeOutMsgDocs();
    }
  }
  scrollToBottom() {
    const container = this.containerRef.current;
    // Check if containerRef is not null before accessing properties
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }
  render() {
    const { profileImage } = this.state;

    const {
      messagesQA,
      selectedButtons,
      loader,
      loader_llama,
      langChainIndex,
      llamaIndex,
      loader_Messages,
      tryCount_llama,
      timeOut_llma,
      retryTime_llma,
      systemError,
      timeOutMsg,
      retryTime,
      tryCount,
      newMessage,
      handleInputChange,
      handleKeyDown,
      handleSendButton,
      handleRetry,
      handleRetry_Llama,
      handleSendMessagellamaindex,
      handleSendMessagelangchain,
      toggleBookmark,
      profilePicDocs,
      assistantNameDocs,
      selectedLanguageModels,
      filenames,
      summary,
      filenameAndSummary,
    } = this.props;
    return (
      <>
        <Box
          ref={this.containerRef}
          style={{
            height: "calc(95vh - 200px)",
            overflow: "auto",
            padding: "10px 10px 10px 10px",
            // marginTop: "2%",
            marginLeft: "-2%",
            width: "97%",
            background: "#F3F6FD",
            borderRadius: "20px 20px 0px 0px",
            fontSize: "30px",
            //borderRadius: '1%',
            // border: "1px solid red",
          }}
        >
          <HeaderText
            profilePicDocs={profilePicDocs}
            assistantNameDocs={assistantNameDocs}
            filenames={filenames}
            summary={summary}
            filenameAndSummary={filenameAndSummary}
          />
          <>
            {messagesQA.map((message, index) =>
              index < messagesQA.length - 1 ? (
                <div
                  key={index}
                  className={`message ${message.sender}`}
                  style={{
                    background: message.sender === "ZUNO" ? "#FFFFFF" : "none",
                    borderRadius: message.sender === "ZUNO" ? "40px" : "0px",
                    padding: "10px 10px 10px 10px",
                    width: "95%",
                    marginLeft: "20px",
                    marginTop: "20px",
                  }}
                >
                  <ListItem key={index}>
                    <ListItemAvatar sx={{ marginRight: "1.3%" }}>
                      {message.sender === "ZUNO" ? (
                        (loader_llama && llamaIndex === index) ||
                        (loader && langChainIndex === index) ? (
                          <WaterLoader />
                        ) : profilePicDocs ? (
                          <Stack
                            direction="row"
                            spacing={2}
                            sx={{ marginRight: "5px" }}
                          >
                            <Avatar
                              alt="profilePic"
                              src={profilePicDocs}
                              sx={{ width: 56, height: 56 }}
                            />
                          </Stack>
                        ) : (
                          <Botimageicon />
                        )
                      ) : (
                        <Avatar
                          sx={{ marginRight: 2, width: 56, height: 56 }}
                          style={{ backgroundColor: "#6001FF" }}
                        >
                          {profileImage ? (
                            <img
                              src={`data:image/jpeg;base64,${profileImage}`}
                              alt="Profile"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <PersonIcon sx={{ width: 40, height: 40 }} />
                          )}
                        </Avatar>
                      )}
                    </ListItemAvatar>

                    <ListItemText
                      key={index}
                      primary=""
                      secondary={
                        message.sender === "ZUNO" &&
                        selectedButtons[message.responseID] === "Llamaindex" ? (
                          // Code for displaying llamatext
                          loader_llama && llamaIndex === index ? (
                            <LoaderMessages loader_Messages={loader_Messages} />
                          ) : (
                            <>
                              {typeof message.llamatext === "object" &&
                              message.llamatext !== null ? (
                                message.llamatext.response
                                  .split("\n")
                                  .map((paragraph, i) => (
                                    <p
                                      key={i}
                                      style={{
                                        fontSize: "1.25rem",
                                        color: "#818589",
                                      }}
                                    >
                                      {paragraph}
                                    </p>
                                  ))
                              ) : (
                                <p
                                  style={{
                                    fontSize: "1.25rem",
                                    color: "#818589",
                                  }}
                                >
                                  {message.llamatext}
                                </p>
                              )}
                            </>
                          )
                        ) : message.sender === "ZUNO" &&
                          selectedButtons[message.responseID] ===
                            "Langchain" ? (
                          // Code for displaying langchaintext as paragraphs
                          loader && langChainIndex === index ? (
                            <LoaderMessages loader_Messages={loader_Messages} />
                          ) : (
                            <>
                              {typeof message.langchaintext === "string" &&
                              message.langchaintext !== null ? (
                                message.langchaintext
                                  .split("\n")
                                  .map((paragraph, i) => (
                                    <p
                                      key={i}
                                      style={{
                                        fontSize: "1.25rem",
                                        color: "#818589",
                                      }}
                                    >
                                      {paragraph}
                                      {/* "answer from fetch" */}
                                    </p>
                                  ))
                              ) : (
                                <p
                                  style={{
                                    fontSize: "1.25rem",
                                    color: "#818589",
                                  }}
                                >
                                  {message.langchaintext}
                                  {/* "error message" */}
                                </p>
                              )}
                            </>
                          )
                        ) : // Code for displaying default text
                        message.sender === "ZUNO" ? (
                          // Code for displaying langchaintext as paragraphs by default for ZUNO
                          typeof message.langchaintext === "string" &&
                          message.langchaintext !== null ? (
                            message.langchaintext
                              .split("\n")
                              .map((paragraph, i) => (
                                <p
                                  key={i}
                                  style={{
                                    fontSize: "1.25rem",
                                    color: "#818589",
                                  }}
                                >
                                  {paragraph}
                                </p>
                              ))
                          ) : (
                            <p
                              style={{
                                fontSize: "1.25rem",
                                color: "#818589",
                              }}
                            >
                              {message.langchaintext}
                            </p>
                          )
                        ) : // Code for displaying regular text
                        typeof message.text === "string" ? (
                          message.text
                        ) : (
                          //"question"
                          JSON.stringify(message.text)
                        )
                      }
                      sx={{ fontSize: "1.25rem", color: "#818589" }}
                      disableTypography
                    />

                    {message.sender === "ZUNO" && (
                      <ChatButtonGroup
                        index={index}
                        message={message}
                        loader={loader}
                        loader_llama={loader_llama}
                        selectedButtons={selectedButtons}
                        handleSendMessagellamaindex={
                          handleSendMessagellamaindex
                        }
                        handleSendMessagelangchain={handleSendMessagelangchain}
                        selectedLanguageModels={selectedLanguageModels}
                      />
                    )}

                    {message.sender !== "ZUNO" ? (
                      message.bookmarked ? (
                        <Tooltip title="Bookmarked">
                          <IconButton onClick={(e) => toggleBookmark(e, index)}>
                            <BookmarkAddedIcon style={{ color: "#6001FF" }} />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Bookmark">
                          <IconButton onClick={(e) => toggleBookmark(e, index)}>
                            <BookmarkBorderIcon />
                          </IconButton>
                        </Tooltip>
                      )
                    ) : null}
                  </ListItem>
                </div>
              ) : (
                <>
                  {(loader_llama && llamaIndex === index) ||
                  (loader && langChainIndex === index) ? (
                    <div
                      style={{
                        background: "#FFFFFF",
                        borderRadius: "40px",
                        padding: "10px 10px 10px 10px",
                        width: "95%",
                        marginLeft: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <ListItem>
                        <ListItemAvatar>
                          <WaterLoader />
                        </ListItemAvatar>
                        <ListItemText
                          secondary={`${loader_Messages}`}
                          style={{
                            marginLeft: "2%",
                            fontSize: "1.25rem",
                            color: "#818589",
                          }}
                          disableTypography
                        />
                      </ListItem>
                    </div>
                  ) : (
                    <div
                      key={index}
                      className={`message ${message.sender}`}
                      style={{
                        background:
                          message.sender === "ZUNO" ? "#FFFFFF" : "none",
                        borderRadius:
                          message.sender === "ZUNO" ? "40px" : "0px",
                        padding: "10px 10px 10px 10px",
                        width: "95%",
                        marginLeft: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <ListItem key={index}>
                        <ListItemAvatar sx={{ marginRight: "1.3%" }}>
                          {message.sender === "ZUNO" ? (
                            profilePicDocs ? (
                              <Stack
                                direction="row"
                                spacing={2}
                                sx={{ marginRight: "5px" }}
                              >
                                <Avatar
                                  alt="Sanjay"
                                  src={profilePicDocs}
                                  sx={{ width: 56, height: 56 }}
                                />
                              </Stack>
                            ) : (
                              <Botimageicon />
                            )
                          ) : (
                            // <AccountCircleSharpIcon
                            //   style={{
                            //     fontSize: 70,
                            //     color: "#6001ff",
                            //   }}
                            // />
                            <Avatar
                              sx={{ marginRight: 2, width: 56, height: 56 }}
                              style={{ backgroundColor: "#6001FF" }}
                            >
                              <PersonIcon sx={{ width: 40, height: 40 }} />
                            </Avatar>
                          )}
                        </ListItemAvatar>
                        <ListItemText
                          key={index}
                          primary=""
                          secondary={
                            message.sender === "ZUNO" &&
                            selectedButtons[message.responseID] ===
                              "Llamaindex" ? (
                              // Code for displaying llamatext
                              typeof message.llamatext === "object" &&
                              message.llamatext !== null ? (
                                message.llamatext.response
                                  .split("\n")
                                  .map((paragraph, i) => (
                                    <p
                                      key={i}
                                      style={{
                                        fontSize: "1.25rem",
                                        color: "#818589",
                                      }}
                                    >
                                      {paragraph}
                                    </p>
                                  ))
                              ) : (
                                <div>
                                  <p
                                    style={{
                                      fontSize: "1.25rem",
                                      color: "#818589",
                                    }}
                                  >
                                    {tryCount_llama < 3 ? (
                                      <>{message.llamatext}</>
                                    ) : (
                                      <>{systemError}</>
                                    )}
                                    {/* llama text error message */}
                                  </p>
                                  <ChatRetryLlama
                                    timeOut_llma={timeOut_llma}
                                    retryTime_llma={retryTime_llma}
                                    tryCount_llama={tryCount_llama}
                                    index={index}
                                    message={message}
                                    llamaIndex={llamaIndex}
                                    handleRetry_Llama={handleRetry_Llama}
                                  />
                                </div>
                              )
                            ) : message.sender === "ZUNO" &&
                              selectedButtons[message.responseID] ===
                                "Langchain" ? (
                              // Code for displaying langchaintext as paragraphs
                              typeof message.langchaintext === "string" &&
                              message.langchaintext !== null ? (
                                message.langchaintext
                                  .split("\n")
                                  .map((paragraph, i) => (
                                    <div>
                                      <p
                                        key={i}
                                        style={{
                                          fontSize: "1.25rem",
                                          color: "#818589",
                                        }}
                                      >
                                        {tryCount < 3 ? (
                                          <>{paragraph}</>
                                        ) : (
                                          <>{systemError}</>
                                        )}
                                        {/* "last question answer" */}
                                      </p>
                                      <ChatRetryLangChain
                                        timeOutMsg={timeOutMsg}
                                        retryTime={retryTime}
                                        tryCount={tryCount}
                                        handleRetry={handleRetry}
                                        langChainIndex={langChainIndex}
                                        index={index}
                                      />
                                    </div>
                                  ))
                              ) : (
                                <p
                                  style={{
                                    fontSize: "1.25rem",
                                    color: "#818589",
                                  }}
                                >
                                  {message.langchaintext}
                                </p>
                              )
                            ) : // Code for displaying default text
                            message.sender === "ZUNO" ? (
                              // Code for displaying langchaintext as paragraphs by default for ZUNO
                              typeof message.langchaintext === "string" &&
                              message.langchaintext !== null ? (
                                message.langchaintext
                                  .split("\n")
                                  .map((paragraph, i) => (
                                    <p
                                      key={i}
                                      style={{
                                        fontSize: "1.25rem",
                                        color: "#818589",
                                      }}
                                    >
                                      {paragraph}
                                    </p>
                                  ))
                              ) : (
                                <p
                                  style={{
                                    fontSize: "1.25rem",
                                    color: "#818589",
                                  }}
                                >
                                  {message.langchaintext}
                                </p>
                              )
                            ) : // Code for displaying regular text
                            typeof message.text === "string" ? (
                              message.text
                            ) : (
                              //last question
                              JSON.stringify(message.text)
                            )
                          }
                          sx={{
                            fontSize: "1.25rem",
                            color: "#818589",
                          }}
                          disableTypography
                        />

                        {message.sender === "ZUNO" && (
                          <ChatButtonGroup
                            index={index}
                            message={message}
                            loader={loader}
                            loader_llama={loader_llama}
                            selectedButtons={selectedButtons}
                            handleSendMessagellamaindex={
                              handleSendMessagellamaindex
                            }
                            handleSendMessagelangchain={
                              handleSendMessagelangchain
                            }
                            selectedLanguageModels={selectedLanguageModels}
                          />
                        )}

                        {message.sender !== "ZUNO" ? (
                          message.bookmarked ? (
                            <Tooltip title="Bookmarked">
                              <IconButton
                                disabled
                                // onClick={(e) => toggleBookmark(e, index)}
                              >
                                <BookmarkAddedIcon
                                  style={{ color: "#6001FF" }}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Bookmark">
                              <IconButton
                                disabled
                                // onClick={(e) => toggleBookmark(e, index)}
                              >
                                <BookmarkBorderIcon />
                              </IconButton>
                            </Tooltip>
                          )
                        ) : null}
                      </ListItem>
                    </div>
                  )}
                </>
              )
            )}

            {loader && langChainIndex === null ? (
              <div
                style={{
                  background: "#FFFFFF",
                  borderRadius: "40px",
                  padding: "10px 10px 10px 10px",
                  width: "95%",
                  marginLeft: "20px",
                  marginTop: "20px",
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <WaterLoader />
                  </ListItemAvatar>
                  <ListItemText
                    // secondary="working on it ..."
                    secondary={`${loader_Messages}`}
                    style={{
                      marginLeft: "2%",
                      fontSize: "1.25rem",
                      color: "#818589",
                    }}
                    disableTypography
                  />
                </ListItem>
              </div>
            ) : (
              <></>
            )}
          </>
        </Box>
        <PdfChatInputBase
          newMessage={newMessage}
          handleInputChange={handleInputChange}
          handleKeyDown={handleKeyDown}
          handleSendButton={handleSendButton}
          loader={loader}
          loader_llama={loader_llama}
        />
      </>
    );
  }
}

export default ChatMap;
