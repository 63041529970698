import React, { Component } from "react";
import {
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Typography,
  Card,
} from "@mui/material";
import {
  ListItemIcon,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Box,
  InputBase,
  TablePagination,
  Checkbox,
  ListSubheader,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
//import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from "@mui/material/Backdrop";
import Chip from "@mui/material/Chip";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import CircleIcon from "@mui/icons-material/Circle";

import MYSQLLogo from "../assets/icons/mysql.webp";
import PostLogo from "../assets/icons/postgresql.webp";
import Oracle from "../assets/icons/orcale.webp";
import SBucket from "../assets/icons/bucket.webp";
import Snowflake from "../assets/icons/snow.webp";
import MongoDB from "../assets/icons/m.webp";
import Spark from "../assets/icons/spark.webp";
import Hive from "../assets/icons/hive.webp";
import CSV from "../assets/icons/csv.webp";
import XLSX from "../assets/icons/xlsx.webp";
import TSV from "../assets/icons/tsv.webp";

import Modal from "@mui/material/Modal";
import FileUpload from "./fileUpload";
import FileUploadQA from "./fileUploadQA";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "./withRouter";
import Notification from "./Notification";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress for the loader
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Container } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import DialogTitle from "@mui/material/DialogTitle";
import DocumentQA from "./DocumentQA";
import Utils from "../userComponents/Utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const EMBEDDING_SERVICE_URL = process.env.REACT_APP_EMBEDDING_SERVICE_URL;
// const application_code = "ZN002";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const APPLICATIONS = ["Zuno for Docs", "Employee Engagement"];
const APPLICATION_MAPPING = {
  "Zuno for Docs": "ZN002",
  "Employee Engagement": "ZN003",
};
const REVERSE_APPLICATION_MAPPING = {
  ZN002: "Zuno for Docs",
  ZN003: "Employee Engagement",
};

const application_mapper = (application) => {
  return APPLICATION_MAPPING[application];
};
const icon = (
  <span
    style={{
      display: "inline-flex",
      color: "#6001FF",
    }}
  >
    <CheckBoxOutlineBlankIcon fontSize="small" />
  </span>
);

const checkedIcon = (
  <span
    style={{
      display: "inline-flex",
      color: "#6001FF",
    }}
  >
    <CheckBoxIcon fontSize="small" />
  </span>
);
const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};
const selectFieldSx = {
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
  },
  "& .MuiMenu-paper": {
    maxHeight: "200px", // Set the maximum height for the dropdown
    maxWidth: "100px",
  },
};

class DataTabs extends Component {
  constructor(props) {
    super(props);
    // this.handleCloseFileUpload=this.handleCloseFileUpload.bind(this)
    this.state = {
      anchorEl: null,
      selectedIndex: -1,
      databases: [],
      table_count: [],
      searchQuery: "", // Initialize the searchQuery state
      csvSearchQuery: "",
      documentSeachQuery: "",
      activeDatabaseIndex: -1, // Initialize with -1 (no active connection)
      selectedTables: [], // Initialize as an empty array
      selectAllTables: false,
      selectAllCSV: false,
      loading: false,
      rowdata: [],
      file: [],
      value: 0,
      selectedItemIndex: null,
      showContinueButton: false, // Add this state variable
      table_data: [],
      currentPageFiles: 1,
      itemsPerPageFiles: 10,
      currentPageDatabases: 1,
      itemsPerPageDatabases: 10,
      currentPageTables: 1,
      itemsPerPageTables: 10,
      selectedDatabase: null, // This will hold the selected database information
      isContinueButtonVisible: false, // Initialize as false
      showLoader: false,
      selectedRows: [],
      connecting: false,
      showBackdrop: false,
      databaseInfo: null, // Initialize the state variable to null
      selectedFiles: [], // Initialize selectedFiles as an empty array for csv
      selectedDocumentFiles: [], // Initialize selectedFiles as an empty array for documents
      selectAllDocuments: false,
      isEditMode: false,
      accessUsers: false,
      isOpenFileUpload: false,
      isEditDatabase: false,
      isEditFile: false,
      isEditDocument: false,
      documentFiles: [],
      page: 0,
      rowsPerPage: 10,
      isDocumentupload: false,
      currentDocument: null,
      currentCsvfile: null,
      currentLoggedInUserRole:
        JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))[
          "role"
        ] || "",
      currentLoggedInUserPermissions:
        JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))[
          "userPermissions"
        ] || {},
      isLoading: false, // Add isLoading state to track data loading
      folderName: "",
      folders: [],
      showModal: false,
      users: ["Loading..."],
      selectedUserNames: [],
      selectedUserEmails: [],
      selectedApplications: [],
      folderInfo: null,
      // selectedFolders: new Set(),
      selectedFolders: [],
      selectedAllFolders: false,
      selectedFoldersArray: [],
      showFiles: false,
      folderIndex: 0,
      responseOk: false,
      snackbarOpen: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
    };
  }
  async componentDidMount() {
    await this.fetchData(); // Fetch and set the initial data
    await this.fetchUploadedFiles(); // Fetch uploaded files
    // await this.fetchDocumentFiles(); // Fetch Upload documents
    await this.fetchDocumentFolders();
    // await this.getUserDetails();
    this.setState({ showContinueButton: true }); // Show the button after fetching data
  }
  fetchData = async () => {
    this.setState({ isLoading: true });
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");

    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    try {
      const response = await fetch(
        `${BASE_URL}/admin/zn001/admin_fetch_databases/${email}?userOrganization=${encodeURIComponent(
          userOrganization
        )}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      const responseData = await response.json();

      if (Array.isArray(responseData.databases)) {
        responseData.databases.sort((a, b) => {
          if (a.isActive && !b.isActive) {
            return -1;
          } else if (!a.isActive && b.isActive) {
            return 1;
          } else {
            return 0;
          }
        });
        const databasesWithLoading = responseData.databases.map((database) => ({
          ...database,
          loading: false,
          connecting: false,
        }));
        this.setState({
          databases: databasesWithLoading,
          isLoading: false, // Set isLoading to false once data is fetched
        });
      } else {
        console.error(
          "Data is not an array in the server response:",
          responseData
        );
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.error("Error fetching data:", error);
    }
  };

  handleDeleteUserData = async (id) => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }
    const email = localStorage.getItem("email");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    try {
      const response = await fetch(
        `${BASE_URL}/admin/zn001/admindatabases/${id}?deletedByUserEmail=${encodeURIComponent(
          email
        )}&userOrganization=${encodeURIComponent(userOrganization)}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        console.log("User data deleted successfully");
        // Fetch updated data after deletion
        this.fetchData();
      } else {
        console.error("Failed to delete user data");
      }
    } catch (error) {
      console.error("Error deleting user data:", error);
    } finally {
      this.handleClose();
    }
  };
  fetchUploadedFiles = async () => {
    this.setState({ isLoading: true });
    const token = localStorage.getItem("token");

    const email = localStorage.getItem("email");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const response = await fetch(
        `${BASE_URL}/admin/zn001/admin_user_files/${email}?userOrganization=${encodeURIComponent(
          userOrganization
        )}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const files = await response.json();
        this.setState({ file: files.user_files, isLoading: false }); // Update the 'file' state with the fetched files
      } else {
        console.error("Failed to fetch uploaded files");
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.error("Error fetching uploaded files:", error);
    }
  };
  handleEditFile = async (file) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }
    this.setState({
      isEditFile: true,
      currentCsvfile: file,
    });
    this.handleClose();
  };
  handleDeleteFile = async (fileIdsToDelete) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    const email = localStorage.getItem("email");
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    const formData = new FormData();

    formData.append("deleted_by_user_email", email);
    formData.append("file_ids", fileIdsToDelete);
    formData.append("userOrganization", userOrganization);

    try {
      const response = await fetch(`${BASE_URL}/admin/zn001/admin_files`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData, // Include file IDs in the request body if needed
      });

      if (response.ok) {
        // Fetch updated file list after deletion
        this.fetchUploadedFiles();

        this.setState({
          selected_files_by_id: [],
          selectedFiles: [],
          selectAllCSV: false,
        });
      } else {
        console.error("Failed to delete file");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  handleClick = (event, index, accessingAppData) => {
    const { currentLoggedInUserPermissions } = this.state;
    if (
      currentLoggedInUserPermissions["Add or Delete Databases"] &&
      accessingAppData === "database"
    ) {
      this.setState({
        anchorEl: event.currentTarget,
        selectedIndex: index,
      });
    } else if (
      currentLoggedInUserPermissions["Add or Delete Files"] &&
      accessingAppData === "csv"
    ) {
      this.setState({
        anchorEl: event.currentTarget,
        selectedIndex: index,
      });
    } else if (
      currentLoggedInUserPermissions["Add or Delete Files"] &&
      accessingAppData === "folder"
    ) {
      this.setState({
        anchorEl: event.currentTarget,
        selectedIndex: index,
      });
    } else {
      toast.error(`You cannot have access to edit ${accessingAppData}`);
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue }, () => {
      if (newValue === 2) {
        // You can replace this with the actual logic to navigate to the "Files" tab
      }
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      selectedIndex: -1,
    });
  };

  // function used to handle add and edit database button
  handleAddDatabase = () => {
    localStorage.removeItem("databaseInfo");
    this.setState({ isOpenFileUpload: true });
  };

  handleAddCsvFile = () => {
    this.setState({ isOpenFileUpload: true });
  };

  // function used to close the  file upload pop up dialog modal tab
  handleCloseFileUpload = () => {
    this.setState({
      isOpenFileUpload: false,
      isEditDatabase: false,
      isEditFile: false,
      currentCsvfile: null,
    });
    this.handleClose();
  };

  handleConnect = async (database) => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    const { Databasename, hostName, password, userName, dbType, id } = database;

    try {
      // Set connecting to true when connecting
      this.setState((prevState) => ({
        databases: prevState.databases.map((db) =>
          db === database ? { ...db, connecting: true } : db
        ),
        showBackdrop: true,
      }));

      const formData = new FormData();

      formData.append("userName", userName);
      formData.append("password", password);
      formData.append("hostName", hostName);
      formData.append("databasename", Databasename);
      let endpoint;
      if (dbType === "PostgreSQL") {
        endpoint = `${BASE_URL}/zn001/user/select_tables_postgres`;
      } else if (dbType === "MYSQL") {
        endpoint = `${BASE_URL}/zn001/user/select_tables`;
      } else if (dbType === "Oracle") {
        endpoint = `${BASE_URL}/zn001/user/select_tables_oracle`;
      } else {
        console.error("Unsupported database type");
        return;
      }
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        // Store the selected database information in the state
        this.setState({
          selectedDatabase: {
            userName,
            password,
            hostName,
            Databasename,
            database,
            id,
          },
          rowdata: data.message,
          value: 1,
          connectionSuccessful: true,
        });
      } else {
        const errorData = await response.json();
        this.setState({
          message: errorData.detail,
          connectionSuccessful: false,
        });
        console.error("DB connect error:", errorData);
      }
    } catch (error) {
      this.setState({
        message: "Network error occurred",
        connectionSuccessful: false,
      });
      console.error("Error:", error);
    } finally {
      // Set connecting to false after connection attempt
      this.setState((prevState) => ({
        databases: prevState.databases.map((db) =>
          db === database ? { ...db, connecting: false } : db
        ),
        showBackdrop: false,
      }));
    }
  };

  handleEditDatabase = async (databaseId) => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      const response = await fetch(
        `${BASE_URL}/admin/zn001/admindatabases/${databaseId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      localStorage.setItem("databaseInfo", JSON.stringify(data));

      this.setState(
        {
          databaseInfo: data,
          isEditMode: true, // Set isEditMode to true here
        },
        () => {
          // this.props.handleUploadClick({
          //   showTab: false,
          //   showUpload: true,
          //   showChat: false,
          //   ShowBook: false,
          // });
          this.setState({ isEditDatabase: true });
          this.handleClose();
        }
      );
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  handleCheckboxChange = (event, row) => {
    // const { selectedRows } = this.state;
    const { rowdata } = this.state;
    const { checked } = event.target;

    if (checked) {
      this.setState((prevState) => ({
        selectedRows: [...prevState.selectedRows, row],
        isContinueButtonVisible: true, // Show the "Continue" button
      }));
      if (this.state.selectedRows.length === rowdata.length - 1) {
        this.setState({ selectAllTables: true });
      }
      if (this.state.selectedRows.length === 1) {
        this.setState({ selectAllTables: false });
      }
    } else {
      this.setState((prevState) => {
        const updatedSelectedRows = prevState.selectedRows.filter(
          (selectedRow) => selectedRow.id !== row.id
        );
        const isAnySelected = updatedSelectedRows.length > 0; // Check if any checkboxes are selected
        return {
          selectedRows: updatedSelectedRows,
          isContinueButtonVisible: isAnySelected, // Update the isContinueButtonVisible state
        };
      });
      if (this.state.selectedRows.length === rowdata.length - 1) {
        this.setState({ selectAllTables: true });
      }
      if (this.state.selectedRows.length === 1) {
        this.setState({ selectAllTables: false });
      }
    }
  };

  handleSelectAllCSVClick = () => {
    this.setState((prevState) => {
      const { selectAllCSV, file } = prevState;
      const updatedSelectedFiles = selectAllCSV ? [] : [...file];
      return {
        selectedFiles: updatedSelectedFiles,
        selectAllCSV: !selectAllCSV,
        isContinueButtonVisible: updatedSelectedFiles.length > 0,
      };
    });
  };

  handleCheckboxChange_csv = (id, files) => {
    const { selectedFiles, file } = this.state;
    if (selectedFiles.some((selectedFile) => selectedFile.id === id)) {
      this.setState(
        (prevState) => ({
          selectedFiles: prevState.selectedFiles.filter(
            (selectedFile) => selectedFile.id !== id
          ),
        }),
        () => {
          if (this.state.selectedFiles.length === file.length) {
            this.setState({ selectAllCSV: true });
          }

          if (this.state.selectedFiles.length === file.length-1) {
            this.setState({ selectAllCSV: false });
          }
        }
      );
    } else {
      // File is not selected, add it to the selected files
      this.setState(
        (prevState) => ({
          selectedFiles: [
            ...prevState.selectedFiles,
            { id: id, filename: files.filename },
          ],
        }),
        () => {
          if (this.state.selectedFiles.length === file.length) {
            this.setState({ selectAllCSV: true });
          }
          if (this.state.selectedFiles.length === file.length-1) {
            this.setState({ selectAllCSV: false });
          }
        }
      );
    }
  };

  handleDBConnect = async () => {
    const { selectedDatabase } = this.state;
    const token = localStorage.getItem("token");
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }
    this.setState({ showLoader: true });
    try {
      const formData = new FormData();

      // Use the selected database information from state
      formData.append("userName", selectedDatabase.userName);
      formData.append("password", selectedDatabase.password);
      formData.append("hostName", selectedDatabase.hostName);
      formData.append("databasename", selectedDatabase.Databasename);
      formData.append("userOrganization", userOrganization);
      const tablenames = this.state.selectedRows.map(
        (entry) => entry.tablename
      );
      formData.append("selectedTables", tablenames.join(","));

      let endpoint;
      if (selectedDatabase.dbType === "PostgreSQL") {
        endpoint = `${BASE_URL}/zn001/user/db_register_postgres`;
      } else if (selectedDatabase.dbType === "MYSQL") {
        endpoint = `${BASE_URL}/zn001/user/db_register`;
      } else if (selectedDatabase.dbType === "Oracle") {
        endpoint = `${BASE_URL}/zn001/user/db_register_oracle`;
      } else {
        console.error("Unsupported database type");
        return;
      }
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const result = await response.json();

      if (response.status === 401) {
        this.props.navigate("/");
      }

      if (response.ok) {
        this.handleChatClickInDataTable();
      } else if (response.status === 403) {
        this.props.navigate("/");
      } else {
        const errorData = result;
        this.setState({
          message: errorData.detail,
        });

        console.error("DB connect error:", errorData);
      }
    } catch (error) {
      this.setState({
        message: "Network error occurred",
      });
      console.error("Error:", error);
    } finally {
      // Hide the loader when the update is complete (whether it succeeds or fails)
      setTimeout(() => {
        this.setState({ showLoader: false });
      }, 6000);
    }
  };

  handleSelectAllClick = () => {
    const { selectAllTables } = this.state;
    const { rowdata } = this.state;

    if (selectAllTables) {
      // If all checkboxes are currently selected, unselect all
      this.setState({
        selectAllTables: false,
        selectedRows: [],
        isContinueButtonVisible: false, // No checkboxes are selected
      });
    } else {
      // If not all checkboxes are selected, select all
      this.setState({
        selectAllTables: true,
        selectedRows: rowdata,
        isContinueButtonVisible: true, // At least one checkbox is selected
      });
    }
  };

  truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  handleDeleteDocumentFolder = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];

    const formData = new FormData();
    const { selectedFolders, folders } = this.state;
    // const selectedFoldersArray = Array.from(selectedFolders);
    const selectedFoldersArray = selectedFolders.map(
      (folder) => folder.folder_name
    );
    const selectedFoldersIds = selectedFolders.map((folder) => folder.id);
    let foldersToDelete = folders
      .filter(
        (folder) =>
          selectedFoldersArray.includes(folder.folder_name) &&
          selectedFoldersIds.includes(folder.id)
      )
      .map((folder) => folder.folder_name);
    formData.append("folder_list", JSON.stringify(foldersToDelete));
    formData.append("organization", userOrganization);

    try {
      const response = await fetch(
        `${BASE_URL}/admin/zn002/deleteadminfolders`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        // Folder was successfully deleted, you can update your state or perform other actions here
        // For example, you can refresh the list of folders to reflect the changes
        this.fetchDocumentFolders();
        this.setState({
          selectedFolders: [],
          selectedFoldersArray: [],
          selectedAllFolders: false,
        });
      } else {
        const errorResponse = await response.json();
        this.handleSnackbarOpen("error", errorResponse.detail);
        console.error("Failed to delete folders");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleDocumentSelectAllClick = () => {
    const { selectAllDocuments, documentFiles } = this.state;
    let selectedDocumentFiles, selected_files_by_id;

    if (selectAllDocuments) {
      // If "Select All" is already checked, unselect all files
      selectedDocumentFiles = [];
      selected_files_by_id = [];
    } else {
      // If "Select All" is not checked, select all files by fileId
      const availableFiles = documentFiles.filter(
        (file) => file.embedding_status === "Available"
      );
      selectedDocumentFiles = availableFiles.map((file) => file.filename);
      selected_files_by_id = availableFiles.map((file) => file.id);
    }

    this.setState({
      selectAllDocuments: !selectAllDocuments,
      selectedDocumentFiles,
      selected_files_by_id,
    });
  };
  handleDocumentCheckboxChange = (filename) => {
    const { selectedDocumentFiles } = this.state;

    if (selectedDocumentFiles.includes(filename)) {
      // File is already selected, remove it from the selected files
      this.setState((prevState) => ({
        selectedDocumentFiles: prevState.selectedDocumentFiles.filter(
          (name) => name !== filename
        ),
      }));
    } else {
      // File is not selected, add it to the selected files
      this.setState((prevState) => ({
        selectedDocumentFiles: [...prevState.selectedDocumentFiles, filename],
      }));
    }
  };
  handleAddDocument = () => {
    this.setState({
      isDocumentupload: true,
    });
  };
  handleCloseDocument = () => {
    this.setState({
      isDocumentupload: false,
      isEditDocument: false,
      currentDocument: null,
    });
    this.handleClose();
  };
  handleEditDocument = (item) => {
    this.setState(
      {
        currentDocument: item,
        isEditDocument: true,
      },
      () => {
        this.getUserDetails();
        this.getApplicationDetails();
      }
    );
    this.handleClose();
  };
  reset = () => {
    window.location.reload();
  };

  getUserDetails = async () => {
    try {
      const userDetails = this.props.userDetails;
      const access_user_list = this.state.currentDocument
        ? this.state.currentDocument.userAccessList
        : [];
      const users = userDetails.map((item) => {
        const username = item.username;
        const email = item.email;
        const accessible = access_user_list.includes(email);
        return { username: username, email: email, accessible: accessible };
      });
      const initiallyAccessibleUsers = users.filter((user) => user.accessible);

      this.setState({
        users: users,
        selectedUserNames: initiallyAccessibleUsers.map(
          (user) => user.username
        ),
        selectedUserEmails: initiallyAccessibleUsers.map((user) => user.email),
      });

      return users;
    } catch (error) {
      console.log(error);
    }
  };

  getApplicationDetails = () => {
    try {
      const applicationCodes = this.state.currentDocument
        ? this.state.currentDocument.application_code
        : [];
      const initialApplicationNames = applicationCodes.map(
        (code) => REVERSE_APPLICATION_MAPPING[code]
      );
      this.setState({
        selectedApplications: initialApplicationNames,
      });
    } catch (error) {
      console.log(error);
    }
  };
  handleCheckBoxChange = (username) => {
    const { selectedUserNames, users } = this.state;
    const updatedUsers = [...users]; // Create a copy of the users array

    // Find the user object in the updatedUsers array by username
    const user = updatedUsers.find((user) => user.username === username);

    if (user) {
      // Toggle the 'accessible' property of the user based on whether it was selected or deselected
      user.accessible = !selectedUserNames.includes(username);
    }
    const index = selectedUserNames.indexOf(username);

    if (index !== -1) {
      // Remove the user from the selected Users list
      selectedUserNames.splice(index, 1);
    } else {
      // Add the user to the selected Users list
      selectedUserNames.push(username);
    }
    // Update the selectedUserNames in the state
    this.setState({ selectedUserNames });

    // Update the users in the state
    this.setState({ users: updatedUsers });
  };

  handleApplicationCheckBoxChange = (applicationName) => {
    const { selectedApplications } = this.state;
    // const selectedApplicationsArray=Array.from(selectedApplications);
    const updatedApplications = [...selectedApplications]; // Create a copy of the selectedApplications array

    const index = updatedApplications.indexOf(applicationName);

    if (index !== -1) {
      // If the application is already selected, remove it
      updatedApplications.splice(index, 1);
    } else {
      // If the application is not selected, add it
      updatedApplications.push(applicationName);
    }

    // Update the selectedApplications in the state
    this.setState({ selectedApplications: updatedApplications });
  };
  getSelectedUsers = () => {
    const selectedUserEmails = this.state.users
      .filter((user) => this.state.selectedUserNames.includes(user.username))
      .map((user) => user.email);
    // this.setState({selectedUserEmails:selectedUserEmails})
    return selectedUserEmails;
  };
  handleSnackbarOpen = (severity, message) => {
    this.setState({
      snackbarOpen: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };
  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };
  async createFolder() {
    const { folderName, selectedApplications } = this.state;
    const foldername = folderName.trim();

    if (foldername.length === 0) {
      this.handleSnackbarOpen(
        "error",
        "folder name should contain some characters"
      );
      return;
    }
    if (foldername.length >= 20) {
      this.handleSnackbarOpen(
        "error",
        "folder name should be less than 20 characters"
      );
      return;
    } else if (this.hasSpecialFirstCharacter(foldername)) {
      this.handleSnackbarOpen(
        "error",
        `${foldername} name should start with alphabetic character`
      );
      return;
    } else if (this.hasSpecialCharacters(foldername)) {
      this.handleSnackbarOpen(
        "error",
        `${foldername} name contains special character`
      );
      return;
    }
    this.setState({ showModal: false });
    // const email = localStorage.getItem("email");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    const token = localStorage.getItem("token");
    const selectedUserEmails = this.getSelectedUsers();
    const selectedApplicationCodes = selectedApplications.map((app) =>
      application_mapper(app)
    );
    if (foldername) {
      const formData = new FormData();
      formData.append("folder_name", foldername);
      formData.append("organization", userOrganization);
      formData.append(
        "application_codes",
        JSON.stringify(selectedApplicationCodes)
      );
      formData.append("selectedUserEmails", JSON.stringify(selectedUserEmails));

      try {
        const response = await fetch(
          `${EMBEDDING_SERVICE_URL}/common/folderapi/admin_create_folder`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        if (response.status === 401) {
          Utils.removeLocalStorage();
          this.props.navigate("/");
        }
        this.setState({ responseOk: response.ok });
        if (!response.ok) {
          const folderExist = await response.json();
          if (folderExist.detail) {
            console.log(this.handleSnackbarOpen("error", folderExist.detail));
            this.setState({ folderName: "" });
          }
          throw new Error("Network response was not ok");
        } else {
          this.setState({ folderName: "" });
          this.fetchDocumentFolders();
        }

        // If you need the response data, you can access it here
      } catch (error) {
        console.error("Error:", error);
      }
    }
  }
  fetchDocumentFolders = async () => {
    this.setState({ isLoading: true });
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    // const email = localStorage.getItem("email");
    try {
      const response = await fetch(
        `${EMBEDDING_SERVICE_URL}/common/folderapi/get_admin_folders/${email}?userOrganization=${encodeURIComponent(
          userOrganization
        )}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const responseData = await response.json(); // Parse the response as JSON
        if (responseData) {
          console.log(responseData.folders);
          this.setState({
            folders: responseData.folders,
            isLoading: false,
          });
        } else {
          console.error(
            "Data is not an array in the server response:",
            responseData
          );
        }
      } else {
        console.error("Server response was not OK. Status:", response.status);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.error("Error fetching data:", error);
    }
  };
  toggleSelectAll = () => {
    this.setState((prevState) => {
      const { selectedAllFolders, folders } = prevState;
      const updatedSelectedFolders = selectedAllFolders ? [] : [...folders];
      return {
        selectedFolders: updatedSelectedFolders,
        selectedAllFolders: !selectedAllFolders,
      };
    });
  };

  toggleFolderSelection = (id, item) => {
    const { selectedFolders, folders } = this.state;
    if (selectedFolders.some((selectedFolder) => selectedFolder.id === id)) {
      // File is already selected,  it from the selected files
      this.setState(
        (prevState) => ({
          selectedFolders: prevState.selectedFolders.filter(
            (selectedFolder) => selectedFolder.id !== id
          ),
        }),
        () => {
          if (this.state.selectedFolders.length === folders.length) {
            this.setState({ selectedAllFolders: true });
          }

          if (this.state.selectedFolders.length ===folders.length-1) {
            this.setState({ selectedAllFolders: false });
          }
        }
      );
    } else {
      // File is not selected, add it to the selected files
      this.setState(
        (prevState) => ({
          selectedFolders: [
            ...prevState.selectedFolders,
            { id: id, folder_name: item.folder_name },
          ],
        }),
        () => {
          if (this.state.selectedFolders.length === folders.length) {
            this.setState({ selectedAllFolders: true });
          }
          if (this.state.selectedFolders.length === folders.length-1) {
            this.setState({ selectedAllFolders: false });
          }
        }
      );
    }
  };
  handleUpdateFolder = async (folderId) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    const selectedApplicationCodes = this.state.selectedApplications.map(
      (app) => application_mapper(app)
    );
    const updatedUserAccessList = this.getSelectedUsers();
    const formData = new FormData();
    formData.append("folder_id", folderId);
    formData.append(
      "updatedUserAccessList",
      JSON.stringify(updatedUserAccessList)
    );
    formData.append("updatedByUserEmail", email);
    formData.append(
      "updatedApplicationCodes",
      JSON.stringify(selectedApplicationCodes)
    );
    formData.append("userOrganization", userOrganization);

    try {
      const response = await fetch(
        `${BASE_URL}/admin/zn002/update_admin_folder`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        console.log("Updated the folder details successfully");
      } else {
        console.log("Failed to update the user details");
      }
    } catch (error) {
      console.log(error);
    } finally {
      // this.setState(()=>{window.location.reload()})
      this.handleCloseDocument();
      this.reset();
      // this.fetchDocumentFolders();
    }
  };
  openFiles(index) {
    this.setState({ showFiles: true, folderIndex: index });
  }
  backFolder = (value) => {
    this.setState({ showFiles: value });
  };
  hasSpecialCharacters = (folderName) => {
    // Define a regular expression to match special characters
    const regex = /[^a-zA-Z0-9_.-\s]+/;
    // returns boolean value from test()
    return regex.test(folderName);
  };
  hasSpecialFirstCharacter = (folderName) => {
    // Check if the first character is a special character
    const firstCharSpecial = /^[^a-zA-Z\s]/.test(folderName.charAt(0));
    return firstCharSpecial;
  };
  render() {
    const {
      anchorEl,
      selectedFiles,
      selectedIndex,
      // selectedRows,
      searchQuery,
      csvSearchQuery,
      documentSeachQuery,
      showBackdrop,
      currentPageDatabases,
      itemsPerPageDatabases,
      value,
      selectedDatabase,
      databases,
      rowdata,
      file,
      currentPageFiles,
      currentPageTables,
      itemsPerPageFiles,
      itemsPerPageTables,
      showModal,
      folderName,
      users,
      folders,
      selectedFolders,
      showFiles,
      folderIndex,
      page,
      rowsPerPage,
      currentDocument,
      snackbarOpen,
      snackbarSeverity,
      snackbarMessage,
      selectedAllFolders,
      selectAllCSV,
      // selectAllTables,
    } = this.state;

    const { currentLoggedInUserRole, currentLoggedInUserPermissions } =
      this.state;

    const filteredDatabases = databases.filter((database) =>
      database.Databasename.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const startIndexDatabases =
      (currentPageDatabases - 1) * itemsPerPageDatabases;
    const endIndexDatabases = startIndexDatabases + itemsPerPageDatabases;

    const startIndexTables = (currentPageTables - 1) * itemsPerPageTables;
    const endIndexTables = startIndexTables + itemsPerPageTables;

    const startIndexFiles = (currentPageFiles - 1) * itemsPerPageFiles;
    const endIndexFiles = startIndexFiles + itemsPerPageFiles;
    const dbTypeToDatabases = {};

    filteredDatabases.forEach((database) => {
      const { dbType } = database;
      if (!dbTypeToDatabases[dbType]) {
        dbTypeToDatabases[dbType] = [];
      }
      dbTypeToDatabases[dbType].push(database);
    });

    // Extract unique dbTypes
    const uniqueDbTypes = Object.keys(dbTypeToDatabases);
    return (
      <div>
        <Card
          elevation={5}
          style={{
            width: "100%",
            borderRadius: "20px",
            backgroundColor: "#FFFFFF",
          }}
        >
          {/* Conditional rendering for loader */}
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {this.state.isLoading && (
              <CircularProgress style={{ color: "#335" }} />
            )}{" "}
          </CardContent>

          {/* Display stats if data is available */}
          {!this.state.isLoading && (
            <Box>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={this.handleChange}
                  aria-label="basic tabs example"
                  sx={{
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#6001FF",
                    },
                  }}
                >
                  <Tab
                    label="Databases"
                    {...a11yProps(0)}
                    sx={{
                      color: value === 0 ? "#6001FF" : "inherit",
                      "&.Mui-selected": {
                        color: "#6001FF",
                      },
                    }}
                  />
                  <Tab
                    label="tables"
                    {...a11yProps(1)}
                    sx={{
                      color: value === 1 ? "#6001FF" : "inherit",
                      "&.Mui-selected": {
                        color: "#6001FF",
                      },
                    }}
                  />
                  <Tab
                    label="Files"
                    {...a11yProps(2)}
                    sx={{
                      color: value === 2 ? "#6001FF" : "inherit",
                      "&.Mui-selected": {
                        color: "#6001FF",
                      },
                    }}
                  />
                  <Tab
                    label="Documents"
                    {...a11yProps(3)}
                    sx={{
                      color: value === 3 ? "#6001FF" : "inherit",
                      "&.Mui-selected": {
                        color: "#6001FF",
                      },
                    }}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                {value === 0 && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "0px 0px 20px 0px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#F3F6FD",
                          borderRadius: "25px",
                          padding: "5px",
                          marginRight: "3%",
                        }}
                      >
                        <SearchIcon
                          sx={{
                            color: "#6001FF",
                            marginLeft: "10px",
                          }}
                        />
                        <InputBase
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={(e) =>
                            this.setState({ searchQuery: e.target.value })
                          }
                          sx={{
                            color: "#6001FF",
                            width: "40%",
                            backgroundColor: "#F3F6FD",
                            borderRadius: "25px",
                          }}
                        />
                      </Box>
                      <ListItemIcon sx={{ color: "#000", pt: 1 }}>
                        <RestartAltOutlinedIcon
                          sx={{
                            color: "#6001FF",
                            width: "45px",
                            height: "35px",
                            "&:hover": {
                              backgroundColor: "#EFEFEF",
                              borderRadius: "50%", // Making background circular
                            },
                          }}
                          onClick={this.reset}
                        />
                      </ListItemIcon>
                      {(currentLoggedInUserPermissions[
                        "Add or Delete Databases"
                      ] ||
                        currentLoggedInUserRole === "superadmin") && (
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<AddIcon />}
                          style={{
                            backgroundColor: "#6001FF",
                            color: "#fff",
                            marginLeft: "20px",
                          }}
                          onClick={this.handleAddDatabase}
                        >
                          Add Database
                        </Button>
                      )}
                      <Modal
                        open={this.state.isOpenFileUpload}
                        onClose={this.handleCloseFileUpload}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <FileUpload
                          addType="MYSQL"
                          handleCloseFileUpload={this.handleCloseFileUpload}
                          userDetails={this.props.userDetails}
                          fetchDBData={this.fetchData}
                          isEditDatabase={this.state.isEditDatabase}
                        />
                      </Modal>
                    </div>
                    <Paper
                      elevation={0}
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        backgroundColor: "#FFF",
                      }}
                    >
                      <div>
                        <List component="nav" aria-label="database list">
                          <ListItem>
                            <ListItemText
                              style={{ width: "3%" }}
                            ></ListItemText>
                            <ListItemText style={{ width: "15%" }}>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "1.1rem",
                                  fontFamily: "Poppins, sans-serif",
                                  fontWeight: 400,
                                }}
                              >
                                Database Name
                              </Typography>
                            </ListItemText>
                            <ListItemText style={{ width: "15%" }}>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "1.1rem",
                                  fontFamily: "Poppins, sans-serif",
                                  fontWeight: 400,
                                }}
                              >
                                Last Connected
                              </Typography>
                            </ListItemText>
                            <ListItemText style={{ width: "11%" }}>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "1.1rem",
                                  fontFamily: "Poppins, sans-serif",
                                  fontWeight: 400,
                                }}
                              >
                                Tables
                              </Typography>
                            </ListItemText>
                            <ListItemText style={{ width: "15%" }}>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "1.1rem",
                                  fontFamily: "Poppins, sans-serif",
                                  fontWeight: 400,
                                }}
                              >
                                Users
                              </Typography>
                            </ListItemText>
                          </ListItem>
                          <Divider />
                          {databases
                            .filter((database) =>
                              database.Databasename.toLowerCase().includes(
                                searchQuery.trim().toLowerCase()
                              )
                            )
                            .slice(startIndexDatabases, endIndexDatabases)
                            .map((database, index) => (
                              <React.Fragment key={index}>
                                <ListItemButton
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                  disableRipple
                                  disableTouchRipple
                                  disableGutters
                                >
                                  <ListItemIcon
                                    style={{ width: "11%", marginLeft: "1%" }}
                                  >
                                    {/* Display the logo based on the databasetype */}
                                    {database.dbType === "MYSQL" && (
                                      <img
                                        src={MYSQLLogo}
                                        alt="MSQL"
                                        style={{ width: "40%", height: "30%" }}
                                      />
                                    )}
                                    {database.dbType === "PostgreSQL" && (
                                      <img
                                        src={PostLogo}
                                        alt="PostgreSQL"
                                        style={{ width: "40%", height: "40%" }}
                                      />
                                    )}
                                    {database.dbType === "Oracle" && (
                                      <img
                                        src={Oracle}
                                        alt="Oracle"
                                        style={{ width: "40%", height: "70%" }}
                                      />
                                    )}
                                    {database.dbType === "S3 Bucket" && (
                                      <img
                                        src={SBucket}
                                        alt="S3 Bucket"
                                        style={{ width: "40%", height: "70%" }}
                                      />
                                    )}
                                    {database.dbType === "Snowflake" && (
                                      <img
                                        src={Snowflake}
                                        alt="Snowflake"
                                        style={{ width: "40%", height: "70%" }}
                                      />
                                    )}
                                    {database.dbType === "Mongo DB" && (
                                      <img
                                        src={MongoDB}
                                        alt="Mongo DB"
                                        style={{ width: "40%", height: "70%" }}
                                      />
                                    )}
                                    {database.dbType === "Spark" && (
                                      <img
                                        src={Spark}
                                        alt="Spark"
                                        style={{ width: "40%", height: "70%" }}
                                      />
                                    )}
                                    {database.dbType === "Hive" && (
                                      <img
                                        src={Hive}
                                        alt="Hive"
                                        style={{ width: "40%", height: "70%" }}
                                      />
                                    )}
                                    {/* Add more conditions for other database types */}
                                  </ListItemIcon>
                                  <ListItemText style={{ width: "15%" }}>
                                    <Tooltip title={database.Databasename}>
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        sx={{
                                          fontSize: "1rem",
                                          fontFamily: "Poppins, sans-serif",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {database.Databasename.length > 15
                                          ? `${database.Databasename.substring(
                                              0,
                                              15
                                            )}...`
                                          : database.Databasename}

                                        {database.isActive && (
                                          <span style={{ marginLeft: "5px" }}>
                                            <CircleIcon
                                              style={{
                                                color: "#228B22",
                                                fontSize: "1rem",
                                                verticalAlign: "middle",
                                              }}
                                            />
                                          </span>
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  </ListItemText>
                                  <ListItemText style={{ width: "15%" }}>
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                      sx={{
                                        fontSize: "1rem",
                                        fontFamily: "Poppins, sans-serif",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {database.last_connected}
                                    </Typography>
                                  </ListItemText>
                                  <ListItemText style={{ width: "8%" }}>
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                      sx={{
                                        fontSize: "1rem",
                                        fontFamily: "Poppins, sans-serif",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {database.table_count}{" "}
                                      {/* Display the count for the corresponding database */}
                                    </Typography>
                                  </ListItemText>
                                  <ListItemText style={{ width: "12%" }}>
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                      sx={{
                                        fontSize: "1rem",
                                        fontFamily: "Poppins, sans-serif",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {database.user}
                                    </Typography>
                                  </ListItemText>
                                  <ListItemIcon style={{ width: "0%" }}>
                                    <IconButton
                                      aria-label="More options"
                                      aria-controls="options-menu"
                                      aria-haspopup="true"
                                      onClick={(event) =>
                                        this.handleClick(
                                          event,
                                          index,
                                          "database"
                                        )
                                      }
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                      sx={{ marginLeft: "-8%" }}
                                      id="options-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={
                                        Boolean(anchorEl) &&
                                        selectedIndex === index
                                      }
                                      onClose={this.handleClose}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      PaperProps={{
                                        style: {
                                          width: "12%",
                                        },
                                      }}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          this.handleConnect(database);
                                          this.handleClose(); // Close the menu when this item is clicked
                                        }}
                                      >
                                        <ListItemIcon>
                                          <LinkIcon />
                                        </ListItemIcon>
                                        Connect
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          this.handleEditDatabase(database.id)
                                        }
                                      >
                                        <ListItemIcon>
                                          <EditIcon />
                                        </ListItemIcon>
                                        Edit
                                      </MenuItem>
                                      <Modal
                                        open={this.state.isEditDatabase}
                                        onClose={this.handleCloseFileUpload}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                      >
                                        <Box>
                                          <FileUpload
                                            addType="MYSQL"
                                            handleCloseFileUpload={
                                              this.handleCloseFileUpload
                                            }
                                            userDetails={this.props.userDetails}
                                            fetchDBData={this.fetchData}
                                            isEditDatabase={
                                              this.state.isEditDatabase
                                            }
                                          />
                                        </Box>
                                      </Modal>
                                      <MenuItem
                                        onClick={() =>
                                          this.handleDeleteUserData(database.id)
                                        }
                                      >
                                        <ListItemIcon>
                                          <DeleteIcon />
                                        </ListItemIcon>
                                        Remove
                                      </MenuItem>
                                    </Menu>
                                  </ListItemIcon>
                                </ListItemButton>
                                {index !== databases.length - 1 && <Divider />}
                              </React.Fragment>
                            ))}
                        </List>
                      </div>
                      {databases.length > 0 && (
                        <TablePagination
                          component="div"
                          count={databases.length}
                          page={currentPageDatabases - 1}
                          onPageChange={(_, newPage) =>
                            this.setState({ currentPageDatabases: newPage + 1 })
                          }
                          rowsPerPage={itemsPerPageDatabases}
                          onRowsPerPageChange={(event) => {
                            const newRowsPerPage = parseInt(
                              event.target.value,
                              10
                            );
                            this.setState({
                              itemsPerPageDatabases: newRowsPerPage,
                              currentPageDatabases: 1,
                            });
                          }}
                        />
                      )}
                    </Paper>
                  </div>
                )}
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={showBackdrop}
                  onClick={this.handleClose} // Close the backdrop when clicked
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </CustomTabPanel>

              <CustomTabPanel value={value} index={1}>
                {value === 1 && (
                  <Paper
                    elevation={0}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      backgroundColor: "#FFF",
                      display: "flex",
                    }}
                  >
                    <Paper
                      elevation={0}
                      style={{
                        width: "25%",
                        borderRadius: "10px 0px 0px 10px ",
                        backgroundColor: "#FFF",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: "20px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#F3F6FD",
                            borderRadius: "25px",
                            padding: "5px",
                            marginRight: "3%",
                          }}
                        >
                          <SearchIcon
                            sx={{
                              color: "#6001FF",
                              marginLeft: "10px",
                              marginRight: "5px",
                            }}
                          />
                          <InputBase
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={(e) =>
                              this.setState({ searchQuery: e.target.value })
                            }
                            sx={{
                              color: "#6001FF",
                              width: "60%",
                              backgroundColor: "#F3F6FD",
                              borderRadius: "25px",
                            }}
                          />
                        </Box>
                        {filteredDatabases.length > 0 ? (
                          <List
                            sx={{
                              width: "100%",
                              overflow: "auto",
                              maxHeight: "250px",
                              "& ul": { padding: 0 },
                              scrollbarWidth: "thin",
                              scrollbarColor: "#6001FF #F5F5F5",
                              "&::-webkit-scrollbar": {
                                width: "8px",
                                backgroundColor: "#F5F5F5",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                borderRadius: "4px",
                                backgroundColor: "#6b6f73",
                              },
                              "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "#555",
                              },
                              "& .scrollbar": {
                                scrollbarWidth: "thin",
                                scrollbarColor: "#6001FF #F5F5F5",
                                overflowY: "auto",
                              },
                            }}
                            subheader={<li />}
                            dense
                          >
                            {uniqueDbTypes.map((dbType, index) => (
                              <li key={`section-${index}`}>
                                <ul>
                                  <ListSubheader sx={{ color: "black" }}>
                                    {dbType}
                                  </ListSubheader>
                                  {dbTypeToDatabases[dbType].map((database) => (
                                    <ListItemButton
                                      key={`item-${database.id}-${database.user}`}
                                      onClick={() => {
                                        this.handleConnect(database);
                                      }}
                                      sx={{
                                        borderRadius: "10px",
                                        backgroundColor:
                                          selectedDatabase &&
                                          selectedDatabase.Databasename ===
                                            database.Databasename &&
                                          selectedDatabase.id === database.id
                                            ? "#6001FF"
                                            : "transparent",
                                        "&:hover": {
                                          backgroundColor: "#EFEFEF",
                                        },
                                      }}
                                    >
                                      <ListItemText
                                        primary={database.Databasename}
                                        primaryTypographyProps={{
                                          style: {
                                            color:
                                              selectedDatabase &&
                                              selectedDatabase.Databasename ===
                                                database.Databasename &&
                                              selectedDatabase.id ===
                                                database.id
                                                ? "#FFF"
                                                : "#6b6f73",
                                          },
                                        }}
                                      />
                                      <Backdrop
                                        sx={{
                                          color: "#fff",
                                          zIndex: (theme) =>
                                            theme.zIndex.drawer + 1,
                                        }}
                                        open={showBackdrop}
                                        onClick={this.handleClose} // Close the backdrop when clicked
                                      >
                                        <CircularProgress color="inherit" />
                                      </Backdrop>
                                    </ListItemButton>
                                  ))}
                                </ul>
                              </li>
                            ))}
                          </List>
                        ) : (
                          <p
                            style={{
                              fontSize: "0.9rem",
                              color: "#6b6f73",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                            }}
                          >
                            No database name found
                          </p>
                        )}
                      </Box>
                    </Paper>
                    <Paper
                      elevation={0}
                      style={{
                        width: "75%",
                        borderRadius: "10px",
                        backgroundColor: "#FFF",
                      }}
                    >
                      <div>
                        <List component="nav" aria-label="datatable list">
                          <ListItem>
                            <ListItemText
                              style={{ width: "5%", marginLeft: "5%" }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "1.1rem",
                                  fontFamily: "Poppins, sans-serif",
                                  fontWeight: 400,
                                }}
                              >
                                ID
                              </Typography>
                            </ListItemText>
                            <ListItemText style={{ width: "15%" }}>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "1.1rem",
                                  fontFamily: "Poppins, sans-serif",
                                  fontWeight: 400,
                                }}
                              >
                                Table Name
                              </Typography>
                            </ListItemText>
                            <ListItemText
                              style={{ width: "6%", marginRight: "4%" }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "1.1rem",
                                  fontFamily: "Poppins, sans-serif",
                                  fontWeight: 400,
                                }}
                              >
                                Rows
                              </Typography>
                            </ListItemText>
                            {/* <ListItemText style={{ width: "8%" }}>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "1.1rem",
                                  fontFamily: "Poppins, sans-serif",
                                  fontWeight: 400,
                                }}
                              >
                                <Checkbox
                                  id="selectAll"
                                  sx={{
                                    color: "#6001FF",
                                    "&.Mui-checked": { color: "#6001FF" },
                                  }}
                                  checked={selectAllTables}
                                  onChange={this.handleSelectAllClick} // Call the function on checkbox change
                                  size="small"
                                />
                                Select
                              </Typography>
                            </ListItemText> */}
                          </ListItem>
                          <Divider />
                          {typeof rowdata !== "string" &&
                            rowdata
                              ?.slice(startIndexTables, endIndexTables)
                              .map((rowDataItem, index) => (
                                <React.Fragment key={index}>
                                  <ListItemButton
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <ListItemText
                                      style={{ width: "3%", marginLeft: "5%" }}
                                    >
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        sx={{
                                          fontSize: "1rem",
                                          fontFamily: "Poppins, sans-serif",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {rowDataItem.id}
                                      </Typography>
                                    </ListItemText>
                                    <ListItemText style={{ width: "11%" }}>
                                      <Tooltip
                                        style={{ width: "10%" }}
                                        title={rowDataItem.tablename}
                                        arrow
                                      >
                                        <Typography
                                          variant="body1"
                                          color="textSecondary"
                                          sx={{
                                            fontSize: "1rem",
                                            fontFamily: "Poppins, sans-serif",
                                            fontWeight: 400,
                                          }}
                                        >
                                          {this.truncateText(
                                            rowDataItem.tablename,
                                            10
                                          )}
                                        </Typography>
                                      </Tooltip>
                                    </ListItemText>
                                    <ListItemText style={{ width: "6%" }}>
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        sx={{
                                          fontSize: "1rem",
                                          fontFamily: "Poppins, sans-serif",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {rowDataItem.rows}
                                      </Typography>
                                    </ListItemText>
                                    {/* <ListItemText
                                      style={{ width: "2%", marginRight: "4%" }}
                                    >
                                      <Checkbox
                                        size="small"
                                        checked={selectedRows.includes(
                                          rowDataItem
                                        )}
                                        sx={{
                                          color: "#6001FF",
                                          "&.Mui-checked": { color: "#6001FF" },
                                          ml: 1.5,
                                        }}
                                        onChange={(event) =>
                                          this.handleCheckboxChange(
                                            event,
                                            rowDataItem
                                          )
                                        }
                                      />
                                    </ListItemText> */}
                                  </ListItemButton>
                                  {index !== rowdata.length - 1 && <Divider />}
                                </React.Fragment>
                              ))}
                        </List>
                      </div>
                      {typeof rowdata !== "string" &&
                        rowdata.length > 0 && ( // Check if there are tables to show
                          <TablePagination
                            component="div"
                            count={rowdata.length}
                            page={currentPageTables - 1}
                            onPageChange={(_, newPage) =>
                              this.setState({ currentPageTables: newPage + 1 })
                            }
                            rowsPerPage={itemsPerPageTables}
                            onRowsPerPageChange={(event) => {
                              const newRowsPerPage = parseInt(
                                event.target.value,
                                10
                              );
                              this.setState({
                                itemsPerPageTables: newRowsPerPage,
                                currentPageTables: 1,
                              });
                            }}
                          />
                        )}
                    </Paper>
                  </Paper>
                )}
              </CustomTabPanel>

              <CustomTabPanel value={value} index={2}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "0px 0px 20px 0px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#F3F6FD",
                      borderRadius: "25px",
                      padding: "5px",
                      marginRight: "3%",
                    }}
                  >
                    <SearchIcon
                      sx={{
                        color: "#6001FF",
                        marginLeft: "10px",
                        marginRight: "5px",
                      }}
                    />
                    <InputBase
                      placeholder="Search..."
                      value={csvSearchQuery}
                      onChange={(e) =>
                        this.setState({ csvSearchQuery: e.target.value })
                      }
                      sx={{
                        color: "#6001FF",
                        width: "40%",
                        backgroundColor: "#F3F6FD",
                        borderRadius: "25px",
                      }}
                    />
                  </Box>
                  <ListItemIcon sx={{ color: "#000", pt: 1 }}>
                    <RestartAltOutlinedIcon
                      sx={{
                        color: "#6001FF",
                        width: "45px",
                        height: "35px",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                          borderRadius: "50%", // Making background circular
                        },
                      }}
                      onClick={this.reset}
                    />
                  </ListItemIcon>
                  {(currentLoggedInUserPermissions["Add or Delete Files"] ||
                    currentLoggedInUserRole === "superadmin") && (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      style={{
                        backgroundColor: "#6001FF",
                        color: "#fff",
                        marginRight: "1%",
                        marginLeft: "20px",
                      }}
                      onClick={this.handleAddCsvFile}
                    >
                      Add File
                    </Button>
                  )}

                  <Modal
                    open={this.state.isOpenFileUpload}
                    onClose={() => this.handleCloseFileUpload()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <FileUpload
                      addType="CSV"
                      handleCloseFileUpload={this.handleCloseFileUpload}
                      userDetails={this.props.userDetails}
                      isEditCsv={false}
                      fetchCSVData={this.fetchUploadedFiles}
                    />
                  </Modal>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  {selectedFiles.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "8%",
                      }}
                    >
                      <Typography variant="body1" color="textSecondary">
                        {`${selectedFiles.length} ${
                          selectedFiles.length === 1 ? "file" : "files"
                        } selected`}
                      </Typography>
                      <IconButton
                        aria-label="Delete"
                        onClick={() =>
                          this.handleDeleteFile(
                            selectedFiles.map((file) => file.id)
                          )
                        }
                      >
                        <Tooltip title="Remove" arrow>
                          <DeleteIcon style={{ marginLeft: "10%" }} />
                        </Tooltip>
                      </IconButton>
                    </div>
                  )}
                </div>
                <Paper
                  elevation={0}
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    backgroundColor: "#FFF",
                  }}
                >
                  <div>
                    <List component="nav" aria-label="file list">
                      <ListItem>
                        <ListItemText style={{ width: "3%" }}></ListItemText>
                        <ListItemText style={{ width: "10%" }}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontSize: "1.1rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                            }}
                          >
                            File Name
                          </Typography>
                        </ListItemText>
                        <ListItemText style={{ width: "15%" }}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontSize: "1.1rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                            }}
                          >
                            Upload Date
                          </Typography>
                        </ListItemText>
                        <ListItemText style={{ width: "9%" }}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontSize: "1.1rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                              // marginLeft: "29px",
                              ml: 1,
                            }}
                          >
                            Size
                          </Typography>
                        </ListItemText>
                        <ListItemText style={{ width: "8%" }}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontSize: "1.1rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                              marginLeft: "23px",
                            }}
                          >
                            User
                          </Typography>
                        </ListItemText>
                        <ListItemText style={{ width: "10%" }}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontSize: "1.1rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                              marginLeft: "36px",
                            }}
                          >
                            <Checkbox
                              sx={{
                                color: "#6001FF",
                                "&.Mui-checked": { color: "#6001FF" },
                              }}
                              id="selectAll"
                              checked={selectAllCSV}
                              onChange={this.handleSelectAllCSVClick} // Call the function on checkbox change
                              size="small"
                            />
                            Select
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <Divider />

                      {Array.isArray(file) &&
                        file
                          .filter((file) =>
                            file.filename
                              .toLowerCase()
                              .includes(csvSearchQuery.trim().toLowerCase())
                          )
                          .slice(startIndexFiles, endIndexFiles)
                          .map((file, index) => (
                            <React.Fragment key={index}>
                              <ListItemButton
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                disableRipple
                                disableTouchRipple
                                disableGutters
                              >
                                <ListItemIcon
                                  style={{ width: "9.5%", marginLeft: "1%" }}
                                >
                                  {/* Display the logo based on the databasetype */}
                                  {file.filetype ===
                                    "application/octet-stream" && (
                                    <img
                                      src={TSV}
                                      alt="tsv"
                                      style={{
                                        width: "30%",
                                        height: "30%",
                                        marginLeft: "6%",
                                      }}
                                    />
                                  )}
                                  {file.filetype ===
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && (
                                    <img
                                      src={XLSX}
                                      alt="xlsx"
                                      style={{ width: "40%", height: "30%" }}
                                    />
                                  )}
                                  {file.filetype === "text/csv" && (
                                    <img
                                      src={CSV}
                                      alt="text/csv"
                                      style={{ width: "40%", height: "30%" }}
                                    />
                                  )}
                                  {/* Add more conditions for other database types */}
                                </ListItemIcon>
                                <ListItemText style={{ width: "7%" }}>
                                  <Tooltip
                                    title={file.filename}
                                    placement="top"
                                  >
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                      sx={{
                                        fontSize: "1rem",
                                        fontFamily: "Poppins, sans-serif",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {file.filename.length > 10
                                        ? `${file.filename.substring(0, 10)}...`
                                        : file.filename}
                                    </Typography>
                                  </Tooltip>
                                </ListItemText>

                                <ListItemText style={{ width: "12%" }}>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{
                                      fontSize: "1rem",
                                      fontFamily: "Poppins, sans-serif",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {file.uploaded_date}
                                  </Typography>
                                </ListItemText>
                                <ListItemText style={{ width: "6%" }}>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{
                                      fontSize: "1rem",
                                      fontFamily: "Poppins, sans-serif",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {file.filesize}
                                  </Typography>
                                </ListItemText>
                                <ListItemText
                                  style={{ width: "4%", marginRight: "2%" }}
                                >
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    sx={{
                                      fontSize: "1rem",
                                      fontFamily: "Poppins, sans-serif",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {file.username}
                                  </Typography>
                                </ListItemText>

                                <ListItemText style={{ width: "1%" }}>
                                  <Checkbox
                                    size="small"
                                    checked={this.state.selectedFiles.some(
                                      (selectedFile) =>
                                        selectedFile.id === file.id &&
                                        selectedFile.filename === file.filename
                                    )}
                                    sx={{
                                      color: "#6001FF",
                                      "&.Mui-checked": { color: "#6001FF" },
                                    }}
                                    onChange={() =>
                                      this.handleCheckboxChange_csv(
                                        file.id,
                                        file
                                      )
                                    }
                                  />
                                </ListItemText>
                                {file.username !== "Admin" && (
                                  <ListItemIcon style={{ width: "0%" }}>
                                    <IconButton
                                      aria-label="More options"
                                      aria-controls="options-menu"
                                      aria-haspopup="true"
                                      onClick={(event) =>
                                        this.handleClick(event, index, "csv")
                                      }
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                      sx={{ marginLeft: "-8%" }}
                                      id="options-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={
                                        Boolean(anchorEl) &&
                                        selectedIndex === index
                                      }
                                      onClose={this.handleClose}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      PaperProps={{
                                        style: {
                                          width: "9%",
                                        },
                                      }}
                                    >
                                      <MenuItem
                                        onClick={() =>
                                          this.handleEditFile(file)
                                        }
                                      >
                                        <ListItemIcon>
                                          <EditIcon />
                                        </ListItemIcon>
                                        Edit
                                      </MenuItem>
                                      <Modal
                                        open={this.state.isEditFile}
                                        onClose={this.handleCloseFileUpload}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                      >
                                        <FileUpload
                                          addType="CSV"
                                          handleCloseFileUpload={
                                            this.handleCloseFileUpload
                                          }
                                          userDetails={this.props.userDetails}
                                          isEditCsv={true}
                                          // fileDetails = {{"id":file.id,"filename":file.filename,"fileuser":file.username,"access_user_list":file.userAccessList}}
                                          fileDetails={
                                            this.state.currentCsvfile
                                          }
                                          fetchCSVData={this.fetchUploadedFiles}
                                        />
                                      </Modal>
                                      {/* <MenuItem onClick={() => { 
                                                this.handleDeleteFile(file.id);
                                                this.handleClose(); // Close the menu when this item is clicked
                                              }}> 
                            <ListItemIcon>
                            <DeleteIcon />
                            </ListItemIcon>
                            Remove
                            </MenuItem> */}
                                    </Menu>
                                  </ListItemIcon>
                                )}
                              </ListItemButton>
                              {index !== file.length - 1 && <Divider />}
                            </React.Fragment>
                          ))}
                    </List>
                  </div>
                  {file.length > 0 && (
                    <TablePagination
                      component="div"
                      count={file.length}
                      page={currentPageFiles - 1}
                      onPageChange={(_, newPage) =>
                        this.setState({ currentPageFiles: newPage + 1 })
                      }
                      rowsPerPage={itemsPerPageFiles}
                      onRowsPerPageChange={(event) => {
                        const newRowsPerPage = parseInt(event.target.value, 10);
                        this.setState({
                          itemsPerPageFiles: newRowsPerPage,
                          currentPageFiles: 1,
                        });
                      }}
                    />
                  )}
                </Paper>
              </CustomTabPanel>

              <CustomTabPanel value={value} index={3}>
                {value === 3 && (
                  <React.Fragment>
                    {!showFiles && (
                      <Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "20px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#F3F6FD",
                              borderRadius: "25px",
                              padding: "5px",
                              marginRight: "3%",
                            }}
                          >
                            <SearchIcon
                              sx={{
                                color: "#6001FF",
                                marginLeft: "10px",
                                marginRight: "5px",
                              }}
                            />
                            <InputBase
                              placeholder="Search..."
                              value={documentSeachQuery}
                              onChange={(e) =>
                                this.setState({
                                  documentSeachQuery: e.target.value,
                                })
                              }
                              sx={{
                                color: "#6001FF",
                                width: "40%",
                                backgroundColor: "#F3F6FD",
                                borderRadius: "25px",
                              }}
                            />
                          </Box>
                          <ListItemIcon sx={{ color: "#000", pt: 1 }}>
                            <RestartAltOutlinedIcon
                              sx={{
                                color: "#6001FF",
                                width: "45px",
                                height: "35px",
                                "&:hover": {
                                  backgroundColor: "#EFEFEF",
                                  borderRadius: "50%", // Making background circular
                                },
                              }}
                              onClick={this.reset}
                            />
                          </ListItemIcon>
                          {(currentLoggedInUserPermissions[
                            "Add or Delete Files"
                          ] ||
                            currentLoggedInUserRole === "superadmin") && (
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<AddIcon />}
                              style={{
                                backgroundColor: "#6001FF",
                                color: "#fff",
                                marginLeft: "20px",
                              }}
                              onClick={() =>
                                this.setState({
                                  showModal: true,
                                  selectedApplications: [],
                                  selectedUserNames: [],
                                })
                              }
                            >
                              Add Folder
                            </Button>
                          )}

                          <Modal
                            open={showModal}
                            onClose={() => this.setState({ showModal: false })}
                          >
                            <Paper
                              elevation={5}
                              style={{
                                marginTop: "15%",
                                marginLeft: "30%",
                                borderRadius: "20px",
                                width: "35%",
                                minHeight: "170px",
                                backgroundColor: "#FFFFFF",
                                // paddingTop: "15px",
                              }}
                            >
                              <DialogTitle>New Folder</DialogTitle>
                              <TextField
                                label="Folder Name"
                                value={folderName}
                                onChange={(e) =>
                                  this.setState({ folderName: e.target.value })
                                }
                                sx={{
                                  width: "89.5%",
                                  marginTop: "0.5%",
                                  marginLeft: "3.7%",
                                  ...textFieldSx,
                                }}
                              />
                              <Container
                                style={{
                                  width: "93%",
                                  marginLeft: "2%",
                                  marginTop: "2%",
                                  padding: "2%",
                                }}
                              >
                                <Autocomplete
                                  multiple
                                  style={{ width: "100%" }}
                                  sx={selectFieldSx}
                                  options={users}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) => option.username}
                                  value={this.state.selectedUserNames.map(
                                    (name) =>
                                      users.find(
                                        (user) => user.username === name
                                      )
                                  )}
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => {
                                    return (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                          onChange={() =>
                                            this.handleCheckBoxChange(
                                              option.username
                                            )
                                          }
                                          onDelete={() =>
                                            this.handleCheckBoxChange(
                                              option.username
                                            )
                                          }
                                        />
                                        {option.username}
                                      </li>
                                    );
                                  }}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Chip
                                        label={option.username}
                                        {...getTagProps({ index })}
                                        style={{
                                          background: "#6001FF",
                                          color: "white",
                                          marginRight: 8,
                                        }}
                                        deleteIcon={
                                          <CancelIcon
                                            style={{ color: "white" }}
                                          />
                                        }
                                      />
                                    ))
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Users"
                                      placeholder="Users"
                                    />
                                  )}
                                  onChange={(event, newValue) => {
                                    this.setState({
                                      selectedUserNames: newValue.map(
                                        (user) => user.username
                                      ),
                                    });
                                  }}
                                />
                                <Autocomplete
                                  multiple
                                  style={{ width: "100%", marginTop: "2%" }}
                                  sx={selectFieldSx}
                                  options={APPLICATIONS.map(
                                    (app) => APPLICATION_MAPPING[app]
                                  )} // Map application names to codes
                                  disableCloseOnSelect
                                  getOptionLabel={(option) =>
                                    APPLICATIONS.find(
                                      (app) =>
                                        APPLICATION_MAPPING[app] === option
                                    )
                                  } // Use application code as label
                                  value={this.state.selectedApplications.map(
                                    (app) => APPLICATION_MAPPING[app]
                                  )} // Map selected application names to codes
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => {
                                    const appName = Object.keys(
                                      APPLICATION_MAPPING
                                    ).find(
                                      (key) =>
                                        APPLICATION_MAPPING[key] === option
                                    );
                                    return (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                          onChange={
                                            () =>
                                              this.handleApplicationCheckBoxChange(
                                                appName
                                              ) // Use application name
                                          }
                                          onDelete={
                                            () =>
                                              this.handleApplicationCheckBoxChange(
                                                appName
                                              ) // Use application name
                                          }
                                        />
                                        {appName}{" "}
                                        {/* Display application name */}
                                      </li>
                                    );
                                  }}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Chip
                                        label={Object.keys(
                                          APPLICATION_MAPPING
                                        ).find(
                                          (key) =>
                                            APPLICATION_MAPPING[key] === option
                                        )} // Display application name
                                        {...getTagProps({ index })}
                                        style={{
                                          background: "#6001FF",
                                          color: "white",
                                          marginRight: 8,
                                        }}
                                        deleteIcon={
                                          <CancelIcon
                                            style={{ color: "white" }}
                                          />
                                        }
                                      />
                                    ))
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Selected Applications"
                                      placeholder="Applications"
                                    />
                                  )}
                                  onChange={(event, newValue) => {
                                    // Handle the change in Autocomplete value
                                    // This can include any logic needed to update your component state
                                    this.setState({
                                      selectedApplications: newValue.map(
                                        (app) =>
                                          Object.keys(APPLICATION_MAPPING).find(
                                            (key) =>
                                              APPLICATION_MAPPING[key] === app
                                          )
                                      ),
                                    });
                                  }}
                                />
                              </Container>

                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginTop: "15px",
                                }}
                              >
                                <p
                                  style={{
                                    marginRight: "9%",
                                    color: "#6001FF",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      showModal: false,
                                      folderName: "",
                                    });
                                  }}
                                >
                                  Cancel
                                </p>
                                <Button
                                  style={{
                                    color: "#fff",
                                    cursor: "pointer",
                                    backgroundColor: "#6001FF",
                                    textTransform: "none",
                                    marginTop: "1.4%",
                                    marginRight: "6.5%",
                                    height: "1%",
                                  }}
                                  onClick={() => {
                                    this.createFolder();
                                  }}
                                >
                                  Create
                                </Button>
                              </Box>
                            </Paper>
                          </Modal>

                          <Modal
                            open={this.state.isDocumentupload}
                            onClose={this.handleCloseDocument}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <FileUploadQA
                              userDetails={this.props.userDetails}
                              isEditDocument={false}
                              handleCloseDocument={this.handleCloseDocument}
                              fetchDocumentFilesData={this.fetchDocumentFiles}
                            />
                          </Modal>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          {this.state.selectedFolders.length > 0 && (
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                // marginRight: "10px",
                                marginLeft: "4%",
                              }}
                            >
                              <Typography variant="body1" color="textSecondary">
                                {`${this.state.selectedFolders.length} ${
                                  this.state.selectedFolders.length === 1
                                    ? "Folder"
                                    : "Folders"
                                } selected`}
                              </Typography>
                              <IconButton
                                aria-label="Delete"
                                onClick={() =>
                                  this.handleDeleteDocumentFolder()
                                }
                              >
                                <Tooltip title="Remove" arrow>
                                  <DeleteIcon style={{ marginLeft: "10%" }} />
                                </Tooltip>
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                        <Paper
                          elevation={0}
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            backgroundColor: "#FFF",
                          }}
                        >
                          <List component="nav" aria-label="file list">
                            <ListItem>
                              <ListItemText
                                style={{ width: "2%" }}
                              ></ListItemText>
                              <ListItemText style={{ width: "10%" }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    fontSize: "1.1rem",
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: 400,
                                  }}
                                >
                                  Folder
                                </Typography>
                              </ListItemText>
                              <ListItemText style={{ width: "10%" }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    fontSize: "1.1rem",
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: 400,
                                  }}
                                >
                                  Folder Name
                                </Typography>
                              </ListItemText>
                              <ListItemText style={{ width: "10%" }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    fontSize: "1.1rem",
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: 400,
                                  }}
                                >
                                  Created Date
                                </Typography>
                              </ListItemText>
                              <ListItemText style={{ width: "10%" }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    fontSize: "1.1rem",
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: 400,
                                    pl: 0,
                                  }}
                                >
                                  User
                                </Typography>
                              </ListItemText>
                              <ListItemText
                                style={{ width: "10%", marginRight: "2%" }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    fontSize: "1.1rem",
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: 400,
                                  }}
                                >
                                  Number of Files
                                </Typography>
                              </ListItemText>
                              <ListItemText style={{ width: "10%" }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    fontSize: "1.1rem",
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: 400,
                                  }}
                                >
                                  <Checkbox
                                    sx={{
                                      color: "#6001FF",
                                      "&.Mui-checked": { color: "#6001FF" },
                                    }}
                                    checked={selectedAllFolders}
                                    onChange={() => this.toggleSelectAll()}
                                  />
                                  Select
                                </Typography>
                              </ListItemText>
                              <ListItemText style={{ width: "10.5%" }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    fontSize: "1.1rem",
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: 400,
                                    pl: 8,
                                  }}
                                >
                                  Action
                                </Typography>
                              </ListItemText>
                            </ListItem>
                            <Divider />
                            {folders
                              .filter((item) =>
                                item.folder_name
                                  .toLowerCase()
                                  .includes(
                                    documentSeachQuery.trim().toLowerCase()
                                  )
                              )
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((item, index) => (
                                <React.Fragment key={index}>
                                  <ListItemButton
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                    disableRipple
                                    disableTouchRipple
                                    disableGutters
                                  >
                                    <ListItemText
                                      style={{ width: "2%" }}
                                    ></ListItemText>
                                    <ListItemIcon
                                      style={{
                                        width: "14%",
                                        marginLeft: "1%",
                                      }}
                                    >
                                      {/* Display the logo based on the databasetype */}
                                      <FolderRoundedIcon />
                                    </ListItemIcon>
                                    <ListItemText style={{ width: "10%" }}>
                                      <Tooltip
                                        title={item.folder_name}
                                        placement="top-start"
                                        arrow
                                      >
                                        <Typography
                                          variant="body1"
                                          color="textSecondary"
                                          sx={{
                                            fontSize: "1rem",
                                            fontFamily: "Poppins, sans-serif",
                                            fontWeight: 400,
                                          }}
                                        >
                                          {this.truncateText(
                                            item.folder_name,
                                            10
                                          )}
                                        </Typography>
                                      </Tooltip>
                                    </ListItemText>
                                    <ListItemText style={{ width: "10%" }}>
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        sx={{
                                          fontSize: "1rem",
                                          fontFamily: "Poppins, sans-serif",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {`${item.created_date.substring(
                                          0,
                                          10
                                        )}`}
                                      </Typography>
                                    </ListItemText>
                                    <ListItemText style={{ width: "10%" }}>
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        sx={{
                                          fontSize: "1rem",
                                          fontFamily: "Poppins, sans-serif",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {item.username}
                                      </Typography>
                                    </ListItemText>
                                    <ListItemText style={{ width: "12%" }}>
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        sx={{
                                          fontSize: "1rem",
                                          fontFamily: "Poppins, sans-serif",
                                          fontWeight: 400,
                                          marginLeft: "42px",
                                        }}
                                      >
                                        {item.total_files_count}
                                      </Typography>
                                    </ListItemText>

                                    <ListItemText
                                      style={{
                                        width: "4%",
                                        marginRight: "10%",
                                      }}
                                    >
                                      <Checkbox
                                        sx={{
                                          color: "#6001FF",
                                          "&.Mui-checked": {
                                            color: "#6001FF",
                                          },
                                          ml: 0.5,
                                        }}
                                        // checked={selectedFolders.has(
                                        //   item.folder_name
                                        // )} // Check if folder is selected
                                        checked={selectedFolders.some(
                                          (selectedFolder) =>
                                            selectedFolder.id === item.id &&
                                            selectedFolder.folder_name ===
                                              item.folder_name
                                        )}
                                        onChange={() =>
                                          this.toggleFolderSelection(
                                            item.id,
                                            item
                                          )
                                        } // Toggle selection on checkbox change
                                      />
                                    </ListItemText>
                                    <Button
                                      sx={{
                                        cursor: "pointer",
                                        color: "#6001FF",
                                        textTransform: "none",
                                        "&:hover": {
                                          backgroundColor: "#EFEFEF",
                                        },
                                      }}
                                      onClick={() => {
                                        this.openFiles(index);
                                      }}
                                    >
                                      Open Folder
                                    </Button>
                                    <ListItemIcon style={{ width: "0%" }}>
                                      <IconButton
                                        aria-label="More options"
                                        aria-controls="options-menu"
                                        aria-haspopup="true"
                                        onClick={(event) =>
                                          this.handleClick(
                                            event,
                                            index,
                                            "folder"
                                          )
                                        }
                                      >
                                        <MoreVertIcon />
                                      </IconButton>

                                      <Menu
                                        sx={{ marginLeft: "-8%" }}
                                        id="options-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={
                                          Boolean(anchorEl) &&
                                          selectedIndex === index
                                        }
                                        onClose={this.handleClose}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "left",
                                        }}
                                        PaperProps={{
                                          style: {
                                            width: "9%",
                                          },
                                        }}
                                      >
                                        <MenuItem
                                          onClick={() => {
                                            this.handleEditDocument(item);
                                            this.handleClose();
                                          }}
                                        >
                                          <ListItemIcon>
                                            <EditIcon />
                                          </ListItemIcon>
                                          Edit
                                        </MenuItem>
                                        <Modal
                                          open={this.state.isEditDocument}
                                          onClose={this.handleCloseDocument}
                                          aria-labelledby="modal-modal-title"
                                          aria-describedby="modal-modal-description"
                                        >
                                          <Box
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              marginTop: "15%",
                                            }}
                                          >
                                            <Box
                                              style={{
                                                width: "30rem",
                                                backgroundColor: "#fff",
                                                padding: "2%",
                                                borderRadius: "5%",
                                              }}
                                            >
                                              <h2>Edit Folder</h2>
                                              <form>
                                                <div
                                                  style={{
                                                    marginBottom: "10px",
                                                    marginTop: "10px",
                                                  }}
                                                >
                                                  <Autocomplete
                                                    multiple
                                                    style={{ width: "100%" }}
                                                    sx={selectFieldSx}
                                                    options={users}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) =>
                                                      option.username
                                                    }
                                                    value={this.state.selectedUserNames.map(
                                                      (name) =>
                                                        users.find(
                                                          (user) =>
                                                            user.username ===
                                                            name
                                                        )
                                                    )}
                                                    renderOption={(
                                                      props,
                                                      option,
                                                      { selected }
                                                    ) => {
                                                      return (
                                                        <li {...props}>
                                                          <Checkbox
                                                            icon={icon}
                                                            checkedIcon={
                                                              checkedIcon
                                                            }
                                                            style={{
                                                              marginRight: 8,
                                                            }}
                                                            checked={selected}
                                                            onChange={() =>
                                                              this.handleCheckBoxChange(
                                                                option.username
                                                              )
                                                            }
                                                            onDelete={() =>
                                                              this.handleCheckBoxChange(
                                                                option.username
                                                              )
                                                            }
                                                          />
                                                          {option.username}
                                                        </li>
                                                      );
                                                    }}
                                                    renderTags={(
                                                      value,
                                                      getTagProps
                                                    ) =>
                                                      value.map(
                                                        (option, index) => (
                                                          <Chip
                                                            label={
                                                              option.username
                                                            }
                                                            {...getTagProps({
                                                              index,
                                                            })}
                                                            style={{
                                                              background:
                                                                "#6001FF",
                                                              color: "white",
                                                              marginRight: 8,
                                                            }}
                                                            deleteIcon={
                                                              <CancelIcon
                                                                style={{
                                                                  color:
                                                                    "white",
                                                                }}
                                                              />
                                                            }
                                                          />
                                                        )
                                                      )
                                                    }
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        label="Users"
                                                        placeholder="Users"
                                                      />
                                                    )}
                                                    onChange={(
                                                      event,
                                                      newValue
                                                    ) => {
                                                      // Handle the change in Autocomplete value
                                                      // This can include any logic needed to update your component state
                                                      this.setState({
                                                        selectedUserNames:
                                                          newValue.map(
                                                            (user) =>
                                                              user.username
                                                          ),
                                                      });
                                                    }}
                                                  />
                                                  <Autocomplete
                                                    multiple
                                                    style={{
                                                      width: "100%",
                                                      marginTop: "2%",
                                                    }}
                                                    sx={selectFieldSx}
                                                    options={APPLICATIONS.map(
                                                      (app) =>
                                                        APPLICATION_MAPPING[app]
                                                    )} // Map application names to codes
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) =>
                                                      APPLICATIONS.find(
                                                        (app) =>
                                                          APPLICATION_MAPPING[
                                                            app
                                                          ] === option
                                                      )
                                                    } // Use application code as label
                                                    value={this.state.selectedApplications.map(
                                                      (app) =>
                                                        APPLICATION_MAPPING[app]
                                                    )} // Map selected application names to codes
                                                    renderOption={(
                                                      props,
                                                      option,
                                                      { selected }
                                                    ) => {
                                                      const appName =
                                                        Object.keys(
                                                          APPLICATION_MAPPING
                                                        ).find(
                                                          (key) =>
                                                            APPLICATION_MAPPING[
                                                              key
                                                            ] === option
                                                        );
                                                      return (
                                                        <li {...props}>
                                                          <Checkbox
                                                            icon={icon}
                                                            checkedIcon={
                                                              checkedIcon
                                                            }
                                                            style={{
                                                              marginRight: 8,
                                                            }}
                                                            checked={selected}
                                                            onChange={
                                                              () =>
                                                                this.handleApplicationCheckBoxChange(
                                                                  appName
                                                                ) // Use application name
                                                            }
                                                            onDelete={
                                                              () =>
                                                                this.handleApplicationCheckBoxChange(
                                                                  appName
                                                                ) // Use application name
                                                            }
                                                          />
                                                          {appName}{" "}
                                                          {/* Display application name */}
                                                        </li>
                                                      );
                                                    }}
                                                    renderTags={(
                                                      value,
                                                      getTagProps
                                                    ) =>
                                                      value.map(
                                                        (option, index) => (
                                                          <Chip
                                                            label={Object.keys(
                                                              APPLICATION_MAPPING
                                                            ).find(
                                                              (key) =>
                                                                APPLICATION_MAPPING[
                                                                  key
                                                                ] === option
                                                            )} // Display application name
                                                            {...getTagProps({
                                                              index,
                                                            })}
                                                            style={{
                                                              background:
                                                                "#6001FF",
                                                              color: "white",
                                                              marginRight: 8,
                                                            }}
                                                            deleteIcon={
                                                              <CancelIcon
                                                                style={{
                                                                  color:
                                                                    "white",
                                                                }}
                                                              />
                                                            }
                                                          />
                                                        )
                                                      )
                                                    }
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        label="Selected Applications"
                                                        placeholder="Applications"
                                                      />
                                                    )}
                                                    onChange={(
                                                      event,
                                                      newValue
                                                    ) => {
                                                      // Handle the change in Autocomplete value
                                                      // This can include any logic needed to update your component state
                                                      this.setState({
                                                        selectedApplications:
                                                          newValue.map((app) =>
                                                            Object.keys(
                                                              APPLICATION_MAPPING
                                                            ).find(
                                                              (key) =>
                                                                APPLICATION_MAPPING[
                                                                  key
                                                                ] === app
                                                            )
                                                          ),
                                                      });
                                                    }}
                                                  />
                                                </div>
                                                <Box
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    gap: "5px",
                                                  }}
                                                >
                                                  {/* <p>{message}</p> */}
                                                  <Typography
                                                    variant="p"
                                                    style={{
                                                      color: "#6001ff",
                                                      textTransform: "none",
                                                      cursor: "pointer",
                                                      alignContent: "center",
                                                    }}
                                                    onClick={() =>
                                                      this.handleCloseDocument()
                                                    }
                                                  >
                                                    Cancel
                                                  </Typography>

                                                  <Button
                                                    style={{
                                                      backgroundColor:
                                                        "#6001ff",
                                                      color: "#ffffff",
                                                      textTransform: "none",
                                                      marginLeft: "3%",
                                                    }}
                                                    variant="contained"
                                                    onClick={() =>
                                                      this.handleUpdateFolder(
                                                        currentDocument.id
                                                      )
                                                    }
                                                  >
                                                    Apply Changes
                                                  </Button>
                                                </Box>
                                              </form>
                                            </Box>
                                          </Box>
                                        </Modal>
                                      </Menu>
                                    </ListItemIcon>
                                  </ListItemButton>
                                  {index !== file.length - 1 && <Divider />}
                                </React.Fragment>
                              ))}
                          </List>

                          <Notification />
                        </Paper>

                        {folders.length > 0 && (
                          <TablePagination
                            rowsPerPageOptions={[
                              10,
                              25,
                              50,
                              { label: "All", value: -1 },
                            ]}
                            component="div"
                            count={folders.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event) =>
                              this.handleChangePage(event)
                            }
                            onRowsPerPageChange={(event) =>
                              this.handleChangeRowsPerPage(event)
                            }
                          />
                        )}
                        <Snackbar
                          open={snackbarOpen} // Use the correct state name here (snackbarOpen)
                          autoHideDuration={3000}
                          onClose={this.handleSnackbarClose}
                        >
                          <MuiAlert
                            elevation={6}
                            variant="filled"
                            onClose={this.handleSnackbarClose}
                            severity={snackbarSeverity}
                          >
                            {snackbarMessage}
                          </MuiAlert>
                        </Snackbar>
                      </Box>
                    )}
                    {showFiles && (
                      <React.Fragment>
                        <DocumentQA
                          backFolder={this.backFolder}
                          folder_name={
                            folders[folderIndex]["folder_name"] || ""
                          }
                          folder_id={folders[folderIndex]["id"] || ""}
                          userDetails={this.props.userDetails}
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={showBackdrop}
                  onClick={this.handleClose} // Close the backdrop when clicked
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </CustomTabPanel>
            </Box>
          )}
        </Card>
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={true}
        />
      </div>
    );
  }
}

export default withRouter(DataTabs);
