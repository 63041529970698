import React, { Component } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Alert, ListItemButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import LanguageIcon from "@mui/icons-material/Language";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress for the loader

const EMBEDDING_SERVICE_URL = process.env.REACT_APP_EMBEDDING_SERVICE_URL;
const application_code = "ZN004";
export class FileUploadWebsite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      showsnack: false,
      snackmessage: "",
      severity: "",
    };
  }
  handleUpload = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const userOrganization = JSON.parse(
        localStorage.getItem("currentLoggedInUserDetails")
      )["organization"];

      const webData = {
        weburls: this.props.selectedLinks,
        user_emails: [localStorage.getItem("email")],
        organization: userOrganization,
        application_code: application_code,
      };
      this.setState({ showLoader: true });
      const response = await fetch(
        `${EMBEDDING_SERVICE_URL}/zn004/user_webembedding`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(webData),
        }
      );
      if (response.status === 200) {
        const msg = await response.json();
        this.setState({ message: msg.message });

        this.handleSnackbarOpen("success", "Website Uploaded Successfully");
        this.props.handleSelectedLinks();
      }
      if (!response.ok) {
        const webExist = await response.json();
        if (webExist.detail) {
          this.handleSnackbarOpen("error", webExist.detail);
        }
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.setState({ showLoader: false });
    }
  };
  handleSnackbarOpen = (severity, message) => {
    this.setState({
      snackmessage: message,
      severity: severity,
      showsnack: true,
    });
  };
  handleSnackbarClose = () => {
    this.setState({ showsnack: false });
  };
  render() {
    const { showsnack, severity, snackmessage, showLoader, message } =
      this.state;
    const {
      webLink,
      selectedLinks,
      onChangeWebLink,
      webCrawlClicked,
      handleWebCrawl,
      handleDeleteLink,
      handleChatClickInUpload,
    } = this.props;
    return (
      <Box>
        <Box>
          <h2 style={{ fontSize: "18px" }}>Paste URL:</h2>
          <TextField
            fullWidth
            label="Web Link"
            id="web"
            value={webLink}
            onChange={(e) => onChangeWebLink(e.target.value)}
            sx={{
              width: "50%",
              height: "53px",

              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#6001FF",
                },
                "&:hover fieldset": {
                  borderColor: "#6001FF",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#6001FF",
                },
              },
            }}
          />
          <Button
            size="large"
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            onClick={handleWebCrawl}
            sx={{
              height: "53px",
              marginLeft: "2%",
              backgroundColor: "#6001FF",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#6001FF",
              },
            }}
          >
            <span style={{ textTransform: "lowercase" }}>fetch</span>
          </Button>
        </Box>

        {selectedLinks.length > 0 && (
          <Box>
            <h3>Selected Links:</h3>
            <List>
              {selectedLinks.map((link, index) => (
                <ListItem  key={index} disablePadding>
                  <ListItemButton  >
                    <ListItemIcon >
                      <LanguageIcon />
                    </ListItemIcon>
                    <ListItemText primary={link} />
                    <ListItemIcon onClick={() => handleDeleteLink(index)}>
                      <DeleteIcon sx={{ color: "#6001FF" }} />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        {message && (
          <Typography
            variant="p"
            component="div"
            style={{
              marginTop: selectedLinks.length > 0 ? "0" : "8.3%",
              marginBottom: "2%",
            }}
          >
            {message}
          </Typography>
        )}
        {webCrawlClicked && (
          <Button
            onClick={(e) => this.handleUpload(e)}
            disabled={selectedLinks.length === 0}
            sx={{
              marginTop: "10px",
              backgroundColor:
                selectedLinks.length === 0 ? "#FFFFFF" : "#6001FF",
              color: selectedLinks.length === 0 ? "#6001FF" : "#FFFFFF",
              textTransform: "none",
              marginLeft: "1%",
              "&:hover": {
                backgroundColor: "#6001FF",
              },
              "&.Mui-disabled": {
                color: "#6001FF", // Add this line to set the color when disabled
              },
            }}
          >
            {showLoader ? (
              <CircularProgress
                size={25}
                sx={{
                  color: "#FFFFFF",
                  marginLeft: "-10%",
                }}
              />
            ) : (
              "Upload"
            )}
          </Button>
        )}
        {snackmessage && webCrawlClicked && (
          <Button
            onClick={handleChatClickInUpload}
            sx={{
              marginTop: "10px",
              backgroundColor: "#6001FF",
              color: "#FFFFFF",
              textTransform: "none",
              marginLeft: "3.5%",
              "&:hover": {
                backgroundColor: "#6001FF",
              },
            }}
          >
            Next
          </Button>
        )}

        <Snackbar
          open={showsnack}
          autoHideDuration={4000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            this.setState({ showsnack: false });
          }}
        >
          <Alert
            variant="filled"
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }

              this.setState({ showsnack: false });
            }}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {snackmessage}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

export default FileUploadWebsite;
