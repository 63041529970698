import React, { Component } from "react";
import ListItemText from "@mui/material/ListItemText";

export class LoaderMessages extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const { loader_Messages } = this.props;
    return (
      <div
        style={{
          background: "#FFFFFF",
          borderRadius: "40px",
          padding: "10px 10px 15px 10px",
          width: "95%",
          marginLeft: "10px",
          marginTop: "20px",
        }}
      >
        <ListItemText
          secondary={`${loader_Messages}`}
          style={{
            fontSize: "1.25rem",
            color: "#818589",
          }}
          disableTypography
        />
      </div>
    );
  }
}

export default LoaderMessages;
