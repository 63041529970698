import { Box, InputBase } from "@mui/material";
import React, { Component } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";

export class LogsSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localSearchQuery: props.searchQuery || "", // Initialize with prop value or empty string
    };

    this.debouncedHandleSearchQuery = debounce(
      this.props.handleSearchQuery,
      300
    );
  }

  handleChange = (e) => {
    const value = e.target.value;
    this.setState({ localSearchQuery: value });
    this.debouncedHandleSearchQuery(value);
  };

  render() {
    const { localSearchQuery } = this.state;

    return (
      <div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#F3F6FD",
            borderRadius: "25px",
            padding: "5px",
          }}
        >
          <SearchIcon
            sx={{
              color: "#6001FF",
              marginLeft: "10px",
              marginRight: "5px",
            }}
          />
          <InputBase
            placeholder="Search..."
            value={localSearchQuery}
            onChange={this.handleChange}
            sx={{
              width: "40%",
              backgroundColor: "#F3F6FD",
              borderRadius: "25px",
            }}
          />
        </Box>
      </div>
    );
  }
}

export default LogsSearch;
