import React, { Component } from "react";
import { Card, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// const BASE_URL = process.env.REACT_APP_BASE_URL;
class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainChartOptions: this.getMainChartOptions([]), // Initialize with an empty array
    };
  }
  componentDidMount() {
    this.updateChartOptions();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.pie_chart_data !== this.props.pie_chart_data) {
      this.updateChartOptions();
    }
  }

  updateChartOptions() {
    const { pie_chart_data } = this.props;
    const mainChartOptions = this.getMainChartOptions(pie_chart_data);
    this.setState({ mainChartOptions });
  }

  getMainChartOptions(pie_chart_data) {
    return {
      chart: {
        type: "pie",
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          innerSize: "45%",
          borderRadius: 8,
          allowPointSelect: true,
          borderWidth: 2,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "{point.name}: <b>{point.percentage:.1f}%</b>",
            distance: 10,
          },
        },
      },
      series: [
        {
          name: "Token Count",
          data: pie_chart_data,
        },
      ],
    };
  }

  render() {
    return (
      <Card
        elevation={2}
        style={{
          borderRadius: "20px",
          backgroundColor: "#6001FF",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#FFFFFF",
            fontSize: "1.5rem",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 500,
            marginLeft: "4%",
            marginTop: "1%",
            display: "flex",
            alignItems: "center",
          }}
        >
          Token Usage
        </Typography>
        <Card
          elevation={2}
          style={{
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            // alignItems: 'center',
            flexDirection: "column",
            marginTop: "1%",
          }}
        >
          <div style={{ marginTop: "5%", marginBottom: "10%" }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={this.state.mainChartOptions}
            />
          </div>
        </Card>
      </Card>
    );
  }
}

export default PieChart;
