import React from "react";
import { Box, } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import Account from "./Account";
import Profile from "./Profile";
import Setpassword from "./Setpassword";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";



class ManageAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedButton: "account",
      showProfile: false,
      showSetpassword: false,
    };
  }

  handleTabClick = (tab) => {
    this.setState(
      { activeTab: tab, showProfile: false, showSetpassword: false },
      () => {
        if (tab === "security" && this.accountRef.current) {
          this.accountRef.current.scrollToBottom();
        }
      }
    );
  };

  handleProfileClick = () => {
    this.setState({ showProfile: true });
  };

  handleCancelProfile = () => {
    this.setState({ showProfile: false, activeTab: "account" });
  };

  handleSetPassword = () => {
    this.setState({ showSetpassword: true });
  };

  handleCancelSetpassword = () => {
    this.setState({ showSetpassword: false, activeTab: "security" });
  };

  handleSelectedButton=(button)=>{
    this.setState({selectedButton:button})
  }

  render() {
    // const { classes } = this.props;
    const { activeTab, showProfile, showSetpassword,selectedButton } = this.state;

    return (
      <Box style={{ display: "flex", height: "100vh",  }}>
        <Box
          style={{
            width: 200,
            height: "18%",
            // padding: 16,
            marginTop: "1%",
            position: "fixed",
           
          }}
        >
           <List>
           <ListItem disablePadding>
           <ListItemButton
                href="#"
                onClick={() =>
                  // this.handleTabClick("account")
                  {
                    this.props.scrollToSection("account");
                    this.setState({ showProfile: false, showSetpassword: false,selectedButton:"account" });
                  }
                }
                sx={{
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  backgroundColor:
                    selectedButton === "account" ? "#6001FF" : "transparent",
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
              >
                  <ListItemIcon
                  sx={{ color: selectedButton === "account" ? "#FFF" : "#000" }}
                >
                   <PersonIcon sx={{color:this.state.selectedButton==="account"?  "#FFF":"#6001FF"}} />
                </ListItemIcon>
                <ListItemText
                  primary="Account"
                  sx={{
                    color: selectedButton === "account" ? "#FFF" : "#000",
                    fontSize: "1.3rem",
                  }}
                  disableTypography
                />
                 </ListItemButton>
           </ListItem>
           <ListItem disablePadding>
           <ListItemButton
                href="#"
                onClick={() =>
                  // this.handleTabClick("security")
                  {
                    this.props.scrollToSection("security");
                    this.setState({ showProfile: false, showSetpassword: false,selectedButton:"security" });
                  }
                }
                sx={{
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  backgroundColor:
                    selectedButton === "security" ? "#6001FF" : "transparent",
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
              >
                  <ListItemIcon
                  sx={{ color: selectedButton === "account" ? "#FFF" : "#000" }}
                >
            <VerifiedUserIcon sx={{color:this.state.selectedButton==="security"?  "#FFF":"#6001FF"}} />
            </ListItemIcon>
                <ListItemText
                  primary="security"
                  sx={{
                    color: selectedButton === "security" ? "#FFF" : "#000",
                    fontSize: "1.3rem",
                  }}
                  disableTypography
                />
                 </ListItemButton>
           </ListItem>
           </List>
        </Box>

        <Box style={{ flex: 1, marginLeft: "215px", }}>

          {showProfile ? (
            <Profile
              onCancel={this.handleCancelProfile}
              profileRef={this.props.profileRef}
              scrollToSection={this.props.scrollToSection}
              profileImage={this.props.profileImage}
              getUserProfileImage={this.props.getUserProfileImage}
            />
          ) : showSetpassword ? (
            <Setpassword
              onCancelSetpassword={this.handleCancelSetpassword}
              scrollToSection={this.props.scrollToSection}
              updatePasswordRef={this.props.updatePasswordRef}
            />
          ) : (
            <Account
              accountRef={this.props.accountRef}
              securityRef={this.props.securityRef}
              scrollToSection={this.props.scrollToSection}
              onProfileClick={this.handleProfileClick}
              onSetpassword={this.handleSetPassword}
              handleManageAccount={this.props.handleManageAccount}
              activeTab={activeTab}
              profileImage={this.props.profileImage}
              handleSelectedButton={this.handleSelectedButton}
            />
          )}
        </Box>
      </Box>
    );
  }
}

export default ManageAccount;
