import { Component } from "react";
import "./cuterobotstyles/styles.scss";

export default class CuteRObot extends Component {
  render() {
    return (
      <div class="cute-robot-v1">
        <div class="circle-bg">
          <div class="robot-ear left"></div>
          <div class="robot-head">
            <div class="robot-face">
              <div class="eyes left"></div>
              <div class="eyes right"></div>
              <div class="mouth"></div>
            </div>
          </div>
          <div class="robot-ear right"></div>
          <div class="robot-body"></div>
        </div>
      </div>
    );
  }
}
