import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { debounce } from "lodash";

const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};

const selectFieldSx = {
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
  },
  "& .MuiMenu-paper": {
    maxHeight: "200px", // Set the maximum height for the dropdown
    maxWidth: "100px",
  },
};

class LogsDateRange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
      endDate: new Date(),
    };
    this.debouncedHandleStartDate = debounce(this.props.handleStartDate, 1000);
    this.debouncedHandleEndDate = debounce(this.props.handleEndDate, 1000);
  }
  handleStartDateChange = (formattedDateString) => {
    // Pass the formatted date string to the handler function
    this.debouncedHandleStartDate(formattedDateString);
  };

  handleEndDateChange = (formattedDateString) => {
    // Pass the formatted date string to the handler function
    this.debouncedHandleEndDate(formattedDateString);
  };
  render() {
    // const { handleStartDate, handleEndDate } = this.props;
    const { startDate, endDate } = this.state;
    return (
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start-date"
            format="DD/MM/YYYY"
            selected={startDate}
            sx={{
              mr: "10px",
              mt: "22px",
              width: "166px",
              backgroundColor: "#F3F6FD",
              ...selectFieldSx,
              ...textFieldSx,
            }}
            views={["day", "month", "year"]}
            onChange={(date) => {
              const formattedDate = new Date(date);

              // Extract the year, month, and day components
              const year = formattedDate.getFullYear();
              const month = (formattedDate.getMonth() + 1)
                .toString()
                .padStart(2, "0"); // Adding 1 because getMonth() returns zero-based month index
              const day = formattedDate.getDate().toString().padStart(2, "0");

              // Construct the formatted date string in the desired format
              const formattedDateString = `${year}/${month}/${day}`;
              // Pass the formatted date string to the handler function
              this.handleStartDateChange(formattedDateString);
            }}
          />
          <DatePicker
            label="End-date"
            format="DD/MM/YYYY"
            selected={endDate}
            sx={{
              ml: "10px",
              mt: "22px",
              width: "166px",
              backgroundColor: "#F3F6FD",
              ...selectFieldSx,
              ...textFieldSx,
            }}
            views={["day", "month", "year"]}
            // InputProps={{sx:{"& .MuiSvgIcon-root":{color:"blue"}}}}
            onChange={(date) => {
              const formattedDate = new Date(date);
              // Extract the year, month, and day components
              const year = formattedDate.getFullYear();
              const month = (formattedDate.getMonth() + 1)
                .toString()
                .padStart(2, "0"); // Adding 1 because getMonth() returns zero-based month index
              const day = formattedDate.getDate().toString().padStart(2, "0");
              // Construct the formatted date string in the desired format
              const formattedDateString = `${year}/${month}/${day}`;
              this.handleEndDateChange(formattedDateString);
            }}
          />
        </LocalizationProvider>
      </div>
    );
  }
}

export default LogsDateRange;
