import React, { Component } from "react";
import { Button, Container, Typography, Box, SvgIcon, CircularProgress } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import axios from "axios";

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "100vh",
  },
  container: {
    backgroundColor: "#1a1a1a",
    borderRadius: "12px",
    padding: "20px",
    textAlign: "center",
  },
  iconContainer: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "16px",
  },
  icon: {
    fontSize: "80px",
    color: "#10e36c",
  },
  errorIcon: {
    fontSize: "80px",
    color: "#ff1744",
  },
  header: {
    color: "#ffffff",
    fontWeight: "600",
    marginBottom: "16px",
  },
  text: {
    color: "#b0b0b0",
    fontWeight: "500",
  },
  buttonContainer: {
    marginTop: "20px",
    textAlign: "right",
  },
  button: {
    margin: "8px",
    backgroundColor: "#6001FF",
    color: "#FFFFFF",
  },
  loader: {
    color: "#ffffff",
  },
};

class ActivateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      isSuccess: false,
      countdown: 5,
      isLoading: true,  // Initial loading state
    };
    this.intervalId = null;
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      this.activateAccount(token);
    } else {
      this.setState({ 
        message: 'No activation token found', 
        isSuccess: false, 
        isLoading: false // Stop loading if no token found
      });
    }
  }

  activateAccount = async (token) => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.get(`${apiUrl}/user/activateAccount?token=${token}`);
      this.setState({ 
        message: response.data.message, 
        isSuccess: true, 
        isLoading: false  // Stop loading once success
      });
      this.startCountdown();
    } catch (error) {
      let errorMessage = "An unexpected error occurred. Please try again later.";
  
      if (error.response) {
        errorMessage = error.response.data.detail || "An error occurred during activation.";
      } else if (error.request) {
        errorMessage = "No response received from the server. Please check your network connection.";
      }
  
      this.setState({ 
        message: errorMessage, 
        isSuccess: false, 
        isLoading: false  // Stop loading once failure
      });
    }
  };

  startCountdown = () => {
    this.intervalId = setInterval(() => {
      this.setState((prevState) => ({
        countdown: prevState.countdown - 1,
      }), () => {
        if (this.state.countdown === 0) {
          clearInterval(this.intervalId);
          this.redirectToLogin();
        }
      });
    }, 1000);
  };

  redirectToLogin = () => {
    window.location.href = "/"; // Replace with your login page route
  };

  render() {
    const { message, isSuccess, countdown, isLoading } = this.state;

    return (
      <Container maxWidth="sm" sx={styles.mainContainer}>
        <Box sx={styles.container}>
          {isLoading ? (
            <Box>
              <CircularProgress sx={styles.loader} />
              <Typography variant="h5" sx={styles.header}>
                Please wait while we verify your account...
              </Typography>
            </Box>
          ) : (
            <Box>
              <Box sx={styles.iconContainer}>
                <SvgIcon sx={isSuccess ? styles.icon : styles.errorIcon}>
                  {isSuccess ? (
                    <CheckCircleOutlineIcon fontSize="inherit" />
                  ) : (
                    <ErrorOutlineIcon fontSize="inherit" />
                  )}
                </SvgIcon>
              </Box>
              <Typography variant="h5" sx={styles.header}>
                {isSuccess ? "Your account is activated!" : "Activation Failed"}
              </Typography>
              <Typography variant="body1" sx={styles.text}>
                {message}
              </Typography>
              {isSuccess ? (
                <Box sx={styles.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={styles.button}
                  >
                    Redirecting to Login page in {countdown}...
                  </Button>
                </Box>
              ) : (
                <Typography variant="body2" sx={styles.text}>
                  Please contact the administrator to activate your account.
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Container>
    );
  }
}

export default ActivateAccount;
