import React, { Component } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Button,
  LinearProgress,
} from "@mui/material";
import { Box, Typography } from "@mui/material";
// import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import { withRouter } from "./withRouter";

import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout";

import BoltIcon from "@mui/icons-material/Bolt";
import Tooltip from "@mui/material/Tooltip";
import SettingsIcon from "@mui/icons-material/Settings";
import Utils from "./Utils";
import PersonIcon from "@mui/icons-material/Person";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const formatIndianNumber = (num) => {
  const numString = num.toString();
  const lastThreeDigits = numString.slice(-3);
  const otherDigits = numString.slice(0, -3);
  const formattedOtherDigits = otherDigits.replace(
    /\B(?=(\d{2})+(?!\d))/g,
    ","
  );
  return otherDigits
    ? formattedOtherDigits + "," + lastThreeDigits
    : lastThreeDigits;
};
const formatNumber = (num) => {
  if (num >= 10000000) {
    return (num / 10000000).toFixed(2) + "Cr";
  } else if (num >= 100000) {
    return (num / 100000).toFixed(2) + "L";
  }
  return formatIndianNumber(num);
};
const BASE_URL = process.env.REACT_APP_BASE_URL;
class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      username: localStorage.getItem("username") || "John",
      user_role:
        JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))?.role ??
        "",
      showManageAccount: false,
    };
  }

  handleSwitchAdmin = () => {
    this.props.navigate("/dashboard");
  };
  handleLogout = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${BASE_URL}/auth/logout`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Attach token using the "Bearer" prefix
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        console.log(data.message);
        localStorage.removeItem("token"); // Remove token from localStorage
        // You can perform additional actions here, such as updating state or redirecting
      } else if (response.status === 401) {
        // Handle unauthorized error
        console.error("Unauthorized: You are not logged in");
        Utils.removeLocalStorage();
        this.props.navigate("/");
      } else {
        // Handle other status codes
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
    for (let key in localStorage) {
      localStorage.removeItem(key);
    }
    Utils.removeLocalStorage();
    this.props.navigate("/");
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl, username, user_role } = this.state;
    const open = Boolean(anchorEl);
    // const { profileImage } = this.props;
    const { tokenUsed, tokenLimit, profileImage } = this.props;
    const tokenPercentage = (tokenUsed / tokenLimit) * 100;
    const formattedTokenUsage = formatNumber(tokenUsed);
    const formattedTotalTokenLimit = formatNumber(tokenLimit);

    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          // "@media (max-height:600px)": {
          //   // Add styles for small screens
          //   justifyContent: "center",
          // },
          // maxHeight: { xs: "90%", sm: "70%", md: "22%" },
        }}
      >
        <IconButton
          aria-label="account"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={this.handleClick}
          color="inherit"
          style={{
            position: "relative",
            top: "50%",
            left: "-5%",
            boxShadow: 2,
          }}
          disableRipple={Boolean(true)}
        >
          {/* <AccountCircleSharpIcon
            style={{ fontSize: "3.75rem", color: "#6001ff" }}
          /> */}
          <Avatar
            style={{ fontSize: "3.75rem" }}
            sx={{ bgcolor: "#6001ff", width: 50, height: 50 }}
          >
            {profileImage ? (
              <img
                src={`data:image/jpeg;base64,${profileImage}`}
                alt="Profile"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <PersonIcon sx={{ width: 40, height: 40 }} />
            )}
          </Avatar>
        </IconButton>

        <Menu
          sx={{
            mt: "5%",
            marginLeft: "-2%",
            // width: { xs: "90%", sm: "70%", md: "22%" }, // Responsive width
            // maxHeight: "50%",
            //  overflowY: "auto",
          }}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              // width: "22%",
              width: { xs: "90%", sm: "70%", md: "22%" }, 
              maxHeight: { xs: "90%", sm: "70%", md: "22%" },
              overflowY: "auto", //  vertical scrollbar
            },
          }}
        >
          <Box style={{ margin: "5% 0% 0% 0%" }}>
            <MenuItem
              sx={{
                "&:hover": { backgroundColor: "transparent" },
                pointerEvents: "none",
              }} // Disable pointer events for this MenuItem
            >
              <Avatar sx={{ bgcolor: "#6001ff", marginRight: 1 }}>
                {profileImage ? (
                  <img
                    src={`data:image/jpeg;base64,${profileImage}`}
                    alt="Profile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <PersonIcon />
                )}
              </Avatar>
              <Typography
                variant="h6"
                style={{
                  fontSize: "1.4rem",
                  fontFamily: "Poppins, sans-serif",
                  color: "#6b6f73",
                  fontWeight: 500,
                }}
              >
                {username}
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem
              sx={{
                mt: "2%",
                "&:hover": { backgroundColor: "transparent" },
                pointerEvents: "auto", // Ensure this MenuItem is clickable
              }}
              onClick={() => {
                this.props.handleManageAccount(true);
                this.handleClose();
              }}
            >
              <SettingsIcon style={{ color: "#6001FF" }} />
              <Typography
                variant="h6"
                style={{
                  fontSize: "1rem",
                  fontFamily: "Poppins, sans-serif",
                  color: "#6b6f73",
                  fontWeight: 500,
                  marginLeft: "4%",
                }}
              >
                Manage account
              </Typography>
            </MenuItem>
            {(user_role === "superadmin" || user_role === "admin") && (
              <Box>
                {/* <Divider /> */}
                <MenuItem
                  sx={{
                    mt: "2%",
                    "&:hover": { backgroundColor: "transparent" },
                    pointerEvents: "auto", // Ensure this MenuItem is clickable
                  }}
                  onClick={this.handleSwitchAdmin}
                >
                  <ManageAccountsIcon style={{ color: "#6001FF" }} />
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: "1rem",
                      fontFamily: "Poppins, sans-serif",
                      color: "#6b6f73",
                      fontWeight: 500,
                      marginLeft: "4%",
                    }}
                  >
                    Admin
                  </Typography>
                </MenuItem>
                <Divider />
              </Box>
            )}
            <MenuItem
              sx={{
                "&:hover": { backgroundColor: "transparent" },
                pointerEvents: "none",
              }} // Disable pointer events for this MenuItem
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    mt: 1,
                  }}
                >
                  <span>
                    Token Consumption{" "}
                    <BoltIcon fontSize="1rem" sx={{ color: "#E0E0E0" }} />{" "}
                  </span>
                  <Tooltip
                    title={formatIndianNumber(this.props.tokenUsed)}
                    placement="top-start"
                    arrow
                  >
                    <span>{formattedTokenUsage}</span>
                  </Tooltip>
                  <span>&nbsp;/&nbsp;</span>
                  <Tooltip
                    title={formatIndianNumber(this.props.tokenLimit)}
                    placement="top-start"
                    arrow
                  >
                    <span>{formattedTotalTokenLimit}</span>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    mt: 1,
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={Math.min(100, tokenPercentage)}
                    sx={{
                      flexGrow: 1,
                      backgroundColor: "#E0E0E0",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#6001ff",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: `${Math.min(100, tokenPercentage)}%`,
                        height: "100%",
                        backgroundImage: `linear-gradient(90deg, #6001ff ${Math.min(
                          100,
                          tokenPercentage
                        )}%, transparent ${Math.min(100, tokenPercentage)}%)`,
                      }}
                    />
                  </LinearProgress>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ marginLeft: "5%" }}
                  >
                    {`${Math.min(100, Math.round(tokenPercentage))}%`}
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            <Divider />
            <MenuItem
              sx={{
                "&:hover": { backgroundColor: "transparent" },
                // pointerEvents: "none",
              }}
            >
              {" "}
              {/* Disable pointer events for this MenuItem */}
              <Button
                variant="text"
                onClick={this.handleLogout}
                style={{ color: "#6001ff" }}
                startIcon={<LogoutIcon size="large" />}
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                Logout
              </Button>
            </MenuItem>
          </Box>
        </Menu>
      </Box>
    );
  }
}

export default withRouter(User);
