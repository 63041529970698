import React, { Component } from "react";
import Highcharts from "highcharts";
import { FormControl, Select, MenuItem, Card, Typography } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
// const BASE_URL = process.env.REACT_APP_BASE_URL;
const selectFieldSx = {
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
  },
  "& .MuiMenu-paper": {
    maxHeight: "200px", // Set the maximum height for the dropdown
    maxWidth: "100px",
  },
};
class MainBarchart extends Component {
  render() {
    const {
      xAxisGranularity,
      yAxisMetric,
      data,
      handleXAxisChange,
      handleYAxisChange,
    } = this.props;

    const options = {
      chart: {
        type: "column",
        scrollablePlotArea: {
          minWidth: 760, // Minimum width of the plot area
          // scrollPositionX: 1 // Initial scroll position
      }
      },
      title: {
        text: "",
        align: "left", // Align the title to the left
      },
      xAxis: {
        categories: data?.map((item) => item.date),
        scrollbar: {
            enabled: true,
        }
      },
      yAxis: {
        title: {
          text: yAxisMetric,
        },
      },
      series: [
        {
          name: yAxisMetric,
          data: data?.map((item) => item[yAxisMetric]),
          color: "#6001FF",
        },
      ],
      credits: {
        enabled: false,
      },
    };

    return (
      <Card
        elevation={2}
        style={{
          borderRadius: "20px",
          backgroundColor: "#6001FF",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#FFFFFF",
            fontSize: "1.5rem",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 500,
            marginLeft: "4%",
            marginTop: "1%",
            display: "flex",
            alignItems: "center",
          }}
        >
          Trend Analysis
        </Typography>
        <Card
          elevation={2}
          style={{
            borderRadius: "20px",
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "3%",
              marginLeft: "50%",
              marginBottom: "20px",
            }}
          >
            <FormControl>
              <Select
                value={yAxisMetric}
                onChange={handleYAxisChange}
                sx={selectFieldSx}
              >
                <MenuItem value="Active Users">No. of Active Users</MenuItem>
                <MenuItem value="Queries">No. of Queries</MenuItem>
                <MenuItem value="Tokens Consumed">
                  No. of Tokens Consumed
                </MenuItem>
                <MenuItem value="Cost">Cost</MenuItem>
              </Select>
            </FormControl>

            <FormControl>
              <Select
                value={xAxisGranularity}
                onChange={handleXAxisChange}
                sx={selectFieldSx}
              >
                <MenuItem value="year">Year</MenuItem>
                <MenuItem value="quarter">Quarter</MenuItem>
                <MenuItem value="month">Month</MenuItem>
                <MenuItem value="day">Day</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* <h2 style={{position: 'absolute',
            top: '20px', // Adjust the vertical position
            left: '30px', // Adjust the horizontal position
            marginTop: '0', // Remove default margin
            marginBottom: '0', // Remove default margin
            fontWeight:600
            }}
            >Trend Analysis</h2> */}
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Card>
      </Card>
    );
  }
}

export default MainBarchart;
