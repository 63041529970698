import React from "react";
import { Avatar, Button, Typography, Paper, Divider, Box } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Utils from "./Utils";
import { withRouter } from "./withRouter";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const BASE_URL = process.env.REACT_APP_BASE_URL;

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profileImageSubmit: null,
      profileImageUpload: null,
      email: localStorage.getItem("email") ? localStorage.getItem("email") : "",
    };
    this.fileInput = React.createRef();
  }
  componentDidMount() {
    this.props.scrollToSection("profile");
    this.setState({ profileImageUpload: this.props.profileImage });
  }
  handleUploadPicture = () => {
    this.fileInput.current.click();
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const file = event.target.files[0];
      if (file.size > 1048576) {
        toast.error("File size should be less than 1MB");
        return;
      }
      if (file) {
        this.setState({ profileImageSubmit: file }, () => {
          // console.log("profileImage", this.state.profileImageSubmit);
        });
      }

      const reader = new FileReader();
      reader.onload = () => {
        this.setState({ profileImageUpload: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  handleSubmit = async () => {
    const { profileImageSubmit, email, } = this.state;
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("email", email);
         // Append only if profileImageSubmit is not null
      if (profileImageSubmit) {
        formData.append("profileImage", profileImageSubmit);
      }
      

      const response = await fetch(`${BASE_URL}/user/update_profile_pic`, {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (response.status === 401) {
        console.error("Unauthorized: You are not logged in");
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.status === 200) {
        toast.success("User Profile updated successfully", {
          onClose: () => {
            this.props.onCancel();
            this.props.getUserProfileImage();
          },
        });
      } else {
        toast.error("Failed to  update User Profile ", {
          onClose: this.props.onCancel,
        });
        console.error("Failed to  update User Profile");
      }
    } catch (error) {
      console.error("error", error);
    }
    localStorage.setItem("profileImageMethod", JSON.stringify(true));
  };
  removeProfilePicture=()=>{
    this.setState({profileImageUpload:null,profileImageSubmit:null},
      ()=>{this.handleSubmit()}
    )
  }
  render() {
    const { profileImageUpload } = this.state;
    return (
      <React.Fragment  >
         <Box sx={{display:"flex",height: "100vh",width:"100%", }}>
         <Divider orientation="vertical"sx={{ height: "105%",backgroundColor: "rgba(0, 0, 0, 0.2)" }}/>
        <Box ref={this.props.profileRef}></Box>
        <Paper style={{ padding: 16, marginTop: "2%", width:"100%",}} elevation={0}>
          <Typography variant="h5">Update profile</Typography>
          <Divider style={{ margin: "16px 0" }} />
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box style={{ display: "inline-block", marginRight: 16 }}>
              <Avatar
                src={profileImageUpload}
                style={{ marginBottom: 0, backgroundColor: "#6001FF" }}
              >
                {profileImageUpload ? (
                  <img
                    src={`data:image/jpeg;base64,${profileImageUpload}`}
                    alt="Profile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <PersonIcon />
                )}
              </Avatar>
              {/* <Avatar
                src={profileImage || (profileImage ? {profileImage} : null)}
                style={{ marginBottom: 0, backgroundColor: "#6001FF" }}
              >
                {!profileImage && !profileImage && <PersonIcon />}
              </Avatar> */}
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 500,
                }}
              >
                Profile image
              </Typography>
              <Box style={{display:"flex"}}>
              <Typography
                variant="body2"
                color="#6001FF"
                style={{
                  cursor: "pointer",
                  marginTop: 4,
                  fontSize: "0.9rem",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 500,
                }}
                onClick={() => this.handleUploadPicture()}
                onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
                onMouseOut={(e) => (e.target.style.textDecoration = "none")}
              >
                Upload image
              </Typography>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={this.fileInput}
                onChange={this.handleFileChange}
              />
             {profileImageUpload && <Typography
                variant="body2"
                color="red"
                style={{
                  cursor: "pointer",
                  marginTop: 4,
                  fontSize: "0.9rem",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 500,
                  marginLeft:"15px"
                }}
                onClick={() => this.removeProfilePicture()}
                onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
                onMouseOut={(e) => (e.target.style.textDecoration = "none")}
              >
                Remove image
              </Typography>}
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              gap: 18,
              marginTop: "2%",
            }}
          >
            <Typography
              style={{
                marginLeft: "70%",
                textTransform: "none",
                cursor: "pointer",
              }}
              onClick={this.props.onCancel}
            >
              Cancel
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#6001FF",
                color: "#fff",
                textTransform: "none",
              }}
              onClick={() => this.handleSubmit()}
            >
              Update Profile
            </Button>
          </Box>

          {/* <Button variant="contained" style={{ marginTop: 8 }}>Upload image</Button> */}
        </Paper>
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={true}
        />
        </Box>
      </React.Fragment>
    );
  }
}

export default withRouter(Profile);
