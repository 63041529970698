import React, { Component } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

export class ChatButtonGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const {
      loader,
      loader_llama,
      selectedButtons,
      index,
      message,
      handleSendMessagellamaindex,
      handleSendMessagelangchain,
      selectedLanguageModels,
    } = this.props;
    return (
      <ButtonGroup
        variant="outlined"
        size="small"
        sx={{
          position: "absolute",
          bottom: "0",
          right: "1%",
          transform: "scale(0.65)",
        }}
      >
        {selectedLanguageModels.Llamaindex && (
          <Button
            onClick={(event) =>
              !loader &&
              !loader_llama &&
              handleSendMessagellamaindex(event, index, message)
            }
            sx={{
              borderRadius: "100px",
              textTransform: "none",
              backgroundColor:
                selectedButtons[message.responseID] === "Llamaindex"
                  ? "#6001FF"
                  : "transparent", // Highlighted when Llama is selected
              color:
                selectedButtons[message.responseID] === "Llamaindex"
                  ? "white"
                  : "#6b6f73",
            }}
          >
            Llama
          </Button>
        )}
        {selectedLanguageModels.Langchain && (
          <Button
            onClick={(event) =>
              !loader &&
              !loader_llama &&
              handleSendMessagelangchain(event, index, message)
            }
            sx={{
              borderRadius: "100px",
              textTransform: "none",
              backgroundColor:
                selectedButtons[message.responseID] === "Langchain"
                  ? "#6001FF"
                  : "transparent", // Highlighted when Langchain is selected
              color:
                selectedButtons[message.responseID] === "Langchain"
                  ? "white"
                  : "#6b6f73",
            }}
          >
            Langchain
          </Button>
        )}
      </ButtonGroup>
    );
  }
}

export default ChatButtonGroup;
