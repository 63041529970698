import React, { Component } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Button,
  LinearProgress,
  Divider,
} from "@mui/material";
import { Box, Typography } from "@mui/material";
// import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import { withRouter } from "./withRouter";
import LogoutIcon from "@mui/icons-material/Logout";
import BoltIcon from "@mui/icons-material/Bolt";
import Tooltip from "@mui/material/Tooltip";
import { handleFetchTokenUsage } from "./AdminUtils";
import PersonIcon from "@mui/icons-material/Person";

const formatIndianNumber = (num) => {
  const numString = num.toString();
  const lastThreeDigits = numString.slice(-3);
  const otherDigits = numString.slice(0, -3);
  const formattedOtherDigits = otherDigits.replace(
    /\B(?=(\d{2})+(?!\d))/g,
    ","
  );
  return otherDigits
    ? formattedOtherDigits + "," + lastThreeDigits
    : lastThreeDigits;
};

const formatNumber = (num) => {
  if (num >= 10000000) {
    return (num / 10000000).toFixed(2) + "Cr";
  } else if (num >= 100000) {
    return (num / 100000).toFixed(2) + "L";
  }
  return formatIndianNumber(num);
};
const BASE_URL = process.env.REACT_APP_BASE_URL;
class User extends Component {
  constructor(props) {
    super(props);
    const profileImage = localStorage.getItem("profileImage");
    let parsedProfileImage = null;

    if (profileImage && profileImage !== "undefined") {
      try {
        parsedProfileImage = JSON.parse(profileImage);
      } catch (error) {
        console.error("Error parsing profile image from localStorage", error);
      }
    }
    this.state = {
      anchorEl: null,
      username: localStorage.getItem("username") || "John",
      profileImage: parsedProfileImage,
      email: localStorage.getItem("email") ? localStorage.getItem("email") : "",
    };
  }
  async componentDidMount() {
    const tokenDetails = localStorage.getItem("tokenDetails");
    if (!tokenDetails) {
      await handleFetchTokenUsage();
    }
  }
  handleLogout = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${BASE_URL}/auth/logout`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Attach token using the "Bearer" prefix
        },
      });

      if (response.status === 200) {
        localStorage.removeItem("token"); // Remove token from localStorage
        // You can perform additional actions here, such as updating state or redirecting
      } else if (response.status === 401) {
        // Handle unauthorized error
        console.error("Unauthorized: You are not logged in");
      } else {
        // Handle other status codes
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
    for (let key in localStorage) {
      localStorage.removeItem(key);
    }
    this.props.navigate("/");
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

 
  render() {
    const { anchorEl, username, profileImage } = this.state;
    const open = Boolean(anchorEl);

    // Fetch token details from local storage
    const tokenDetails = localStorage.getItem("tokenDetails");
    let token_usage = 0;
    let total_token_limit = 10000;

    if (tokenDetails) {
      try {
        const tokenDetailsData = JSON.parse(tokenDetails);
        token_usage = tokenDetailsData.token_usage || 0;
        total_token_limit = tokenDetailsData.total_token_limit || 10000;
      } catch (error) {
        console.error("Error parsing token details:", error);
      }
    }
    const tokenPercentage = (token_usage / total_token_limit) * 100;
    const formattedTokenUsage = formatNumber(token_usage);
    const formattedTotalTokenLimit = formatNumber(total_token_limit);
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <IconButton
          aria-label="account"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={this.handleClick}
          color="inherit"
          style={{
            position: "relative",
            top: "50%",
            left: "-5%",
            boxShadow: 2,
          }}
          disableRipple={Boolean(true)}
        >
          {/* <AccountCircleSharpIcon
            style={{ fontSize: "3.75rem", color: "#6001ff" }}
          /> */}
          <Avatar
            style={{ fontSize: "3.75rem" }}
            sx={{ bgcolor: "#6001ff", width: 50, height: 50 }}
          >
            {profileImage ? (
              <img
                src={`data:image/jpeg;base64,${profileImage}`}
                alt="Profile"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <PersonIcon sx={{ width: 40, height: 40 }} />
            )}
          </Avatar>
        </IconButton>
        <Menu
          sx={{ mt: "5%", marginLeft: "-2%" }}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            // style: {
            //   width: "22%",
            //   maxHeight: "50%",
            // },
            style: {
              width: { xs: "90%", sm: "70%", md: "22%" }, 
              maxHeight: { xs: "90%", sm: "70%", md: "22%" },
              overflowY: "auto", //  vertical scrollbar
            },
          }}
        >
          <div style={{ margin: "5% 0% 0% 0%" }}>
            <MenuItem
              sx={{
                "&:hover": { backgroundColor: "transparent" },
                pointerEvents: "none",
              }} // Remove hover effect
              // onClick={this.handleClose}
            >
              {/* <Avatar sx={{ bgcolor: "#6001ff", marginRight: 1 }}>
                {username.charAt(0)}
              </Avatar> */}
              <Avatar
                sx={{ marginRight: 2 }}
                style={{ backgroundColor: "#6001FF" }}
              >
                {profileImage ? (
                  <img
                    src={`data:image/jpeg;base64,${profileImage}`}
                    alt="Profile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <PersonIcon />
                )}
              </Avatar>
              <Typography
                variant="h6"
                style={{
                  fontSize: "1.4rem",
                  fontFamily: "Poppins, sans-serif",
                  color: "#6b6f73",
                  fontWeight: 500,
                }}
              >
                {username}
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem
              // onClick={this.handleClose}
              sx={{
                "&:hover": { backgroundColor: "transparent" },
                pointerEvents: "none",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    mt: 1,
                  }}
                >
                  {/* <span>
                  Token Remaining
                  <BoltIcon fontSize="1rem" sx={{ color: "#E0E0E0" }} />{" "}
                    </span>
                  <span>{`${token_usage}/${total_token_limit}`}</span> */}
                  <span>
                    Token Remaining{" "}
                    <BoltIcon fontSize="1rem" sx={{ color: "#E0E0E0" }} />{" "}
                  </span>
                  <Tooltip
                    title={formatIndianNumber(token_usage)}
                    placement="top-start"
                    arrow
                  >
                    <span>{formattedTokenUsage}</span>
                  </Tooltip>
                  <span>&nbsp;/&nbsp;</span>
                  <Tooltip
                    title={formatIndianNumber(total_token_limit)}
                    placement="top-start"
                    arrow
                  >
                    <span>{formattedTotalTokenLimit}</span>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    mt: 1,
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    // value={tokenPercentage}
                    value={Math.min(100, tokenPercentage)}
                    sx={{ flexGrow: 1, 
                       "& .MuiLinearProgress-bar": {
                      backgroundColor: "#6001ff",
                    },
                   }}
                  >
                    <Box
                      sx={{
                        // width: `${tokenPercentage}%`,
                        width: `${Math.min(100, tokenPercentage)}%`, // Ensure the width is capped at 100
                        height: "100%",
                        backgroundImage: `linear-gradient(90deg, #6001ff ${Math.min(
                          100,
                          tokenPercentage
                        )}%, transparent ${Math.min(100, tokenPercentage)}%)`,
                      }}
                    />
                  </LinearProgress>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ marginLeft: "5%" }}
                  >
                    {/* {`${Math.round(tokenPercentage)}%`} */}
                    {`${Math.min(100, Math.round(tokenPercentage))}%`}{" "}
                    {/* Ensure the displayed percentage is capped at 100 */}
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            <Divider />
            <MenuItem sx={{ "&:hover": { backgroundColor: "transparent" } }}>
              <Button
                variant="text"
                // size="large"
                // color="primary"
                onClick={this.handleLogout}
                style={{ color: "#6001ff" }}
                endIcon={<LogoutIcon size="large" />}
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                Logout
              </Button>
            </MenuItem>
          </div>
        </Menu>
      </div>
    );
  }
}

export default withRouter(User);
