import React, { Component } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import WaterLoader from "./waterLoader";
export class LoaderMessages extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const { loader_Messages } = this.props;
    return (
      <>
        <div
          style={{
            background: "#FFFFFF",
            borderRadius: "40px",
            padding: "10px 10px 10px 10px",
            width: "95%",
            marginLeft: "20px",
            // marginTop: "20px",
          }}
        >
          <ListItem>
            <ListItemAvatar>
              <WaterLoader />
            </ListItemAvatar>
            <ListItemText
              //secondary="working on it ..."
              secondary={`${loader_Messages}`}
              style={{
                marginLeft: "2%",
                fontSize: "1.25rem",
                color: "#818589",
              }}
              disableTypography
            />
          </ListItem>
        </div>
      </>
    );
  }
}

export default LoaderMessages;
