import React, { Component } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Botimageicon from "./botimageicon";
// import AvatarProfile from "./AvatarProfile";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { Button, Tooltip, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress for the loader

// import { withRouter } from "../";
import Utils from "../userComponents/Utils";
const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
    "& .MuiInputBase-input": {
      padding: "8px", // Adjust the padding to reduce the height
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};
const selectFieldSx = {
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
  },
  "& .MuiMenuItem": {
    maxHeight: "70px", // Set the maximum height for the dropdown
    maxWidth: "100px",
  },
  "& .MuiMenuItem-root": {
    padding: "4px 10px", // Adjust the padding to reduce the height
  },
};
const BASE_URL = process.env.REACT_APP_BASE_URL;
// const application_code = "ZN002";
export class HeaderText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectInfo:  [],
      selected:  "",
      summary:  "",
      filenameAndSummary:  {},
      showLoader: false,

    };
  }

  componentDidMount() {
  
    const filenamesAndSummary = localStorage.getItem("filenameAndSummary")
      ? JSON.parse(localStorage.getItem("filenameAndSummary"))
      : {};
    const filenames = Object.keys(filenamesAndSummary);
    const firstSummary = Object.values(filenamesAndSummary);
    this.setState(
      {
        filenameAndSummary: filenamesAndSummary,
        selectInfo: filenames,
        selected: filenames[0],
        summary: firstSummary[0],
      },
      () => {
        // console.log("selectInfo", this.state.selectInfo);
      }
    );
  }
  handleDropDown = (filename) => {
    const updatedFilenameAndSummary = { ...this.state.filenameAndSummary };
    const pdfSummary = updatedFilenameAndSummary[filename];
    this.setState({ selected: filename, summary: pdfSummary });
  };
  generateSummary = async (selected) => {
    const filename = selected;
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }

    try {
      // Show the loader while the update is in progress
      this.setState({ showLoader: true });
      const response = await fetch(
        `${BASE_URL}/zn002/bot/get_file_summary/${filename}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const responseData = await response.json(); // Parse the response as JSON
        const updatedFilenameAndSummary = { ...this.state.filenameAndSummary };
        updatedFilenameAndSummary[filename] = responseData.file_summary;
        this.setState(
          {
            summary: responseData.file_summary,
            filenameAndSummary: updatedFilenameAndSummary,
          },
          () => {
            // console.log("filenameAndSummaryHeadertext",this.state.filenameAndSummary);
            localStorage.setItem(
              "filenameAndSummary",
              JSON.stringify(this.state.filenameAndSummary)
            );
          }
        );

      } else {
        console.error("Server response was not OK. Status:", response.status);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.error("Error fetching data:", error);
    } finally {
      // Hide the loader when the update is complete (whether it succeeds or fails)
      this.setState({ showLoader: false });
    }
  };
  render() {
    const { selectInfo, selected, summary, showLoader } = this.state;
    const { profilePicDocs, assistantNameDocs } = this.props;

    return (
      <div
        className={` ZUNO`}
        style={{
          background: "#FFFFFF",
          borderRadius: "40px",
          padding: "10px 10px 10px 10px",
          width: "95%",
          marginLeft: "20px",
          marginTop: "20px",
        }}
      >
        <ListItem>
          <ListItemAvatar sx={{ marginRight: "1%" }}>
            {profilePicDocs ? (
              <Stack direction="row" spacing={2} sx={{ marginRight: "5px" }}>
                <Avatar
                  alt="profilePic"
                  src={profilePicDocs}
                  sx={{ width: 56, height: 56 }}
                />
              </Stack>
            ) : (
              <Botimageicon />
            )}
          </ListItemAvatar>
          <div style={{ fontSize: "1.25rem", color: "#818589" }}>
            <p>
              Hello! I'm {assistantNameDocs}, your smart and helpful data
              analyst. I'm here to assist you in extracting valuable insights
              and data from PDF and word documents.
            </p>

            <p>Not sure where to start? You can try:</p>
            <ul>
              <li>
                Can you find information about the product specifications in
                this manual?
              </li>
              <li>
                What are the key differences between the two plans outlined in
                this document?{" "}
              </li>
              <li>Can you summarize the key points in this document?</li>
            </ul>
            {selectInfo.length > 0 && (
              <Box style={{ display: "flex", marginBottom: "10px" }}>
                <Tooltip title={selected} arrow placement="top">
                  <TextField
                    sx={{
                      color: "#6001FF",
                      width: "175px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#F3F6FD",
                      paddingBottom: "2px",
                      marginRight: "10px",
                      // border:"2px solid red",
                      ...textFieldSx,
                    }}
                    label="PDF Name"
                    variant="outlined"
                    value={selected}
                    onChange={(e) => this.handleDropDown(e.target.value)}
                    select
                    fullWidth
                    InputProps={{
                      sx: {
                        height: "40px", // Adjust the height of the TextField
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        lineHeight: "1.0", // Adjust the line height of the label
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        PaperProps: {
                          style: {
                            maxHeight: "200px", 
                            overflowY: 'auto', 
                           
                            marginLeft: "2px", 
                          },
                        },
                      },
                    }}
                  >
                    {selectInfo.map((item) => {
                      return (
                        <MenuItem
                          sx={{ ...selectFieldSx }}
                          value={item}
                          key={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Tooltip>
                {!summary && (
                  <Button
                    onClick={() => this.generateSummary(selected)}
                    style={{
                      textTransform: "none",
                      color: "#FFF",
                      backgroundColor: "#6001FF",
                      maxHeight: "38px",
                      marginLeft: "20px",
                    }}
                  >
                    {showLoader ? (
                      <CircularProgress
                        size={25}
                        sx={{
                          color: "#FFFFFF",
                          marginLeft: "-7%",
                        }}
                      />
                    ) : (
                      "Generate"
                    )}
                  </Button>
                )}
              </Box>
            )}

            {selectInfo.length > 0 && (
              <span style={{ marginTop: "10px" }}>
                Summary
                <br />
                {summary}
              </span>
            )}
          </div>
        </ListItem>
      </div>
    );
  }
}
export default HeaderText;
