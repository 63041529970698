import React, { Component } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton } from "@mui/material";
import Button from "@mui/material/Button";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import LinearProgressLabel from "./LinearProgressLabel";
export class FileUploadCSV extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef=React.createRef();

    this.state = {};
  }

  handleAddMoreFiles = () => {
    this.fileInputRef.value = null;
    this.fileInputRef.click();
  };
  render() {
    const {
      connecting,
      selectedFiles,
      progress,
      showAddMoreButton,
      next,
      handleUpload,
      handleFileChange,
     
      cancelUpload,
      handleDeleteFile,
      handleNextClick,
    } = this.props;
    return (
      <>
        <div>
          <h2>{!connecting ? "File Upload" : "Uploading Files"}</h2>
          <form onSubmit={handleUpload}>
            <input
              type="file"
              multiple
              style={{ display: "none" }}
              onChange={ handleFileChange}
              ref={(ref) => (this.fileInputRef = ref)}
            />
            {selectedFiles.length >= 0 && !connecting && (
              <Button onClick={()=>this.handleAddMoreFiles()}>
                {showAddMoreButton ? "Add More Files" : "Add File"}
              </Button>
            )}
            &nbsp;&nbsp;
            {!connecting && (
              <Button
                variant="contained"
                color="error"
                onClick={ handleUpload}
                disabled={selectedFiles.length === 0}
                style={{
                  backgroundColor:
                    selectedFiles.length === 0 ? "initial" : "#6001FF",
                  color: selectedFiles.length === 0 ? "#6001FF" : "#ffffff",
                }}
              >
                Upload Files
              </Button>
            )}
            {connecting && (
              <div style={{ margin: "5px" }}>
                {" "}
                <LinearProgressLabel value={progress} />
              </div>
            )}
            {connecting && (
              <div>
                {/* <p>{message}</p> onClick={this.props.handleCloseFileUpload} */}
                <Button
                  variant="contained"
                  color="success"
                  onClick={cancelUpload}
                  style={{
                    backgroundColor: "#6001FF",
                    color: "#ffffff",
                    marginRight: "10%",
                    marginTop: "5%",
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
            {!connecting && (
              <div>
                {" "}
                <h3>Selected Files:</h3>
                {selectedFiles.length > 4 ? (
                  <List>
                    <div style={{ height: "260px", overflow: "auto" }}>
                      {selectedFiles.map((file, index) => (
                        <ListItem key={index} disablePadding>
                          <ListItemButton>
                            <ListItemIcon>
                              <InsertDriveFileOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={file.name} />
                            <ListItemIcon
                              onClick={() => handleDeleteFile(index)}
                            >
                              <DeleteIcon sx={{ color: "#6001FF" }} />
                            </ListItemIcon>
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </div>
                  </List>
                ) : (
                  <List>
                    {selectedFiles.map((file, index) => (
                      <ListItem key={index} disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <InsertDriveFileOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary={file.name} />
                          <ListItemIcon onClick={() => handleDeleteFile(index)}>
                            <DeleteIcon sx={{ color: "#6001FF" }} />
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                )}
              </div>
            )}
            {next && !connecting && (
              <div>
                {/* <p>{message}</p> */}
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleNextClick}
                  style={{ backgroundColor: "#6001FF", color: "#ffffff" }}
                >
                  Next
                </Button>
              </div>
            )}
          </form>
        </div>
      </>
    );
  }
}

export default FileUploadCSV;
