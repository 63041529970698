import React, { Component } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { InputAdornment } from "@mui/material";

import {
  ListItemIcon,
  IconButton,
  Menu,
  MenuItem,
  Button,
  InputBase,
  TablePagination,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import LockResetIcon from "@mui/icons-material/LockReset";
import ReplyIcon from '@mui/icons-material/Reply';
import Tooltip from "@mui/material/Tooltip";
import UserForm from "./UserForm";
import Utils from "../userComponents/Utils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};
const statusObj = {
  active: { color: "success" },
  inactive: { color: "error" },
};
const BASE_URL = process.env.REACT_APP_BASE_URL;
class DashboardTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: this.props.userDetails,
      token: localStorage.getItem("token"),
      anchorEl: null,
      selectedIndex: -1,
      currentPageUsers: 1,
      usersPerPage: 10,
      searchQuery: "",
      isAddFormOpen: false,
      isEditFormOpen: false,
      isUserGroupsFormOpen: false,
      isResetFormOpen: false,
      newpassword: "",
      currentLoggedInUserRole:
        JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))[
          "role"
        ] || "",
      currentLoggedInUserPermissions:
        JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))[
          "userPermissions"
        ] || {},
      showPassword: false,
      errors: {
        newpassword: "",
      },
      isPasswordInputNotEmpty: false,
      isValidForm: false,
    };
  }

  handleClick = (event, index, row) => {
    const { currentLoggedInUserPermissions, currentLoggedInUserRole } =
      this.state;
    const isEditable =
      ((currentLoggedInUserPermissions["Add or Delete Users"] ||
        currentLoggedInUserPermissions["Add or Delete Admins"]) &&
        (currentLoggedInUserPermissions["Add or Delete Admins"] ||
          row.role !== "admin") &&
        row.role !== "superadmin") ||
      currentLoggedInUserRole === "superadmin";
    if (isEditable) {
      this.setState({
        anchorEl: event.currentTarget,
        selectedIndex: index,
      });
    } else {
      toast.error(`You cannot edit the ${row.role}`);
    }
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      selectedIndex: -1,
    });
  };

  handleAddUserClick = () => {
    this.setState({ isAddFormOpen: true });
  };

  handleCloseForm = () => {
    this.setState({
      isAddFormOpen: false,
    });
  };

  handleEditUserClick = () => {
    this.setState({ isEditFormOpen: true });
  };

  handleCloseEditForm = () => {
    this.setState({
      isEditFormOpen: false,
      anchorEl: null, // Close the options menu as well
      selectedIndex: -1, // Reset the selectedIndex to -1
    });
  };

  handleResetPassword = () => {
    this.setState({ isResetFormOpen: true });
  };

  handleCloseResetForm = () => {
    this.setState({
      isResetFormOpen: false,
      anchorEl: null, // Close the options menu as well
      selectedIndex: -1, // Reset the selectedIndex to -1
      newpassword: "",
      showPassword: false,
      isPasswordInputNotEmpty: false,
    });
  };
  updateUserDetails = (updatedDetails, isEditMode) => {
    if (isEditMode) {
      toast.success("User details updated successfully.");
    } else {
      toast.success("User created successfully.");
    }
    this.setState({ userDetails: updatedDetails });
    this.props.updateUserDetails(updatedDetails);
  };

  handleResetFormSubmit = async (event) => {
    event.preventDefault();
    const { isValidForm } = this.state;

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }
    if (!isValidForm) {
      return;
    }
    const formData = new FormData(event.currentTarget);

    try {
      const response = await fetch(`${BASE_URL}/auth/reset_password`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.status === 200) {
        toast.success("Reset Password Successfull");
      } else {
        toast.error("Resetting user password failed");
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      this.handleCloseResetForm();
    }
  };
  handleResendActivationLink = async (userData) => {
    const token = localStorage.getItem("token");
    const formData = {
      email: userData.email,
      organization: userData.organization,
  };
  try{
    const response = await fetch(`${BASE_URL}/user/resendActivationLink`,{
      method:"POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData)
    });
    if (response.status === 401) {
      Utils.removeLocalStorage();
      window.location.href = "/";
    }
    if (response.ok) {
      // const result = await response.json();
      toast.success("Activation link sent successfully")
    } else{
      toast.success("Resending activation link failed. Try later!")
    }
  } catch(error){
    console.log(error)
  } finally {
    this.handleCloseResetForm();
  }
  }
  handleDeleteUser = async (email) => {
    // write backend logic
    const response = await fetch(`${BASE_URL}/admin/delete_user/${email}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${this.state.token}`,
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      const updatedUserDetails = this.props.userDetails.filter(
        (user) => user.email !== email
      );
      this.props.updateUserDetails(updatedUserDetails);
      this.setState({ userDetails: updatedUserDetails });
      toast.success("User Deleted Successfully.");
    } else {
      toast.error("User Deletion Successfully.");
    }
  };
  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handlePasswordChange = (event) => {
    const { name, value } = event.target;
    const password_regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,}$/;
    if (!password_regex.test(value)) {
      this.setState((prevState) => ({
        isPasswordInputNotEmpty: value.length > 0,
        isValidForm: false,
        [name]: value,
        errors: {
          ...prevState.errors,
          newpassword:
            "Password must be at least 10 characters and it should contain at least one uppercase letter, one lowercase letter, one digit, and one special character.",
        },
      }));
    } else {
      this.setState((prevState) => ({
        isPasswordInputNotEmpty: value.length > 0,
        isValidForm: true,
        [name]: value,
        errors: { ...prevState.errors, newpassword: "" },
      }));
    }
  };

  handlePasswordVisibilityToggle = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  render() {
    const rows = this.props.userDetails;
    const {
      anchorEl,
      selectedIndex,
      currentPageUsers,
      usersPerPage,
      searchQuery,
      isAddFormOpen,
      isEditFormOpen,
      isResetFormOpen,
      newpassword,
      showPassword,
      isPasswordInputNotEmpty,
    } = this.state;
    const { currentLoggedInUserPermissions, currentLoggedInUserRole } =
      this.state;
    const startIndexUser = (currentPageUsers - 1) * usersPerPage;
    const endIndexUser = startIndexUser + usersPerPage;
    return (
      <Card elevation={5} style={{ borderRadius: "20px" }}>
        <TableContainer sx={{ width: "1289px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F3F6FD",
                borderRadius: "25px",
                padding: "5px",
                marginRight: "3%",
              }}
            >
              <SearchIcon
                sx={{
                  color: "#6001FF",
                  marginLeft: "10px",
                  marginRight: "5px",
                }}
              />
              <InputBase
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => this.setState({ searchQuery: e.target.value })}
                sx={{
                  color: "#6001FF",
                  width: "40%",
                  backgroundColor: "#F3F6FD",
                  borderRadius: "25px",
                }}
              />
            </Box>
            {(currentLoggedInUserPermissions["Add or Delete Users"] ||
              currentLoggedInUserPermissions["Add or Delete Admins"] ||
              currentLoggedInUserRole === "superadmin") && ( // Based on access
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                style={{
                  backgroundColor: "#6001FF",
                  color: "#fff",
                  marginRight: "35px",
                }}
                onClick={this.handleAddUserClick} // handle Adding user data
              >
                Add User
              </Button>
            )}
            <UserForm
              open={isAddFormOpen}
              handleClose={this.handleCloseForm}
              userDetails={this.props.userDetails}
              updateUserDetails={this.updateUserDetails}
              isEditMode={false}
            />
          </div>
          <TableContainer>
            <Table aria-label="table in dashboard">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Email</b>
                  </TableCell>
                  <TableCell>
                    <b>Last Login Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Organization</b>
                  </TableCell>
                  <TableCell>
                    <b>Department</b>
                  </TableCell>
                  <TableCell>
                    <b>Designation</b>
                  </TableCell>
                  <TableCell>
                    <b>Role</b>
                  </TableCell>
                  <TableCell>
                    <b>Tokens Usage</b>
                  </TableCell>
                  <TableCell>
                    <b>Total Messages</b>
                  </TableCell>
                  <TableCell>
                    <b>Total Cost</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .filter((row) =>
                    row.username
                      .toLowerCase()
                      .includes(searchQuery.trim().toLowerCase())
                  )
                  .slice(startIndexUser, endIndexUser)
                  .map((row, index) => (
                    <TableRow
                      hover
                      key={row.email}
                      sx={{
                        "&:last-of-type td, &:last-of-type th": { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{
                          py: (theme) => `${theme.spacing(0.5)} !important`,
                        }}
                      >
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "0.875rem !important",
                            }}
                          >
                            {row.username}
                          </Typography>
                          {/* <Typography variant='caption'>{row.designation}</Typography> */}
                        </Box>
                      </TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.last_active_date}</TableCell>
                      <TableCell>{row.organization}</TableCell>
                      <TableCell>{row.department}</TableCell>
                      <TableCell>{row.designation}</TableCell>
                      <TableCell>{row.role}</TableCell>
                      <TableCell>{row.token_used}</TableCell>
                      <TableCell>
                        <Tooltip
                          title={
                            <div style={{ fontSize: "0.9rem" }}>
                              <div>
                                SQL Message:{" "}
                                {
                                  row.message_count_by_each_application
                                    .SQL_message
                                }
                              </div>
                              <div>
                                CSV Message:{" "}
                                {
                                  row.message_count_by_each_application
                                    .CSV_message
                                }
                              </div>
                              <div>
                                QA Message:{" "}
                                {
                                  row.message_count_by_each_application
                                    .QA_message
                                }
                              </div>
                              <div>
                                HR Message:{" "}
                                {
                                  row.message_count_by_each_application
                                    .HR_message
                                }
                              </div>
                              <div>
                                WEB Message:{" "}
                                {
                                  row.message_count_by_each_application
                                    .WEB_message
                                }
                              </div>
                            </div>
                          }
                          placement="top"
                          arrow
                        >
                          <span>{row.total_messages}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={
                            <div style={{ fontSize: "0.9rem" }}>
                              <div>
                                SQL Cost:{" "}
                                {row.token_cost_by_each_application[
                                  "sql cost"
                                ].toFixed(3)}
                              </div>
                              <div>
                                CSV Cost:{" "}
                                {row.token_cost_by_each_application[
                                  "csv cost"
                                ].toFixed(3)}
                              </div>
                              <div>
                                QA Cost:{" "}
                                {row.token_cost_by_each_application[
                                  "document cost"
                                ].toFixed(3)}
                              </div>
                            </div>
                          }
                          placement="top"
                          arrow
                        >
                          <span>{row.total_token_cost.toFixed(3)}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={row.status}
                          color={statusObj[row.status].color}
                          variant="outlined"
                          sx={{
                            height: 24,
                            fontSize: "0.75rem",
                            textTransform: "capitalize",
                            "& .MuiChip-label": { fontWeight: 500 },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="More options"
                          aria-controls="options-menu"
                          aria-haspopup="true"
                          onClick={(event) =>
                            this.handleClick(event, index, row)
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          sx={{ marginLeft: "-5%" }}
                          id="option-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl) && selectedIndex === index}
                          onClose={this.handleClose}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          PaperProps={{
                            style: {
                              width: "14%",
                            },
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              this.handleEditUserClick();
                            }}
                          >
                            <ListItemIcon>
                              <EditIcon />
                            </ListItemIcon>
                            Edit user
                          </MenuItem>
                          <UserForm
                            open={isEditFormOpen}
                            handleClose={this.handleCloseEditForm}
                            userDetails={this.props.userDetails}
                            updateUserDetails={this.updateUserDetails}
                            isEditMode={true}
                            currentEmail={row.email}
                            initialName={row.username} // Pass the initial username to edit
                            initialEmail={row.email} // Pass the initial email to edit
                            initialOrganization={row.organization}
                            initialDesignation={row.designation}
                            initialDepartment={row.department}
                            initialRole={row.role}
                            initialStatus={row.status}
                            initialTokenLimit={row.token_limit}
                            initialAssignedApplications={
                              row.assigned_applications
                            }
                            userGroupPermissions={row.user_permissions}
                            isEmailVerified={row.isEmailVerified}
                          />
                          { !row.isEmailVerified  && (<MenuItem
                            onClick={() => {
                              this.handleResendActivationLink(row);
                            }}
                          >
                            <ListItemIcon>
                              <ReplyIcon />
                            </ListItemIcon>
                            Resend Activation link
                          </MenuItem>)}
                          {currentLoggedInUserPermissions[
                            "Create and Reset Password"
                          ] && (
                            <MenuItem
                              onClick={() => {
                                this.handleResetPassword(row.email);
                              }}
                            >
                              <ListItemIcon>
                                <LockResetIcon />
                              </ListItemIcon>
                              Reset Password
                            </MenuItem>
                          )}
                          <Dialog
                            open={isResetFormOpen}
                            onClose={this.handleCloseResetForm}
                            aria-labelledby="reset-modal"
                            aria-describedby="reset-password-modal"
                            maxWidth="sm" // Set a fixed max width
                            fullWidth // Ensure the dialog uses the full width
                          >
                            <form onSubmit={this.handleResetFormSubmit}>
                              <DialogTitle>Reset Password</DialogTitle>
                              <DialogContent>
                                <TextField
                                  label="Email"
                                  name="userEmail"
                                  value={row.email}
                                  aria-readonly
                                  fullWidth
                                  margin="normal"
                                  sx={textFieldSx}
                                />
                                <TextField
                                  label="New Password"
                                  name="newpassword"
                                  type={showPassword ? "text" : "password"}
                                  value={newpassword}
                                  onChange={(event) =>
                                    this.handlePasswordChange(event)
                                  }
                                  fullWidth
                                  margin="normal"
                                  sx={textFieldSx}
                                  autoComplete="current-newpassword"
                                  error={!!this.state.errors.newpassword}
                                  helperText={
                                    this.state.errors.newpassword || ""
                                  } // Reserve space for helper text
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {isPasswordInputNotEmpty && (
                                          <Button
                                            onClick={() =>
                                              this.handlePasswordVisibilityToggle()
                                            }
                                            style={{
                                              minWidth: "unset",
                                              padding: 0,
                                              color: "#6001FF",
                                            }}
                                          >
                                            {showPassword ? (
                                              <VisibilityOutlinedIcon />
                                            ) : (
                                              <VisibilityOffOutlinedIcon />
                                            )}
                                          </Button>
                                        )}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </DialogContent>
                              <DialogActions>
                                <Typography
                                  variant="body1"
                                  color="primary"
                                  style={{
                                    color: "#6001FF",
                                    marginRight: "2.5%",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => this.handleCloseResetForm()}
                                >
                                  Cancel
                                </Typography>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{
                                    backgroundColor: "#6001FF",
                                    color: "#fff",
                                    marginRight: "3.5%",
                                    textTransform: "none",
                                  }}
                                  type="submit"
                                >
                                  Reset Password
                                </Button>
                              </DialogActions>
                            </form>
                          </Dialog>
                          <MenuItem
                            onClick={() => {
                              this.handleDeleteUser(row.email); // to delete user
                              this.handleClose();
                            }}
                          >
                            <ListItemIcon>
                              <DeleteIcon />
                            </ListItemIcon>
                            Delete user
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rows.length}
            page={currentPageUsers - 1}
            onPageChange={(_, newPage) => {
              const startIndex = newPage * usersPerPage;
              const endIndex = startIndex + usersPerPage;
              this.setState({
                currentPageUsers: newPage + 1,
                startIndexUser: startIndex,
                endIndexUser: endIndex,
              });
            }}
            rowsPerPage={usersPerPage}
            onRowsPerPageChange={(event) => {
              const newRowsPerPage = parseInt(event.target.value, 10);
              this.setState({
                usersPerPage: newRowsPerPage,
                currentPageUsers: 1,
              });
            }}
          />
        </TableContainer>
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={true}
        />
      </Card>
    );
  }
}

export default DashboardTable;
