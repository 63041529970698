import React, { Component } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import { withRouter } from "./withRouter";
import Snackbar from "@mui/material/Snackbar";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Button from "@mui/material/Button";
import login_bg from "../assets/images/png/login-bg.jpg";

const defaultTheme = createTheme();
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BASE_URL = process.env.REACT_APP_BASE_URL;
const GOOGLE_RECAPTCHA_SITE_KEY =
  process.env.REACT_APP_RECAPTCHA_CLIENT_SITE_KEY;

const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff",
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSnackbar: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
      username: localStorage.getItem("username") || "John",
      email: localStorage.getItem("email") || "",
      password: localStorage.getItem("password"),
      loading: false,
      showPassword: false,
      isPasswordInputNotEmpty: false,
    };
  }

  handlePasswordVisibilityToggle = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handlePasswordInputChange = (event) => {
    const { value } = event.target;
    this.setState({
      isPasswordInputNotEmpty: value.length > 0,
    });
  };

  handleSnackbarOpen = (severity, message) => {
    this.setState({
      showSnackbar: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({ showSnackbar: false });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
  
    const formData = {
      email: event.currentTarget.email.value,
      password: event.currentTarget.password.value,
    };
  
    if (!formData.email || !formData.password) {
      this.setState({ error: "Please enter both email and password." });
      return;
    }
  
    this.setState({ loading: true });
  
    try {
      // Get reCAPTCHA token
      const token = await window.grecaptcha.execute(GOOGLE_RECAPTCHA_SITE_KEY, {
        action: "submit",
      });
  
      // Perform login request
      const response = await fetch(`${BASE_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, recaptchaToken: token }),
      });
  
      if (response.ok) {
        const result = await response.json();
        localStorage.setItem("token", result.token);
        localStorage.setItem("username", result.username);
        localStorage.setItem("email", result.email);
        localStorage.setItem("currentLoggedInUserDetails", JSON.stringify(result));
        this.props.navigate("/home");
      } else {
        // Handle errors based on status code
        const backendError = await response.json();
        if (response.status === 500) {
          this.handleSnackbarOpen(
            "error",
            "Login failed. Please check your credentials or Contact Administrator"
          );
          this.setState({
            error: "Invalid email or password. or Contact Administrator",
          });
        } else {
          // Display specific backend error message
          this.handleSnackbarOpen("error", backendError.detail || "Login failed.");
          this.setState({
            error: backendError.detail || "An error occurred during login.",
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      this.handleSnackbarOpen("error", "An error occurred while logging in.");
      this.setState({ error: "An error occurred during login." });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      showSnackbar,
      snackbarSeverity,
      snackbarMessage,
      loading,
      showPassword,
      isPasswordInputNotEmpty,
    } = this.state;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Container fixed>
          <ThemeProvider theme={defaultTheme}>
            <Grid
              container
              component="main"
              style={{ height: "60vh", borderRadius: "10px" }}
            >
              <CssBaseline />

              <Grid
                item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={6}
                style={{ height: "70vh", borderRadius: "10px" }}
              >
                <Box
                  style={{
                    my: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <Typography
                    component="h1"
                    variant="h5"
                    style={{ marginTop: "60px" }}
                  >
                    <b style={{ color: "#6001FF" }}>Sign In</b>
                  </Typography>
                  <br />
                  <Box
                    component="form"
                    noValidate
                    onSubmit={this.handleSubmit}
                    style={{ mt: 1 }}
                  >
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      sx={textFieldSx}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      autoComplete="current-password"
                      onChange={this.handlePasswordInputChange}
                      sx={textFieldSx}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {isPasswordInputNotEmpty && (
                              <Button
                                onClick={this.handlePasswordVisibilityToggle}
                                style={{
                                  minWidth: "unset",
                                  padding: 0,
                                  color: "#6001FF",
                                }}
                              >
                                {showPassword ? (
                                  <VisibilityOutlinedIcon />
                                ) : (
                                  <VisibilityOffOutlinedIcon />
                                )}
                              </Button>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <br />
                    <br />
                    <span>New User? </span>
                    <Link
                      to="/signup"
                      variant="body2"
                      style={{ fontSize: "1rem", color: "#6001FF" }}
                    >
                      {"Create account"}
                    </Link>
                    <br />
                    <br />
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      loading={loading}
                      loadingPosition="end"
                      sx={{ "& .MuiCircularProgress-svg": { color: "#FFFFF" } }}
                      style={{
                        mt: 3,
                        mb: 2,
                        fontSize: "1rem",
                        height: "50px",
                        backgroundColor: "#6001FF",
                        color: "#FFFFFF",
                      }}
                      endIcon={<ArrowForwardIcon />}
                    >
                      Sign IN
                    </LoadingButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Container>
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          style={{
            backgroundImage: `url(${login_bg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "50%",
            height: "75%",
            position: "absolute",
            marginLeft: "50%",
          }}
        />
        <Snackbar
          open={showSnackbar}
          autoHideDuration={5000}
          onClose={this.handleSnackbarClose}
        >
          <Alert
            onClose={this.handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default withRouter(Login);
