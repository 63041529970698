import React, { Component } from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  TablePagination,
} from "@mui/material";
import { withRouter } from "./withRouter";
import {
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Divider,
  Box,
  InputBase,
  Tooltip,
} from "@mui/material";
import BookIcon from "@mui/icons-material/Book";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import Utils from "../userComponents/Utils";
import ViewBookmark from "./ViewBookmark";
const BASE_URL = process.env.REACT_APP_BASE_URL;

class BookMark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      deleteDialogOpen: false,
      selectedIndex: -1,
      searchQuery: "",
      popupOpen: false,
      bookmarkedMessages: [], // Initialize an empty array for bookmarks
      showSnackbar: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
      currentPageBookmarks: 1,
      itemsPerPageBookmarks: 10,
      viewBookmark: false,
      currentViewingBookmark: null,
    };
  }

  handleClick = (event, index) => {
    event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget,
      selectedIndex: index,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      selectedIndex: -1,
    });
  };

  componentDidMount() {
    // Attach a click event listener to the document body to handle clicks outside the popup
    document.body.addEventListener("click", this.handleDocumentClick);
    this.fetchBookmarks();
  }

  componentWillUnmount() {
    // Remove the click event listener when the component is unmounted
    document.body.removeEventListener("click", this.handleDocumentClick);
  }
  handleSnackbarOpen = (severity, message) => {
    this.setState({
      showSnackbar: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({ showSnackbar: false });
  };
  handleDocumentClick = (event) => {
    // Close the popup if clicked outside of it
    if (this.state.popupOpen && !this.state.anchorEl.contains(event.target)) {
      this.handleMoreVertClose();
    }
  };

  getbookmarkedMessages = () => {
    const { bookmarkedMessages } = this.state;

    // Check if bookmarkedMessages is defined and is an array
    if (bookmarkedMessages && Array.isArray(bookmarkedMessages)) {
      return bookmarkedMessages;
    } else {
      // Handle the case where bookmarkedMessages is undefined or not an array
      return [];
    }
  };

  handleDeleteClick = () => {
    this.handleMoreVertClose();
    this.setState({ deleteDialogOpen: true });
  };

  handleDeleteConfirm = () => {
    const { bookmarkedMessages, selectedIndex } = this.state;
    const updatedMessages = [...bookmarkedMessages];
    updatedMessages.splice(selectedIndex, 1);
    this.setState({
      bookmarkedMessages: updatedMessages,
      deleteDialogOpen: false,
    });
    localStorage.setItem("bookmarkedMessages", JSON.stringify(updatedMessages));
  };

  handleDeleteCancel = () => {
    this.setState({ deleteDialogOpen: false });
  };

  handleDeleteBookmark = async (bookmarkID) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("token not found in localStorage");
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      this.handleClose();
      const userDetails = localStorage.getItem("currentLoggedInUserDetails");
      const userDetailsString = JSON.parse(userDetails);

      const organization = userDetailsString.organization;
      const response = await fetch(
        `${BASE_URL}/zn004/user/remove_bookmark/${bookmarkID}?userOrganization=${encodeURIComponent(
          organization
        )}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        this.handleSnackbarOpen("success", "Bookmark deleted successfully.");
        this.fetchBookmarks();
      }
    } catch (error) {
      console.log(error);
    }
  };

  fetchBookmarks = async () => {
    try {
      const token = localStorage.getItem("token");
      const userDetails = localStorage.getItem("currentLoggedInUserDetails");
      const userDetailsString = JSON.parse(userDetails);

      const organization = userDetailsString.organization;
      const response = await fetch(
        `${BASE_URL}/zn004/user/get_bookmarks/${organization}?&application_code=${encodeURIComponent(
          "ZN004"
        )}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const bookmarks = data.bookmarks_data;
        console.log(bookmarks);
        // Do something with the bookmarks, e.g., update state
        this.setState({ bookmarkedMessages: bookmarks });
      } else {
        // Handle error responses here
        console.error("Failed to fetch bookmarks");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  formatTimeDifferenceToNow = (uploadedDate) => {
    const currentDate = new Date();
    const bookmarkDate = new Date(uploadedDate);
    const timeDifference = currentDate - bookmarkDate;

    // Define time intervals in milliseconds
    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;
    // const month = 30 * day;

    if (timeDifference < minute) {
      return "Just now";
    } else if (timeDifference < hour) {
      const minutesAgo = Math.floor(timeDifference / minute);
      return `${minutesAgo} minute${minutesAgo > 1 ? "s" : ""} ago`;
    } else if (timeDifference < day) {
      const hoursAgo = Math.floor(timeDifference / hour);
      return `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
    } else {
      return uploadedDate.toString();
    }
    // else if (timeDifference < month) {
    //   const daysAgo = Math.floor(timeDifference / day);
    //   return `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`;
    // } else {
    //   const monthsAgo = Math.floor(timeDifference / month);
    //   return `${monthsAgo} month${monthsAgo > 1 ? "s" : ""} ago`;
    // }
  };
  handleshowBookmark = (bookmarkData) => {
    this.setState({
      viewBookmark: true,
      currentViewingBookmark: bookmarkData,
    });
    this.handleClose();
  };

  handleCloseShowBookmark = () => {
    this.setState({
      viewBookmark: false,
      currentViewingBookmark: null,
    });
  };

  render() {
    const {
      anchorEl,
      searchQuery,
      selectedIndex,
      currentPageBookmarks,
      itemsPerPageBookmarks,
      bookmarkedMessages,
      viewBookmark,
      currentViewingBookmark,
    } = this.state;
    const startIndexBookmarks =
      (currentPageBookmarks - 1) * itemsPerPageBookmarks;
    const endIndexBookmarks = startIndexBookmarks + itemsPerPageBookmarks;
    // const bookmarkedMessages = this.getbookmarkedMessages();

    return (
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5%",
            padding: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F3F6FD",
              borderRadius: "25px",
              padding: "5px",
              marginRight: "3%",
            }}
          >
            <SearchIcon
              sx={{ color: "#6001FF", marginLeft: "10px", marginRight: "5px" }}
            />
            <InputBase
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => this.setState({ searchQuery: e.target.value })}
              sx={{
                color: "#6001FF",
                width: "40%",
                backgroundColor: "#F3F6FD",
                borderRadius: "25px",
              }}
            />
          </Box>
        </div>
        <Paper
          elevation={5}
          style={{
            width: "100%",
            borderRadius: "10px",
            backgroundColor: "#FFF",
          }}
        >
          <div>
            <List component="nav" aria-label="Bookmark list">
              <ListItem>
                <ListItemText style={{ width: "3%" }}></ListItemText>
                <ListItemText style={{ width: "30%" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: "1.1rem",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                    }}
                  >
                    Query
                  </Typography>
                </ListItemText>
                <ListItemText style={{ width: "25%" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: "1.1rem",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                    }}
                  >
                    Response
                  </Typography>
                </ListItemText>
                <ListItemText style={{ width: "25%" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: "1.1rem",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 400,
                    }}
                  >
                    Time
                  </Typography>
                </ListItemText>
              </ListItem>
              <Divider />
              {bookmarkedMessages.length > 0 ? (
                <List>
                  {bookmarkedMessages
                    .filter((row) =>
                      row.bookmark_data.query
                        .toLowerCase()
                        .includes(searchQuery.trim().toLowerCase())
                    )
                    .slice(startIndexBookmarks, endIndexBookmarks)
                    .map((message, index) => (
                      <div key={message.id}>
                        <ListItemButton
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          onClick={() => this.handleshowBookmark(message)}
                        >
                          <ListItemIcon
                            style={{ width: "5%", marginLeft: "1%" }}
                          >
                            <BookIcon />
                          </ListItemIcon>
                          <ListItemText style={{ width: "35%" }}>
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              sx={{
                                fontSize: "1rem",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 400,
                              }}
                            >
                              {message.bookmark_data.query}{" "}
                              {/* Display the message title or relevant field */}
                            </Typography>
                          </ListItemText>
                          <ListItemText style={{ width: "33%" }}>
                            <Tooltip title={message.bookmark_data.response}>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{
                                  fontSize: "1rem",
                                  fontFamily: "Poppins, sans-serif",
                                  fontWeight: 400,
                                }}
                              >
                                {message.bookmark_data.response.length > 20
                                  ? `${message.bookmark_data.response.substring(
                                      0,
                                      20
                                    )}...`
                                  : message.bookmark_data.response}
                                {/* Display the message token or relevant field */}
                              </Typography>
                            </Tooltip>
                          </ListItemText>
                          <ListItemText style={{ width: "25%" }}>
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              sx={{
                                fontSize: "1rem",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 400,
                              }}
                            >
                              {this.formatTimeDifferenceToNow(
                                message.bookmarked_at
                              )}
                            </Typography>
                          </ListItemText>
                          <ListItemIcon style={{ width: "0%" }}>
                            <IconButton
                              aria-label="More options"
                              aria-controls="options-menu"
                              aria-haspopup="true"
                              onClick={(event) =>
                                this.handleClick(event, index)
                              }
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              sx={{ marginLeft: "-6%" }}
                              id="options-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={
                                Boolean(anchorEl) && selectedIndex === index
                              }
                              onClose={this.handleClose}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              onClick={(event) => event.stopPropagation()}
                              // PaperProps={{
                              //   style: {
                              //     width: "9%",
                              //   },
                              // }}
                            >
                              {/* <MenuItem
                                onClick={() => this.handleshowBookmark(message)}
                              >
                                <ListItemIcon>
                                  <VisibilityIcon />
                                </ListItemIcon>
                                Show Bookmark
                              </MenuItem> */}
                              <MenuItem
                                onClick={() =>
                                  this.handleDeleteBookmark(message.id)
                                }
                              >
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                Remove
                              </MenuItem>
                            </Menu>
                          </ListItemIcon>
                        </ListItemButton>
                        <Divider />
                      </div>
                    ))}
                </List>
              ) : (
                <Typography variant="subtitle1" style={{ marginLeft: "3%" }}>
                  No bookmarked messages.
                </Typography>
              )}
            </List>
          </div>
          {bookmarkedMessages.length > 0 && (
            <TablePagination
              component="div"
              count={bookmarkedMessages.length}
              page={currentPageBookmarks - 1}
              onPageChange={(_, newPage) =>
                this.setState({ currentPageBookmarks: newPage + 1 })
              }
              rowsPerPage={itemsPerPageBookmarks}
              onRowsPerPageChange={(event) => {
                const newRowsPerPage = parseInt(event.target.value, 10);
                this.setState({
                  itemsPerPageBookmarks: newRowsPerPage,
                  currentPageBookmarks: 1,
                });
              }}
            />
          )}
        </Paper>
        <Snackbar
          open={this.state.showSnackbar}
          autoHideDuration={3000}
          onClose={this.handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.handleSnackbarClose}
            severity={this.state.snackbarSeverity}
          >
            {this.state.snackbarMessage}
          </MuiAlert>
        </Snackbar>
        {viewBookmark && (
          <ViewBookmark
            open={viewBookmark}
            bookmarkData={currentViewingBookmark}
            onClose={this.handleCloseShowBookmark}
          />
        )}
      </Container>
    );
  }
}

export default withRouter(BookMark);
