import React from "react";
import Grid from "@mui/material/Grid";
import SideNav from "./sideNav";
import FileUpload from "./fileUpload";
import ChatUI from "./chatUI";
import Bookmark from "./Bookmark";
import Tab from "./Tab";
import PromptTemplate from "./promptTemplate";
// const BASE_URL = process.env.REACT_APP_BASE_URL;

class Adminview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTab: true,
      showUpload: false,
      showChat: false,
      ShowBook: false,
      customTabValue: 2,
      username: localStorage.getItem("username") || "username",
      chatMessages: [],
      selectedBookmark: null,
      showCSVChat: false,
      showPromptTemplate: false,
    };
  }

  handleUploadClick = () => {
    this.setState({
      showTab: false,
      showUpload: true,
      showChat: false,
      ShowBook: false,
      showCSVChat: false,
      showPromptTemplate: false,
    });
  };

  handleTabClick = (customTabValue) => {
    this.setState({
      showTab: true,
      showUpload: false,
      showChat: false,
      ShowBook: false,
      customTabValue: customTabValue,
      showCSVChat: false,
      showPromptTemplate: false,
    });
  };

  handleChatClick = (event) => {
    this.setState({
      showTab: false,
      showUpload: false,
      showChat: true,
      ShowBook: false,
      showCSVChat: false,
      showPromptTemplate: false,
    });
  };

  handleBookClick = () => {
    this.setState({
      showTab: false,
      showUpload: false,
      showChat: false,
      ShowBook: true,
      showCSVChat: false,
      showPromptTemplate: false,
    });
  };

  handlePromptTemplateClick = () => {
    this.setState({
      showTab: false,
      showUpload: false,
      showChat: false,
      ShowBook: false,
      showPromptTemplate: true,
      showCSVChat: false,
    });
  };

  resetChat = () => {
    this.setState({
      showTab: false,
      chatMessages: [],
      showUpload: false,
      showChat: true,
      ShowBook: false,
      showCSVChat: false,
      showPromptTemplate: false,
    });
  };
  handleCSVChatClick = (event) => {
    this.setState({
      showTab: false,
      showUpload: false,
      showChat: false,
      ShowBook: false,
      showCSVChat: true,
      showPromptTemplate: false,
    });
  };
  handleShowBookmark = (messageData) => {
    // Logic to handle showing the bookmarked message in ChatUI
    // Set the bookmarked message data in ChatUI component's state
    this.setState({ bookmarkedMessage: messageData });
  };

  handleViewInChat = (bookmark) => {
    // Set the selected bookmark in the parent component's state
    this.setState({ selectedBookmark: bookmark });
    // Navigate to the ChatUI view
    this.handleChatClick();
  };
  render() {
    const {
      showUpload,
      showChat,
      showTab,
      ShowBook,
      username,
      selectedBookmark,
      showCSVChat,
      showPromptTemplate,
    } = this.state;
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={2} style={{ marginTop: "5%" }}>
            <SideNav
              handleChatClick={this.handleChatClick}
              handleUploadClick={this.handleUploadClick}
              handleTabClick={this.handleTabClick}
              handleBookClick={this.handleBookClick}
              resetChat={this.resetChat}
              handlePromptTemplateClick={this.handlePromptTemplateClick}
              showChat={showChat}
              showCSVChat={showCSVChat}
              ShowBook={ShowBook}
            />
          </Grid>
          <Grid item xs={10}>
            {showTab && !showUpload && (
              <Tab
                handleTabClick={this.handleTabClick}
                handleUploadClick={this.handleUploadClick}
                handleChatClick={this.handleChatClick}
                customTabValue={this.state.customTabValue}
                handleCSVChatClick={this.handleCSVChatClick}
              />
            )}
            {showUpload && (
              <FileUpload
                customTabValue={this.state.customTabValue}
                showUpload={showUpload}
                handleTabClick={this.handleTabClick}
                handleChatClick={this.handleChatClick}
              />
            )}
            {showChat && (
              <ChatUI
                is_sql={true}
                chatMessages={this.state.chatMessages}
                selectedBookmark={selectedBookmark} // Pass the selected bookmark as a prop to ChatUI
              />
            )}
            {ShowBook && (
              <Bookmark
                username={username}
                handleBookClick={this.handleBookClick}
                onShowBookmark={this.handleShowBookmark}
                handleChatClick={this.handleChatClick}
                onViewInChat={this.handleViewInChat} // Pass the function to handle "View in Chat"
              />
            )}
            {showCSVChat && <ChatUI is_sql={false} />}
            {showPromptTemplate && <PromptTemplate />}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default Adminview;
