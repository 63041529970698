import React, { Component } from "react";
import styled from "styled-components";
import {
  Dialog as MuiDialog,
  Button,
  Typography,
  ListItemAvatar,
  ListItemText,
  Grid,
  ButtonGroup,
  Tooltip,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer as MuiTableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
// import DownloadIcon from "@mui/icons-material/Download";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import CodeIcon from "@mui/icons-material/Code";
import CodeOffIcon from "@mui/icons-material/CodeOff";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Botimageicon from "./botimageicon";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Barchart from "./chatComponents/Barchart";

const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    border-radius: 20px;
  }
`;

const StyledTableContainer = styled(MuiTableContainer)`
  overflow: auto;
  margin-left: 4%;
  max-height: 320px;
  scrollbar-width: thin;
  scrollbar-color: #6001ff #f5f5f5;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    height: 4px;
    background-color: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  & .scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #6001ff #f5f5f5;
  }
`;
const UserQuery = ({ query }) => (
  <div
    key="userQuery"
    className="message userQuery"
    style={{
      display: "flex",
      background: "#FFFFFF",
      borderRadius: "20px",
      padding: "10px 10px 10px 10px",
      width: "95%",
      margin: "10px 10px 10px 10px",
    }}
  >
    <ListItemAvatar sx={{ marginRight: "1.3%", marginTop: "10px" }}>
      <AccountCircleSharpIcon style={{ fontSize: 50, color: "#6001ff" }} />
    </ListItemAvatar>
    <ListItemText
      primary=""
      secondary={query.split("\n").map((paragraph, i) => (
        <p key={i} style={{ fontSize: "1.25rem", color: "#818589" }}>
          {paragraph}
        </p>
      ))}
      sx={{ fontSize: "1.25rem", color: "#818589" }}
      disableTypography
    />
  </div>
);

const SQLQuery = ({ sqlQuery, viewSQLQuery }) =>
  viewSQLQuery && (
    <Grid item xs={12}>
      <div style={{ marginTop: "10%", marginLeft: "8%" }}>
        <div style={{ overflow: "auto", maxWidth: "100%" }}>
          <SyntaxHighlighter
            language="SQL"
            style={docco}
            customStyle={{
              marginLeft: "4%",
              marginTop: "2%",
              fontSize: "1.25rem",
              maxWidth: "max-content",
            }}
          >
            {sqlQuery}
          </SyntaxHighlighter>
        </div>
      </div>
    </Grid>
  );

const SQLQueryAccordion = ({
  bookmarkData,
  viewSQLQuery,
  toggleSQLQueryView,
  isChartVisible,
  toggleChartVisibility,
  isChartDataValid,
}) => (
  <Accordion style={{ width: "90%", marginLeft: "25px", boxShadow: "none" }}>
    <AccordionSummary
      aria-controls="panel1a-content"
      id="panel1a-header"
      expandIcon={
        <Tooltip title="View Query">
          <AutoAwesomeIcon style={{ color: "#6001ff" }} />
        </Tooltip>
      }
    ></AccordionSummary>
    <AccordionDetails>
      <div style={{ display: "flex" }}>
        <Grid container>
          <Grid item xs={12}>
            <StyledTableContainer component={Paper}>
              <Table sx={{ maxWidth: "max-content" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {bookmarkData.bookmark_data.tableData.column_names &&
                      bookmarkData.bookmark_data.tableData.column_names.map(
                        (columnName, index) => (
                          <TableCell key={index}>{columnName}</TableCell>
                        )
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bookmarkData.bookmark_data.tableData.rows &&
                    bookmarkData.bookmark_data.tableData.rows.map(
                      (row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {row &&
                            row.map((cols, col_index) => (
                              <TableCell
                                key={col_index}
                                component="th"
                                scope="row"
                              >
                                {cols}
                              </TableCell>
                            ))}
                        </TableRow>
                      )
                    )}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Grid>
          {isChartVisible && (
            <Grid item xs={12}>
              <div style={{ marginTop: "10%", marginLeft: "8%" }}>
                {bookmarkData.bookmark_data.chartData &&
                bookmarkData.bookmark_data.chartData.x_data &&
                bookmarkData.bookmark_data.chartData.y_data &&
                bookmarkData.bookmark_data.chartData.x_data.length > 0 &&
                bookmarkData.bookmark_data.chartData.y_data.length > 0 &&
                isChartDataValid(
                  bookmarkData.bookmark_data.chartData.x_data,
                  bookmarkData.bookmark_data.chartData.y_data
                ) ? (
                  <Barchart
                    col_names={
                      bookmarkData.bookmark_data.tableData.column_names || []
                    }
                    x_axis_data={bookmarkData.bookmark_data.chartData.x_data}
                    y_axis_data={bookmarkData.bookmark_data.chartData.y_data}
                  />
                ) : (
                  <div></div>
                )}
              </div>
            </Grid>
          )}
          <SQLQuery
            sqlQuery={bookmarkData.bookmark_data.sqlQuery}
            viewSQLQuery={viewSQLQuery}
          />
          <Grid item xs={12}>
            <ButtonGroup variant="text" aria-label="text button group">
              <Tooltip title={viewSQLQuery ? "Hide SQL" : "Show SQL"}>
                <IconButton onClick={toggleSQLQueryView}>
                  {viewSQLQuery ? <CodeOffIcon /> : <CodeIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip title={isChartVisible ? "Show Chart" : "Hide Chart"}>
                <IconButton onClick={toggleChartVisibility}>
                  <AutoGraphIcon />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          </Grid>
        </Grid>
      </div>
    </AccordionDetails>
  </Accordion>
);

const ZunoResponse = ({
  bookmarkData,
  viewSQLQuery,
  toggleSQLQueryView,
  isChartVisible,
  toggleChartVisibility,
  isChartDataValid,
}) => (
  <div
    key="zunoResponse"
    className="zunoResponse"
    style={{
      display: "flex",
      flexDirection: "column",
      background: "#FFFFFF",
      borderRadius: "20px",
      padding: "10px 10px 10px 10px",
      width: "95%",
      margin: "10px 10px 20px 10px",
    }}
  >
    <SQLQueryAccordion
      bookmarkData={bookmarkData}
      viewSQLQuery={viewSQLQuery}
      toggleSQLQueryView={toggleSQLQueryView}
      isChartVisible={isChartVisible}
      toggleChartVisibility={toggleChartVisibility}
      isChartDataValid={isChartDataValid}
    />
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <ListItemAvatar sx={{ marginRight: "1.3%" }}>
        <Botimageicon />
      </ListItemAvatar>
      <ListItemText
        primary=""
        secondary={bookmarkData.bookmark_data.response
          .split("\n")
          .map((paragraph, i) => (
            <p key={i} style={{ fontSize: "1.25rem", color: "#818589" }}>
              {paragraph}
            </p>
          ))}
        sx={{ fontSize: "1.25rem", color: "#818589" }}
        disableTypography
      />
    </div>
  </div>
);

const CloseButton = ({ onClose }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "flex-end",
      marginRight: "20px",
      marginBottom: "20px",
    }}
  >
    <Button
      onClick={onClose}
      variant="contained"
      sx={{ backgroundColor: "#6001FF" }}
    >
      Close
    </Button>
  </div>
);

class ViewBookmark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewSQLQuery: false,
      isChartVisible: false,
    };
  }

  toggleSQLQueryView = () => {
    this.setState((prevState) => ({
      viewSQLQuery: !prevState.viewSQLQuery,
    }));
  };

  toggleChartVisibility = () => {
    this.setState((prevState) => ({
      isChartVisible: !prevState.isChartVisible,
    }));
  };

  isChartDataValid = (x_axis_data, y_axis_data) => {
    const isXStringArray =
      Array.isArray(x_axis_data) &&
      x_axis_data.every((item) => typeof item === "string");
    const isYStringArray =
      Array.isArray(y_axis_data) &&
      y_axis_data.every((item) => typeof item === "string");

    return !(isXStringArray && isYStringArray);
  };

  render() {
    const { viewSQLQuery, isChartVisible } = this.state;
    const { open, onClose, bookmarkData } = this.props;

    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth sx={{}}>
        <div
          style={{
            overflow: "auto",
            background: "#F3F6FD",
            fontSize: "30px",
          }}
        >
          {bookmarkData ? (
            <>
              <UserQuery query={bookmarkData.bookmark_data.query} />
              <ZunoResponse
                bookmarkData={bookmarkData}
                viewSQLQuery={viewSQLQuery}
                toggleSQLQueryView={this.toggleSQLQueryView}
                isChartVisible={isChartVisible}
                toggleChartVisibility={this.toggleChartVisibility}
                isChartDataValid={this.isChartDataValid}
              />
            </>
          ) : (
            <Typography variant="body1">No bookmark data available</Typography>
          )}
          <CloseButton onClose={onClose} />
        </div>
      </Dialog>
    );
  }
}

export default ViewBookmark;
