import React, { Component } from 'react'
import Box from "@mui/material/Box"
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import LinearProgressLabel from "./LinearProgressLabel";


export class FileUploadQAFile extends Component {
    constructor(props) {
      super(props)
      this.fileInputRef=React.createRef();
      this.state = {
         
      }
    }
    handleAddMoreFiles = () => {
        this.fileInputRef.value = null;
        this.fileInputRef.click();
      };
  render() {
    const {
        showLoader,
        selectedFiles,
        showAddMoreButton,
        disableUploadButton,
        progress,
        next,
        message,
        handleUpload,
        handleFileChange,
        handleDeleteFile,
        cancelUpload,
        handleChatClickInUpload,
    }=this.props
    return (
      <Box>     
        {!showLoader && (
        <Box>
          <h2>My Files</h2>
          <form onSubmit={handleUpload}>
            <input
              type="file"
              multiple
              style={{ display: "none" }}
              onChange={handleFileChange}
              ref={(ref) => (this.fileInputRef = ref)}
            />
            {selectedFiles.length >= 0 && (
              <Button
                onClick={()=>this.handleAddMoreFiles()}
                style={{ color: "#6001FF" }}
              >
                {showAddMoreButton ? "Add More Files" : "Add File"}
              </Button>
            )}
            &nbsp;&nbsp;
            <Button
              variant="contained"
              color="error"
              onClick={handleUpload}
              disabled={
                selectedFiles.length === 0 ||
                disableUploadButton === true
              }
              style={{
                backgroundColor:
                  selectedFiles.length === 0 ? "initial" : "#6001FF",
                color:
                  selectedFiles.length === 0 ? "#6001FF" : "#ffffff",
              }}
            >
              {showLoader ? (
                <CircularProgress
                  size={25}
                  sx={{
                    color: "#FFFFFF",
                    marginLeft: "-7%",
                  }}
                />
              ) : (
                "Upload Files"
              )}
            </Button>
          </form>
        </Box>
      )}
      {showLoader && (
        <h2 style={{ marginBottom: "5%" }}>Uploading files</h2>
      )}
      {showLoader && (
        <Box style={{ margin: "5px" }}>
          {" "}
          <LinearProgressLabel value={progress} />
        </Box>
      )}
      {!showLoader && (
        <Box>
          <h3>Selected Files:</h3>
          {selectedFiles.length > 4 ? (
            <List>
              <Box style={{ height: "260px", overflow: "auto" }}>
                {selectedFiles.map((file, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <InsertDriveFileOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={file.name} />
                      <ListItemIcon
                        onClick={()=>handleDeleteFile(index)}
                      >
                        <DeleteIcon sx={{ color: "#6001FF" }} />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                ))}
              </Box>
            </List>
          ) : (
            <List>
              {selectedFiles.map((file, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <InsertDriveFileOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={file.name} />
                    <ListItemIcon
                      onClick={() => handleDeleteFile(index)}
                    >
                      <DeleteIcon sx={{ color: "#6001FF" }} />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      )}

      {showLoader && (
        <Box>
          {/* <p>{message}</p> */}
          <Button
            variant="contained"
            color="success"
            onClick={cancelUpload}
            style={{
              backgroundColor: "#6001FF",
              color: "#ffffff",
              marginTop: "10%",
            }}
          >
            {/* {showAddMoreButton ? "Add More Files" : "Add File"} */}
            Cancel
          </Button>
        </Box>
      )}

      {next && !showLoader && (
        <Box>
          <p>{message}</p>
          <Button
            variant="contained"
            color="success"
            onClick={handleChatClickInUpload}
            style={{ backgroundColor: "#6001FF", color: "#ffffff" }}
          >
            Next
          </Button>
        </Box>
      )}</Box>
    )
  }
}

export default FileUploadQAFile