import React, { Component } from "react";
import { Link } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";

export class ChatRetryLlama extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const {
      timeOut_llma,
      retryTime_llma,
      tryCount_llama,
      index,
      message,
      llamaIndex,
      handleRetry_Llama,
    } = this.props;
    return (
      <p>
        {timeOut_llma.length > 0 &&
        retryTime_llma < 6 &&
        tryCount_llama < 3 &&
        llamaIndex === index ? (
          <ListItemText
            secondary={`${timeOut_llma} ${retryTime_llma}`}
            style={{
              marginLeft: "37%",
              fontSize: "1.25rem",
              color: "#818589",
              maxHeight: "1px",
            }}
            disableTypography
          />
        ) : (
          <>
            {timeOut_llma.length > 0 && tryCount_llama < 3 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  maxHeight: "1px",
                  paddingBottom: "4px",
                }}
              >
                <Link
                  href=""
                  color="#6001FF"
                  component="button"
                  variant="body2"
                  onClick={(event) => {
                    handleRetry_Llama(event, index, message);

                    console.log("after send message click here to retry llama");
                  }}
                >
                  <h3>Click here to Retry </h3>
                </Link>
              </div>
            ) : (
              <> </>
            )}
          </>
        )}
      </p>
    );
  }
}

export default ChatRetryLlama;
