
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./userComponents/Login";
import Adminview from "./ZN001/adminview";
import AdminQA from "./ZN002/adminQA";
import SignUp from "./userComponents/Signup";
import CuteRObot from "./ZN001/cuterobot";
import WaterLoader from "./ZN001/waterLoader";
import Pdfchat from "./ZN002/pdfchatQA";
import Home from "./userComponents/Home";

// import Admin from "./Admin/Admin";
import Dashboard from "./Admin/Dashboard";
// import Pdf from './ZN002/pdf';
import UserManagement from "./Admin/UserManagement";
import DataManagement from "./Admin/DataManagement";
import Logs from "./Admin/Logs";
import ZunoApp from "./Admin/ZunoApp";
import CustomizePrompts from "./Admin/AppManagement/Data/CustomizePrompts";
import CustomizePromptsDocs from "./Admin/AppManagement/docs/CustomizePrompts";
import CustomizePromptsEmployeeEngagement from "./Admin/AppManagement/hrbot/CustomizePrompts";
import ScrollToTop from "./Admin/ScrollToTop";

import AdminHR from "./ZN003/adminHR";
import PdfchatHR from "./ZN003/pdfchatHR";
import AdminWeb from "./ZN004/adminWeb";
import PdfchatWeb from "./ZN004/ChatWeb";
import CustomizePromptsWebsites from "./Admin/AppManagement/Web/CustomizePromptswebsites";

import ActivateAccount from "./userComponents/ActivateAccount";

const router = createBrowserRouter([
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/admin",
    element: <Adminview />,
  },
  {
    path: "/cb",
    element: <CuteRObot />,
  },
  {
    path: "/bi",
    element: <WaterLoader />,
  },
  {
    path: "/chat",
    element: <Pdfchat />,
  },
  {
    path: "/adminQA",
    element: <AdminQA />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  // {
  //   "path":'/pdf',
  //   "element":<Pdf/>
  // },
  {
    path: "/usermanagement",
    element: <UserManagement />,
  },
  {
    path: "/datamanagement",
    element: <DataManagement />,
  },
  {
    path: "/querylogs",
    element: <Logs />,
  },
  {
    path: "/appmanagement",
    element: <ZunoApp />,
  },
  {
    path: "/LLMdata",
    element: <CustomizePrompts />,
  },
  {
    path: "/LLMdocs",
    element: <CustomizePromptsDocs />,
  },
  {
    path: "/employeeEngagement",
    element: <CustomizePromptsEmployeeEngagement />,
  },
  {
    path: "/adminHR",
    element: <AdminHR />,
  },
  {
    path: "/pdfchartHR",
    element: <PdfchatHR />,
  },
  {
    path: "/website",
    element: <CustomizePromptsWebsites />,
  },
  {
    path:"/adminWeb",
    element:<AdminWeb/>
  },
  {
    path:"/chatWeb",
    element:<PdfchatWeb/>
  },
  {
    path: "/activateAccount",
    element: <ActivateAccount />,
  }
  
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ScrollToTop>
    <RouterProvider router={router} />
  </ScrollToTop>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
