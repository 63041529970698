import React, { Component } from "react";
import {
  TextField,
  Box,
  CircularProgress,
  Button,
  Snackbar,
  IconButton,
  InputAdornment,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
// import { json } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};

const BASE_URL = process.env.REACT_APP_BASE_URL;
const application_code = "ZN001"; // for csv
class S3bucketComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aws_access_key_id: "",
      aws_secret_access_key: "",
      aws_bucket_name: "",
      aws_bucket_folder_path: "",
      bucket_info: null,
      connecting: false,
      showsnack: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
      showSnackbar: false,
      severity: "",
      message: "",
      showAccessKeyId: false,
      showSecretAccessKey: false,
    };
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleToggleVisibility = (field) => {
    this.setState((prevState) => ({
      [field]: !prevState[field],
    }));
  };
  handleSnackbarOpen = (severity, message) => {
    this.setState({
      showSnackbar: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({ showSnackbar: false });
  };

  handleFetchBucketData = async () => {
    this.setState({ connecting: true });

    const {
      aws_access_key_id,
      aws_secret_access_key,
      aws_bucket_name,
      aws_bucket_folder_path,
    } = this.state;
    if (
      aws_access_key_id === "" ||
      aws_secret_access_key === "" ||
      aws_bucket_name === "" ||
      aws_bucket_folder_path === ""
    ) {
      this.setState({
        showsnack: true,
        severity: "error",
        snackmessage: "Fill all the fields",
        connecting: false,
      });
      return;
    }
    try {
      let selete_endpoint;
      const formData = new FormData();
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");
      const userOrganization = JSON.parse(
        localStorage.getItem("currentLoggedInUserDetails")
      )["organization"];
      if (this.props.dbType === "S3 Bucket") {
        formData.append("aws_access_key_id", aws_access_key_id);
        formData.append("aws_secret_access_key", aws_secret_access_key);
        formData.append("aws_bucket_name", aws_bucket_name);
        formData.append("aws_bucket_folder_path", aws_bucket_folder_path);

        selete_endpoint = `${BASE_URL}/zn001/user/select_s3_bucket_data`;
      } else {
        console.error("Unsupported database type");
        return;
      }

      const responseS3BucketData = await fetch(selete_endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (responseS3BucketData.ok) {
        const responseFetchS3BucketData = await responseS3BucketData.json();
        if (responseFetchS3BucketData.status) {
          // Handle error response from the server
          this.setState({
            showSkeleton: false,
            showsnack: true,
            severity: "error",
            snackmessage: responseFetchS3BucketData.message,
          });
        } else {
          const { file_count, } = responseFetchS3BucketData; // Extract table_count from the response
          this.setState({
            showSkeleton: false,
            showsnack: true,
            severity: "success",
            snackmessage: "Connection successful",
            connecting: false, // check at last
          });
          const formData = new FormData();
          if (this.props.dbType === "S3 Bucket") {
            const source_information = JSON.stringify({
              aws_access_key_id: aws_access_key_id,
              aws_secret_access_key: aws_secret_access_key,
              aws_bucket_name: aws_bucket_name,
              aws_bucket_folder_path: aws_bucket_folder_path,
              file_count: file_count,
            });
            formData.append("source", "s3bucket");
            formData.append("source_information", source_information);
          } else {
            console.error("Unsupported database type");
            return;
          }

          formData.append("selectedEmails", JSON.stringify([email]));
          formData.append("organization", userOrganization);
          formData.append("application_code", application_code);
          formData.append("isAdmin", false);
          // only call the s3 bucket endpoint if there were no errors
          const responseStoreData = await fetch(
            `${BASE_URL}/zn001/user/csv_cloud_source`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );

          if (responseStoreData.ok) {
            const result = await responseStoreData.json();
            if (result.status) {
              this.setState({
                showSkeleton: false,
                showsnack: true,
                severity: "error",
                snackmessage: result.message,
              });
            } else {
              this.setState({
                showSkeleton: false,
                showsnack: true,
                severity: "success",
                snackmessage: "Connection successful",
              });
              this.props.handleTabClick({
                showTab: true,
                showUpload: false,
                showChat: false,
                ShowBook: false,
              });
            }
          }
        }
      }
    } catch (error) {
      // Handle network errors or unexpected errors
      this.setState({
        message: "Network error occurred",
      });
      console.log(error);
    }
  };
  render() {
    const {
      aws_access_key_id,
      aws_secret_access_key,
      aws_bucket_name,
      aws_bucket_folder_path,
      bucket_info,
      connecting,
      showsnack,
      severity,
      snackmessage,
      showAccessKeyId,
      showSecretAccessKey,
    } = this.state;
    const has_bucket_info = bucket_info != null;

    return (
      <>
        <form>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <TextField
                id="aws_access_key_id"
                label="Aws Access Key ID"
                name="aws_access_key_id"
                sx={textFieldSx}
                value={aws_access_key_id}
                onChange={this.handleInputChange}
                type={showAccessKeyId ? "text" : "password"}
                style={{ width: "60.5%", marginBottom: "10px" }}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.handleToggleVisibility("showAccessKeyId")
                        }
                        style={{
                          minWidth: "unset",
                          padding: 0,
                          color: "#6001FF",
                        }}
                      >
                        {showAccessKeyId ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <TextField
                id="aws_secret_access_key"
                label="Aws Secret Access Key"
                name="aws_secret_access_key"
                sx={textFieldSx}
                value={aws_secret_access_key}
                onChange={this.handleInputChange}
                type={showSecretAccessKey ? "text" : "password"}
                style={{ width: "60.5%", marginBottom: "10px" }}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.handleToggleVisibility("showSecretAccessKey")
                        }
                        style={{
                          minWidth: "unset",
                          padding: 0,
                          color: "#6001FF",
                        }}
                      >
                        {showSecretAccessKey ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <TextField
                id="aws_bucket_name"
                label="Aws Bucket Name"
                name="aws_bucket_name"
                sx={textFieldSx}
                value={aws_bucket_name}
                onChange={this.handleInputChange}
                style={{ width: "60.5%", marginBottom: "10px" }}
                required
              />
            </div>

            <div>
              <TextField
                id="aws_bucket_folder_path"
                label="Aws Bucket Folder Path"
                name="aws_bucket_folder_path"
                sx={textFieldSx}
                value={aws_bucket_folder_path}
                onChange={this.handleInputChange}
                style={{ width: "60.5%", marginBottom: "10px" }}
                required
              />
            </div>
          </div>
          <Box m={1} display="flex" justifyContent="center" alignItems="center">
            {/* Conditionally render CircularProgress based on the 'connecting' state */}
            {has_bucket_info ? (
              <div>
                {connecting ? (
                  <CircularProgress
                    size={25}
                    style={{ color: "#6001ff", marginLeft: "12%" }}
                  />
                ) : (
                  <Button
                    style={{
                      backgroundColor: "#6001ff",
                      marginBottom: "20px",
                      color: "#ffffff",
                    }}
                    variant="contained"
                    onClick={this.handleUpdateBucketInfo}
                  >
                    Apply Changes
                  </Button>
                )}
              </div>
            ) : (
              <div>
                {connecting ? (
                  <CircularProgress
                    size={25}
                    style={{ color: "#6001ff",marginLeft: "114%"  }}
                  />
                ) : (
                  <Button
                    style={{ backgroundColor: "#6001ff", marginLeft: "114%" ,}}
                    variant="contained"
                    onClick={this.handleFetchBucketData}
                  >
                    Save
                  </Button>
                )}
              </div>
            )}
          </Box>
        </form>
        <Snackbar
          open={showsnack}
          autoHideDuration={6000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            this.setState({ showsnack: false });
          }}
        >
          <Alert
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }

              this.setState({ showsnack: false });
            }}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {snackmessage}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default S3bucketComponent;
