import React, { Component } from "react";
import { Grid, Typography } from "@mui/material";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";

import DashboardTable from "./DashboardTable ";
import Menu from "./Menu";
import User from "./Useract";
import { withRouter } from "./withRouter";
import Utils from "../userComponents/Utils";

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });
const BASE_URL = process.env.REACT_APP_BASE_URL;

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("token"),
      userDetails: JSON.parse(localStorage.getItem("userDetails"))
        ? JSON.parse(localStorage.getItem("userDetails"))
        : [],
      cummulative_token_usage: 0,
    };
  }
  async componentDidMount() {
      const token = localStorage.getItem("token");
  
      if (!token) {
        console.error("Token not found in localStorage");
          Utils.removeLocalStorage()
          this.props.navigate("/");
      }
      try {
        const response = await fetch(
          `${BASE_URL}/admin/fetch_user_details`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 401) {
          console.error("Unauthorized: You are not logged in");
          Utils.removeLocalStorage();
          this.props.navigate("/");
        }
        if (response.status === 200) {
          const users_data = await response.json();
          this.setState({
            userDetails: users_data.userDetails,
          });
        } else {
          throw new Error("Failed to fetch  users Data");
        }
      } catch (error) {
        console.error("Error fetching  users Data:", error);
      }
  }
  componentDidUpdate(){
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
        Utils.removeLocalStorage()
        this.props.navigate("/");
    }
  }
  updateUserDetails = (updatedDetails, isEditMode) => {
    localStorage.setItem("userDetails", JSON.stringify(updatedDetails));
    this.setState({ userDetails: updatedDetails });
  };

  handleSnackbarOpen = (severity, message) => {
    this.setState({
      snackmessage: message,
      severity: "error",
      showsnack: true,
    });
  };
  handleSnackbarClose = () => {
    this.setState({ showsnack: false });
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "2%",
        }}
      >
        <div
          style={{ position: "absolute", top: 0, right: "3%", margin: "1rem" }}
        >
          <User />
        </div>
        <Menu selectedButton="User Management" />
        <Typography
          variant="h1"
          component="div"
          sx={{
            flexGrow: 1,
            color: "#6001FF",
            fontSize: "2rem",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 600,
            marginRight: "5%",
          }}
        >
          User Management
        </Typography>
        {/* <Paper elevation={5} style={{ borderRadius: '20px', backgroundColor: '#F3F6FD',marginTop:'5%' }}> */}
        <div style={{ margin: "2%" }}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              {/* {console.log(this.state.userDetails[0].assignedApplictions)} */}
              <DashboardTable
                userDetails={this.state.userDetails}
                updateUserDetails={this.updateUserDetails}
              />
            </Grid>
            {/* <BasicModal /> */}
            {/* <Grid item xs={12} md={12}>
                        {showTab && !showUpload && (
              <Tab
                handleTabClick={this.handleTabClick}
                handleUploadClick={this.handleUploadClick}
                userDetails={this.state.userDetails}
              />
            )} */}
            {/* {showUpload && (
              <FileUpload
                customTabValue={this.state.customTabValue}
                showUpload={showUpload}
                handleTabClick={this.handleTabClick}
                userDetails={this.state.userDetails}
              />
            )}  */}
            {/* </Grid> */}
          </Grid>
        </div>
        {/* </Paper> */}
      </div>
    );
  }
}

export default withRouter(UserManagement);
