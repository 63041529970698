import React, { Component } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Link } from "@mui/material";

import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";

import Botimageicon from "./botimageicon";
import SqlViewQuery from "./SqlViewQuery";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import PersonIcon from "@mui/icons-material/Person";

export class ChatMapData extends Component {
  constructor(props) {
    super(props);
    const profileImage = localStorage.getItem("profileImage");
    let parsedProfileImage = null;

    if (profileImage && profileImage !== "undefined") {
      try {
        parsedProfileImage = JSON.parse(profileImage);
      } catch (error) {
        console.error("Error parsing profile image from localStorage", error);
      }
    }
    this.state = {
      queryindex: null,
      profileImage: parsedProfileImage,
    };
  }

  render() {
    const { profileImage } = this.state;

    const {
      messages,
      viewQuery,
      timeOutMsg,
      retryTime,
      tryCount,
      systemError,
      handleRetry,
      toggleBookmark,
      toggleChartVisibility,
      viewSQL,
      isChartVisible,
      isChartDataValid,
      profilePicData,
    } = this.props;
    return (
      <>
        {messages.map((message, index) =>
          index < messages.length - 1 ? (
            <div
              key={index}
              className={`message ${message.sender}`}
              style={{
                background: message.sender === "ZUNO" ? "#FFFFFF" : "none",
                borderRadius: message.sender === "ZUNO" ? "40px" : "0px",
                padding: "10px 10px 10px 10px",
                width: "95%",
                marginLeft: "20px",
                marginTop: "20px",
              }}
            >
              {message.sender === "ZUNO" && message.SQL ? (
                <div>
                  <SqlViewQuery
                    index={index}
                    message={message}
                    isChartVisible={isChartVisible}
                    viewQuery={viewQuery}
                    toggleBookmark={toggleBookmark}
                    isChartDataValid={isChartDataValid}
                    toggleChartVisibility={toggleChartVisibility}
                    viewSQL={viewSQL}
                  />
                </div>
              ) : (
                <></>
              )}

              <ListItem key={index}>
                <ListItemAvatar sx={{ marginRight: "1.3%" }}>
                  {message.sender === "ZUNO" ? (
                    profilePicData ? (
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{ marginRight: "5px" }}
                      >
                        <Avatar
                          alt="profilePic"
                          src={profilePicData}
                          sx={{ width: 56, height: 56 }}
                        />
                      </Stack>
                    ) : (
                      <Botimageicon />
                    )
                  ) : (
                    <Avatar
                      sx={{ marginRight: 2, width: 56, height: 56 }}
                      style={{ backgroundColor: "#6001FF" }}
                    >
                      {profileImage ? (
                        <img
                          src={`data:image/jpeg;base64,${profileImage}`}
                          alt="Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <PersonIcon sx={{ width: 30, height: 30 }} />
                      )}
                    </Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary=""
                  secondary={
                    message.sender === "ZUNO"
                      ? message.text.split("\n").map((paragraph, i) => (
                          <p
                            key={i}
                            style={{
                              fontSize: "1.25rem",
                              color: "#818589",
                            }}
                          >
                            {paragraph}
                          </p>
                        ))
                      : message.text
                  }
                  sx={{ fontSize: "1.25rem", color: "#818589" }}
                  disableTypography
                />
              </ListItem>
            </div>
          ) : (
            <div
              key={index}
              className={`message ${message.sender}`}
              style={{
                background: message.sender === "ZUNO" ? "#FFFFFF" : "none",
                borderRadius: message.sender === "ZUNO" ? "40px" : "0px",
                padding: "10px 10px 10px 10px",
                width: "95%",
                marginLeft: "20px",
                marginTop: "20px",
              }}
            >
              {message.sender === "ZUNO" && message.SQL ? (
                <div>
                  <SqlViewQuery
                    index={index}
                    message={message}
                    isChartVisible={isChartVisible}
                    viewQuery={viewQuery}
                    toggleBookmark={toggleBookmark}
                    isChartDataValid={isChartDataValid}
                    toggleChartVisibility={toggleChartVisibility}
                    viewSQL={viewSQL}
                  />
                </div>
              ) : (
                <></>
              )}

              <ListItem key={index}>
                <ListItemAvatar sx={{ marginRight: "1.3%" }}>
                  {message.sender === "ZUNO" ? (
                    profilePicData ? (
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{ marginRight: "5px" }}
                      >
                        <Avatar
                          alt="Sanjay"
                          src={profilePicData}
                          sx={{ width: 56, height: 56 }}
                        />
                      </Stack>
                    ) : (
                      <Botimageicon />
                    )
                  ) : (
                    // <AccountCircleSharpIcon
                    //   style={{
                    //     // marginLeft: "10px",
                    //     fontSize: 50,
                    //     color: "#6001ff",
                    //   }}
                    // />
                    <Avatar
                      sx={{ marginRight: 2, width: 56, height: 56 }}
                      style={{ backgroundColor: "#6001FF" }}
                    >
                      <PersonIcon sx={{ width: 40, height: 40 }} />
                    </Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary=""
                  secondary={
                    message.sender === "ZUNO"
                      ? message.text.split("\n").map((paragraph, i) => (
                          <div>
                            <p
                              key={i}
                              style={{
                                fontSize: "1.25rem",
                                color: "#818589",
                              }}
                            >
                              {tryCount < 3 ? (
                                <>{paragraph}</>
                              ) : (
                                <>{systemError}</>
                              )}
                            </p>
                            <p>
                              {timeOutMsg.length > 0 &&
                              retryTime < 6 &&
                              tryCount < 3 ? (
                                <ListItemText
                                  secondary={`${timeOutMsg} ${retryTime}`}
                                  style={{
                                    marginLeft: "37%",
                                    fontSize: "1.25rem",
                                    color: "#818589",
                                    maxHeight: "1px",
                                  }}
                                  disableTypography
                                />
                              ) : (
                                <>
                                  {/* (retryTime===5) */}
                                  {timeOutMsg.length > 0 && tryCount < 3 ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        maxHeight: "1px",

                                        paddingBottom: "4px",
                                      }}
                                    >
                                      <Link
                                        href=""
                                        color="#6001FF"
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                          handleRetry(index);

                                          console.log(
                                            "after send message click here to retry CSV"
                                          );
                                        }}
                                      >
                                        <h3>Click here to Retry </h3>
                                      </Link>
                                    </div>
                                  ) : (
                                    <> </>
                                  )}
                                </>
                              )}
                            </p>
                          </div>
                        ))
                      : message.text
                  }
                  sx={{ fontSize: "1.25rem", color: "#818589" }}
                  disableTypography
                />
              </ListItem>
            </div>
          )
        )}
      </>
    );
  }
}

export default ChatMapData;
