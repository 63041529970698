import React, { Component } from "react";
import { Container } from "@mui/material";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { withRouter } from "./withRouter";
import FileUploadWebsite from "./FileUploadWebsite";

// const MAX_FILE_SIZE_BYTES = 50 * 1024 * 1024;
// const VALID_FILE_EXTENSIONS = [".pdf", ".docx", ".doc", ".txt"];
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
class FileUploadWeb extends Component {
  constructor(props) {
    super(props);
    this.cancelFileUpload = React.createRef();
    this.state = {
      // selectedFiles: [],
      selectedLinks: [],
      message: "",
      value: 0,
      webCrawlClicked: false,
      filesUploaded: false,
      disableUploadButton: false,
      showLoader: false,
      showsnack: false,
      snackmessage: "",
      severity: "",
      progress: 0,
      loaded_1: 0,
      target: 0,
      check: false,
      next: false,
      webLink: "",
    };
  }

  componentDidUpdate() {
    // Save data to local storage whenever state changes
    localStorage.setItem(
      "selectedLinks",
      JSON.stringify(this.state.selectedLinks)
    );
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  handleSnackbarOpen = (severity, message) => {
    this.setState({
      snackmessage: message,
      severity: "error",
      showsnack: true,
    });
  };
  handleSnackbarClose = () => {
    this.setState({ showsnack: false });
  };

  handleChatClickInUpload = () => {
    this.props.handleListdataClick({
      showUpload: false,
      showChat: false,
      ShowKnowledge: true,
    });
  };

  handleWebCrawl = () => {
    const { webLink, selectedLinks } = this.state;
    const trimmedWebLink = webLink.trim();
    if (webLink.trim() !== "") {
      if (!selectedLinks.includes(trimmedWebLink)) {
        this.setState({
          selectedLinks: [...selectedLinks, webLink.trim()],
          webLink: "",
          webCrawlClicked: true,
        });
      } else {
        this.setState({ webLink: "" });
        this.handleSnackbarOpen("error", "URL is already in the list");
      }
    }
  };
  handleSelectedLinks = () => {
    this.setState({ selectedLinks: [] });
  };

  handleDeleteLink = (index) => {
    this.setState((prevState) => {
      const newSelectedLinks = [...prevState.selectedLinks];
      newSelectedLinks.splice(index, 1);
      return { selectedLinks: newSelectedLinks };
    });
  };

  onChangeWebLink = (value) => {
    this.setState({ webLink: value });
  };
  render() {
    const {
      value,
      webCrawlClicked,
      severity,
      showsnack,
      snackmessage,
      // next,
      webLink,
      selectedLinks,
    } = this.state;

    return (
      <Container>
        <Paper
          elevation={5}
          style={{
            marginTop: "5%",
            borderRadius: "20px",
            width: "100%",
            minHeight: "370px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={this.handleChange}
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#6001FF",
                  },
                }}
              >
                <Tab
                  label="Website"
                  {...a11yProps(0)}
                  sx={{
                    color: value === 0 ? "#6001FF" : "inherit",
                    "&.Mui-selected": {
                      color: "#6001FF",
                    },
                  }}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Box style={{ marginLeft: "5%" }}>
                <FileUploadWebsite
                  webLink={webLink}
                  selectedLinks={selectedLinks}
                  webCrawlClicked={webCrawlClicked}
                  onChangeWebLink={this.onChangeWebLink}
                  handleWebCrawl={this.handleWebCrawl}
                  handleDeleteLink={this.handleDeleteLink}
                  handleChatClickInUpload={this.handleChatClickInUpload}
                  handleSelectedLinks={this.handleSelectedLinks}
                />
              </Box>
            </CustomTabPanel>
          </Box>
        </Paper>
        <Snackbar
          open={showsnack}
          autoHideDuration={4000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            this.setState({ showsnack: false });
          }}
        >
          <Alert
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }
              this.setState({ showsnack: false });
            }}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {snackmessage}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export default withRouter(FileUploadWeb);
