import React, { Component } from "react";
import {
  Container,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
// import { ListItemIcon, IconButton, Menu, MenuItem, Button, Box, Checkbox, InputBase, TablePagination } from '@mui/material';
import {
  ListItemIcon,
  IconButton,
  Button,
  Box,
  Checkbox,
  InputBase,
  TablePagination,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
//import CircularProgress from '@mui/material/CircularProgress';
import { withRouter } from "./withRouter";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import Chip from "@mui/material/Chip";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import PdfIcon from "../assets/icons/pdf.webp";
import DocIcon from "../assets/icons/doc.webp";
import TextIcon from "../assets/icons/txt.webp";
import Notification from "./Notification";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress for the loader
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import Utils from "../userComponents/Utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL ="http://127.0.0.1:8001";
// const EMBEDDING_SERVICE_URL = "http://127.0.0.1:8001";
const application_code = "ZN002";
class ListData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedIndex: -1,
      searchQuery: "",
      selectAll: false,
      documentFiles: [],
      selectedFiles: [],
      page: 0,
      rowsPerPage: 10,
      showLoader: false,
      selected_files_by_id: [],
      username: localStorage.getItem("username"),
      snackbarOpen: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
      isLoading: false, // Add isLoading state to track data loading
      // folder_name: this.props.folders[this.props.folderIndex]["folder_name"],
      folder_name: this.props.folder_name,
      folder_id: this.props.folder_id,
    };
  }
  componentDidMount() {
    this.fetchDocumentFiles(); // Call the function when the component mounts
  }
  fetchDocumentFiles = async () => {
    const { folder_id } = this.state;
    // const { folders, folderIndex } = this.props;
    const token = localStorage.getItem("token");
    this.setState({
      isLoading: true,
      // folder_name: folders[folderIndex]["folder_name"],
    });
    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    const email = localStorage.getItem("email");
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    try {
      const response = await fetch(
        `${BASE_URL}/zn002/user/document_files/${email}?userOrganization=${encodeURIComponent(
          userOrganization
        )}&application_code=${encodeURIComponent(
          application_code
        )}&folder_id=${encodeURIComponent(folder_id)}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const responseData = await response.json(); // Parse the response as JSON
        if (Array.isArray(responseData.user_files)) {
          this.setState({
            documentFiles: responseData.user_files,
            isLoading: false, // Set isLoading to false once data is fetched
          });
        } else {
          console.error(
            "Data is not an array in the server response:",
            responseData
          );
        }
      } else {
        console.error("Server response was not OK. Status:", response.status);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.error("Error fetching data:", error);
    }
  };

  handleClick = (event, index) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedIndex: index,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      selectedIndex: -1,
    });
  };

  handleAddfile = () => {
    const { folder_name, folder_id } = this.state;
    this.props.handleUploadClick(folder_name, folder_id, {
      showUpload: true,
      showChat: false,
      ShowListdata: false,
    });
  };

  truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  handleDeleteFile = async (fileIdsToDelete) => {
    const token = localStorage.getItem("token");
    // const username = localStorage.getItem('username')
    // const designation = localStorage.getItem('designation')
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    const formData = new FormData();
    const { documentFiles } = this.state;

    let filteredDocumentFiles = [];
    let adminFiles = documentFiles.filter(
      (file) => fileIdsToDelete.includes(file.id) && file.isAdmin
    );

    if (adminFiles.length > 0) {
      if (adminFiles.length === 1) {
        this.handleSnackbarOpen(
          "error",
          `${adminFiles.length} file cannot be deleted that is added by admin`
        );
      } else if (adminFiles.length > 1) {
        this.handleSnackbarOpen(
          "error",
          `${adminFiles.length} files cannot be deleted that are added by admin`
        );
      }
    }

    filteredDocumentFiles = documentFiles.filter(
      (file) =>
        fileIdsToDelete.includes(file.id) &&
        !file.isAdmin &&
        file.embedding_status !== "Processing"
    );
    filteredDocumentFiles.map((item) => item.id);
    const file_ids = filteredDocumentFiles.map((item) => item.id);
    formData.append("file_ids", file_ids);
    formData.append("userOrganization", userOrganization);
    formData.append("application_code", application_code);
    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }

    try {
      const response = await fetch(`${BASE_URL}/zn002/user/document_files`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        // Fetch updated file list after deletion
        this.fetchDocumentFiles();
        this.setState({
          selected_files_by_id: [],
          selectedFiles: [],
          selectAll: false,
        });
      } else {
        console.error("Failed to delete files");
      }
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;

    if (newRowsPerPage === -1) {
      // If "All" is selected, set rowsPerPage to the total number of rows.
      this.setState({
        rowsPerPage: this.state.documentFiles.length,
        page: 0, // Reset the page to the first page
      });
    } else {
      // For other values, set rowsPerPage to the selected value.
      this.setState({
        rowsPerPage: parseInt(newRowsPerPage, 10),
        page: 0, // Reset the page to the first page
      });
    }
  };

  handleSelectAllClick = () => {
    const { selectAll, documentFiles } = this.state;
    let selectedFiles, selected_files_by_id;

    if (selectAll) {
      // If "Select All" is already checked, unselect all files
      selectedFiles = [];
      selected_files_by_id = [];
    } else {
      // If "Select All" is not checked, select all files by fileId
      const availableFiles = documentFiles.filter(
        (file) => file.embedding_status === "Available"
      );
      selectedFiles = availableFiles.map((file) => ({
        id: file.id,
        filename: file.filename,
      }));
      selected_files_by_id = availableFiles.map((file) => file.id);
    }

    this.setState({
      selectAll: !selectAll,
      selectedFiles,
      selected_files_by_id,
    });
  };

  handleCheckboxChange = (filename, id) => {
    const { selectedFiles, selected_files_by_id, documentFiles } = this.state;

    if (selectedFiles.some((selectedFile) => selectedFile.id === id)) {
      this.setState(
        (prevState) => ({
          selectedFiles: prevState.selectedFiles.filter(
            (selectedFile) => selectedFile.id !== id
          ),
        }),
        () => {
          if (this.state.selectedFiles.length === documentFiles.length) {
            this.setState({ selectAll: true });
          }
          if (this.state.selectedFiles.length === documentFiles.length-1) {
            this.setState({ selectAll: false });
          }
        }
      );
    } else {
      // File is not selected, add it to the selected files
      this.setState(
        (prevState) => ({
          selectedFiles: [
            ...prevState.selectedFiles,
            { id: id, filename: filename },
          ],
        }),
        () => {
          if (this.state.selectedFiles.length === documentFiles.length) {
            this.setState({ selectAll: true });
          }
          if (this.state.selectedFiles.length === documentFiles.length-1) {
            this.setState({ selectAll: false });
          }
        }
      );
    }
    if (selected_files_by_id.includes(id)) {
      // File is already selected, remove it from the selected files
      this.setState((prevState) => ({
        // selected_files_by_id: prevState.selected_files_by_id.filter((id) => id !== id),
        selected_files_by_id: prevState.selected_files_by_id.filter(
          (fileid) => fileid !== id
        ),
      }));
    } else {
      // File is not selected, add it to the selected files
      this.setState((prevState) => ({
        selected_files_by_id: [...prevState.selected_files_by_id, id],
      }));
    }
  };

  handleUpdateKnowledge = async () => {
    // Get the selected files and user token
    // const { selectedFiles } = this.state;
    const token = localStorage.getItem("token");
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    // Create a FormData object to send data as a multipart/form-data request
    if(localStorage.getItem('filenameAndSummary')){
      localStorage.removeItem('filenameAndSummary');
    }
    const formData = new FormData();

    // Append selected files and user information to the FormData

    formData.append("username", localStorage.getItem("username"));
    formData.append("organization", userOrganization);
    const filenames = this.state.selectedFiles.map((file) => file.filename);
    const fileIds = this.state.selectedFiles.map((file) => file.id);
    const filteredData = this.state.documentFiles.filter(
      (obj) => filenames.includes(obj.filename) && fileIds.includes(obj.id)
    );
    formData.append("filedetails", JSON.stringify(filteredData));
    // Check if a user token is available
    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }

    try {
      // Show the loader while the update is in progress
      this.setState({ showLoader: true });

      // Send a POST request to the server to analyze the documents
      const response = await fetch(`${BASE_URL}/zn002/bot/analyseDocument`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Include the user's token in the request headers
        },
        body: formData, // Include the FormData with files and user information in the request body
      });

      if(response.status===500){
        this.handleSnackbarOpen(
          "error",
          "Something went wrong."
        );
      }
      // Check if the response status is OK (HTTP 200)
      if (response.ok) {
        // Call the chat click handler to navigate to the chat page
        const res = await response.json();
        const files_path = res["files_path"];
        const filenames=res["filenames"];
        const file_summary=res["summary_response"];
        const filenameAndSummary = {};
        filenames.forEach((filename, index) => {
          if (index === 0) {
            filenameAndSummary[filename] = file_summary;
        } else {
            filenameAndSummary[filename] = "";
        }
      });
        //  console.log("filenames",filenames);
        localStorage.setItem("zn002_files_path", JSON.stringify(files_path));
        localStorage.setItem("filenameAndSummary", JSON.stringify(filenameAndSummary));
        this.props.handleChatClick(files_path,filenames,file_summary,filenameAndSummary, {
          showUpload: false, // Hide the upload interface
          showChat: true, // Show the chat interface
          ShowKnowledge: false, // Hide the knowledge interface
        });
      } else {
        // Log an error message if the response status is not OK
        console.error("Error updating knowledge. Status:", response.status);
      }
    } catch (error) {
      // Log an error message if there is an exception during the request
      console.error("Error updating knowledge:", error);
    } finally {
      // Hide the loader when the update is complete (whether it succeeds or fails)
      this.setState({ showLoader: false });
    }
  };
  getUsername = (username, isadmin) => {
    if (isadmin) {
      return "Admin";
    } else {
      return username;
    }
  };
  handleSnackbarOpen = (severity, message) => {
    this.setState({
      snackbarOpen: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };
  reset = () => {
    window.location.reload();
  };
  backToFolder = () => {
    this.props.backFolder(false);
  };

  render() {
    // const { anchorEl, selectedIndex, searchQuery, disableUploadButton, showLoader, selectAll, documentFiles, page, rowsPerPage } = this.state;
    const {
      searchQuery,
      disableUploadButton,
      showLoader,
      selectAll,
      documentFiles,
      page,
      rowsPerPage,
      snackbarOpen,
      snackbarSeverity,
      snackbarMessage,
    } = this.state;

    const { is_Admin_folder } = this.props;

    return (
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5%",
            padding: "20px",
          }}
        >
          <Button
            style={{
              backgroundColor: "#6001FF",
              color: "#ffffff",
              marginRight: !is_Admin_folder ? "48%" : "59%",
              textTransform: "none",
            }}
            variant="contained"
            onClick={this.backToFolder}
          >
            Back To Folder
          </Button>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F3F6FD",
              borderRadius: "25px",
              padding: "5px",
              marginRight: "3%",
            }}
          >
            <SearchIcon
              sx={{ color: "#6001FF", marginLeft: "10px", marginRight: "5px" }}
            />
            <InputBase
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => this.setState({ searchQuery: e.target.value })}
              sx={{
                color: "#6001FF",
                width: "40%",
                backgroundColor: "#F3F6FD",
                borderRadius: "25px",
              }}
            />
          </Box>
          <ListItemIcon sx={{ color: "#000", pt: 1 }}>
            <RestartAltOutlinedIcon
              sx={{
                color: "#6001FF",
                width: "45px",
                height: "35px",
                "&:hover": {
                  backgroundColor: "#EFEFEF",
                  borderRadius: "50%", // Making background circular
                },
              }}
              onClick={this.reset}
            />
          </ListItemIcon>

          {!is_Admin_folder && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              style={{
                backgroundColor: "#6001FF",
                color: "#fff",
                marginLeft: "15px",
                textTransform: "none",
              }}
              onClick={this.handleAddfile}
            >
              Add File
            </Button>
          )}
        </div>
        {/* <div
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        > */}
        {this.state.selectedFiles.length > 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "2%",
            }}
          >
            <Typography variant="body1" color="textSecondary">
              {`${this.state.selectedFiles.length} ${
                this.state.selectedFiles.length === 1 ? "file" : "files"
              } selected`}
            </Typography>
            <IconButton
              aria-label="Delete"
              onClick={() =>
                this.handleDeleteFile(this.state.selected_files_by_id)
              }
            >
              <Tooltip title="Remove" arrow>
                <DeleteIcon style={{ marginLeft: "10%" }} />
              </Tooltip>{" "}
            </IconButton>
          </div>
        )}
        {/* </div> */}
        <Paper
          elevation={5}
          style={{
            width: "100%",
            borderRadius: "10px",
            backgroundColor: "#FFF",
          }}
        >
          <>
            {/* <p style={{paddingTop:"4px",paddingLeft:"60px"}}>Inside {folder_name}</p> */}
            {/* {`${folder_name}`} */}
            {/* Conditional rendering for loader */}
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {this.state.isLoading && (
                <CircularProgress style={{ color: "#335" }} />
              )}
              {""}
            </CardContent>
            {/* Display stats if data is available */}
            {!this.state.isLoading && (
              <List component="nav" aria-label="file list">
                <ListItem>
                  <ListItemText style={{ width: "2%" }}></ListItemText>
                  <ListItemText style={{ width: "10%" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "1.1rem",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                      }}
                    >
                      File Type
                    </Typography>
                  </ListItemText>
                  <ListItemText style={{ width: "10%" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "1.1rem",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                      }}
                    >
                      File Name
                    </Typography>
                  </ListItemText>
                  <ListItemText style={{ width: "10%" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "1.1rem",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                      }}
                    >
                      Upload Date
                    </Typography>
                  </ListItemText>
                  <ListItemText style={{ width: "10%" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "1.1rem",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                      }}
                    >
                      Size
                    </Typography>
                  </ListItemText>
                  <ListItemText style={{ width: "10%" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "1.1rem",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                      }}
                    >
                      User
                    </Typography>
                  </ListItemText>
                  <ListItemText style={{ width: "10%" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "1.1rem",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                      }}
                    >
                      Status
                    </Typography>
                  </ListItemText>
                  <ListItemText style={{ width: "10.5%" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "1.1rem",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                      }}
                    >
                      <Checkbox
                        sx={{
                          color: "#6001FF",
                          "&.Mui-checked": { color: "#6001FF" },
                        }}
                        id="selectAll"
                        checked={selectAll}
                        onChange={this.handleSelectAllClick}
                        size="small"
                      />
                      Select
                    </Typography>
                  </ListItemText>
                </ListItem>
                <Divider />
                {documentFiles
                  .filter((file) =>
                    file.filename
                      .toLowerCase()
                      .includes(searchQuery.trim().toLowerCase())
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((file, index) => (
                    <React.Fragment key={index}>
                      <ListItemButton
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <ListItemText style={{ width: "2%" }}></ListItemText>
                        <ListItemIcon
                          style={{ width: "10%", marginLeft: "1%" }}
                        >
                          {/* Display the logo based on the databasetype */}
                          {file.filetype === "application/pdf" && (
                            <img
                              src={PdfIcon}
                              alt="application/pdf"
                              style={{ width: "40%", height: "30%" }}
                            />
                          )}
                          {file.filetype ===
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                            <img
                              src={DocIcon}
                              alt="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              style={{ width: "38%", height: "40%" }}
                            />
                          )}
                          {file.filetype === "application/doc" && (
                            <img
                              src={DocIcon}
                              alt="application/doc"
                              style={{ width: "38%", height: "40%" }}
                            />
                          )}
                          {file.filetype === "text/plain" && (
                            <img
                              src={TextIcon}
                              alt="text/plain"
                              style={{ width: "38%", height: "40%" }}
                            />
                          )}
                          {/* Add more conditions for other database types */}
                        </ListItemIcon>
                        <ListItemText style={{ width: "10%" }}>
                          <Tooltip
                            title={file.filename}
                            placement="top-start"
                            arrow
                          >
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              sx={{
                                fontSize: "1rem",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 400,
                                ml: 3,
                              }}
                            >
                              {this.truncateText(file.filename, 10)}
                            </Typography>
                          </Tooltip>
                        </ListItemText>
                        <ListItemText style={{ width: "10%" }}>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={{
                              fontSize: "1rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                              ml: 3,
                            }}
                          >
                            {file.uploaded_date}
                          </Typography>
                        </ListItemText>
                        <ListItemText style={{ width: "10%" }}>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={{
                              fontSize: "1rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                            }}
                          >
                            {file.filesize}
                          </Typography>
                        </ListItemText>
                        <ListItemText style={{ width: "10%" }}>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={{
                              fontSize: "1rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                            }}
                          >
                            {this.getUsername(file.username, file.isAdmin)}
                          </Typography>
                        </ListItemText>
                        <ListItemText style={{ width: "10%" }}>
                          {file.embedding_status === "Processing" && (
                            <Chip
                              label={file.embedding_status}
                              variant="outlined"
                            />
                          )}
                          {file.embedding_status === "Unavailable" && (
                            <Chip
                              label={file.embedding_status}
                              variant="outlined"
                              color="warning"
                            />
                          )}
                          {file.embedding_status === "Failed" && (
                            <Chip
                              label={file.embedding_status}
                              variant="outlined"
                              color="error"
                            />
                          )}

                          {file.embedding_status === "Available" && (
                            <Chip
                              label={file.embedding_status}
                              variant="outlined"
                              color="success"
                            />
                          )}

                          {/* <Typography variant="body1" color="textSecondary" sx={{ fontSize: '1rem', fontFamily: 'Poppins, sans-serif', fontWeight: 400, }}>{file.embedding_status}</Typography> */}
                        </ListItemText>
                        <ListItemText style={{ width: "10%" }}>
                          {file.embedding_status === "Processing" ||
                          file.embedding_status === "Unavailable" ? (
                            <Checkbox
                              size="small"
                              sx={{
                                color: "#6001FF",
                                "&.Mui-checked": { color: "#6001FF" },
                              }}
                              checked={this.state.selectedFiles.includes(
                                file.filename
                              )}
                              onChange={() =>
                                this.handleCheckboxChange(
                                  file.filename,
                                  file.id
                                )
                              }
                              disabled
                            />
                          ) : (
                            <Checkbox
                              size="small"
                              sx={{
                                color: "#6001FF",
                                "&.Mui-checked": { color: "#6001FF" },
                              }}
                              checked={this.state.selectedFiles.some(
                                (selectedFile) =>
                                  selectedFile.id === file.id &&
                                  selectedFile.filename === file.filename
                              )}
                              onChange={() =>
                                this.handleCheckboxChange(
                                  file.filename,
                                  file.id
                                )
                              }
                            />
                          )}
                        </ListItemText>
                      </ListItemButton>
                      {index !== file.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
              </List>
            )}

            {/* onClick={() => { this.handleClose(); this.handleDeleteFile(file.id); }} */}
          </>
        </Paper>

        <div
          style={{
            display: "flex",
            marginTop: "10px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TablePagination
            style={{ marginLeft: "1%" }} // Adjust the margin as needed
            rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
            component="div"
            count={documentFiles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />

          <Button
            variant="contained"
            color="primary"
            style={{
              marginRight: "2%",
              backgroundColor: "#6001FF",
              color: "#FFFFFF",
              cursor: "pointer",
              textTransform: "none",
            }}
            disabled={
              documentFiles.length === 0 ||
              disableUploadButton === true ||
              this.state.selectedFiles.length === 0
            }
            onClick={this.handleUpdateKnowledge}
            endIcon={<ArrowCircleRightOutlinedIcon style={{ fontSize: 30 }} />}
          >
            {showLoader ? (
              <CircularProgress
                size={25}
                sx={{
                  color: "#FFFFFF",
                  marginLeft: "-7%",
                }}
              />
            ) : (
              "Query"
            )}
          </Button>
        </div>

        <Snackbar
          open={snackbarOpen} // Use the correct state name here (snackbarOpen)
          autoHideDuration={3000}
          onClose={this.handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.handleSnackbarClose}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
        <Notification />
      </Container>
    );
  }
}

export default withRouter(ListData);
