import React, { Component } from "react";
import { Container } from "@mui/material";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import TableComponent from "./datatable";
import { withRouter } from "./withRouter";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import S3bucketComponent from "./S3bucketComponent";
import axios, { CancelToken, isCancel } from "axios";
import FormFileUpload from "./FormFileUpload";
import FileUploadCSV from "./FileUploadCSV";
import FileUploadSQLite from "./FileUploadSQLite";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const application_code = "ZN001"; // for csv
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const dbtype = [
  {
    value: "CSV",
    label: "CSV",
  },
  {
    value: "SQLite",
    label: "SQLite",
  },
  {
    value: "PostgreSQL",
    label: "PostgreSQL",
  },
  {
    value: "MYSQL",
    label: "MYSQL",
  },
  {
    value: "RedShift",
    label: "RedShift",
  },
  {
    value: "S3 Bucket",
    label: "S3 Bucket",
  },
  {
    value: "Oracle",
    label: "Oracle",
  },
];
const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};
const selectFieldSx = {
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
  },
  "& .MuiMenu-paper": {
    maxHeight: "200px", // Set the maximum height for the dropdown
    maxWidth: "100px",
  },
};
class FileUpload extends Component {
  constructor(props) {
    super(props);
    // Create a ref
    //  this.handleAddMoreFiles=this.handleAddMoreFiles.bind(this)
    this.cancelFileUpload = React.createRef();
    this.state = {
      selectedFiles: [],
      message: "",
      showAddMoreButton: false,
      dbType: "CSV",
      userName: "",
      password: "",
      hostName: "",
      databasename: "",
      alreadyExistingFiles: [],
      connecting: false,
      value: 0,
      databaseInfo: null,
      showLoader: false,

      columns: [
        { field: "id", headerName: "ID", width: 70 },
        { field: "tablename", headerName: "Table Name", width: 130 },
        {
          field: "rows",
          headerName: "Rows",
          type: "number",
          width: 90,
        },
      ],
      rows: null,
      showsnack: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
      showSnackbar: false,
      severity: "",
      progress: 0, //file uploading tracker
      loaded_1: 0,
      target_1: 0,
      next: false,
      isEditDatabase: false,
    };
  }
  componentDidMount() {
    // Retrieve databaseInfo from localStorage when the component mounts
    const storedDatabaseInfo = localStorage.getItem("databaseInfo");
    if (storedDatabaseInfo) {
      // Parse the stored JSON string and update the component's state
      this.setState({ databaseInfo: JSON.parse(storedDatabaseInfo) }, () => {
        // Once the state is updated, set the form field values
        const { hostName, userName, password, databasename, table_count } =
          this.state.databaseInfo;
        const dbType = this.state.databaseInfo["databasetype"];
        this.setState({
          dbType,
          hostName,
          userName,
          password,
          databasename,
          table_count,
          isEditDatabase: true,
        });
      });
    }
  }
  handleChatClickInUpload = () => {
    this.props.handleChatClick({
      showUpload: false,
      showChat: true,
    });
  };
  handleSnackbarOpen = (severity, message) => {
    this.setState({
      showSnackbar: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({ showSnackbar: false });
  };

  handleNextClick = () => {
    this.props.handleTabClick({
      customTabValue: 2, // Set the customTabValue to 2
    });
  };

  handleFileChange = (e) => {
    e.preventDefault();
    const files = Array.from(e.target.files);

    // Check for valid file formats (e.g., allow only CSV files)
    const validFormats = ["csv", "xlsx", "tsv"]; // Add valid file formats here
    const invalidFiles = files.filter((file) => {
      const fileFormat = file.name.split(".").pop().toLowerCase();
      return !validFormats.includes(fileFormat);
    });

    if (invalidFiles.length > 0) {
      this.setState({
        showSnackbar: true,
        snackbarSeverity: "error",
        snackbarMessage: `Invalid file format: ${invalidFiles
          .map((file) => file.name)
          .join(", ")}`,
      });
      return;
    }

    this.setState((prevState) => ({
      selectedFiles: [...prevState.selectedFiles, ...files],
      showAddMoreButton: true,
    }));
  };
  handleDeleteFile = (index) => {
    this.setState((prevState) => {
      const newSelectedFiles = [...prevState.selectedFiles];
      newSelectedFiles.splice(index, 1);
      return { selectedFiles: newSelectedFiles };
    });
  };

  showSkeletonVisible = () => {
    this.setState({ showSkeleton: true });
  };
  showSkeletonHide = () => {
    this.setState({ showSkeleton: false });
  };

  hiderows = (event) => {
    this.setState({ rows: null });
  };

  handleFetchTables = async () => {
    const { userName, password, hostName, databasename, dbType } = this.state;
    if (
      userName === "" ||
      password === "" ||
      hostName === "" ||
      databasename === ""
    ) {
      this.setState({
        showsnack: true,
        severity: "error",
        snackmessage: "Fill all the fields",
      });
      return;
    }
    this.setState({ connecting: true });

    this.hiderows();
    this.showSkeletonVisible();

    try {
      const formData = new FormData();
      const token = localStorage.getItem("token");

      formData.append("userName", userName);
      formData.append("password", password);
      formData.append("hostName", hostName);
      formData.append("databasename", databasename);
      formData.append("dbType", dbType);
      let endpoint;
      if (dbType === "MYSQL") {
        endpoint = `${BASE_URL}/zn001/user/select_tables`;
      } else if (dbType === "PostgreSQL") {
        endpoint = `${BASE_URL}/zn001/user/select_tables_postgres`;
      } else if (dbType === "Oracle") {
        endpoint = `${BASE_URL}/zn001/user/select_tables_oracle`;
      } else {
        console.error("Unsupported database type");
        return;
      }

      const responseSelectTables = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const resultFetchTables = await responseSelectTables.json();

      if (responseSelectTables.ok) {
        if (resultFetchTables.status) {
          // Handle error response from the server
          this.setState({
            showSkeleton: false,
            showsnack: true,
            severity: "error",
            snackmessage: resultFetchTables.message,
          });
        } else {
          // Handle success response from the server
          const { table_count } = resultFetchTables; // Extract table_count from the response

          this.setState({
            showSkeleton: false,
            showsnack: true,
            severity: "success",
            snackmessage: "Connection successful",
          });
          const userOrganization =
            JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
              ?.organization ?? "";
          const email = localStorage.getItem("email") || "";
          // Only call the database endpoint if there were no errors during table selection
          const responseStoreData = await fetch(
            `${BASE_URL}/zn001/user/databases`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                userName: userName,
                hostName: hostName,
                password: password,
                databasename: databasename,
                dbType: dbType,
                table_count: table_count, // Include table_count in the request payload
                is_active: true,
                userAccessList: [email],
                organization: userOrganization,
              }),
            }
          );

          const result = await responseStoreData.json();

          if (responseStoreData.ok) {
            if (result.status) {
              // Handle error response from the server
              console.log(result.message);
              this.setState({
                showSkeleton: false,
                showsnack: true,
                severity: "error",
                snackmessage: result.message,
              });
            } else {
              // Handle success response from the server
              this.setState({
                showSkeleton: false,
                showsnack: true,
                severity: "success",
                snackmessage: "Connection successful",
              });
              this.props.handleTabClick({
                showTab: true,
                showUpload: false,
                showChat: false,
                ShowBook: false,
              });
            }
          } else if (responseStoreData.status === 401) {
            // Handle a 403 (Forbidden) response
            this.props.navigate("/");
          } else {
            // Handle other error responses from the server
            // const errorData = await responseStoreData.json();
            this.setState({
              showSkeleton: false,
              showsnack: true,
              severity: "error",
              snackmessage: result.detail,
            });
            console.error("DB connect error:", result.detail);
          }
        }
      } else if (responseSelectTables.status === 403) {
        // Handle a 403 (Forbidden) response for table selection
        this.props.navigate("/");
      } else {
        // Handle other error responses from table selection
        // const errorData = await responseSelectTables.json();
        this.setState({
          showSkeleton: false,
          showsnack: true,
          severity: "error",
          snackmessage: "Error occurred while selecting tables",
        });
        console.error("Table selection error:", resultFetchTables);
      }
    } catch (error) {
      // Handle network errors or unexpected errors
      this.setState({
        message: "Network error occurred",
      });
      console.error("Error:", error);
    } finally {
      // Set the 'connecting' state to false when connection completes (success or failure)
      this.setState({ connecting: false });
    }
  };
  handletable = async () => {
    const { userName, password, hostName, databasename, dbType } = this.state;

    if (
      userName === "" ||
      password === "" ||
      hostName === "" ||
      databasename === ""
    ) {
      this.setState({
        showsnack: true,
        severity: "error",
        snackmessage: "Fill all the fields",
      });
      return;
    }

    this.hiderows();
    this.showSkeletonVisible();

    try {
      const formData = new FormData();
      const token = localStorage.getItem("token");

      formData.append("userName", userName);
      formData.append("password", password);
      formData.append("hostName", hostName);
      formData.append("databasename", databasename);
      formData.append("dbType", dbType);
      let endpoint;
      if (dbType === "MYSQL") {
        endpoint = `${BASE_URL}/zn001/user/select_tables`;
      } else if (dbType === "PostgreSQL") {
        endpoint = `${BASE_URL}/zn001/user/select_tables_postgres`;
      } else if (dbType === "Oracle") {
        endpoint = `${BASE_URL}/zn001/user/select_tables_oracle`;
      } else {
        console.error("Unsupported database type");
        return;
      }

      const responseSelectTables = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (responseSelectTables.ok) {
        const resultFetchTables = await responseSelectTables.json();
        if (resultFetchTables.status) {
          // Handle error response from the server

          this.setState({
            showSkeleton: false,
            showsnack: true,
            severity: "error",
            snackmessage: resultFetchTables.message,
          });
        } else {
          // Handle success response from the server
          const tableCount = resultFetchTables.table_count;

          this.setState({
            showSkeleton: false,
            showsnack: true,
            severity: "success",
            snackmessage: "Connection successful",
            table_count: tableCount, // Update the table_count in your UI state
          });
          return tableCount; // Return the table_count value
        }
      } else {
        // Handle non-OK response (e.g., network error)
        this.setState({
          showSkeleton: false,
          showsnack: true,
          severity: "error",
          snackmessage: "Failed to fetch tables",
        });
      }
    } catch (error) {
      // Handle unexpected errors
      this.setState({
        showSkeleton: false,
        showsnack: true,
        severity: "error",
        snackmessage: "An error occurred while fetching tables",
      });
      console.error("Error:", error);
    }
  };

  handleReceiveMessage = (event) => {
    // this.setState({ receivedMessage: event.data });
    console.log(event.data);
  };
  enableLoader = () => {
    this.setState({ showLoader: true });
  };
  disableLoader = () => {
    this.setState({ showLoader: false });
  };

  handleUpload = async (e) => {
    e.preventDefault();
    this.setState({ connecting: true });
    const { selectedFiles, alreadyExistingFiles } = this.state;
    const largeFiles = selectedFiles.filter(
      (file) => file.size > MAX_FILE_SIZE
    );
    const existingFiles = selectedFiles.filter((file) =>
      alreadyExistingFiles.includes(file.name)
    );

    if (largeFiles.length > 0) {
      this.setState({
        showSnackbar: true,
        snackbarMessage: "One or more files exceed the size limit of 10MB.",
      });
      return;
    }

    if (existingFiles.length > 0) {
      this.setState({
        showSnackbar: true,
        snackbarSeverity: "error",
        snackbarMessage: `The following files already exist ${
          existingFiles.name
        }: ${existingFiles.map((file) => file.name).join(", ")}`,
        //snackbarMessage: `The following files already exist ${existingFiles.name}`
      });
      return;
    }

    try {
      const formData = new FormData();
      selectedFiles.forEach((file) => formData.append("files", file));
      const userOrganization = JSON.parse(
        localStorage.getItem("currentLoggedInUserDetails")
      )["organization"];

      formData.append("organization", userOrganization);
      formData.append("userEmail", localStorage.getItem("email"));
      formData.append("application_code", application_code);
      const token = localStorage.getItem("token");

      const url = `${BASE_URL}/zn001/user/new_csvfileupload`;
      const responseData = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // Add a progress event listener to track upload progress
        onUploadProgress: (data) => {
          let uploadProgress = Math.round((data.loaded * 100) / data.total);

          if (uploadProgress < 100) {
            this.setState({
              progress: uploadProgress,
              loaded_1: data.loaded,
              target_1: data.total,
            });
          }
        },
        cancelToken: new CancelToken(
          (cancel) => (this.cancelFileUpload.current = cancel)
        ),
      });
      // connecting:false
      this.setState({ progress: 100, loaded_1: 100, target_1: 100 });

      if (responseData.statusText) {
        this.setState({
          message: responseData.data.message,
          next: true,
          selectedFiles: [],
          showAddMoreButton: false,
          severity: "success",
          snackmessage: responseData.data.message,
          showsnack: true,
          // Perform further actions after successful file upload
        });
      } else {
        this.setState({
          message: responseData.data.detail,
          next: true,
          severity: "error",
          snackmessage: responseData.data.detail,
          showsnack: true,
        });
        console.error("File upload error:", responseData.data.detail);
      }
    } catch (error) {
      this.setState({
        message: error.response?.data?.detail || error.message,

        severity: "error",
        snackmessage: error.response?.data?.detail || error.message,
        showsnack: true,
      });
      if (error.response?.data?.detail) {
        this.setState({
          next: false,
        });
      }
      if (isCancel(error)) {
        console.error("Error:", error.message);
      }
      console.error("Error:", error);
    } finally {
      // Set the 'connecting' state to false when connection completes (success or failure)
      this.setState({ connecting: false });
    }
  };
  handleSQliteUpload = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      this.state.selectedFiles.forEach((file) =>
        formData.append("files", file)
      );

      const response = await fetch(`${BASE_URL}/sqliteupload`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        this.setState({
          message: data.message,
          selectedFiles: [],
          showAddMoreButton: false,
        });
        // Perform further actions after successful file upload
      } else {
        const errorData = await response.json();
        this.setState({
          message: errorData.detail,
        });
        console.error("File upload error:", errorData);
      }
    } catch (error) {
      this.setState({
        message: "Network error occurred",
      });
      console.error("Error:", error);
    }
  };
  handleUpdateDatabase = async () => {
    const { dbType, hostName, userName, password, databasename, databaseInfo } =
      this.state;
    this.setState({ connecting: true });

    // Check if the form is dirty (changes have been made)
    const isFormDirty =
      dbType !== databaseInfo.databasetype ||
      hostName !== databaseInfo.hostName ||
      userName !== databaseInfo.userName ||
      password !== databaseInfo.password ||
      databasename !== databaseInfo.databasename;
    let tableCount;
    try {
      // Perform the update operation only if the form is dirty
      if (isFormDirty) {
        // Update the database information including the ID
        tableCount = await this.handletable();
      } else {
        this.setState({
          showsnack: true,
          severity: "info",
          snackmessage: "Database details are same. Checking for new tables",
        });
        tableCount = await this.handletable();
      }
      if (typeof tableCount === "undefined") {
        // Exit the function
        return;
      }
      const token = localStorage.getItem("token");
      const userOrganization =
        JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
          ?.organization ?? "";
      const response = await fetch(
        `${BASE_URL}/zn001/user/databases/${databaseInfo.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: databaseInfo.id, // Include the ID here
            dbType,
            hostName,
            userName,
            password,
            databasename,
            table_count: tableCount, // Use the updated table_count value obtained from handletable
            organization: userOrganization,
          }),
        }
      );

      if (response.ok) {
        // Handle success
        const result = await response.json();
        this.setState({
          showSnackbar: true,
          snackbarSeverity: "success",
          snackbarMessage: result.message,
        });

        // Call handletable after a successful database update
        await this.handletable(); // Assuming handletable is an asynchronous function
        this.props.handleTabClick({
          showTab: true,
          showUpload: false,
          showChat: false,
          ShowBook: false,
        });
      } else {
        // Handle error response
        const errorData = await response.json();
        this.setState({
          showSnackbar: true,
          snackbarSeverity: "error",
          snackbarMessage: errorData.detail,
        });
      }

      // Rest of your code
    } catch (error) {
      // Handle network errors or unexpected errors
      this.setState({
        showSnackbar: true,
        snackbarSeverity: "error",
        snackbarMessage: "An error occurred while updating the database.",
      });
      console.error("Error:", error);
    } finally {
      // Set the 'connecting' state to false when connection completes (success or failure)
      this.setState({ connecting: false });
    }
  };

  handleAddMoreFiles = () => {
    this.fileInputRef.value = null;
    this.fileInputRef.click();
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  cancelUpload = () => {
    if (this.cancelFileUpload.current) {
      this.cancelFileUpload.current("User has cancelled the file upload");
    }
  };
  render() {
    const {
      databaseInfo,
      connecting,
      selectedFiles,
      message,
      showAddMoreButton,
      dbType,
      userName,
      password,
      hostName,
      databasename,
      rows,
      showsnack,
      severity,
      snackmessage,
      progress,
      next,
    } = this.state;
    const hasDatabaseInfo = databaseInfo !== null;

    return (
      <Container>
        <Paper
          elevation={5}
          style={{
            marginTop: "5%",
            borderRadius: "20px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <div style={{ marginLeft: "5%", padding: "10px" }}>
            {!connecting && (
              <div>
                {!this.state.isEditDatabase && (
                  <TextField
                    id="dbType"
                    select
                    label="Database Type"
                    name="dbType"
                    value={dbType}
                    onChange={this.handleInputChange}
                    defaultValue="SQLite"
                    helperText="Please select your Database Type"
                    style={{
                      marginTop: "25px",
                      width: "400px",
                      marginBottom: "10px",
                    }}
                    SelectProps={{
                      MenuProps: {
                        style: { maxHeight: "200px" },
                      },
                    }}
                    sx={selectFieldSx}
                  >
                    {dbtype.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </div>
            )}
            {dbType === "CSV" && (
              <FileUploadCSV
                connecting={connecting}
                selectedFiles={selectedFiles}
                progress={progress}
                showAddMoreButton={showAddMoreButton}
                next={next}
                handleUpload={this.handleUpload}
                handleFileChange={this.handleFileChange}
                handleAddMoreFiles={this.handleAddMoreFiles}
                cancelUpload={this.cancelUpload}
                handleDeleteFile={this.handleDeleteFile}
                handleNextClick={this.handleNextClick}
              />
            )}
            {dbType === "MYSQL" && (
              // <form onSubmit={this.handledbconnect}>
              <FormFileUpload
                hostName={hostName}
                userName={userName}
                password={password}
                databasename={databasename}
                hasDatabaseInfo={hasDatabaseInfo}
                connecting={connecting}
                textFieldSx={textFieldSx}
                handleInputChange={this.handleInputChange}
                handleUpdateDatabase={this.handleUpdateDatabase}
                handleFetchTables={this.handleFetchTables}
              />
            )}
            {dbType === "PostgreSQL" && (
              // <form onSubmit={this.handledbconnect}>
              <FormFileUpload
                hostName={hostName}
                userName={userName}
                password={password}
                databasename={databasename}
                hasDatabaseInfo={hasDatabaseInfo}
                connecting={connecting}
                textFieldSx={textFieldSx}
                handleInputChange={this.handleInputChange}
                handleUpdateDatabase={this.handleUpdateDatabase}
                handleFetchTables={this.handleFetchTables}
              />
            )}
            {dbType === "SQLite" && (
              <FileUploadSQLite
                selectedFiles={selectedFiles}
                showAddMoreButton={showAddMoreButton}
                message={message}
                handleFileChange={this.handleFileChange}
                handleSQliteUpload={this.handleSQliteUpload}
                handleChatClickInUpload={this.handleChatClickInUpload}
              />
            )}
            {dbType === "S3 Bucket" && (
              <S3bucketComponent
                dbType={dbType}
                handleTabClick={this.props.handleTabClick}
              />
            )}
            {dbType === "Oracle" && (
              // <form onSubmit={this.handledbconnect}>
              <FormFileUpload
                hostName={hostName}
                userName={userName}
                password={password}
                databasename={databasename}
                hasDatabaseInfo={hasDatabaseInfo}
                connecting={connecting}
                textFieldSx={textFieldSx}
                handleInputChange={this.handleInputChange}
                handleUpdateDatabase={this.handleUpdateDatabase}
                handleFetchTables={this.handleFetchTables}
              />
            )}
          </div>
        </Paper>
        <br />
        <br />
        {rows !== null ? (
          <div>
            <div style={{ height: 400, width: "100%" }}>
              <TableComponent
                data={rows}
                username={userName}
                password={password}
                host={hostName}
                databasename={databasename}
                handleChatClick={this.handleChatClickInUpload}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <Snackbar
          open={showsnack}
          autoHideDuration={6000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            this.setState({ showsnack: false });
          }}
        >
          <Alert
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }

              this.setState({ showsnack: false });
            }}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {snackmessage}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export default withRouter(FileUpload);
