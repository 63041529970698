import React, { Component } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton } from "@mui/material";
import Button from "@mui/material/Button";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

export class FileUploadSQLite extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {};
  }
  handleAddMoreFiles = () => {
    this.fileInputRef.value = null;
    this.fileInputRef.click();
  };
  render() {
    const {
      selectedFiles,
      showAddMoreButton,
      message,
      handleFileChange,
      handleSQliteUpload,
      handleChatClickInUpload,
    } = this.props;
    return (
      <>
        <div>
          <h2>File Upload</h2>
          <form onSubmit={handleSQliteUpload}>
            <input
              type="file"
              multiple
              style={{ display: "none" }}
              onChange={handleFileChange}
              ref={(ref) => (this.fileInputRef = ref)}
            />
            {selectedFiles.length >= 0 && (
              <Button onClick={() => this.handleAddMoreFiles()}>
                {showAddMoreButton ? "Add More Files" : "Add File"}
              </Button>
            )}
            &nbsp;&nbsp;
            <Button
              variant="contained"
              color="error"
              onClick={handleSQliteUpload}
              disabled={selectedFiles.length === 0}
            >
              Upload Files
            </Button>
          </form>

          <h3>Selected Files:</h3>
          <List>
            {selectedFiles.map((file, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <InsertDriveFileOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={file.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          {message && (
            <div>
              <p>{message}</p>
              <Button
                variant="contained"
                color="success"
                onClick={handleChatClickInUpload}
              >
                Next
              </Button>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default FileUploadSQLite;
