import React, { Component } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Card,
  Paper,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import CustomMenu from "@mui/material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import Menu from "../../Menu";
import { withRouter } from "../withRouter";
import Utils from "../../../userComponents/Utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};

const LLMModel = [
  {
    value: "gpt-3.5-turbo",
    label: "gpt-3.5-turbo",
  },
  {
    value: "gpt-3.5-turbo-16k",
    label: "gpt-3.5-turbo-16k",
  },
  {
    value: "gpt-3.5-turbo-0613",
    label: "gpt-3.5-turbo-0613",
  },
  {
    value: "gpt-3.5-turbo-16k-0613",
    label: "gpt-3.5-turbo-16k-0613",
  },
  {
    value: "gpt-4o-mini",
    label: "gpt-4o-mini",
  }
  // {
  //   value: 'gpt-3.5-turbo-1106',
  //   label: 'gpt-3.5-turbo-1106',
  // },
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 4 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

class CustomizePrompts extends Component {
  constructor(props) {
    super(props);
    let url = window.location.href;
    let params = new URL(url).searchParams;
    let page = params.get("p");
    this.state = {
      //value: props.value,
      value: parseInt(page),
      // Set the default tab index to 0
      profilePic: null,
      assistantName: "",
      tasks: "",
      outputPresentation: "",
      responseLength: "",
      avoidActions: "",
      selectedCheckboxes: {
        dataAnalysis: false,
        informationExtraction: false,
        accessLLMCorpus: false,
      },
      checkboxArray: [],
      showSnackbar: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
      languageModel: "gpt-3.5-turbo-16k",
      modelTemperature: 0.4,
      isCacheReuse: true,
      anchorEl: null,
      methodsCalled: localStorage.getItem("methodsCalled")
        ? JSON.parse(localStorage.getItem("methodsCalled"))
        : {},
      botImages: localStorage.getItem("botImages")
        ? JSON.parse(localStorage.getItem("botImages"))
        : {},

      isChatMemoryEnabled: false,
      customizePromptAlert: false,
      // didUpdateCount:0,
    };
    //we need to store previous data for alert pop up after clicking on Menu Icon
    this.prevState = {};
  }
  async componentDidMount() {
    await this.fetchPromtDetails();
    await this.fetchLLmConfigDetails();
    // console.log("componentDidMount");
    // this.prevState={...this.state}
    console.log("isChatMemoryEnabled", this.prevState.isChatMemoryEnabled);
    // console.log("prevState",this.prevState);
  }
  async componentDidUpdate(prevProps, prevState) {
    const token = localStorage.getItem("token");

    //  console.log("componentDidUpdate");
    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    // console.log("component didUpdate");
    // console.log("this.prevState",this.prevState);
  }
  componentWillUnmount() {
    console.log("componentWillUnmount");
  }
  resetState = () => {
    this.setState(
      {
        showSnackbar: false,
        snackbarSeverity: "success",
        snackbarMessage: "",
        languageModel: "gpt-3.5-turbo-16k",
        modelTemperature: 0.4,
        isCacheReuse: true,
        changesMade: false,
      },
      this.handleSaveLLMConfigChanges
    );
  };
  handleResetCustomizedPrompts = () => {
    this.setState(
      {
        profilePic: null,
        assistantName: "",
        tasks: "",
        outputPresentation: "",
        responseLength: "",
        avoidActions: "",
        selectedCheckboxes: {
          dataAnalysis: false,
          informationExtraction: false,
          accessLLMCorpus: false,
        },
        checkboxArray: [],
      },
      this.handleSaveChanges
    );
  };

  fetchPromtDetails = async () => {
    // console.log("fetchPromtDetails");
    const token = localStorage.getItem("token");

    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const response = await fetch(
        `${BASE_URL}/admin/customize_prompts/${userOrganization}?applicationType=${encodeURIComponent(
          "zunofordata"
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const result = await response.json();
        if (result.message) {
          // here the user is creating the prompt for first time
          return;
        } else {
          const equipAssistant = result.equip_assistant;
          const checkboxArray = Object.keys(equipAssistant).filter(
            (key) => equipAssistant[key]
          );
          this.setState(
            {
              profilePic: result.profilePic,
              assistantName: result.assistantName,
              tasks: result.tasks,
              outputPresentation: result.outputPresentation,
              responseLength: result.responseLength,
              avoidActions: result.avoidActions,
              selectedCheckboxes: equipAssistant,
              checkboxArray: checkboxArray,
            },
            () => {
              this.prevState = { ...this.state };
              console.log("this.prevState", this.prevState);
            }
          );
          // this.prevState={
          //   profilePic: result.profilePic,
          //   assistantName: result.assistantName,
          //   tasks: result.tasks,
          //   outputPresentation: result.outputPresentation,
          //   responseLength: result.responseLength,
          //   avoidActions: result.avoidActions,
          //   selectedCheckboxes: equipAssistant,
          //   checkboxArray: checkboxArray,
          // }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  fetchLLmConfigDetails = async () => {
    // console.log("fetchLLmConfigDetails");
    const token = localStorage.getItem("token");

    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const response = await fetch(
        `${BASE_URL}/admin/llm_configuration/${userOrganization}?applicationType=${encodeURIComponent(
          "zunofordata"
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const result = await response.json();

        if (result.message) {
          // here the user is creating the prompt for first time
          return;
        } else {
          this.setState(
            {
              languageModel: result.llm_configuration.llm_model,
              modelTemperature: result.llm_configuration.temperature,
              isCacheReuse: result.llm_configuration.cache_reuse,
              isChatMemoryEnabled: result.llm_configuration.isChatMemoryEnabled,
            },
            () => {
              this.prevState = { ...this.state };
              console.log("this.prevState", this.prevState);
            }
          );

          // this.prevState={
          //   ...this.prevState,
          //   languageModel: result.llm_configuration.llm_model,
          //   modelTemperature: result.llm_configuration.temperature,
          //   isCacheReuse: result.llm_configuration.cache_reuse,
          //   isChatMemoryEnabled: result.llm_configuration.isChatMemoryEnabled,
          // }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  handleCheckboxChange = (checkboxName) => {
    this.setState({ customizePromptAlert: true });

    this.setState((prev) => {
      const updatedCheckboxes = {
        ...prev.selectedCheckboxes,
        [checkboxName]: !prev.selectedCheckboxes[checkboxName],
      };
      const updatedCheckboxArray = Object.keys(updatedCheckboxes).filter(
        (checkbox) => updatedCheckboxes[checkbox]
      );

      return {
        selectedCheckboxes: updatedCheckboxes,
        checkboxArray: updatedCheckboxArray,
      };
    });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleEnableButton = (event) => {
    const { name, checked } = event.target;
    this.setState({
      [name]: checked,
    });
  };
  handleSaveLLMConfigChanges = async () => {
    const token = localStorage.getItem("token");

    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
      return;
    }
    this.setState({ customizePromptAlert: false });
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    const formData = {
      organization: userOrganization,
      applicationType: "zunofordata",
      llm_config: {
        llm_model: this.state.languageModel,
        temperature: this.state.modelTemperature,
        cache_reuse: this.state.isCacheReuse,
        isChatMemoryEnabled: this.state.isChatMemoryEnabled,
      },
    };
    this.prevState = { ...this.state };

    try {
      const response = await fetch(`${BASE_URL}/admin/llm_configuration_data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        this.handleSnackbarOpen(
          "success",
          "Successfully saved LLM Configuration"
        );
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      this.handleSnackbarOpen("error", error);
    }
  };
  handleSaveChanges = async () => {
    const token = localStorage.getItem("token");

    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    this.setState({ customizePromptAlert: false });
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    const formData = {
      applicationType: "zunofordata",
      organization: userOrganization,
      equip_assistant: this.state.selectedCheckboxes, // Corrected variable name
      profilePic: this.state.profilePic,
      assistantName: this.state.assistantName,
      tasks: this.state.tasks,
      outputPresentation: this.state.outputPresentation,
      responseLength: this.state.responseLength,
      avoidActions: this.state.avoidActions,
    };
    this.prevState = { ...this.state };
    try {
      const response = await fetch(`${BASE_URL}/admin/customize_prompts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        this.handleSnackbarOpen(
          "success",
          "Successfully saved prompt template"
        );
      } else {
        console.log(response);
      }
      // Optionally, you can reset the form or show a success message
    } catch (error) {
      console.error("Error saving data:", error);
      this.handleSnackbarOpen("error", error);
      // Handle errors, show an error message, etc.
    }
    const methodCalled = { ...this.state.methodsCalled };
    const botImage = { ...this.state.botImages };
    botImage["profilePicData"] = this.state.profilePic;
    methodCalled["CSVgetProfileData"] = true;
    this.setState({ methodsCalled: methodCalled, botImages: botImage }, () => {
      localStorage.setItem(
        "methodsCalled",
        JSON.stringify(this.state.methodsCalled)
      );
      localStorage.setItem("botImages", JSON.stringify(this.state.botImages));
    });
  };

  handleProfilePicChange = (event) => {
    // Handle profile picture change
    const file = event.target.files[0];

    if (file) {
      if (file.size > 1048576) {
        // toast.error("File size should be less than 1MB");
        this.handleSnackbarOpen("error", "File size should be less than 1MB");
        return;
      }
      const reader = new FileReader();

      reader.onloadend = () => {
        this.setState({
          profilePic: reader.result,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  handleSnackbarOpen = (severity, message) => {
    this.setState({
      showSnackbar: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({ showSnackbar: false });
  };
  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleRemovePicture = () => {
    this.setState({ profilePic: null, anchorEl: null });
  };
  handleUploadPicture = () => {
    this.fileInput.click();
    this.handleMenuClose();
  };

  handleSideMenuClick = (event) => {
    console.log("entered handleSideMenuClick");
    if (this.state.customizePromptAlert) {
      // Show an alert or confirmation dialog
      const userConfirmed = window.confirm(
        "You have unsaved changes. Are you sure you want to leave this page?"
      );
      // console.log("entered handleSideMenuClick",userConfirmed);
      if (!userConfirmed) {
        // If user cancels, prevent navigation
        console.log("!userConfirmed");
        return true;
      } else {
        console.log("userConfirmed");
        this.setState({ customizePromptAlert: false });
        //  () => {
        this.fetchPromtDetails();
        this.fetchLLmConfigDetails();
        // });
        return false;
      }
    }
  };

  checkAllStates = async () => {
    if (this.state.profilePic !== this.prevState.profilePic) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.assistantName !== this.prevState.assistantName) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.tasks !== this.prevState.tasks) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.outputPresentation !== this.prevState.outputPresentation) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.responseLength !== this.prevState.responseLength) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.avoidActions !== this.prevState.avoidActions) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (
      this.state.selectedCheckboxes.dataAnalysis !==
      this.prevState.selectedCheckboxes.dataAnalysis
    ) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (
      this.state.selectedCheckboxes.informationExtraction !==
      this.prevState.selectedCheckboxes.informationExtraction
    ) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (
      this.state.selectedCheckboxes.accessLLMCorpus !==
      this.prevState.selectedCheckboxes.accessLLMCorpus
    ) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.languageModel !== this.prevState.languageModel) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.modelTemperature !== this.prevState.modelTemperature) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.isCacheReuse !== this.prevState.isCacheReuse) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    if (this.state.isChatMemoryEnabled !== this.prevState.isChatMemoryEnabled) {
      this.setState({ customizePromptAlert: true });
      return;
    }
    this.setState({ customizePromptAlert: false });
  };

  render() {
    const {
      value,
      profilePic,
      assistantName,
      tasks,
      avoidActions,
      outputPresentation,
      responseLength,
      showSnackbar,
      snackbarSeverity,
      snackbarMessage,
      modelTemperature,
      languageModel,
      isCacheReuse,
      anchorEl,
      isChatMemoryEnabled,
      customizePromptAlert,
    } = this.state;
    const open = Boolean(anchorEl);
    // console.log("customizePromptAlert",customizePromptAlert);
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "2%",
        }}
      >
        {/* <Prompt
          when={this.state.customizePromptAlert}
          message={this.handleLeavePage}
        /> */}
        <Menu
          selectedButton="App Management"
          customizePromptAlert={customizePromptAlert}
          onMenuClick={this.handleSideMenuClick}
          checkAllStates={this.checkAllStates}
        />
        <Typography
          variant="h1"
          component="div"
          sx={{
            flexGrow: 1,
            color: "#6001FF",
            fontSize: "2rem",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 600,
          }}
        >
          Zuno For Data
        </Typography>
        <Card
          elevation={2}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "20px",
            marginTop: "4%",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              textColor="#6001FF"
              onChange={this.handleChange}
              aria-label="basic tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#6001FF",
                },
              }}
            >
              <Tab
                label="LLM Configurations"
                {...a11yProps(0)}
                sx={{
                  color: value === 0 ? "#6001FF" : "inherit",
                  "&.Mui-selected": {
                    color: "#6001FF",
                  },
                }}
              />
              <Tab
                label="Customize Prompts"
                {...a11yProps(1)}
                sx={{
                  color: value === 1 ? "#6001FF" : "inherit",
                  "&.Mui-selected": {
                    color: "#6001FF",
                  },
                }}
              />
              <Tab
                label="Manage Data Resources"
                {...a11yProps(2)}
                sx={{
                  color: value === 2 ? "#6001FF" : "inherit",
                  "&.Mui-selected": {
                    color: "#6001FF",
                  },
                }}
              />
              <Tab
                label="Deployment Settings"
                {...a11yProps(3)}
                sx={{
                  color: value === 3 ? "#6001FF" : "inherit",
                  "&.Mui-selected": {
                    color: "#6001FF",
                  },
                }}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Box
              id="sql-csv-llm-config-box"
              sx={{
                // '& .MuiTextField-root': { m: 1, width: '50ch' },
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Box
                id="languageModel"
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "50ch" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
                noValidate
                autoComplete="off"
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  style={{
                    fontSize: "1.2rem",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Choose your language model
                </Typography>
                <TextField
                  id="LanguageModel"
                  select
                  label="Language Model"
                  value={languageModel}
                  style={{ marginLeft: "10%" }}
                  name="languageModel"
                  onChange={(event) => {
                    this.handleInputChange(event);
                    this.setState({ customizePromptAlert: true });
                  }}
                  sx={textFieldSx}
                >
                  {LLMModel.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box
                component="form"
                sx={{
                  "& .MuiSlider-root": { m: 2, width: "50ch" },
                  "& .MuiSlider-valueLabel": {
                    lineHeight: 1.2,
                    fontSize: 12,
                    background: "unset",
                    padding: 0,
                    width: 32,
                    height: 32,
                    borderRadius: "50% 50% 50% 0",
                    backgroundColor: "#6001FF",
                    transformOrigin: "bottom left",
                    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
                    "&:before": { display: "none" },
                    "&.MuiSlider-valueLabelOpen": {
                      transform:
                        "translate(50%, -100%) rotate(-45deg) scale(1)",
                    },
                    "& > *": {
                      transform: "rotate(45deg)",
                    },
                  },

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
                noValidate
                autoComplete="off"
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  style={{
                    fontSize: "1.2rem",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Set the temperature for your model
                </Typography>
                <Slider
                  valueLabelDisplay="auto"
                  aria-labelledby="discrete-slider"
                  marks={[
                    { value: 0, label: "0" },
                    { value: 1, label: "1" },
                  ]}
                  value={modelTemperature}
                  step={0.1}
                  min={0}
                  max={1}
                  slotProps={{
                    markLabel: {
                      style: {
                        transform: "translateY(-10px) translateX(-3.3px)", // Adjust this value as needed
                        fontFamily: "Poppins, sans-serif", // Optional: Keep consistent with your typography style
                        fontSize: "1.2rem", // Optional: Keep consistent with your typography style
                      },
                    },
                  }}
                  style={{
                    color: "#6001FF",
                    marginLeft: "6.7%",
                    marginBottom: "2%",
                  }}
                  name="modelTemperature"
                  onChange={(event) => {
                    this.handleInputChange(event);
                    this.setState({ customizePromptAlert: true });
                  }}
                />
              </Box>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ display: "flex", flexDirection: "row", marginTop: "2%" }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  style={{
                    fontSize: "1.2rem",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Enable chat memory
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isChatMemoryEnabled}
                      inputProps={{
                        "aria-label": "Enable model chat memory",
                        style: {
                          "&.MuiChecked": {
                            color: "#6001FF", // Color when checked
                          },
                          "&.MuiDisabled": {
                            color: "#818589", // Color when disabled
                          },
                        },
                      }}
                      onChange={(event) => {
                        this.setState({ customizePromptAlert: true });
                        this.handleEnableButton(event);
                      }}
                      name="isChatMemoryEnabled"
                    />
                  }
                  // label="Enable chat memory"
                  labelPlacement="start"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "14%",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "2%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "2%",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    style={{
                      fontSize: "1.2rem",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Should the model utilize previously processed results to
                    save token consumption?
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    style={{ color: "#818589", fontSize: "0.8rem" }}
                  >
                    It helps to give response without consuming tokens when
                    asked exact same query again.
                    <br />
                    Enable or disable the cache storing as per your
                    requirements.
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "2%" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isCacheReuse}
                        inputProps={{
                          "aria-label": "Cache Reuse",
                          style: {
                            "&.MuiChecked": {
                              color: "#6001FF", // Color when checked
                            },
                            "&.MuiDisabled": {
                              color: "#818589", // Color when disabled
                            },
                          },
                        }}
                        onChange={(event) => {
                          this.setState({ customizePromptAlert: true });
                          this.handleEnableButton(event);
                        }}
                        name="isCacheReuse"
                      />
                    }
                    label="Cache reuse"
                    labelPlacement="start"
                    sx={{ marginLeft: "-1%" }}
                  />
                </Box>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#6001FF",
                    color: "#fff",
                    marginLeft: "40%",
                  }}
                  onClick={() => {
                    this.resetState();
                  }}
                >
                  Reset Changes{" "}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#6001FF",
                    color: "#fff",
                    marginLeft: "2%",
                  }}
                  onClick={() => {
                    this.handleSaveLLMConfigChanges();
                  }}
                >
                  Save Changes
                </Button>
              </Box>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box style={{ display: "flex" }}>
              <Paper elevation={0} sx={{ width: "100%" }}>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  {/* Empty Avatar */}
                  <Box
                    // onClick={() => this.fileInput.click()} // Trigger file input on avatar click
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      backgroundColor: "#E0E0E0",
                      cursor: "pointer",
                      overflow: "hidden",
                      marginRight: "20px",
                      position: "relative",
                    }}
                  >
                    {profilePic ? (
                      <img
                        src={profilePic}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <Box
                        style={{
                          position: "absolute",
                          top: "45%",
                          left: "45%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        Profile Picture
                      </Box>
                    )}
                    <Box
                      onClick={(event) => this.handleMenuOpen(event)}
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        backgroundColor: "white",
                        borderRadius: "50%",
                        padding: "2px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <EditIcon style={{ fontSize: "20px", color: "#000" }} />
                    </Box>
                  </Box>

                  {/* Hidden file input */}
                  <input
                    type="file"
                    accept="image/*"
                    ref={(input) => (this.fileInput = input)}
                    style={{ display: "none" }}
                    onChange={this.handleProfilePicChange}
                  />
                  <CustomMenu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={(event) => this.handleMenuClose(event)}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        this.setState({ customizePromptAlert: true });
                        this.handleUploadPicture();
                      }}
                    >
                      Upload Picture
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        this.setState({ customizePromptAlert: true });
                        this.handleRemovePicture();
                      }}
                    >
                      Remove Picture
                    </MenuItem>
                  </CustomMenu>

                  <Box style={{ marginLeft: "0.4%" }}>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        flexGrow: 1,
                        fontSize: "1.2rem",
                        fontFamily: "Poppins, sans-serif",
                        // marginTop: "5%",
                      }}
                    >
                      Name your AI Assistant
                    </Typography>
                    <TextField
                      id="standard-basic"
                      name="assistantName"
                      type="assistantName"
                      value={assistantName}
                      onChange={(event) => {
                        this.setState({ customizePromptAlert: true });
                        this.handleInputChange(event);
                      }} // Update the onChange handler
                      fullWidth
                      sx={textFieldSx}
                      style={{
                        width: "604%",
                      }}
                    />
                  </Box>
                </Box>
                {/* TextField */}

                <Box style={{ marginLeft: "9%" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      fontSize: "1.2rem",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: "5%",
                    }}
                  >
                    What should your AI Assistant do?
                  </Typography>

                  <TextField
                    id="outlined-multiline-static"
                    name="tasks"
                    type="tasks"
                    value={tasks}
                    onChange={(event) => {
                      this.setState({ customizePromptAlert: true });
                      this.handleInputChange(event);
                    }} // Update the onChange handler
                    fullWidth
                    multiline
                    rows={8}
                    sx={textFieldSx}
                  />
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      fontSize: "1.2rem",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: "6%",
                    }}
                  >
                    Equip your Assistant
                  </Typography>
                </Box>
                <Box style={{ display: "flex", gap: "20px", marginLeft: "9%" }}>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.selectedCheckboxes.dataAnalysis}
                          onChange={() => {
                            this.handleCheckboxChange("dataAnalysis");
                          }}
                          style={{
                            color: this.state.selectedCheckboxes.dataAnalysis
                              ? "#6001FF"
                              : "",
                          }}
                        />
                      }
                      label="Data Analysis"
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            this.state.selectedCheckboxes.informationExtraction
                          }
                          onChange={() => {
                            this.handleCheckboxChange("informationExtraction");
                          }}
                          style={{
                            color: this.state.selectedCheckboxes
                              .informationExtraction
                              ? "#6001FF"
                              : "",
                          }}
                        />
                      }
                      label="Information Extraction"
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            this.state.selectedCheckboxes.accessLLMCorpus
                          }
                          onChange={() => {
                            this.handleCheckboxChange("accessLLMCorpus");
                          }}
                          style={{
                            color: this.state.selectedCheckboxes
                              .informationExtraction
                              ? "#6001FF"
                              : "",
                          }}
                        />
                      }
                      label="Access LLM Corpus"
                    />
                  </Box>
                </Box>

                <Box style={{ marginLeft: "9%" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      fontSize: "1.2rem",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: "5%",
                    }}
                  >
                    How do you want the output to be presented ?
                  </Typography>

                  <TextField
                    id="outlined-multiline-static"
                    name="outputPresentation"
                    type="outputPresentation"
                    value={outputPresentation}
                    onChange={(event) => {
                      this.setState({ customizePromptAlert: true });
                      this.handleInputChange(event);
                    }} // Update the onChange handler
                    multiline
                    rows={3}
                    style={{ width: "100%" }}
                    sx={textFieldSx}
                  />
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      fontSize: "1.2rem",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: "5%",
                    }}
                  >
                    What is the preferrable response length?
                  </Typography>

                  <TextField
                    id="outlined-multiline-static"
                    name="responseLength"
                    type="responseLength"
                    value={responseLength}
                    placeholder="e.g. 1000 characters"
                    onChange={(event) => {
                      this.setState({ customizePromptAlert: true });
                      this.handleInputChange(event);
                    }}
                    multiline
                    rows={3}
                    style={{ width: "100%" }}
                    sx={textFieldSx}
                  />
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      fontSize: "1.2rem",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: "5%",
                    }}
                  >
                    Anything the AI assistant should avoid doing?
                  </Typography>
                  <TextField
                    id="outlined-multiline-static"
                    name="avoidActions"
                    type="avoidActions"
                    value={avoidActions}
                    onChange={(event) => {
                      this.setState({ customizePromptAlert: true });
                      this.handleInputChange(event);
                    }}
                    multiline
                    rows={3}
                    style={{ width: "100%" }}
                    sx={textFieldSx}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#6001FF",
                      color: "#fff",
                      marginLeft: "75%",
                      marginTop: "20px",
                    }}
                    onClick={() => {
                      this.handleResetCustomizedPrompts();
                    }}
                  >
                    Reset Changes
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#6001FF",
                      color: "#fff",
                      marginLeft: "2.5%",
                      marginTop: "20px",
                    }}
                    onClick={() => {
                      this.handleSaveChanges();
                    }}
                  >
                    Save Changes
                  </Button>
                </Box>
              </Paper>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}></CustomTabPanel>
          <CustomTabPanel value={value} index={3}></CustomTabPanel>
        </Card>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
        >
          <Alert
            onClose={this.handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

export default withRouter(CustomizePrompts);
