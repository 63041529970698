import React, { Component } from "react";
// Accordion
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// Table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CodeIcon from "@mui/icons-material/Code";
import CodeOffIcon from "@mui/icons-material/CodeOff";

import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ButtonGroup from "@mui/material/ButtonGroup";
import DownloadIcon from "@mui/icons-material/Download";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import IconButton from "@mui/material/IconButton";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import Barchart from "./Barchart";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};
export class SqlViewQuery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      CSVFileName: "",
      snackbarOpen: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
    };
  }
  async downloadCSVFile() {
    const { CSVFileName } = this.state;
    // Check for folders with name length greater than 20
    const filename = CSVFileName.trim();
    console.log("filename", filename);
    if (filename.length === 0) {
      this.handleSnackbarOpen(
        "error",
        "file name should contain some characters"
      );
      return;
    } else if (filename.length >= 20) {
      this.handleSnackbarOpen(
        "error",
        "file name should be less than 20 characters"
      );
      return;
    } else if (this.hasSpecialFirstCharacter(filename)) {
      this.handleSnackbarOpen(
        "error",
        `${filename} name should start with alphabetic character`
      );
      return;
    } else if (this.hasSpecialCharacters(filename)) {
      this.handleSnackbarOpen(
        "error",
        `${filename} name contains special character`
      );
      return;
    }

    this.setState({ showModal: false });
    const columnArray = this.props.message.col_names;
    const rowArray = this.props.message.rows;
    let csvRows = [];
    csvRows.push(columnArray.join(","));

    // Add the data rows
    rowArray.forEach((row) => {
      // Map each value, converting null to 'null' and enclosing values with commas in quotes
      let csvRow = row.map((value) => {
        if (value === null) {
          return "null";
        } else if (
          typeof value === "string" &&
          (value.includes(",") || value.includes('"') || value.includes("\n"))
        ) {
          return `"${value.replace(/"/g, '""')}"`; // Escape double quotes by doubling them
        } else {
          return value;
        }
      });
      csvRows.push(csvRow.join(","));
    });

    let CSVContent = csvRows.join("\n");
    // console.log(CSVContent);

    // Optional: If you want to download the CSV file
    const blob = new Blob([CSVContent], { type: "text/csv;charset=utf-8;" });
    console.log("blob", blob);
    const link = document.createElement("a");
    // console.log("link",link);
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      //console.log("url",url);
      link.setAttribute("href", url);
      link.setAttribute("download", filename + ".csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.setState({ CSVFileName: "" });
      this.handleSnackbarOpen("success", "downloded successfully");
    }
  }

  handleSnackbarOpen = (severity, message) => {
    this.setState({
      snackbarOpen: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };
  hasSpecialCharacters = (folderName) => {
    // Define a regular expression to match special characters
    const regex = /[^a-zA-Z0-9_.-\s]+/;
    // returns boolean value from test()
    return regex.test(folderName);
  };

  hasSpecialFirstCharacter = (folderName) => {
    // Check if the first character is a special character
    const firstCharSpecial = /^[^a-zA-Z\s]/.test(folderName.charAt(0));
    return firstCharSpecial;
  };
  render() {
    const {
      message,
      index,
      viewQuery,
      toggleBookmark,
      toggleChartVisibility,
      viewSQL,
      isChartVisible,
      isChartDataValid,
    } = this.props;
    const {
      showModal,
      CSVFileName,
      snackbarOpen,
      snackbarSeverity,
      snackbarMessage,
    } = this.state;
    return (
      <>
        <Accordion
          style={{
            width: "90%",
            marginLeft: "25px",
            "box-shadow": "none",
          }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={
              <Tooltip title="View Query">
                <AutoAwesomeIcon style={{ color: "#6001ff" }} />
              </Tooltip>
            }
          ></AccordionSummary>
          <AccordionDetails>
            {
              <div style={{ display: "flex" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <TableContainer
                      component={Paper}
                      sx={{
                        overflow: "auto",
                        marginLeft: "4%",
                        maxHeight: "320px",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#6001FF #F5F5F5",
                        "&::-webkit-scrollbar": {
                          width: "8px",
                          height: "8px",
                          backgroundColor: "#F5F5F5",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          borderRadius: "4px",
                          height: "4px",
                          backgroundColor: "#888",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                          backgroundColor: "#555",
                        },
                        "& .scrollbar": {
                          scrollbarWidth: "thin",
                          scrollbarColor: "#6001FF #F5F5F5",
                        },
                      }}
                    >
                      <Table
                        sx={{ maxWidth: "max-content" }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            {message.col_names &&
                              message.col_names.map((columnName, index) => (
                                <TableCell key={index}>{columnName}</TableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {message.rows &&
                            message.rows.map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                {row &&
                                  row.map((cols, col_index) => (
                                    <TableCell
                                      key={col_index}
                                      component="th"
                                      scope="row"
                                    >
                                      {cols}
                                    </TableCell>
                                  ))}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        marginTop: "10%",
                        marginLeft: "8%",
                      }}
                    >
                      {isChartVisible &&
                      message.chartdata &&
                      message.chartdata.x_data &&
                      message.chartdata.y_data &&
                      message.chartdata.x_data.length > 0 &&
                      message.chartdata.y_data.length > 0 &&
                      isChartDataValid(
                        message.chartdata.x_data,
                        message.chartdata.y_data
                      ) ? (
                        <Barchart
                          col_names={message.col_names || []}
                          x_axis_data={message.chartdata.x_data}
                          y_axis_data={message.chartdata.y_data}
                        />
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            }
            {viewQuery === true ? (
              <div>
                <br />
                <div style={{ overflow: "auto", maxWidth: "100%" }}>
                  <SyntaxHighlighter
                    language="SQL"
                    style={docco}
                    customStyle={{
                      marginLeft: "4%",
                      marginTop: "2%",
                      fontSize: "1.25rem",
                      maxWidth: "max-content",
                    }}
                  >
                    {message.SQL}
                  </SyntaxHighlighter>
                </div>
                <ButtonGroup variant="text" aria-label="text button group">
                  <Tooltip title="Hide SQL">
                    <IconButton onClick={viewSQL}>
                      <CodeOffIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Download CSV">
                    <IconButton
                      onClick={() => this.setState({ showModal: true })}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                  {message.bookmarked ? (
                    <Tooltip title="Bookmarked">
                      <IconButton>
                        <BookmarkAddedIcon style={{ color: "#6001FF" }} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Bookmark">
                      <IconButton onClick={() => toggleBookmark(index)}>
                        <BookmarkBorderIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip title="Graph">
                    <IconButton onClick={toggleChartVisibility}>
                      <AutoGraphIcon />
                    </IconButton>
                  </Tooltip>
                </ButtonGroup>
              </div>
            ) : (
              <div>
                <ButtonGroup
                  variant="text"
                  aria-label="text button group"
                  style={{ marginTop: "2%" }}
                >
                  <Tooltip title="Show SQL">
                    <IconButton onClick={viewSQL}>
                      <CodeIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Download CSV">
                    <IconButton
                      onClick={() => this.setState({ showModal: true })}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                  {message.bookmarked ? (
                    <Tooltip title="Bookmarked">
                      <IconButton onClick={() => toggleBookmark(index)}>
                        <BookmarkAddedIcon style={{ color: "#6001FF" }} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Bookmark">
                      <IconButton onClick={() => toggleBookmark(index)}>
                        <BookmarkBorderIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip title="Graph">
                    <IconButton onClick={toggleChartVisibility}>
                      <AutoGraphIcon />
                    </IconButton>
                  </Tooltip>
                </ButtonGroup>
              </div>
            )}

            <br />
          </AccordionDetails>
        </Accordion>
        <Modal
          open={showModal}
          onClose={() => this.setState({ showModal: false })}
        >
          <Paper
            elevation={5}
            style={{
              marginTop: "15%",
              marginLeft: "30%",
              borderRadius: "20px",
              width: "35%",
              minHeight: "170px",
              backgroundColor: "#FFFFFF",
              // paddingTop: "15px",
            }}
          >
            <DialogTitle>New CSV File</DialogTitle>
            <TextField
              label="CSV File Name"
              value={CSVFileName}
              onChange={(e) => this.setState({ CSVFileName: e.target.value })}
              sx={{
                width: "92%",
                marginTop: "0.5%",
                marginLeft: "3.5%",
                ...textFieldSx,
              }}
            />
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "15px",
              }}
            >
              <p
                style={{
                  marginRight: "8%",
                  color: "#6001FF",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({ showModal: false, CSVFileName: "" });
                }}
              >
                Cancel
              </p>
              <Button
                style={{
                  color: "#fff",
                  cursor: "pointer",
                  backgroundColor: "#6001FF",
                  textTransform: "none",
                  marginTop: "1.4%",
                  marginRight: "5%",
                  height: "1%",
                }}
                onClick={() => {
                  this.downloadCSVFile();
                }}
              >
                Download
              </Button>
            </Box>
          </Paper>
        </Modal>
        <Snackbar
          open={snackbarOpen} // Use the correct state name here (snackbarOpen)
          autoHideDuration={3000}
          onClose={this.handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.handleSnackbarClose}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

export default SqlViewQuery;
