import React, { Component } from "react";
import styled from "styled-components";
import {
  Dialog as MuiDialog,
  Button,
  Typography,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";

import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import Botimageicon from "./botimageicon";

const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    border-radius: 20px;
  }
`;

const UserQuery = ({ query }) => (
  <div
    key="userQuery"
    className="message userQuery"
    style={{
      display: "flex",
      background: "#FFFFFF",
      borderRadius: "20px",
      padding: "10px 10px 10px 10px",
      width: "95%",
      margin: "10px 10px 10px 10px",
    }}
  >
    <ListItemAvatar sx={{ marginRight: "1.3%", marginTop: "10px" }}>
      <AccountCircleSharpIcon style={{ fontSize: 50, color: "#6001ff" }} />
    </ListItemAvatar>
    <ListItemText
      primary=""
      secondary={query.split("\n").map((paragraph, i) => (
        <p key={i} style={{ fontSize: "1.25rem", color: "#818589" }}>
          {paragraph}
        </p>
      ))}
      sx={{ fontSize: "1.25rem", color: "#818589" }}
      disableTypography
    />
  </div>
);

const ZunoResponse = ({ bookmarkData }) => (
  <div
    key="zunoResponse"
    className="zunoResponse"
    style={{
      display: "flex",
      flexDirection: "column",
      background: "#FFFFFF",
      borderRadius: "20px",
      padding: "10px 10px 10px 10px",
      width: "95%",
      margin: "10px 10px 20px 10px",
    }}
  >
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <ListItemAvatar sx={{ marginRight: "1.3%" }}>
        <Botimageicon />
      </ListItemAvatar>
      <ListItemText
        primary=""
        secondary={bookmarkData.bookmark_data.response
          .split("\n")
          .map((paragraph, i) => (
            <p key={i} style={{ fontSize: "1.25rem", color: "#818589" }}>
              {paragraph}
            </p>
          ))}
        sx={{ fontSize: "1.25rem", color: "#818589" }}
        disableTypography
      />
    </div>
  </div>
);

const CloseButton = ({ onClose }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "flex-end",
      marginRight: "20px",
      marginBottom: "20px",
    }}
  >
    <Button
      onClick={onClose}
      variant="contained"
      sx={{ backgroundColor: "#6001FF" }}
    >
      Close
    </Button>
  </div>
);

class ViewBookmark extends Component {
  render() {
    const { open, onClose, bookmarkData } = this.props;

    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth sx={{}}>
        <div
          style={{
            overflow: "auto",
            background: "#F3F6FD",
            fontSize: "30px",
          }}
        >
          {bookmarkData ? (
            <>
              <UserQuery query={bookmarkData.bookmark_data.query} />
              <ZunoResponse bookmarkData={bookmarkData} />
            </>
          ) : (
            <Typography variant="body1">No bookmark data available</Typography>
          )}
          <CloseButton onClose={onClose} />
        </div>
      </Dialog>
    );
  }
}

export default ViewBookmark;
