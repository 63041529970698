import React, { Component } from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ChatRetryLangChain from "../ChatRetryLangChain";
import PropTypes from "prop-types";
import WebChatInputBase from "./WebChatInputBase";
import LoaderMessages from "../LoaderMessages";
import Botimageicon from "./botimageicon";
import WaterLoader from "../waterLoader";
import HeaderTextWeb from "./HeaderTextWeb";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import PersonIcon from "@mui/icons-material/Person";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
export class ChatMapWeb extends Component {
  constructor(props) {
    super(props);
    const profileImage = localStorage.getItem("profileImage");
    let parsedProfileImage = null;

    if (profileImage && profileImage !== "undefined") {
      try {
        parsedProfileImage = JSON.parse(profileImage);
      } catch (error) {
        console.error("Error parsing profile image from localStorage", error);
      }
    }
    this.state = {
      profileImage: parsedProfileImage,
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    if (this.props.messagesWeb.length !== prevProps.messagesWeb.length)
      this.props.handleSetTimeOutMsgWeb();
    this.scrollToBottom();
  }
  scrollToBottom() {
    const container = this.containerRef.current;
    // Check if containerRef is not null before accessing properties
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }
  render() {
    const { profileImage } = this.state;

    const {
      messagesWeb,
      loader,
      langChainIndex,
      loader_Messages,
      systemError,
      timeOutMsg,
      retryTime,
      tryCount,
      newMessage,
      handleInputChange,
      handleKeyDown,
      handleSendButton,
      handleRetry,
      toggleBookmark,
      profilePicWeb,
      assistantNameWeb,
    } = this.props;
    return (
      <>
        <Box
          ref={this.containerRef}
          style={{
            height: "calc(95vh - 160px)",
            overflow: "auto",
            padding: "10px 10px 10px 10px",
            marginLeft: "-2%",
            width: "97%",
            background: "#F3F6FD",
            borderRadius: "20px 20px 0px 0px",
            fontSize: "30px",
          }}
        >
          <HeaderTextWeb
            profilePicWeb={profilePicWeb}
            assistantNameWeb={assistantNameWeb}
          />

          {messagesWeb.map((message, index) =>
            index < messagesWeb.length - 1 ? (
              <Box
                key={index}
                className={`message ${message.sender}`}
                style={{
                  background: message.sender === "ZUNO" ? "#FFFFFF" : "none",
                  borderRadius: message.sender === "ZUNO" ? "40px" : "0px",
                  padding: "10px 10px 10px 10px",
                  width: "95%",
                  marginLeft: "20px",
                  marginTop: "20px",
                }}
              >
                <ListItem key={index}>
                  <ListItemAvatar sx={{ marginRight: "1.3%" }}>
                    {message.sender === "ZUNO" ? (
                      loader && langChainIndex === index ? (
                        <WaterLoader />
                      ) : profilePicWeb ? (
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{ marginRight: "5px" }}
                        >
                          <Avatar
                            alt="profilePic"
                            src={profilePicWeb}
                            sx={{ width: 56, height: 56 }}
                          />
                        </Stack>
                      ) : (
                        <Botimageicon />
                      )
                    ) : (
                      <Avatar
                        sx={{ marginRight: 2, width: 56, height: 56 }}
                        style={{ backgroundColor: "#6001FF" }}
                      >
                        {profileImage ? (
                          <img
                            src={`data:image/jpeg;base64,${profileImage}`}
                            alt="Profile"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <PersonIcon sx={{ width: 40, height: 40 }} />
                        )}
                      </Avatar>
                    )}
                  </ListItemAvatar>

                  <ListItemText
                    key={index}
                    primary=""
                    secondary={
                      message.sender === "ZUNO" ? (
                        // Code for displaying langchaintext as paragraphs
                        loader && langChainIndex === index ? (
                          <LoaderMessages loader_Messages={loader_Messages} />
                        ) : (
                          <>
                            {typeof message.langchaintext === "string" &&
                            message.langchaintext !== null ? (
                              message.langchaintext
                                .split("\n")
                                .map((paragraph, i) => (
                                  <p
                                    key={i}
                                    style={{
                                      fontSize: "1.25rem",
                                      color: "#818589",
                                    }}
                                  >
                                    {paragraph}
                                    {/* "answer from fetch" */}
                                  </p>
                                ))
                            ) : (
                              <p
                                style={{
                                  fontSize: "1.25rem",
                                  color: "#818589",
                                }}
                              >
                                {message.langchaintext}
                                {/* "error message" */}
                              </p>
                            )}
                          </>
                        )
                      ) : // Code for displaying default text
                      message.sender === "ZUNO" ? (
                        // Code for displaying langchaintext as paragraphs by default for ZUNO
                        typeof message.langchaintext === "string" &&
                        message.langchaintext !== null ? (
                          message.langchaintext
                            .split("\n")
                            .map((paragraph, i) => (
                              <p
                                key={i}
                                style={{
                                  fontSize: "1.25rem",
                                  color: "#818589",
                                }}
                              >
                                {paragraph}
                              </p>
                            ))
                        ) : (
                          <p
                            style={{
                              fontSize: "1.25rem",
                              color: "#818589",
                            }}
                          >
                            {message.langchaintext}
                          </p>
                        )
                      ) : // Code for displaying regular text
                      typeof message.text === "string" ? (
                        message.text
                      ) : (
                        //"question"
                        JSON.stringify(message.text)
                      )
                    }
                    sx={{ fontSize: "1.25rem", color: "#818589" }}
                    disableTypography
                  />

                  {message.sender !== "ZUNO" ? (
                    message.bookmarked ? (
                      <Tooltip title="Bookmarked">
                        <IconButton onClick={(e) => toggleBookmark(e, index)}>
                          <BookmarkAddedIcon style={{ color: "#6001FF" }} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Bookmark">
                        <IconButton onClick={(e) => toggleBookmark(e, index)}>
                          <BookmarkBorderIcon />
                        </IconButton>
                      </Tooltip>
                    )
                  ) : null}
                </ListItem>
              </Box>
            ) : (
              <>
                {loader && langChainIndex === index ? (
                  <Box
                    style={{
                      background: "#FFFFFF",
                      borderRadius: "40px",
                      padding: "10px 10px 10px 10px",
                      width: "95%",
                      marginLeft: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <WaterLoader />
                      </ListItemAvatar>
                      <ListItemText
                        secondary={`${loader_Messages}`}
                        style={{
                          marginLeft: "2%",
                          fontSize: "1.25rem",
                          color: "#818589",
                        }}
                        disableTypography
                      />
                    </ListItem>
                  </Box>
                ) : (
                  <Box
                    key={index}
                    className={`message ${message.sender}`}
                    style={{
                      background:
                        message.sender === "ZUNO" ? "#FFFFFF" : "none",
                      borderRadius: message.sender === "ZUNO" ? "40px" : "0px",
                      padding: "10px 10px 10px 10px",
                      width: "95%",
                      marginLeft: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <ListItem key={index}>
                      <ListItemAvatar sx={{ marginRight: "1.3%" }}>
                        {message.sender === "ZUNO" ? (
                          profilePicWeb ? (
                            <Stack
                              direction="row"
                              spacing={2}
                              sx={{ marginRight: "5px" }}
                            >
                              <Avatar
                                alt="Sanjay"
                                src={profilePicWeb}
                                sx={{ width: 56, height: 56 }}
                              />
                            </Stack>
                          ) : (
                            <Botimageicon />
                          )
                        ) : (
                          // <AccountCircleSharpIcon
                          //   style={{
                          //     fontSize: 50,
                          //     color: "#6001ff",
                          //   }}
                          // />
                          <Avatar
                            sx={{ marginRight: 2, width: 56, height: 56 }}
                            style={{ backgroundColor: "#6001FF" }}
                          >
                            <PersonIcon sx={{ width: 40, height: 40 }} />
                          </Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        key={index}
                        primary=""
                        secondary={
                          message.sender === "ZUNO" ? (
                            // Code for displaying langchaintext as paragraphs
                            typeof message.langchaintext === "string" &&
                            message.langchaintext !== null ? (
                              message.langchaintext
                                .split("\n")
                                .map((paragraph, i) => (
                                  <Box>
                                    <p
                                      key={i}
                                      style={{
                                        fontSize: "1.25rem",
                                        color: "#818589",
                                      }}
                                    >
                                      {tryCount < 3 ? (
                                        <>{paragraph}</>
                                      ) : (
                                        <>{systemError}</>
                                      )}
                                      {/* "last question answer" */}
                                    </p>
                                    <ChatRetryLangChain
                                      timeOutMsg={timeOutMsg}
                                      retryTime={retryTime}
                                      tryCount={tryCount}
                                      handleRetry={handleRetry}
                                      langChainIndex={langChainIndex}
                                      index={index}
                                    />
                                  </Box>
                                ))
                            ) : (
                              <p
                                style={{
                                  fontSize: "1.25rem",
                                  color: "#818589",
                                }}
                              >
                                {message.langchaintext}
                              </p>
                            )
                          ) : // Code for displaying default text
                          message.sender === "ZUNO" ? (
                            // Code for displaying langchaintext as paragraphs by default for ZUNO
                            typeof message.langchaintext === "string" &&
                            message.langchaintext !== null ? (
                              message.langchaintext
                                .split("\n")
                                .map((paragraph, i) => (
                                  <p
                                    key={i}
                                    style={{
                                      fontSize: "1.25rem",
                                      color: "#818589",
                                    }}
                                  >
                                    {paragraph}
                                  </p>
                                ))
                            ) : (
                              <p
                                style={{
                                  fontSize: "1.25rem",
                                  color: "#818589",
                                }}
                              >
                                {message.langchaintext}
                              </p>
                            )
                          ) : // Code for displaying regular text
                          typeof message.text === "string" ? (
                            message.text
                          ) : (
                            //last question
                            JSON.stringify(message.text)
                          )
                        }
                        sx={{
                          fontSize: "1.25rem",
                          color: "#818589",
                        }}
                        disableTypography
                      />

                      {message.sender !== "ZUNO" ? (
                        message.bookmarked ? (
                          <Tooltip title="Bookmarked">
                            <IconButton
                              disabled
                              // onClick={(e) => toggleBookmark(e, index)}
                            >
                              <BookmarkAddedIcon style={{ color: "#6001FF" }} />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Bookmark">
                            <IconButton
                              disabled
                              // onClick={(e) => toggleBookmark(e, index)}
                            >
                              <BookmarkBorderIcon />
                            </IconButton>
                          </Tooltip>
                        )
                      ) : null}
                    </ListItem>
                  </Box>
                )}
              </>
            )
          )}

          {loader && langChainIndex === null ? (
            <Box
              style={{
                background: "#FFFFFF",
                borderRadius: "40px",
                padding: "10px 10px 10px 10px",
                width: "95%",
                marginLeft: "20px",
                marginTop: "20px",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <WaterLoader />
                </ListItemAvatar>
                <ListItemText
                  // secondary="working on it ..."
                  secondary={`${loader_Messages}`}
                  style={{
                    marginLeft: "2%",
                    fontSize: "1.25rem",
                    color: "#818589",
                  }}
                  disableTypography
                />
              </ListItem>
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <WebChatInputBase
          newMessage={newMessage}
          handleInputChange={handleInputChange}
          handleKeyDown={handleKeyDown}
          handleSendButton={handleSendButton}
          loader={loader}
        />
      </>
    );
  }
}

export default ChatMapWeb;
