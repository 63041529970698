import React, { Component } from "react";
import {
  TextField,
  Box,
  CircularProgress,
  Button,
  IconButton,
  InputAdornment,
  Autocomplete,
  Typography
} from "@mui/material";
// import MuiAlert from "@mui/material/Alert";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Popper from "@mui/material/Popper";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });
const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};
const selectFieldSx = {
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
  },
  "& .MuiMenu-paper": {
    maxHeight: "200px", // Set the maximum height for the dropdown
    maxWidth: "100px",
  },
};
const icon = (
  <span
    style={{
      display: "inline-flex",
      color: "#6001FF",
    }}
  >
    <CheckBoxOutlineBlankIcon fontSize="small" />
  </span>
);

const checkedIcon = (
  <span
    style={{
      display: "inline-flex",
      color: "#6001FF",
    }}
  >
    <CheckBoxIcon fontSize="small" />
  </span>
);
const BASE_URL = process.env.REACT_APP_BASE_URL;
const application_code = "ZN001"; // for csv
class S3bucketComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aws_access_key_id: "",
      aws_secret_access_key: "",
      aws_bucket_name: "",
      aws_bucket_folder_path: "",
      bucket_info: null,
      connecting: false,
      showsnack: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
      showSnackbar: false,
      severity: "",
      message: "",
      showAccessKeyId: false,
      showSecretAccessKey: false,
      selectedUserNames: [],
      selectedUserEmails: [],
      users: this.props.users,
    };
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleToggleVisibility = (field) => {
    this.setState((prevState) => ({
      [field]: !prevState[field],
    }));
  };


  handleFetchBucketData = async () => {
    this.setState({ connecting: true });

    const {
      aws_access_key_id,
      aws_secret_access_key,
      aws_bucket_name,
      aws_bucket_folder_path,
    } = this.state;
    if (
      aws_access_key_id === "" ||
      aws_secret_access_key === "" ||
      aws_bucket_name === "" ||
      aws_bucket_folder_path === ""
    ) {
      this.setState({
        // showsnack: true,
        // severity: "error",
        // snackmessage: "Fill all the fields",
        connecting: false,
      });
      this.props.handleSnackbarOpen("error", "Fill all the fields");
      return;
    }
    try {
      let endpoint;
      const formData = new FormData();
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");
      formData.append("userEmail", email);
      const userOrganization = JSON.parse(
        localStorage.getItem("currentLoggedInUserDetails")
      )["organization"];
      if (this.props.dbType === "S3 Bucket") {
        formData.append("aws_access_key_id", aws_access_key_id);
        formData.append("aws_secret_access_key", aws_secret_access_key);
        formData.append("aws_bucket_name", aws_bucket_name);
        formData.append("aws_bucket_folder_path", aws_bucket_folder_path);

        endpoint = `${BASE_URL}/zn001/user/select_s3_bucket_data`;
      } else {
        console.error("Unsupported database type");
        return;
      }

      const responseS3BucketData = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (responseS3BucketData.ok) {
        const responseFetchS3BucketData = await responseS3BucketData.json();

        if (responseFetchS3BucketData.status) {
          this.props.handleSnackbarOpen(
            "error",
            responseFetchS3BucketData.message
          );
        } else {
          const { file_count, } = responseFetchS3BucketData; // Extract table_count from the response
          this.props.handleSnackbarOpen("success", "Connection successful");
          const formData = new FormData();
          if (this.props.dbType === "S3 Bucket") {
            const source_information = JSON.stringify({
              aws_access_key_id: aws_access_key_id,
              aws_secret_access_key: aws_secret_access_key,
              aws_bucket_name: aws_bucket_name,
              aws_bucket_folder_path: aws_bucket_folder_path,
              file_count: file_count,
            });
            formData.append("source", "s3bucket");
            formData.append("source_information", source_information);
            const selectedUserEmails = this.getSelectedUsers();
            formData.append(
              "selectedEmails",
              JSON.stringify(selectedUserEmails)
            );
          } else {
            console.error("Unsupported database type");
            return;
          }

          formData.append("userEmail", email);
          formData.append("organization", userOrganization);
          formData.append("application_code", application_code);
          formData.append("isAdmin", true);
          // only call the s3 bucket endpoint if there were no errors
          const responseStoreData = await fetch(
            `${BASE_URL}/admin/zn001/csv_cloud_source`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          );

          if (responseStoreData.ok) {
            const result = await responseStoreData.json();
            if (result.status) {
              // this.setState({
              //   showSkeleton: false,
              //   showsnack: true,
              //   severity: "error",
              //   snackmessage: result.message,
              // });
              this.props.handleSnackbarOpen("error", result.message);
            } else {
              // this.setState({
              //   showSkeleton: false,
              //   showsnack: true,
              //   severity: "success",
              //   snackmessage: "Connection successful",
              // });
              this.props.handleSnackbarOpen(
                "success",
                "Connection successfull"
              );
              this.props.handleCloseFileUpload();
              this.props.fetchCSVData();
            }
          }
        }
      }
    } catch (error) {
      // Handle network errors or unexpected errors
      this.setState({
        message: "Network error occurred",
      });
      console.log(error);
    }
  };
  handleCheckBoxChange = (username) => {
    const { selectedUserNames, users } = this.state;
    const updatedUsers = [...users]; // Create a copy of the users array

    // Find the user object in the updatedUsers array by username
    const user = updatedUsers.find((user) => user.username === username);

    if (user) {
      // Toggle the 'accessible' property of the user based on whether it was selected or deselected
      user.accessible = !selectedUserNames.includes(username);
    }
    const index = selectedUserNames.indexOf(username);

    if (index !== -1) {
      // Remove the user from the selected Users list
      selectedUserNames.splice(index, 1);
    } else {
      // Add the user to the selected Users list
      selectedUserNames.push(username);
    }
    // Update the selectedUserNames in the state
    this.setState({ selectedUserNames });

    // Update the users in the state
    this.setState({ users: updatedUsers });
  };
  getSelectedUsers = () => {
    const selectedUserEmails = this.state.users
      .filter((user) => this.state.selectedUserNames.includes(user.username))
      .map((user) => user.email);
    return selectedUserEmails;
  };
  render() {
    const {
      aws_access_key_id,
      aws_secret_access_key,
      aws_bucket_name,
      aws_bucket_folder_path,
      bucket_info,
      connecting,
      showAccessKeyId,
      showSecretAccessKey,
    } = this.state;
    const has_bucket_info = bucket_info != null;
    const { users } = this.props;
    return (
      <>
        <form style={{ width: "100%" }}>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <TextField
                id="aws_access_key_id"
                label="Aws Access Key ID"
                name="aws_access_key_id"
                sx={textFieldSx}
                value={aws_access_key_id}
                onChange={this.handleInputChange}
                type={showAccessKeyId ? "text" : "password"}
                style={{ width: "100%", marginBottom: "10px" }}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.handleToggleVisibility("showAccessKeyId")
                        }
                        style={{
                          minWidth: "unset",
                          padding: 0,
                          color: "#6001FF",
                        }}
                      >
                        {showAccessKeyId ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <TextField
                id="aws_secret_access_key"
                label="Aws Secret Access Key"
                name="aws_secret_access_key"
                sx={textFieldSx}
                value={aws_secret_access_key}
                onChange={this.handleInputChange}
                type={showSecretAccessKey ? "text" : "password"}
                style={{ width: "100%", marginBottom: "10px" }}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.handleToggleVisibility("showSecretAccessKey")
                        }
                        style={{
                          minWidth: "unset",
                          padding: 0,
                          color: "#6001FF",
                        }}
                      >
                        {showSecretAccessKey ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <TextField
                id="aws_bucket_name"
                label="Aws Bucket Name"
                name="aws_bucket_name"
                sx={textFieldSx}
                value={aws_bucket_name}
                onChange={this.handleInputChange}
                style={{ width: "100%", marginBottom: "10px" }}
                required
              />
            </div>

            <div>
              <TextField
                id="aws_bucket_folder_path"
                label="Aws Bucket Folder Path"
                name="aws_bucket_folder_path"
                sx={textFieldSx}
                value={aws_bucket_folder_path}
                onChange={this.handleInputChange}
                style={{ width: "100%", marginBottom: "10px" }}
                required
              />
            </div>
            <div>
              <Autocomplete
                multiple
                style={{ width: "100%" }}
                sx={selectFieldSx}
                options={users}
                disableCloseOnSelect
                getOptionLabel={(option) => option.username}
                value={this.state.selectedUserNames.map((name) =>
                  users.find((user) => user.username === name)
                )}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        onChange={() =>
                          this.handleCheckBoxChange(option.username)
                        }
                        onDelete={() =>
                          this.handleCheckBoxChange(option.username)
                        }
                      />
                      {option.username}
                    </li>
                  );
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option.username}
                      {...getTagProps({ index })}
                      style={{
                        background: "#6001FF",
                        color: "white",
                        marginRight: 8,
                      }}
                      deleteIcon={<CancelIcon style={{ color: "white" }} />}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} label="Users" placeholder="Users" />
                )}
                onChange={(event, newValue) => {
                  // Handle the change in Autocomplete value
                  // This can include any logic needed to update your component state
                  this.setState({
                    selectedUserNames: newValue.map((user) => user.username),
                  });
                }}
                PopperComponent={(props) => (
                  <Popper {...props} placement="bottom-start" />
                )}
              />
            </div>
          </div>
          <Box m={1} display="flex" justifyContent="center" alignItems="center">
            {/* Conditionally render CircularProgress based on the 'connecting' state */}
            {has_bucket_info ? (
              <div>
                {connecting ? (
                  <CircularProgress
                    size={25}
                    style={{ color: "#6001ff", marginLeft: "12%" }}
                  />
                ) : (
                  <Button
                    style={{
                      backgroundColor: "#6001ff",
                      marginBottom: "20px",
                      color: "#ffffff",
                    }}
                    variant="contained"
                    onClick={this.handleUpdateBucketInfo}
                  >
                    Apply Changes
                  </Button>
                )}
              </div>
            ) : (
              <div>
                {connecting ? (
                  <CircularProgress
                    size={25}
                    style={{ color: "#6001ff", marginLeft: "12%" }}
                  />
                ) : (
                  <Box style={{ display: "flex", gap: "1.25rem",width:"150%",marginLeft:"52%", }}>
                   <Typography variant="p"
                        style={{ color:"#6001ff", textTransform:"none", alignContent:"center",cursor:"pointer"}}
                        onClick={this.props.handleCloseFileUpload}
                      >
                        Cancel
                    </Typography>

                    <Button
                      style={{ backgroundColor: "#6001ff", marginLeft: "5%",textTransform:"none",width:"50%", }}
                      variant="contained"
                      onClick={this.handleFetchBucketData}
                    >
                      Apply Changes
                    </Button>
                  </Box>
                )}
              </div>
            )}
          </Box>
        </form>
      </>
    );
  }
}

export default S3bucketComponent;
