import React, { Component } from "react";
// import Button from '@mui/material/Button';
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import login_bg from "../assets/images/png/login-bg.jpg";
import { withRouter } from "./withRouter";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Button from "@mui/material/Button";
import { InputAdornment } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// function Copyright(props) {
//     return (
//         <Typography variant="body2" color="text.secondary" align="center" {...props}>
//             {'Copyright © '}
//             <Link color="inherit" href="https://mui.com/">
//                 Your Website
//             </Link>{' '}
//             {new Date().getFullYear()}
//             {'.'}
//         </Typography>
//     );
// }

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};
const selectFieldSx = {
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
  },
  "& .MuiMenu-paper": {
    maxHeight: "200px", // Set the maximum height for the dropdown
    maxWidth: "100px",
  },
};

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      department: "",
      designation: "",
      email: "",
      password: "",
      errors: {
        email: "",
        password: "",
        username: "",
        organization: "",
      },
      isValidForm: false,
      isValidUsername: false,
      isValidOrganization: false,
      showPassword: false,
      isPasswordInputNotEmpty: false,
      username: "",
      organization: "",
      snackbarOpen: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
    };
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const { isValidForm, isValidUsername, isValidOrganization } = this.state;
    if (!isValidForm) {
      return;
    } else if (!isValidUsername) {
      return;
    } else if (!isValidOrganization) {
      return;
    }
    const formData = new FormData(event.currentTarget);
    this.setState({ loading: true });
    try {
      const response = await fetch(`${BASE_URL}/user/register_user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: formData.get("username").trim(),
          email: formData.get("email"),
          password: formData.get("password"),
          organization: formData.get("organization").trim(),
          department: formData.get("department"),
          designation: formData.get("designation"),
        }),
      });
     
        if(response.status===401){
          const backendError = await response.json();
          this.handleSnackbarOpen(
            "error",
            backendError.detail
          );
    
      }
      if (response.ok) {
        const result = await response.json();

        localStorage.setItem("username", formData.get("username")); // Store username in localStorage
        localStorage.setItem("department", formData.get("department"));
        this.handleSnackbarOpen("success", result.message);
        setTimeout(() => {
          this.props.navigate("/");
        }, 5000); 
      } else {
        console.error("Registration failed");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleEmailChange = (event) => {
    const { name, value } = event.target;
    //  const inputEmail=value.trim()
    // Remove all spaces from the input value
    const inputEmail = value.replace(/\s/g, "");
    const emailRegex = /[a-zA-Z0-9]+@[a-z]+\.[a-z]{2,3}/;

    if (!emailRegex.test(inputEmail)) {
      this.setState((prevState) => ({
        isValidForm: false,
        [name]: inputEmail,
        errors: { ...prevState.errors, email: "Please enter a valid email" },
      }));
    } else {
      this.setState((prevState) => ({
        isValidForm: true,
        [name]: inputEmail,
        errors: { ...prevState.errors, email: "" },
      }));
    }
  };
  handlePasswordChange = (event) => {
    const { name, value } = event.target;
    // const inputPassword=value.trim()
    const inputPassword = value.replace(/\s/g, "");
    const password_regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,}$/;

    if (!password_regex.test(inputPassword)) {
      this.setState((prevState) => ({
        isPasswordInputNotEmpty: value.length > 0,
        isValidForm: false,
        [name]: inputPassword,
        errors: {
          ...prevState.errors,
          password:
            "Password must be at least 10 characters and it should contain at least one uppercase letter, one lowercase letter, one digit, and one special character.",
        },
      }));
    } else {
      this.setState((prevState) => ({
        isPasswordInputNotEmpty: value.length > 0,
        isValidForm: true,
        [name]: inputPassword,
        errors: { ...prevState.errors, password: "" },
      }));
    }
  };
  handlePasswordVisibilityToggle = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleOrganizationChange = (event) => {
    const { name, value } = event.target;
    if (this.hasSpecialFirstCharacter(value)) {
      this.setState((prevState) => ({
        [name]: value,
        isValidOrganization: false,
        errors: {
          ...prevState.errors,
          organization:
            "Organization name can contain letters,numbers,spaces and underscore(_). Must start with a letter",
        },
      }));
    } else if (this.hasSpecialCharacters(value.replace(/\s+/g, " "))) {
      this.setState((prevState) => ({
        [name]: value,
        isValidOrganization: false,
        errors: {
          ...prevState.errors,
          organization:
            "Organization name should not contain special characters.",
        },
      }));
    } else if (value.length === 0) {
      this.setState((prevState) => ({
        [name]: value,
        isValidOrganization: false,
        errors: {
          ...prevState.errors,
          organization: "Organization name cannot be empty.",
        },
      }));
    } else {
      this.setState((prevState) => ({
        isValidOrganization: true,
        [name]: value,
        errors: { ...prevState.errors, organization: "" },
      }));
    }
    console.log(this.state.organization);
  };
  handleKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };
  handleUsernameChange = (event) => {
    const { name, value } = event.target;
    if (this.hasSpecialFirstCharacter(value)) {
      this.setState((prevState) => ({
        [name]: value,
        isValidUsername: false,
        errors: {
          ...prevState.errors,
          username:
            "Username can contain letters,numbers,spaces and underscore(_).Must start with a letter.",
        },
      }));
    } else if (this.hasSpecialCharacters(value.replace(/\s+/g, " "))) {
      this.setState((prevState) => ({
        [name]: value,
        isValidUsername: false,
        errors: {
          ...prevState.errors,
          username: "Username should not contain special characters.",
        },
      }));
    } else if (value.length === 0) {
      this.setState((prevState) => ({
        [name]: value,
        isValidUsername: false,
        errors: {
          ...prevState.errors,
          username: "Username cannot be empty.",
        },
      }));
    } else {
      this.setState((prevState) => ({
        isValidUsername: true,
        [name]: value,
        errors: { ...prevState.errors, username: "" },
      }));
    }
  };

  hasSpecialCharacters = (userName) => {
    // Define a regular expression to match special characters
    const regex = /[^a-zA-Z0-9_\s]+/;
    // returns boolean value from test()
    return regex.test(userName);
  };
  hasSpecialFirstCharacter = (userName) => {
    // Check if the first character is a special character
    const firstCharSpecial = /^[^a-zA-Z]/.test(userName.charAt(0));
    return firstCharSpecial;
  };
  handleSnackbarOpen = (severity, message) => {
    this.setState({
      snackbarOpen: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };
  render() {
    const {
      loading,
      department,
      designation,
      showPassword,
      isPasswordInputNotEmpty,
      username,
      organization,
      snackbarOpen,
      snackbarSeverity,
      snackbarMessage,
    } = this.state;
    const departmentOptions = [
      "Development",
      "Quality Assurance (QA)",
      "Product Management",
      "User Experience (UX) Design",
      "DevOps",
      "Data Science",
      "Information Security",
      "Technical Support",
      "Research and Development",
      "IT Operations",
    ];
    const designationOptions = [
      "Software Engineer",
      "Senior Software Engineer",
      "Software Developer",
      "Frontend Developer",
      "Backend Developer",
      "Full Stack Developer",
      "QA Engineer",
      "Product Manager",
      "UX Designer",
      "DevOps Engineer",
    ];
    return (
      <React.Fragment>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Container fixed>
            <ThemeProvider theme={defaultTheme}>
              <Grid
                container
                component="main"
                style={{ height: "85vh", borderRadius: "10px" }}
              >
                <CssBaseline />
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={5}
                  component={Paper}
                  elevation={6}
                  style={{ height: "90vh", borderRadius: "10px" }}
                >
                  <Box
                    style={{
                      my: 4,
                      mx: 4,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "20px",
                    }}
                  >
                    <Typography
                      component="h1"
                      variant="h5"
                      style={{ marginTop: "0.2%", color: "#6001FF" }}
                    >
                      <b style={{ color: "#6001FF" }}>Sign Up</b>
                    </Typography>
                    <Box
                      component="form"
                      onSubmit={(event) => this.handleSubmit(event)}
                      style={{ mt: 1 }}
                    >
                      <TextField
                        // margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="username "
                        name="username"
                        autoComplete="username"
                        autoFocus
                        sx={textFieldSx}
                        style={{
                          height: "100px", // Set a fixed height
                        }}
                        value={username}
                        onChange={this.handleUsernameChange}
                        error={!!this.state.errors.username}
                        helperText={this.state.errors.username}
                        FormHelperTextProps={{
                          style: { margin: 0, padding: 0, height: "1em" },
                        }}
                      />
                      <TextField
                        // margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        sx={textFieldSx}
                        style={{
                          height: "80px", // Set a fixed height
                        }}
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        error={!!this.state.errors.email}
                        helperText={this.state.errors.email}
                        onKeyDown={this.handleKeyDown}
                        FormHelperTextProps={{
                          style: { margin: 0, padding: 0, height: "1em" },
                        }}
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        id="password"
                        autoComplete="current-password"
                        sx={textFieldSx}
                        style={{
                          height: "85px", // Set a fixed height
                        }}
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        error={!!this.state.errors.password}
                        helperText={this.state.errors.password}
                        onKeyDown={this.handleKeyDown}
                        FormHelperTextProps={{
                          style: { margin: 0, padding: 0, height: "1em" },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {isPasswordInputNotEmpty && (
                                <Button
                                  onClick={this.handlePasswordVisibilityToggle}
                                  style={{
                                    minWidth: "unset",
                                    padding: 0,
                                    color: "#6001FF",
                                  }}
                                >
                                  {showPassword ? (
                                    <VisibilityOutlinedIcon />
                                  ) : (
                                    <VisibilityOffOutlinedIcon />
                                  )}
                                </Button>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box
                        style={{
                          display: "flex",
                          gap: "1rem",
                          marginTop: "1%",
                        }}
                      >
                        <FormControl sx={{ width: "50%" }}>
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            sx={{
                              color: "grey",
                              "&.Mui-focused": {
                                color: "grey",
                              },
                            }}
                          >
                            Department
                          </InputLabel>
                          <Select
                            id="department"
                            name="department"
                            label="Department"
                            autoComplete="department"
                            margin="normal"
                            required
                            width="50%"
                            value={department}
                            onChange={this.handleInputChange}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: "200px",
                                  maxWidth: "100px",
                                },
                              },
                            }}
                            sx={selectFieldSx}
                          >
                            {departmentOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl sx={{ width: "50%" }}>
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            sx={{
                              color: "grey",
                              "&.Mui-focused": {
                                color: "grey",
                              },
                            }}
                          >
                            Designation
                          </InputLabel>
                          <Select
                            id="designation"
                            name="designation"
                            label="designation"
                            autoComplete="designation"
                            margin="normal"
                            required
                            width="50%"
                            value={designation}
                            onChange={this.handleInputChange}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: "200px",
                                  maxWidth: "100px",
                                },
                              },
                            }}
                            sx={selectFieldSx}
                          >
                            {designationOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                      <TextField
                        // margin="normal"
                        required
                        fullWidth
                        name="organization"
                        label="organization"
                        type="organization"
                        id="organization"
                        autoComplete="organization"
                        sx={textFieldSx}
                        style={{
                          marginTop: "5%",
                          height: "80px", // Set a fixed height
                        }}
                        value={organization}
                        onChange={this.handleOrganizationChange}
                        error={!!this.state.errors.organization}
                        helperText={this.state.errors.organization}
                        FormHelperTextProps={{
                          style: { margin: 0, padding: 0, height: "1em" },
                        }}
                      />
                      <br />
                      <Box style={{ marginTop: "3%" }}>
                        <Grid container>
                          <Grid item>
                            Already have an account? &nbsp;
                            <Link
                              to="/"
                              variant="body2"
                              style={{ fontSize: "1rem", color: "#6001FF" }}
                            >
                              {"Sign In"}
                            </Link>
                          </Grid>
                        </Grid>
                        <LoadingButton
                          type="submit"
                          fullWidth
                          variant="contained"
                          loading={loading}
                          loadingPosition="end"
                          sx={{
                            "& .MuiCircularProgress-svg": { color: "#FFFFF" },
                          }}
                          style={{
                            mt: 3,
                            mb: 2,
                            fontSize: "1rem",
                            height: "50px",
                            backgroundColor: "#6001FF",
                            color: "#FFFFFF",
                            marginTop: "10px",
                          }}
                          endIcon={<ArrowForwardIcon />}
                        >
                          Sign up
                        </LoadingButton>
                        <br />
                        <br />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </ThemeProvider>
          </Container>

          <Grid
            item
            xs={false}
            sm={4}
            md={6}
            style={{
              backgroundImage: `url(${login_bg})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "50%",
              height: "75%",
              position: "absolute",
              marginLeft: "50%",
            }}
          />
        </Box>
        <Snackbar
          open={snackbarOpen} // Use the correct state name here (snackbarOpen)
          autoHideDuration={5000}
          onClose={this.handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.handleSnackbarClose}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </React.Fragment>
    );
  }
}
export default withRouter(SignUp);
