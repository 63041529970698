import React from "react";
import {
  Avatar,
  Typography,
  Box,
  Divider,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddIcon from "@mui/icons-material/Add";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { withRouter } from "./withRouter";
import PersonIcon from "@mui/icons-material/Person";

const formatIndianNumber = (num) => {
  const numString = num.toString();
  const lastThreeDigits = numString.slice(-3);
  const otherDigits = numString.slice(0, -3);
  const formattedOtherDigits = otherDigits.replace(
    /\B(?=(\d{2})+(?!\d))/g,
    ","
  );
  return otherDigits
    ? formattedOtherDigits + "," + lastThreeDigits
    : lastThreeDigits;
};
const formatNumber = (num) => {
  if (num >= 10000000) {
    return (num / 10000000).toFixed(2) + "Cr";
  } else if (num >= 100000) {
    return (num / 100000).toFixed(2) + "L";
  }
  return formatIndianNumber(num);
};
class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverProfile: false,
      hoverUpdatePassword: false,
      expand: false,
      userInformation: JSON.parse(
        localStorage.getItem("currentLoggedInUserDetails")
      )
        ? JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        : [],
      tokenUsed: localStorage.getItem("tokenUsed")
        ? localStorage.getItem("tokenUsed")
        : 0,
      tokenLimit: localStorage.getItem("tokenLimit")
        ? localStorage.getItem("tokenLimit")
        : 10000,
    };
  }

  handleMouseEnterProfile = () => {
    this.setState({ hoverProfile: true });
  };

  handleMouseLeaveProfile = () => {
    this.setState({ hoverProfile: false });
  };
  handleMouseEnterUpdatepassword = () => {
    this.setState({ hoverUpdatePassword: true });
  };

  handleMouseLeaveUpdatepassword = () => {
    this.setState({ hoverUpdatePassword: false });
  };

  handleAccordionToggle = () => {
    this.setState((prevState) => ({ expand: !prevState.expand }));
  };
  render() {
    const {
      hoverProfile,
      hoverUpdatePassword,
      expand,
      userInformation,
      tokenUsed,
      tokenLimit,
    } = this.state;

    const { profileImage } = this.props;
    const tokenUsage = formatNumber(tokenUsed);
    const totalTokenLimit = formatNumber(tokenLimit);
    return (
      <React.Fragment >
      <Box sx={{display:"flex",height: "100vh",width:"100%", }}>
      <Divider orientation="vertical"sx={{ height: "105%",backgroundColor: "rgba(0, 0, 0, 0.2)" }}/>
      <Paper
        style={{
          paddingTop: 16,
          paddingLeft: 16,
          paddingRight: 16,
           marginTop:"10px",
          // width:"100%",
          height: expand ? "135%" : "95%",
          width:"100%",
        }}
        elevation={0}
      >
        <IconButton
          style={{
            position: "fixed",
            // top: 10,
            right: 300,
            color: "gray",
            transition: "color 0.3s",
            cursor: "pointer",
          }}
          onClick={() => this.props.handleManageAccount(false)}
          onMouseOver={(e) => (e.currentTarget.style.color = "black")}
          onMouseOut={(e) => (e.currentTarget.style.color = "gray")}
        >
          <Tooltip title="Close">
            <CloseIcon style={{ color: "#6001FF" }} />
          </Tooltip>
        </IconButton>
        <Box ref={this.props.accountRef}>
          <Typography
            variant="h4"
            style={{
              // fontSize: "1rem",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
            }}
          >
            Account
          </Typography>
          <Typography
            variant="body1"
            sx={{ mt: 2 }}
            style={{
              fontSize: "1rem",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 500,
            }}
          >
            Manage your account information
          </Typography>
        </Box>
        <Typography variant="h6" sx={{ mt: 5 }}>
          Profile
        </Typography>
        <Divider style={{ margin: "6px 0" }} />
        <Box
          onClick={()=>{this.props.onProfileClick();this.props.handleSelectedButton("account")}}
          onMouseEnter={this.handleMouseEnterProfile}
          onMouseLeave={this.handleMouseLeaveProfile}
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 2,
            marginTop: 2,
            padding: 1,
            borderRadius: 1,
            cursor: "pointer",
            transition: "background-color 0.3s, box-shadow 0.3s",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Avatar
            sx={{ marginRight: 2 }}
            style={{ backgroundColor: "#6001FF" }}
          >
            {profileImage ? (
              <img
                src={`data:image/jpeg;base64,${profileImage}`}
                alt="Profile"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <PersonIcon />
            )}
          </Avatar>
          {/* <AccountCircleSharpIcon style={{marginRight: 2,backgroundColor:"#6001FF"}}/> */}
          <Typography sx={{ flexGrow: 1 }} variant="h6">
            {userInformation.username}
          </Typography>
          {hoverProfile && <ArrowForwardIosIcon style={{ color: "#6001FF" }} />}
        </Box>
        <Accordion
          style={{ marginTop: "4%" }}
          expanded={expand}
          onChange={this.handleAccordionToggle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "#6001FF" }} />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography
              style={{
                fontSize: "1rem",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
              }}
            >
              User Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontSize: "1rem",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
              }}
            >
              Email : {userInformation.email}
            </Typography>
            <Divider style={{ margin: "8px 0" }} />
            <Typography
              style={{
                fontSize: "1rem",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
              }}
            >
              Organization : {userInformation.organization}
            </Typography>
            <Divider style={{ margin: "8px 0" }} />
            <Typography
              style={{
                fontSize: "1rem",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
              }}
            >
              User role : {userInformation.role}
            </Typography>
            <Divider style={{ margin: "8px 0" }} />
            <Typography
              style={{
                fontSize: "1rem",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
              }}
            >
              Department : {userInformation.department}
            </Typography>
            <Divider style={{ margin: "8px 0" }} />
            <Typography
              style={{
                fontSize: "1rem",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
              }}
            >
              Designation: {userInformation.designation}
            </Typography>
            <Divider style={{ margin: "8px 0" }} />

            <Typography
              style={{
                fontSize: "1rem",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
              }}
            >
              Token Consumption :{" "}
              <Tooltip
                title={formatIndianNumber(tokenUsed)}
                placement="top-start"
                arrow
              >
                <span>{tokenUsage}</span>
              </Tooltip>
              <span>&nbsp;/&nbsp;</span>
              <Tooltip
                title={formatIndianNumber(tokenLimit)}
                placement="top-start"
                arrow
              >
                <span>{totalTokenLimit}</span>
              </Tooltip>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Typography
          variant="h4"
          sx={{ mt: 8 }}
          style={{
            // fontSize: "1rem",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
          }}
        >
          Security
        </Typography>
        <Typography
          variant="body1"
          sx={{ mt: 2 }}
          style={{
            fontSize: "1rem",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 500,
          }}
        >
          Manage your security preferences
        </Typography>
        <Box ref={this.props.securityRef}>
          <Typography variant="h6" sx={{ mt: 5 }}>
            Password
          </Typography>
          <Divider style={{ margin: "16px 0" }} />
        </Box>
        <Box
          onClick={() => {
            this.props.onSetpassword();
            this.props.scrollToSection("updatePassword");
            this.props.handleSelectedButton("security");
          }}
          onMouseEnter={this.handleMouseEnterUpdatepassword}
          onMouseLeave={this.handleMouseLeaveUpdatepassword}
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 2,
            marginTop: 2,
            padding: 1,
            borderRadius: 1,
            cursor: "pointer",
            transition: "background-color 0.3s, box-shadow 0.3s",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <AddIcon
            style={{ marginLeft: "2%", color: "#6001FF" }}
            color="#6001FF"
          />
          <Typography sx={{ flexGrow: 1, ml: 2 }} color="#6001FF">
            Update password
          </Typography>
          {hoverUpdatePassword && (
            <ArrowForwardIosIcon style={{ color: "#6001FF" }} />
          )}
        </Box>
      </Paper>
       </Box>
       </React.Fragment>
    );
  }
}

export default withRouter(Account);
