import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
//import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
//import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { withRouter } from "./withRouter";
import tenzai_logo from "../assets/images/png/tenzai-logo.png";
import { Helmet } from "react-helmet";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';

// .css-1jbeu5y-MuiListItemIcon-root
// const listItemIcon={
//   " & .MuiListItemIcon-root":{
//     "& .css-1nxmd3h-MuiListItem-root":{
//       // minwidth:"10px"
//       minWidth:"10px"
//     }
//   }
// }
// const listItemIcon={
//   " & .MuiListItemIcon-root":{
//   // ".css-1jbeu5y-MuiListItemIcon-root":{
//     minWidth:"0px"
//   // }
//   }
// }
const listItemIconStyles = {
  '& .MuiListItemIcon-root': {
    minWidth: '10px !important',
  },
};
// const useStyles = makeStyles({
//   customListItemIcon: {
//     minWidth: '20px !important',
//   },
// });

// const classes = useStyles();
class SideNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedButton: localStorage.getItem("selectedButton") || "Data Sources",
    };
  }

  resetmessages = () => {
    this.props.handleChatClick({
      showTab: false,
      showUpload: false,
      showChat: true,
      ShowBook: false,
      showCSVChat: true,
      showPromptTemplate: false,
    });
    localStorage.setItem("messages", null);
    localStorage.setItem("selectedButton", "chat");
    this.setState({ selectedButton: "chat" }, () => {
      window.location.reload();
    });
  };
  handleChatClickInNav = (event) => {
    event.preventDefault();
    this.props.handleChatClick({
      showUpload: false,
      showChat: true,
      showbook: false,
      showCSVChat: true,
      showPromptTemplate: false,
    });
    this.setState({ selectedButton: "chat" });
  };

  handleTabClickInNav = () => {
    this.props.handleTabClick({
      showTab: true,
      showUpload: false,
      showChat: false,
      showbook: false,
      showCSVChat: false,
      showPromptTemplate: false,
    });
    this.setState({ selectedButton: "Data Sources" });
  };

  handleBookClickInNav = () => {
    this.props.handleBookClick({
      showTab: false,
      showUpload: false,
      showChat: false,
      showbook: true,
      showCSVChat: false,
      showPromptTemplate: false,
    });
    this.setState({ selectedButton: "Bookmark" });
  };

  handlePromptTemplateClickInNav = () => {
    this.props.handlePromptTemplateClick({
      showTab: false,
      showUpload: false,
      showChat: false,
      showPromptTemplate: true,
      showCSVChat: false,
    });
    this.setState({ selectedButton: "Prompt Template" });
  };

  componentDidMount() {
    if (localStorage.getItem("selectedButton") === "chat") {
      this.props.handleChatClick(
        {
          showUpload: false,
          showChat: true,
          showbook: false,
          showPromptTemplate: false,
        },
        localStorage.removeItem("selectedButton")
      );
    } else {
      this.handleTabClickInNav();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.showTab !== prevProps.showTab) {
      // Update the selected button when showUpload prop changes
      if (this.props.showTab) {
        this.setState({ selectedButton: "Data Sources" });
      }
    }

    if (this.props.showChat !== prevProps.showChat) {
      // Update the selected button when showChat prop changes
      if (this.props.showChat) {
        this.setState({ selectedButton: "chat" });
      }
    }
    if (this.props.showCSVChat !== prevProps.showCSVChat) {
      // Update the selected button when showChat prop changes
      if (this.props.showCSVChat) {
        this.setState({ selectedButton: "chat" });
      }
    }
    if (this.props.showbook !== prevProps.showbook) {
      // Update the selected button when showListdata prop changes
      if (this.props.showbook) {
        this.setState({ selectedButton: "Bookmark" });
      }
    }
  }

  render() {
    const { selectedButton } = this.state;
    return (
      <>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <nav aria-label="main mailbox folders" sx={{ position: "fixed" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <img
              src={tenzai_logo}
              alt="logo"
              style={{
                cursor: "pointer",
                width: "175px",
                height: "90px",
                position: "absolute",
                top: "10px",
                left: "20px",
              }}
              onClick={() => {
                this.props.navigate("/home");
              }}
            />
          </div>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                href="#"
                onClick={this.handleChatClickInNav}
                sx={{
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  backgroundColor:
                    selectedButton === "chat" ? "#6001FF" : "transparent",
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
              >
                <ListItemIcon
                  sx={{ color: selectedButton === "chat" ? "#FFF" : "#000" }}
                >
                  <ChatBubbleOutlineIcon
                    sx={{
                      color: selectedButton === "chat" ? "#FFF" : "#6001FF",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Chat"
                  sx={{
                    color: selectedButton === "chat" ? "#FFF" : "#000",
                    fontSize: "1.3rem",
                  }}
                  disableTypography
                />
              </ListItemButton>
            </ListItem>

            {/* <ListItem disablePadding>
                            <ListItemButton href='#' onClick={this.handleChatClickInNav}
                                sx={{
                                    borderTopRightRadius: '30px', borderBottomRightRadius: '30px',
                                    backgroundColor: selectedButton === "history" ? '#6001FF' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: '#EFEFEF'
                                    },
                                }}
                            >
                                <ListItemIcon sx={{ color: selectedButton === "history" ? '#FFF' : '#000' }}>
                                    <HistoryOutlinedIcon sx={{ color: selectedButton === "history" ? '#FFF' : '#6001FF' }} />
                                </ListItemIcon>
                                <ListItemText primary="History" sx={{ color: selectedButton === "history" ? '#FFF' : '#000', fontSize: "1.3rem" }} disableTypography />
                            </ListItemButton>
                        </ListItem> */}

            <ListItem disablePadding>
              <ListItemButton
                href="#"
                onClick={this.handleBookClickInNav}
                sx={{
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  backgroundColor:
                    selectedButton === "Bookmark" ? "#6001FF" : "transparent",
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color: selectedButton === "Bookmark" ? "#FFF" : "#000",
                  }}
                >
                  <BookmarkBorderOutlinedIcon
                    sx={{
                      color: selectedButton === "Bookmark" ? "#FFF" : "#6001FF",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Bookmark"
                  sx={{
                    color: selectedButton === "Bookmark" ? "#FFF" : "#000",
                    fontSize: "1.3rem",
                  }}
                  disableTypography
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                href="#"
                sx={{
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  backgroundColor:
                    selectedButton === "reset chat" ? "#6001FF" : "transparent",
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
                onClick={this.resetmessages}
              >
                <ListItemIcon
                  sx={{
                    color: selectedButton === "reset chat" ? "#FFF" : "#000",
                  }}
                >
                  <RestartAltOutlinedIcon
                    sx={{
                      color:
                        selectedButton === "reset chat" ? "#FFF" : "#6001FF",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Reset Chat"
                  sx={{
                    color: selectedButton === "reset chat" ? "#FFF" : "#000",
                    fontSize: "1.3rem",
                  }}
                  disableTypography
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                href="#"
                onClick={this.handleTabClickInNav}
                sx={{
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  backgroundColor:
                    selectedButton === "Data Sources"
                      ? "#6001FF"
                      : "transparent",
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color: selectedButton === "Data Sources" ? "#FFF" : "#000",
                  }}
                >
                  <UploadFileOutlinedIcon
                    sx={{
                      color:
                        selectedButton === "Data Sources" ? "#FFF" : "#6001FF",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Data Sources"
                  sx={{
                    color: selectedButton === "Data Sources" ? "#FFF" : "#000",
                    fontSize: "1.3rem",
                  }}
                  disableTypography
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
                            <ListItemButton href='#' onClick={this.handlePromptTemplateClickInNav}
                                sx={{
                                  
                                    borderTopRightRadius: '30px', borderBottomRightRadius: '30px',
                                    backgroundColor: selectedButton === "Prompt Template" ? '#6001FF' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: '#EFEFEF'
                                    },
                                }}
                            >
                                <ListItemIcon sx={{ color: selectedButton === "Prompt Template" ? '#FFF' : '#000',
                                  }}>
                                    <StickyNote2Icon sx={{ color: selectedButton === "Prompt Template" ? '#FFF' : '#6001FF' }} />
                                </ListItemIcon>
                                <ListItemText primary="Prompt Template" sx={{ color: selectedButton === "Prompt Template" ? '#FFF' : '#000', fontSize: "1.3rem", }} disableTypography />
                            </ListItemButton>
                        </ListItem>
          </List>
        </nav>
      </>
    );
  }
}

export default withRouter(SideNav);
