import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

// ** Icons Imports
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PhonelinkIcon from "@mui/icons-material/Phonelink";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress for the loader
import { formatIndianNumber, formatNumber } from "./StatisticsCard";
import { Tooltip } from "@mui/material";
import Utils from "../userComponents/Utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const salesData = [
  {
    title: "Total Queries",
    color: "#6001FF",
    icon: <TrendingUpIcon sx={{ fontSize: "2.5rem" }} />,
  },
  {
    title: "Total Users",
    color: "#6001FF",
    icon: <PermIdentityIcon sx={{ fontSize: "2.5rem" }} />,
  },
  {
    title: "Queries in last 1 hour",
    color: "#6001FF",
    icon: <PhonelinkIcon sx={{ fontSize: "2.5rem" }} />,
  },
  {
    title: "Avg Cost / Query",
    color: "#6001FF",
    icon: <AttachMoneyIcon sx={{ fontSize: "2.5rem" }} />,
  },
  {
    title: "Avg Cost / Day",
    color: "#6001FF",
    icon: <AttachMoneyIcon sx={{ fontSize: "2.5rem" }} />,
  },
];

class Cards_logs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      log: null,
      isLoading: false, // Add isLoading state to track data loading
    };
  }

  componentDidMount() {
    this.fetchdata();
  }
  fetchdata = async () => {
    this.setState({ isLoading: true });

    const token = localStorage.getItem("token");
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];

    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    try {
      const response = await fetch(
        `${BASE_URL}/admin/log_details/${userOrganization}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
        return;
      }
      if (response.ok) {
        const data = await response.json();
        // Assuming the data is an array, update the state
        this.setState({ log: data, isLoading: false }); // Set isLoading to false once data is fetched
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  };
  calculateTotalTokenUsage = (usersData) => {
    let totalTokenUsage = 0;
    for (const user of usersData) {
      totalTokenUsage += user.token_used;
    }
    return totalTokenUsage;
  };

  getStatsByTitle = (title) => {
    let stats;
    switch (title) {
      case "Total Users":
        stats = this.state.log
          ? formatNumber(this.state?.log?.unique_user_list_count)
          : "N/A";
        break;
      case "Total Queries":
        stats = this.state.log
          ? formatNumber(this.state?.log?.total_logs_messages)
          : "N/A";
        break;
      case "Queries in last 1 hour":
        stats = this.state.log
          ? formatNumber(this.state?.log?.total_logs_last_1hr)
          : "N/A";
        break;
      case "Avg Cost / Query":
        stats =
          "$" + this.state?.log?.average_cost_per_query?.toFixed(3) || "N/A";
        break;
      case "Avg Cost / Day":
        stats =
          "$" + this.state?.log?.average_cost_per_day?.toFixed(3) || "N/A";
        break;
      default:
        stats = "";
        break;
    }
    return stats;
  };

  renderStats() {
    return salesData.map((item, index) => (
      <Grid item xs={12} sm={2.3} key={index}>
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar
            sx={{
              mr: 2,
              width: 60,
              height: 60,
              boxShadow: 3,
              color: "common.white",
              backgroundColor: "#6001FF",
              borderRadius: "10px",
            }}
          >
            {item.icon}
          </Avatar>
          <Box>
            <Typography
              variant="h6"
              style={{ fontSize: "1.6rem", color: "#262626" }}
            >
              {item.title === "Total Queries" ||
              item.title === "Total Users" ||
              item.title === "Queries in last 1 hour" ? (
                <Tooltip
                  title={
                    item.title === "Total Queries"
                      ? this.state.log
                        ? formatIndianNumber(
                            this.state?.log?.total_logs_messages
                          )
                        : "N/A"
                      : item.title === "Total Users"
                      ? this.state.log
                        ? formatIndianNumber(
                            this.state?.log?.unique_user_list_count
                          )
                        : "N/A"
                      : this.state.log
                      ? formatIndianNumber(this.state?.log?.total_logs_last_1hr)
                      : "N/A"
                  }
                  placement="top-start"
                  arrow
                >
                  <span>{this.getStatsByTitle(item.title)}</span>
                </Tooltip>
              ) : (
                this.getStatsByTitle(item.title)
              )}
              {/* {this.getStatsByTitle(item.title)} */}
            </Typography>
            <Typography
              variant="caption"
              style={{
                fontSize: "1rem",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 300,
              }}
            >
              {item.title}
            </Typography>
          </Box>
        </Box>
      </Grid>
    ));
  }

  render() {
    return (
      <Card elevation={2} style={{ borderRadius: "20px" }}>
        <CardHeader/>
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            pt: (theme) => `${theme.spacing(0)} !important`,
          }}
        >
          {/* Conditional rendering for loader */}
          {this.state.isLoading && (
            <CircularProgress style={{ color: "#335" }} />
          )}

          {/* Display stats if data is available */}
          {!this.state.isLoading && (
            <Grid container spacing={[5, 0]}>
              {this.renderStats()}
            </Grid>
          )}
        </CardContent>
      </Card>
    );
  }
}

export default Cards_logs;
