import React, { Component, } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
// import { Alert, AlertProps } from "@mui/material";
import { styled } from "@mui/material/styles";

// const SnackbarAlert=forwardRef<HTMLDivElement,AlertProps>(
//   function SnackbarAlert(props,ref){
//     return <Alert elevation={6} ref={ref} {...props}/>
//   }
// )
const NOTIFICATION_SERVICE_URL = process.env.REACT_APP_NOTIFICATION_URL;
const MyButton = styled(Button)({
  color: "#fff",
  // color:"inherit",
  backgroundColor: "#212121",
  "&:hover": {
    backgroundColor: "#212121",
  },
  // contrastText: 'white',
  padding: 8,
  margin: 5,
  borderRadius: 4,
});
class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      webSocket: null,
      open: false,
    };
    this.isAnyNotification = false;
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    this.isAnyNotification = true;
    this.connectWebSocket();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.isAnyNotification = false;
    this.closeWebSocket();
  }

  connectWebSocket() {
    // Connect to WebSocket
    const formData = new FormData();
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    formData.append("token", token);
    formData.append("redis_key", email); // Add additional form data if needed
    const webSocket = new WebSocket(
      `${NOTIFICATION_SERVICE_URL}/zn002/embedding/ws`
    );
    const currMessage = this.state.message;
    // Event listener for when the connection is open
    webSocket.addEventListener("open", (event) => {
      webSocket.send(JSON.stringify(Object.fromEntries(formData.entries())));
    });

    // Event listener for when a message is received from the server
    webSocket.addEventListener("message", (event) => {
      if (event.data === "-1") {
        this.isAnyNotification = false;
        this.closeWebSocket();
        return;
      }
      if (event.data !== "0" && currMessage !== event.data) {
        this.setState({ message: event.data, open: true });
      }
      // this.handleOpen();

      // Close the WebSocket connection immediately
      this.closeWebSocket();

      // Reconnect WebSocket after 3 minutes
      // setTimeout(() => {
      //   this.connectWebSocket();
      // }, 60000);
    });

    // Event listener for when there is an error with the WebSocket connection
    webSocket.addEventListener("error", (event) => {
      console.error("WebSocket error:", event);
      this.reconnectWebSocket();
    });

    // Event listener for when the connection is closed
    webSocket.addEventListener("close", (event) => {
      // console.log(event.data)
      if (this.mounted && this.isAnyNotification) {
        setTimeout(() => {
          this.reconnectWebSocket();
        }, 30000);
      }
    });

    // Save the WebSocket instance to the component state
    this.setState({ webSocket });
  }

  closeWebSocket() {
    if (this.state.webSocket) {
      this.state.webSocket.close();
    }
  }

  reconnectWebSocket() {
    setTimeout(() => {
      this.connectWebSocket();
    }, 30000); // Retry connection after 1 second (adjust as needed)
  }
  action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="white"
        onClick={this.handleClose} // Call handleClose when the close icon is clicked
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        {/* <h1>WebSocket Component</h1>
        <p>Received Message: {this.state.message}</p>
        <Button color="inherit" onClick={this.handleOpen}>Show Notification</Button> */}
        <Snackbar
          open={this.state.open}
          action={this.action}
          autoHideDuration={12000}
          onClose={this.handleClose}
          message={this.state.message}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MyButton size="medium">
            {this.state.message}
            <CloseIcon fontSize="small" onClick={this.handleClose} />
          </MyButton>
        </Snackbar>
      </div>
    );
  }
}

export default Notification;
