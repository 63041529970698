import React from "react";
import Grid from "@mui/material/Grid";
import FileUploadQA from "./fileUploadQA";
import SideNav from "./sidenavQA";
import ChatUI from "./pdfchatQA";
import BookmarkQA from "./BookmarkQA";
import Datasource from "./Datasource";
import ListFolder from "./ListFolder";
import PromptTemplate from "./promptTemplate";
class AdminQA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpload: false,
      showChat: false,
      showListdata: true,
      showBook: false,
      showData: false,
      folder_name: "",
      folder_id: "",
      current_files: null,
      filenames:null,
      summary:"",
      filenameAndSummary:null,
      showPromptTemplate: false,
    };
  }
  handleDatasourceClick = () => {
    this.setState({
      showUpload: false,
      showChat: false,
      showListdata: false,
      showBook: false,
      showData: true,
      showPromptTemplate: false,
    });
  };

  handleUploadClick = (folder_name, folder_id) => {
    this.setState({
      showUpload: true,
      showChat: false,
      showListdata: false,
      showBook: false,
      showData: false,
      folder_name: folder_name,
      folder_id: folder_id,
      showPromptTemplate: false,
    });
  };

  handleListdataClick = () => {
    this.setState({
      showUpload: false,
      showChat: false,
      showListdata: true,
      showBook: false,
      showData: false,
      showPromptTemplate: false,
    });
  };

  handleChatClick = (current_files,filenames,summary,filenameAndSummary, args) => {
    this.setState({
      showUpload: false,
      showChat: true,
      showListdata: false,
      showBook: false,
      showData: false,
      current_files: current_files,
      filenames:filenames,
      summary:summary,
      filenameAndSummary:filenameAndSummary,
      showPromptTemplate: false,
    });
  };

  handleBookClick = () => {
    this.setState({
      showUpload: false,
      showChat: false,
      showListdata: false,
      showBook: true,
      showData: false,
      showPromptTemplate: false,
    });
  };
  handlePromptTemplateClick = () => {
    this.setState({
      showUpload: false,
      showChat: false,
      showListdata: false,
      ShowBook: false,
      showData: false,
      showPromptTemplate: true,
    });
  };

  render() {
    const {
      showUpload,
      showChat,
      showListdata,
      showBook,
      showData,
      folder_name,
      folder_id,
      filenames,
      summary,
      filenameAndSummary,
      showPromptTemplate,
    } = this.state;
    //  console.log("filenameAndSummary",filenameAndSummary);
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={2} style={{ marginTop: "5%" }}>
            <SideNav
              handleListdataClick={this.handleDatasourceClick}
              handleChatClick={this.handleChatClick}
              handleUploadClick={this.handleUploadClick}
              handleDatasourceClick={this.handleListdataClick}
              handleBookClick={this.handleBookClick}
              handlePromptTemplateClick={this.handlePromptTemplateClick}
              showUpload={showUpload}
              showChat={showChat}
              showListdata={showData}
              showData={showListdata}
            />
          </Grid>
          <Grid item xs={10}>
            {showUpload && (
              <FileUploadQA
                handleListdataClick={this.handleListdataClick}
                folder_name={folder_name}
                folder_id={folder_id}
              />
            )}
            {showChat && (
              <ChatUI 
              current_files={this.state.current_files || []} 
              filenames={filenames|| []}
              summary={summary|| ""}
              filenameAndSummary={filenameAndSummary || {}}
              />
            )}
            {showListdata && (
              <ListFolder
                handleChatClick={this.handleChatClick}
                handleUploadClick={this.handleUploadClick}
                handleDatasourceClick={this.handleDatasourceClick}
              />
            )}
            {showBook && <BookmarkQA />}
            {showPromptTemplate && <PromptTemplate />}
            {/* {showData && <Datasource handleChatClick={this.handleChatClick} />} */}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default AdminQA;
