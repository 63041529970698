import React from "react";
import boticon from "../assets/images/png/bot.png";

const Botimageicon = () => {
  return (
    <div>
      <img src={boticon} alt="icon for bot" height="60px" width="70px" />
    </div>
  );
};

export default Botimageicon;
