import React, { Component } from "react";
import {
  Container,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
// import { ListItemIcon, IconButton, Menu, MenuItem, Button, Box, Checkbox, InputBase, TablePagination } from '@mui/material';
import {
  ListItemIcon,
  IconButton,
  Button,
  Box,
  Checkbox,
  InputBase,
  TablePagination,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
//import CircularProgress from '@mui/material/CircularProgress';
import { withRouter } from "./withRouter";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import Chip from "@mui/material/Chip";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Notification from "./Notification";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress for the loader
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import LanguageIcon from "@mui/icons-material/Language";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL ="http://127.0.0.1:8001";
// const EMBEDDING_SERVICE_URL = "http://127.0.0.1:8001";
const application_code = "ZN004";
class ListData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedIndex: -1,
      searchQuery: "",
      selectAll: false,
      websites: [],
      selectedWebs: [],
      page: 0,
      rowsPerPage: 10,
      showLoader: false,
      selected_webs_by_id: [],
      username: localStorage.getItem("username"),
      snackbarOpen: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
      isLoading: false, // Add isLoading state to track data loading
    };
  }
  componentDidMount() {
    this.fetchDocumentWeb(); // Call the function when the component mounts
  }
  fetchDocumentWeb = async () => {
    const token = localStorage.getItem("token");
    this.setState({
      isLoading: true,
    });
    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }
    const email = localStorage.getItem("email");
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    try {
      const response = await fetch(
        `${BASE_URL}/zn004/user/get_user_web_document/${email}?userOrganization=${encodeURIComponent(
          userOrganization)}&application_code=${encodeURIComponent(application_code)}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json(); // Parse the response as JSON
        if (Array.isArray(responseData.data)) {
          this.setState({
             websites: responseData.data,
            isLoading: false, // Set isLoading to false once data is fetched
          });
        } else {
          console.error(
            "Data is not an array in the server response:",
            responseData
          );
        }
      } else {
        console.error("Server response was not OK. Status:", response.status);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.error("Error fetching data:", error);
    }
  };

  handleAddWebsite = () => {
    this.props.handleUploadClick( {
      showUpload: true,
      showChat: false,
      ShowListdata: false,
    });
  };

  truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  handleDeleteWeb = async (webIdsToDelete) => {
    const token = localStorage.getItem("token");
    // const username = localStorage.getItem('username')
    // const designation = localStorage.getItem('designation')
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    const formData = new FormData();
    const { websites } = this.state;

    let filteredWebs = [];
    let adminWebs = websites.filter(
      (web) => webIdsToDelete.includes(web.id) && web.isAdmin
    );

    if (adminWebs.length > 0) {
      if (adminWebs.length === 1) {
        this.handleSnackbarOpen(
          "error",
          `${adminWebs.length} website cannot be deleted that is added by admin`
        );
      } else if (adminWebs.length > 1) {
        this.handleSnackbarOpen(
          "error",
          `${adminWebs.length} websites cannot be deleted that are added by admin`
        );
      }
    }

    filteredWebs = websites.filter(
      (web) =>
        webIdsToDelete.includes(web.id) &&
        !web.isAdmin &&
        web.embedding_status !== "Processing"
    );
    filteredWebs.map((item) => item.id);
    const web_ids = filteredWebs.map((item) => item.id);
    formData.append("web_document_ids", web_ids);
    formData.append("userOrganization", userOrganization);
    formData.append("application_code", application_code);
    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/zn004/user/delete_user_web_documents`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        // Fetch updated file list after deletion
        this.fetchDocumentWeb();
        this.setState({
          selected_webs_by_id: [],
          selectedWebs: [],
          selectAll: false,
        });
      } else {
        console.error("Failed to delete websites");
      }
    } catch (error) {
      console.error("Error deleting websites:", error);
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;

    if (newRowsPerPage === -1) {
      // If "All" is selected, set rowsPerPage to the total number of rows.
      this.setState({
        rowsPerPage: this.state.websites.length,
        page: 0, // Reset the page to the first page
      });
    } else {
      // For other values, set rowsPerPage to the selected value.
      this.setState({
        rowsPerPage: parseInt(newRowsPerPage, 10),
        page: 0, // Reset the page to the first page
      });
    }
  };

  handleSelectAllClick = () => {
    const { selectAll, websites } = this.state;
    let selectedWebs, selected_webs_by_id;

    if (selectAll) {
      // If "Select All" is already checked, unselect all files
      selectedWebs = [];
      selected_webs_by_id = [];
    } else {
      // If "Select All" is not checked, select all files by fileId
      const availableWebs = websites.filter(
        (web) => web.embedding_status === "Available"
      );
      selectedWebs = availableWebs.map((web) => ({
        id: web.id,
        website_url: web.website_url,
      }));
      selected_webs_by_id = availableWebs.map((web) => web.id);
    }

    this.setState({
      selectAll: !selectAll,
      selectedWebs,
      selected_webs_by_id,
    });
  };

  handleCheckboxChange = (website_url, id) => {
    const { selectedWebs, selected_webs_by_id, websites } = this.state;

    if (selectedWebs.some((selectedWeb) => selectedWeb.id === id)) {
      this.setState(
        (prevState) => ({
          selectedWebs: prevState.selectedWebs.filter(
            (selectedWeb) => selectedWeb.id !== id
          ),
        }),
        () => {
          if (this.state.selectedWebs.length === websites.length) {
            this.setState({ selectAll: true });
          }
          if (this.state.selectedWebs.length === websites.length-1) {
            this.setState({ selectAll: false });
          }
        }
      );
    } else {
      // File is not selected, add it to the selected files
      this.setState(
        (prevState) => ({
          selectedWebs: [
            ...prevState.selectedWebs,
            { id: id, website_url: website_url },
          ],
        }),
        () => {
          if (this.state.selectedWebs.length === websites.length) {
            this.setState({ selectAll: true });
          }
          if (this.state.selectedWebs.length === websites.length-1) {
            this.setState({ selectAll: false });
          }
        }
      );
    }
    if (selected_webs_by_id.includes(id)) {
      // File is already selected, remove it from the selected files
      this.setState((prevState) => ({
        // selected_webs_by_id: prevState.selected_webs_by_id.filter((id) => id !== id),
        selected_webs_by_id: prevState.selected_webs_by_id.filter(
          (fileid) => fileid !== id
        ),
      }));
    } else {
      // File is not selected, add it to the selected files
      this.setState((prevState) => ({
        selected_webs_by_id: [...prevState.selected_webs_by_id, id],
      }));
    }
  };

  handleUpdateKnowledge = async () => {
    const token = localStorage.getItem("token");
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    // Create a FormData object to send data as a multipart/form-data request
    const webnames = this.state.selectedWebs.map((web) => web.website_url);
    const webIds = this.state.selectedWebs.map((web) => web.id);
    const filteredData = this.state.websites.filter(
      (web) => webnames.includes(web.website_url) && webIds.includes(web.id)
    ).map((web)=>web.website_url);

    const queryData={
      "weburls":filteredData,
      "organization":userOrganization
    }
    // Check if a user token is available
    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    try {
      // Show the loader while the update is in progress
      this.setState({ showLoader: true });

      // Send a POST request to the server to analyze the webs
      const response = await fetch(`${BASE_URL}/zn004/bot/analyseWebUrls`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,// Include the user's token in the request headers
          "Content-Type": "application/json"
        },
        body: JSON.stringify(queryData), // Includes webname and organisation in the request body
      });

      if(response.status===500){
        this.handleSnackbarOpen(
          "error",
          "Something went wrong."
        );
      }
      // Check if the response status is OK (HTTP 200)
      if (response.ok) {
        const res = await response.json();
        const webss_path = res["webs_path"];
        const webnames=res["webnames"];
        const web_summary=res["summary_response"];
        const webnameAndSummary = {};
        webnames.forEach((webname, index) => {
          if (index === 0) {
            webnameAndSummary[webname] = web_summary;
        } else {
            webnameAndSummary[webname] = "";
        }
      });
      localStorage.setItem("webnameAndSummary", JSON.stringify(webnameAndSummary));
        // Call the chat click handler to navigate to the chat page
        this.props.handleChatClick(webss_path,{
          showUpload: false, // Hide the upload interface
          showChat: true, // Show the chat interface
          ShowKnowledge: false, // Hide the knowledge interface
        });
      } else {
        // Log an error message if the response status is not OK
        console.error("Error updating knowledge. Status:", response.status);
      }
    } catch (error) {
      // Log an error message if there is an exception during the request
      console.error("Error updating knowledge:", error);
    } finally {
      // Hide the loader when the update is complete (whether it succeeds or fails)
      this.setState({ showLoader: false });
    }
  };
  getUsername = (username, isadmin) => {
    if (isadmin) {
      return "Admin";
    } else {
      return username;
    }
  };
  handleSnackbarOpen = (severity, message) => {
    this.setState({
      snackbarOpen: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };
  reset = () => {
    window.location.reload();
  };


  render() {
    const {
      searchQuery,
      disableUploadButton,
      showLoader,
      selectAll,
      websites,
      page,
      rowsPerPage,
      snackbarOpen,
      snackbarSeverity,
      snackbarMessage,
    } = this.state;


    return (
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5%",
            padding: "20px",
          }}
        >
     
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F3F6FD",
              borderRadius: "25px",
              padding: "5px",
              marginRight: "3%",
            }}
          >
            <SearchIcon
              sx={{ color: "#6001FF", marginLeft: "10px", marginRight: "5px" }}
            />
            <InputBase
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => this.setState({ searchQuery: e.target.value })}
              sx={{
                color: "#6001FF",
                width: "40%",
                backgroundColor: "#F3F6FD",
                borderRadius: "25px",
              }}
            />
          </Box>
          <ListItemIcon sx={{ color: "#000", pt: 1 }}>
            <RestartAltOutlinedIcon
              sx={{
                color: "#6001FF",
                width: "45px",
                height: "35px",
                "&:hover": {
                  backgroundColor: "#EFEFEF",
                  borderRadius: "50%", // Making background circular
                },
              }}
              onClick={this.reset}
            />
          </ListItemIcon>

            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              style={{
                backgroundColor: "#6001FF",
                color: "#fff",
                marginLeft: "15px",
                textTransform: "none",
              }}
              onClick={this.handleAddWebsite}
            >
              Add Website
            </Button>
        </div>
        {this.state.selectedWebs.length > 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "2%",
            }}
          >
            <Typography variant="body1" color="textSecondary">
              {`${this.state.selectedWebs.length} ${
                this.state.selectedWebs.length === 1 ? "website" : "websites"
              } selected`}
            </Typography>
            <IconButton
              aria-label="Delete"
              onClick={() =>
                this.handleDeleteWeb(this.state.selected_webs_by_id)
              }
            >
              <Tooltip title="Remove" arrow>
                <DeleteIcon style={{ marginLeft: "10%" }} />
              </Tooltip>{" "}
            </IconButton>
          </div>
        )}
        <Paper
          elevation={5}
          style={{
            width: "100%",
            borderRadius: "10px",
            backgroundColor: "#FFF",
          }}
        >
          <>
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {this.state.isLoading && (
                <CircularProgress style={{ color: "#335" }} />
              )}
              {""}
            </CardContent>
            {/* Display stats if data is available */}
            {!this.state.isLoading && (
              <List component="nav" aria-label="web list">
                <ListItem>
                  <ListItemText style={{ width: "2%" }}></ListItemText>
                  <ListItemText style={{ width: "2%",}}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "1.1rem",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                      }}
                    >
                     Web
                    </Typography>
                  </ListItemText>
                  <ListItemText style={{ width: "25%", }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "1.1rem",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                        ml:8,
                      }}
                    >
                      Website Name
                    </Typography>
                  </ListItemText>
                  <ListItemText style={{ width: "10%" ,}}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "1.1rem",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                      }}
                    >
                      Upload Date
                    </Typography>
                  </ListItemText>
           
                  <ListItemText style={{ width: "10%", }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "1.1rem",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                      }}
                    >
                      User
                    </Typography>
                  </ListItemText>
                  <ListItemText style={{ width: "10%",}}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "1.1rem",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                        ml:1,
                      }}
                    >
                      Status
                    </Typography>
                  </ListItemText>
                  <ListItemText style={{ width: "10.5%" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "1.1rem",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 400,
                      }}
                    >
                      <Checkbox
                        sx={{
                          color: "#6001FF",
                          "&.Mui-checked": { color: "#6001FF" },
                        }}
                        id="selectAll"
                        checked={selectAll}
                        onChange={()=>this.handleSelectAllClick()}
                        size="small"
                      />
                      Select
                    </Typography>
                  </ListItemText>
                </ListItem>
                <Divider />
                {websites
                  .filter((web) =>
                    web.website_url
                      .toLowerCase()
                      .includes(searchQuery.trim().toLowerCase())
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((web, index) => (
                    <React.Fragment key={index}>
                      <ListItemButton
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <ListItemText style={{ width: "2%" }}></ListItemText>
                        <ListItemIcon
                          style={{ width: "5%", marginLeft: "1%",  }}
                        >
                          {/* Display the logo based on the databasetype */}         
                                    <LanguageIcon />   
                          {/* Add more conditions for other database types */}
                        </ListItemIcon>
                        <ListItemText style={{ width: "25%",  }}>
                          <Tooltip
                            title={web.website_url}
                            placement="top-start"
                            arrow
                          >
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              sx={{
                                fontSize: "1rem",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 400,
                                ml: 2,
                              }}
                            >
                              {this.truncateText(web.website_url, 30)}
                            </Typography>
                          </Tooltip>
                        </ListItemText>
                        <ListItemText style={{ width: "10%",  }}>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={{
                              fontSize: "1rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                              ml: 2,
                            }}
                          >
                            {`${web.uploaded_date.substring(0,10)}`}
                          </Typography>
                        </ListItemText>
                   
                        <ListItemText style={{ width: "10%",  }}>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={{
                              fontSize: "1rem",
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: 400,
                             
                            }}
                          >
                            {this.getUsername(web.username, web.isAdmin)}
                          </Typography>
                        </ListItemText>
                        <ListItemText style={{ width: "10%", }}>
                          {web.embedding_status === "Processing" && (
                            <Chip
                              label={web.embedding_status}
                              variant="outlined"
                            />
                          )}
                          {web.embedding_status === "Unavailable" && (
                            <Chip
                              label={web.embedding_status}
                              variant="outlined"
                              color="warning"
                            />
                          )}
                          {web.embedding_status === "Failed" && (
                            <Chip
                              label={web.embedding_status}
                              variant="outlined"
                              color="error"
                            />
                          )}

                          {web.embedding_status === "Available" && (
                            <Chip
                              label={web.embedding_status}
                              variant="outlined"
                              color="success"
                            />
                          )}

                          {/* <Typography variant="body1" color="textSecondary" sx={{ fontSize: '1rem', fontFamily: 'Poppins, sans-serif', fontWeight: 400, }}>{file.embedding_status}</Typography> */}
                        </ListItemText>
                        <ListItemText style={{ width: "10%" }}>
                          {web.embedding_status === "Processing" ||
                          web.embedding_status === "Unavailable" ? (
                            <Checkbox
                              size="small"
                              sx={{
                                color: "#6001FF",
                                "&.Mui-checked": { color: "#6001FF" },
                              }}
                              checked={this.state.selectedWebs.includes(
                                web.website_url
                              )}
                              onChange={() =>
                                this.handleCheckboxChange(
                                  web.website_url,
                                  web.id
                                )
                              }
                              disabled
                            />
                          ) : (
                            <Checkbox
                              size="small"
                              sx={{
                                color: "#6001FF",
                                "&.Mui-checked": { color: "#6001FF" },
                              }}
                              checked={this.state.selectedWebs.some(
                                (selectedWeb) =>
                                  selectedWeb.id === web.id &&
                                  selectedWeb.website_url === web.website_url
                              )}
                              onChange={() =>
                                this.handleCheckboxChange(
                                  web.website_url,
                                  web.id
                                )
                              }
                            />
                          )}
                        </ListItemText>
                      </ListItemButton>
                      {index !== web.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
              </List>
            )}

          </>
        </Paper>

        <div
          style={{
            display: "flex",
            marginTop: "10px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TablePagination
            style={{ marginLeft: "1%" }} // Adjust the margin as needed
            rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
            component="div"
            count={websites.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />

          <Button
            variant="contained"
            color="primary"
            style={{
              marginRight: "2%",
              backgroundColor: "#6001FF",
              color: "#FFFFFF",
              cursor: "pointer",
              textTransform: "none",
            }}
            disabled={
              websites.length === 0 ||
              disableUploadButton === true ||
              this.state.selectedWebs.length === 0
            }
            onClick={this.handleUpdateKnowledge}
            endIcon={<ArrowCircleRightOutlinedIcon style={{ fontSize: 30 }} />}
          >
            {showLoader ? (
              <CircularProgress
                size={25}
                sx={{
                  color: "#FFFFFF",
                  marginLeft: "-7%",
                }}
              />
            ) : (
              "Query"
            )}
          </Button>
        </div>

        <Snackbar
          open={snackbarOpen} // Use the correct state name here (snackbarOpen)
          autoHideDuration={3000}
          onClose={this.handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.handleSnackbarClose}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
        <Notification />
      </Container>
    );
  }
}

export default withRouter(ListData);
