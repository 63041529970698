import React, { Component } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import StatisticsCard from "./StatisticsCard";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import MainBarchart from "./MainBarchart";
import Menu from "./Menu";
import { withRouter } from "./withRouter";
import User from "./Useract";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress for the loader
import Backdrop from "@mui/material/Backdrop";
import Utils from "../userComponents/Utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("token"),
      userDetails: JSON.parse(localStorage.getItem("userDetails"))
        ? JSON.parse(localStorage.getItem("userDetails"))
        : [],
      weekly_token_usage: JSON.parse(localStorage.getItem("weekly_token_usage"))
        ? JSON.parse(localStorage.getItem("weekly_token_usage"))
        : [],
      analytics_data: JSON.parse(localStorage.getItem("analytics_data"))
        ? JSON.parse(localStorage.getItem("analytics_data"))
        : [],
      cummulative_token_usage: 0,
      customTabValue: 2,
      showTab: true,
      showUpload: false,
      average_cost_per_query: 0,
      average_cost_per_day: 0,
      xAxisGranularity: "day",
      yAxisMetric: "Active Users",
      pie_chart_data: [],

      showDashboard: true,
      showUserManagement: false,
      showDataManagement: false,
      showLogs: false,
      showPrompt: false,
      isPromptOpendata: false,
      isPromptOpendocs: false,
      showLLMdocs: false,
      showLLMdata: false,

      isLoading: false, // Add isLoading state to track data loading
      showBackdrop: false,
      token_usage: 0,
      total_token_limit: 10000,
      user_card_analytics: {
        total_cost: 0,
        total_queries: 0,
        total_tokens_consumed: 0,
        total_users: 0,
      },
      organization_card_analytics: {
        average_cost_per_day: 0,
        average_cost_per_query: 0,
      },
      aggregated_organization_data: null,
      data: [],
      departmentData: [],
      top_users_data: {},
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true, showBackdrop: true });

    try {
      await this.fetchPieChartData();
      await this.fetchStatscardData();
      await this.fetchTrendAnalysis();
      await this.fetchDepartmentAnalysis();
      await this.fetchTopUsersData();
      this.setState({ isLoading: false, showBackdrop: false });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    } finally {
      this.setState({ showBackdrop: false });
    }
  }

  handleDashboardClick = () => {
    this.setState({
      showDashboard: true,
      showUserManagement: false,
      showDataManagement: false,
      showLogs: false,
      showPrompt: false,
      isPromptOpendata: false,
      isPromptOpendocs: false,
      showLLMdocs: false,
      showLLMdata: false,
    });
  };

  handleUserManagementClick = () => {
    this.setState({
      showDashboard: false,
      showUserManagement: true,
      showDataManagement: false,
      showLogs: false,
      showPrompt: false,
      isPromptOpendata: false,
      isPromptOpendocs: false,
      showLLMdocs: false,
      showLLMdata: false,
    });
  };

  handleDataManagementClick = () => {
    this.setState({
      showDashboard: false,
      showUserManagement: false,
      showDataManagement: true,
      showLogs: false,
      showPrompt: false,
      isPromptOpendata: false,
      isPromptOpendocs: false,
      showLLMdocs: false,
      showLLMdata: false,
    });
  };

  handleLogClick = () => {
    this.setState({
      showDashboard: false,
      showUserManagement: false,
      showDataManagement: false,
      showLogs: true,
      showPrompt: false,
      isPromptOpendata: false,
      isPromptOpendocs: false,
      showLLMdocs: false,
      showLLMdata: false,
    });
  };

  handlePromptClick = () => {
    this.setState({
      showDashboard: false,
      showUserManagement: false,
      showDataManagement: false,
      showLogs: false,
      showPrompt: true,
      isPromptOpendata: false,
      isPromptOpendocs: false,
      showLLMdocs: false,
      showLLMdata: false,
    });
  };

  handleCustomizepromptClick = () => {
    this.setState({
      showDashboard: false,
      showUserManagement: false,
      showDataManagement: false,
      showLogs: false,
      showPrompt: false,
      isPromptOpendata: true,
      isPromptOpendocs: false,
      showLLMdocs: false,
      showLLMdata: false,
    });
  };

  handleCustomizepromptdocsClick = () => {
    this.setState({
      showDashboard: false,
      showUserManagement: false,
      showDataManagement: false,
      showLogs: false,
      showPrompt: false,
      isPromptOpendata: false,
      isPromptOpendocs: true,
      showLLMdocs: false,
      showLLMdata: false,
    });
  };

  handleLLMconfigDocsClick = () => {
    this.setState({
      showDashboard: false,
      showUserManagement: false,
      showDataManagement: false,
      showLogs: false,
      showPrompt: false,
      isPromptOpendata: false,
      isPromptOpendocs: false,
      showLLMdocs: true,
      showLLMdata: false,
    });
  };

  handleLLMconfigDataClick = () => {
    this.setState({
      showDashboard: false,
      showUserManagement: false,
      showDataManagement: false,
      showLogs: false,
      showPrompt: false,
      isPromptOpendata: false,
      isPromptOpendocs: false,
      showLLMdocs: false,
      showLLMdata: true,
    });
  };

  handleTabChange = (newValue) => {
    this.setState({ value: newValue });
  };

  handleUploadClick = () => {
    this.setState({
      showTab: false,
      showUpload: true,
    });
  };

  handleTabClick = (customTabValue) => {
    this.setState({
      showTab: true,
      showUpload: false,
      customTabValue: customTabValue,
    });
  };

  updateUserDetails = (updatedDetails) => {
    localStorage.setItem("userDetails", JSON.stringify(updatedDetails));
    this.setState({ userDetails: updatedDetails });
  };

  handleXAxisChange = (event) => {
    let bar_chart_data = [];
    if (event.target.value === "year") {
      bar_chart_data =
        this.state.aggregated_organization_data["merged_data_by_year"];
    }
    if (event.target.value === "quarter") {
      bar_chart_data =
        this.state.aggregated_organization_data["merged_data_by_quarter"];
    }
    if (event.target.value === "month") {
      bar_chart_data =
        this.state.aggregated_organization_data["merged_data_by_month"];
    }
    if (event.target.value === "day") {
      bar_chart_data =
        this.state.aggregated_organization_data["merged_data_by_date"];
    }
    this.setState({
      xAxisGranularity: event.target.value,
      data: bar_chart_data,
    });
  };

  handleYAxisChange = (event) => {
    this.setState({ yAxisMetric: event.target.value });
  };

  fetchPieChartData = async () => {
    const token = localStorage.getItem("token");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    if (!token) {
      console.error("token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const response = await fetch(
        `${BASE_URL}/admin/fetch_organization_pie_chart_data/${userOrganization}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        console.error("Unauthorized: You are not logged in");
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.status === 200) {
        const result = await response.json();
        this.setState({ pie_chart_data: result["pie_chart_data"] });
      } else {
        throw new Error("Failed to fetch pieChart Data");
      }
    } catch (error) {
      console.error("Error fetching pieChart Data:", error);
    }
  };
  fetchStatscardData = async () => {
    const token = localStorage.getItem("token");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    if (!token) {
      console.error("token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const response = await fetch(
        `${BASE_URL}/admin/fetch_organization_statistics_card_data/${userOrganization}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        console.error("Unauthorized: You are not logged in");
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.status === 200) {
        const result = await response.json();

        this.setState({
          user_card_analytics: result.user_card_analytics,
          organization_card_analytics: result.organization_card_analytics,
        });
      } else {
        throw new Error("Failed to fetch statscard Data");
      }
    } catch (error) {
      console.error("error while fetching statscard Data", error);
    }
  };
  fetchTrendAnalysis = async () => {
    const token = localStorage.getItem("token");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    if (!token) {
      console.error("token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const response = await fetch(
        `${BASE_URL}/admin/fetch_organization_trend_analysis/${userOrganization}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        console.error("Unauthorized: You are not logged in");
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.status === 200) {
        const aggregated_organization_data = await response.json();
        this.setState({
          aggregated_organization_data: aggregated_organization_data,
          data: aggregated_organization_data["merged_data_by_date"],
        });
      } else {
        throw new Error("Failed to fetch Trend Analysis Data");
      }
    } catch (error) {
      console.error("Error fetching Trend Analysis Data:", error);
    }
  };
  fetchDepartmentAnalysis = async () => {
    const token = localStorage.getItem("token");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    if (!token) {
      console.error("token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const response = await fetch(
        `${BASE_URL}/admin/fetch_organization_department_analytics/${userOrganization}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        console.error("Unauthorized: You are not logged in");
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.status === 200) {
        const department_analytics = await response.json();
        this.setState({
          departmentData: department_analytics,
        });
      } else {
        throw new Error("Failed to fetch department Data");
      }
    } catch (error) {
      console.error("Error fetching deparment Data:", error);
    }
  };
  fetchTopUsersData = async () => {
    const token = localStorage.getItem("token");
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    if (!token) {
      console.error("token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const response = await fetch(
        `${BASE_URL}/admin/fetch_organizations_top_users/${userOrganization}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        console.error("Unauthorized: You are not logged in");
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.status === 200) {
        const top_users_data = await response.json();
        this.setState({
          top_users_data: top_users_data,
        });
      } else {
        throw new Error("Failed to fetch Top users Data");
      }
    } catch (error) {
      console.error("Error fetching Top users Data:", error);
    }
  };
  render() {
    const {
      xAxisGranularity,
      yAxisMetric,
      data,
      departmentData,
      showBackdrop,
      pie_chart_data,
      user_card_analytics,
      organization_card_analytics,
      top_users_data,
    } = this.state;
    return (
      <>
        {/* Conditional rendering for loader */}
        {/* style={{ backgroundColor:'#000',  zIndex:9,position:'fixed',}} */}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showBackdrop}
          style={{ backgroundColor: "black" }}
        >
          {/* marginTop: "30px", marginLeft: "40px", */}
          {this.state.isLoading && (
            <CircularProgress style={{ color: "purple" }} />
          )}
        </Backdrop>

        {/* Display stats if data is available */}
        {!this.state.isLoading && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "2%",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                right: "3%",
                margin: "1rem",
              }}
            >
              <User />
            </div>
            <Menu selectedButton="Dashboard" />

            <Typography
              variant="h1"
              component="div"
              sx={{
                flexGrow: 1,
                color: "#6001FF",
                fontSize: "2rem",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                marginRight: "5%",
              }}
            >
              Admin Dashboard
            </Typography>
            <Paper
              elevation={5}
              style={{
                borderRadius: "20px",
                backgroundColor: "#F3F6FD",
                marginTop: "5%",
              }}
            >
              <div style={{ margin: "3%" }}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <StatisticsCard
                      organizationAnalytics={this.state.analytics_data}
                      user_card_analytics={user_card_analytics}
                      organization_card_analytics={organization_card_analytics}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <PieChart pie_chart_data={pie_chart_data} />
                  </Grid>
                  {data.length > 0 && (
                    <Grid item xs={12} md={6}>
                      <MainBarchart
                        data={data}
                        handleXAxisChange={this.handleXAxisChange}
                        handleYAxisChange={this.handleYAxisChange}
                        xAxisGranularity={xAxisGranularity}
                        yAxisMetric={yAxisMetric}
                      />
                    </Grid>
                  )}
                  {departmentData.length > 0 && top_users_data && (
                    <Grid item xs={12}>
                      <BarChart
                        departmentData={departmentData}
                        yAxisMetric={yAxisMetric}
                        top_users_data={top_users_data}
                      />
                    </Grid>
                  )}
                  {/* <Grid item xs={12}>

            <DashboardTable
              userDetails={this.state.userDetails}
              updateUserDetails={this.updateUserDetails}
            />
          </Grid>

          <Grid item xs={12} md={12}>
                        {showTab && !showUpload && (
              <Tab
                handleTabClick={this.handleTabClick}
                handleUploadClick={this.handleUploadClick}
                handleChatClick={this.handleChatClick}
                customTabValue={this.state.customTabValue}
                userDetails={this.state.userDetails}
              />
            )}
                        {showUpload && (
              <FileUpload
                customTabValue={this.state.customTabValue}
                showUpload={showUpload}
                handleTabClick={this.handleTabClick}
                userDetails={this.state.userDetails}
              />
            )}  
          </Grid> */}
                </Grid>
              </div>
            </Paper>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(Dashboard);
