import React, { Component } from "react";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { Box, InputAdornment } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Grid from "@mui/material/Grid";

export class WebChatInputBase extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      newMessage,
      loader,
      handleInputChange,
      handleKeyDown,
      handleSendButton,
    } = this.props;
    return (
      <Box
        style={{
          height: "100px",
          overflow: "auto",
          padding: "10px 10px 10px 10px",
          width: "97%",
          marginLeft: "-2%",
          background: "#F3F6FD",
          borderRadius: "0px 0px 20px 20px ",
          fontSize: "30px",
          //borderRadius: '1%',
        }}
      >
        <Grid container sx={{ position: "fixed", bottom: 70 }}>
          <Grid item sx={{ width: "80%" }}>
            <Grid container alignItems="center">
              <InputBase
                className="input-field"
                sx={{
                  ml: 20,
                  flex: 1,
                  marginRight: 20,
                  marginLeft: 15,
                  borderRadius: 10,
                  border: "1px solid #000000",
                  "&:hover": {
                    borderColor: "#6001FF",
                  },
                  backgroundColor: "#FFFFFF",
                  padding: "5px 7% 5px 5%",
                  minHeight: "70px",
                  height: "auto",
                  maxHeight: "130px",
                  color: "#6b6f73",
                  fontSize: "1.25rem",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 400,
                  overflowY: "hidden",
                  overflowX:"hidden",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#6001FF #F5F5F5",
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    backgroundColor: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "4px",
                    backgroundColor: "transparentF",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#555",
                  },
                  "& .scrollbar": {
                    scrollbarWidth: "thin",
                    scrollbarColor: "#6001FF #F5F5F5",
                    overflowY: "auto",
                  },
                }}
                // placeholder="Ask Questions"
                multiline
                maxrows={5}
                type="text"
                placeholder="Type a message..."
                value={newMessage}
                onChange={handleInputChange}
                onFocus={this.handleInputFocus}
                onBlur={this.handleInputBlur}
                onKeyDown={handleKeyDown}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      style={{
                        marginLeft: "130%",
                        color: "#6001FF",
                        marginTop: "-5%",
                        transform: "rotate(340deg)",
                      }}
                      onClick={(event) => {
                        !loader  && handleSendButton(event);
                      }}
                      // onClick={this.handleLoadAndSendMessage()}
                      // onClick={this.retryOperation.bind(this)}
                      sx={{
                        "&:hover": { backgroundColor: "transparent" },
                      }}
                    >
                      <SendIcon
                        style={{
                          fontSize: 40,
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default WebChatInputBase;
