import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const BarChart = ({ x_axis_data, y_axis_data, col_names }) => {
  // Convert data into Highcharts series format
  const x_data = x_axis_data.slice(0, 40);
  const y_data = y_axis_data.slice(0, 40);
  let seriesData;
  let column_names;
  // let categories;

  if (
    x_data.every((value) => typeof value === "string" || Number) &&
    y_data.every((value) => typeof value === "number")
  ) {
    // If x_data values are strings and y_data values are numeric
    seriesData = y_data.map((value, index) => ({
      name: x_data[index],
      y: value,
    }));
    column_names = [col_names[0], col_names[1]];
    // categories=x_data
  } else {
    // Otherwise, assume x_data values are numeric and y_data values are strings
    seriesData = y_data.map((value, index) => ({
      name: value,
      y: x_data[index],
    }));
    column_names = [col_names[1], col_names[0]];
    // categories=y_data
  }
  const options = {
    chart: {
      type: "column",
      // width: x_data.length > 10 ? 1100 : 600,
      width: x_data.length <= 10 ? 600 : 800,
      height: 400,
      scrollablePlotArea: {
        minWidth: x_data.length <= 10 ? 600 : 1200,
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: x_data,
      title: {
        text: column_names[0] || "",
        style: {
          fontSize: "12px",
          color: "#6001FF",
          margin: 2,
        },
      },
      labels: {
        style: {
          fontSize: "12px",
        },
        rotation: -45,
        align: "right",
      },
      // scrollbar: {
      //   enabled: x_data.length > 10,
      // },
    },
    yAxis: {
      title: {
        text: column_names[1] || "Frequency (%)",
        style: {
          fontSize: "12px",
          color: "#6001FF",
          margin: 2,
        },
      },
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    series: [
      {
        data: seriesData,
        color: "#6001FF",
        dataLabels: {
          enabled: true,
          format: "{point.y:.2f}", // Display values on top of bars with two decimal places
          style: {
            fontSize: "8px",
            fontWeight: 0, // Make top values bold
          },
        },
      },
    ],
    legend: {
      enabled: false, // Hide the legend
    },
    credits: {
      enabled: false,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default BarChart;
