import React, { Component } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";
import {
  ListItem,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { withRouter } from "./AppManagement/withRouter";
import { Grid } from "@mui/material";

import DashboardIcon from "@mui/icons-material/Dashboard";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import StorageIcon from "@mui/icons-material/Storage";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import Menu from "./Menu";
import User from "./Useract";
import Utils from "../userComponents/Utils";
import { Link } from 'react-router-dom';

class ZunoApp extends Component {
  state = {
    selectedButton: " ",
    cummulative_token_usage: 0,
  };

  async componentDidMount() {
    // this.fetchdata();
    const token = localStorage.getItem("token");
  
    if (!token) {
      console.error("Token not found in localStorage");
        Utils.removeLocalStorage()
        this.props.navigate("/");
    }
  }
  componentDidUpdate(){
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
        Utils.removeLocalStorage()
        this.props.navigate("/");
    }
  }

  handleCustomizeClickInNav = () => {
    this.props.handleCustomizepromptClick();
    this.setState({ selectedButton: "Customize Prompts" });
  };

  handleCustomizeDocsClickInNav = () => {
    this.props.handleCustomizepromptdocsClick();
    this.setState({ selectedButton: "Customize Prompt" });
  };

  handleLLMconfigDocsClickInNav = () => {
    this.props.handleLLMconfigDocsClick();
    this.setState({ selectedButton: "LLM Configurations" });
  };

  handleLLMconfigDataClickInNav = () => {
    this.props.handleLLMconfigDataClick();
    this.setState({ selectedButton: "LLM Configurations" });
  };

  render() {
   
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "2%",
        }}
      >
        <div
          style={{ position: "absolute", top: 0, right: "3%", margin: "1rem" }}
        >
          <User />
        </div>

        <Menu selectedButton="App Management" />

        <Typography
          variant="h1"
          component="div"
          sx={{
            flexGrow: 1,
            color: "#6001FF",
            fontSize: "2rem",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 600,
            marginRight: "5%",
          }}
        >
          App Management
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{ padding: "4%", overflow: "auto", marginTop: "2%", }}
        >
          {/* First Card */}
          <Grid item xs={4}>
            <Card
              elevation={0}
              sx={{ backgroundColor: "#F3F6FD", borderRadius: "25px" }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{
                    marginLeft: "3%",
                    color: "#818589",
                    fontSize: "2rem",
                  }}
                >
                  Zuno for Data
                </Typography>
              </CardContent>
              <CardActions sx={{ marginLeft: "2%", marginTop: "-10px" }}>
                <List style={{ width: "100%" }}>
                  <ListItem disablePadding>
                    <ListItemButton
                      // href="LLMdata?p=0"
                        component={Link}
                        to="/LLMdata?p=0"
                      // onClick={this.handleLLMconfigDataClickInNav}
                      sx={{
                        borderRadius: "10px",
                        backgroundColor:
                          this.props.selectedButtonLLM === "LLM Configurations"
                            ? "#6001FF"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            this.props.selectedButtonLLM ===
                            "LLM Configurations"
                              ? "#FFF"
                              : "#000",
                        }}
                      >
                        <DashboardIcon
                          sx={{
                            color:
                              this.props.selectedButtonLLM ===
                              "LLM Configurations"
                                ? "#FFF"
                                : "#6001FF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="LLM Configurations"
                        sx={{
                          color:
                            this.props.selectedButtonLLM ===
                            "LLM Configurations"
                              ? "#FFF"
                              : "#000",
                        }}
                        disableTypography
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      // href="LLMdata?p=1"
                        component={Link}
                        to="/LLMdata?p=1"
                      // onClick={this.handleCustomizeClickInNav}
                      sx={{
                        borderRadius: "10px",
                        backgroundColor:
                          this.props.selectedButtonCustom ===
                          "Customize Prompts"
                            ? "#6001FF"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            this.props.selectedButtonCustom ===
                            "Customize Prompts"
                              ? "#FFF"
                              : "#000",
                        }}
                      >
                        <StickyNote2Icon
                          sx={{
                            color:
                              this.props.selectedButtonCustom ===
                              "Customize Prompts"
                                ? "#FFF"
                                : "#6001FF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Customize Prompts"
                        sx={{
                          color:
                            this.props.selectedButtonCustom ===
                            "Customize Prompts"
                              ? "#FFF"
                              : "#000",
                        }}
                        disableTypography
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      // href="LLMdata?p=2"
                       component={Link}
                        to="/LLMdata?p=2"
                      sx={{
                        borderRadius: "10px",
                        backgroundColor:
                          this.props.selectedButtonManage ===
                          "Manage Data Resources"
                            ? "#6001FF"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            this.props.selectedButtonManage ===
                            "Manage Data Resources"
                              ? "#FFF"
                              : "#000",
                        }}
                      >
                        <StorageIcon
                          sx={{
                            color:
                              this.props.selectedButtonManage ===
                              "Manage Data Resources"
                                ? "#FFF"
                                : "#6001FF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Manage Data Resources"
                        sx={{
                          color:
                            this.props.selectedButtonManage ===
                            "Manage Data Resources"
                              ? "#FFF"
                              : "#000",
                        }}
                        disableTypography
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      // href="LLMdata?p=3"
                       component={Link}
                        to="/LLMdata?p=3"
                      sx={{
                        borderRadius: "10px",
                        backgroundColor:
                          this.props.selectedButtonDeployment ===
                          "Deployment Settings"
                            ? "#6001FF"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            this.props.selectedButtonDeployment ===
                            "Deployment Settings"
                              ? "#FFF"
                              : "#000",
                        }}
                      >
                        <SettingsSuggestIcon
                          sx={{
                            color:
                              this.props.selectedButtonDeployment ===
                              "Deployment Settings"
                                ? "#FFF"
                                : "#6001FF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Deployment Settings"
                        sx={{
                          color:
                            this.props.selectedButtonDeployment ===
                            "Deployment Settings"
                              ? "#FFF"
                              : "#000",
                        }}
                        disableTypography
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </CardActions>
            </Card>
          </Grid>

          {/* Second Card */}
          <Grid item xs={4}>
            <Card
              elevation={0}
              sx={{ backgroundColor: "#F3F6FD", borderRadius: "20px" }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{
                    marginLeft: "3%",
                    color: "#818589",
                    fontSize: "2rem",
                  }}
                >
                  Zuno for Docs
                </Typography>
              </CardContent>
              <CardActions sx={{ marginLeft: "2%", marginTop: "-10px" }}>
                <List style={{ width: "100%" }}>
                  <ListItem disablePadding>
                    <ListItemButton
                      // href="LLMdocs?p=0"
                       component={Link}
                        to="/LLMdocs?p=0"
                      //  onClick={this.handleLLMconfigDocsClickInNav}
                      sx={{
                        borderRadius: "10px",
                        backgroundColor:
                          this.props.selectedButtonDocsLLM ===
                          "LLM Configurations"
                            ? "#6001FF"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            this.props.selectedButtonDocsLLM ===
                            "LLM Configurations"
                              ? "#FFF"
                              : "#000",
                        }}
                      >
                        <DashboardIcon
                          sx={{
                            color:
                              this.props.selectedButtonDocsLLM ===
                              "LLM Configurations"
                                ? "#FFF"
                                : "#6001FF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="LLM Configurations"
                        sx={{
                          color:
                            this.props.selectedButtonDocsLLM ===
                            "LLM Configurations"
                              ? "#FFF"
                              : "#000",
                        }}
                        disableTypography
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      // href="LLMdocs?p=1"
                      component={Link}
                        to="/LLMdocs?p=1"
                      // onClick={this.handleCustomizeDocsClickInNav}
                      sx={{
                        borderRadius: "10px",
                        backgroundColor:
                          this.props.selectedButtonDocsCustom ===
                          "Customize Prompt"
                            ? "#6001FF"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            this.props.selectedButtonDocsCustom ===
                            "Customize Prompt"
                              ? "#FFF"
                              : "#000",
                        }}
                      >
                        <StickyNote2Icon
                          sx={{
                            color:
                              this.props.selectedButtonDocsCustom ===
                              "Customize Prompt"
                                ? "#FFF"
                                : "#6001FF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Customize Prompt"
                        sx={{
                          color:
                            this.props.selectedButtonDocsCustom ===
                            "Customize Prompt"
                              ? "#FFF"
                              : "#000",
                        }}
                        disableTypography
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      // href="LLMdocs?p=2"
                      component={Link}
                        to="/LLMdocs?p=2"
                      sx={{
                        borderRadius: "10px",
                        backgroundColor:
                          this.props.selectedButtonDocsManage ===
                          "Manage Data Resources"
                            ? "#6001FF"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            this.props.selectedButtonDocsManage ===
                            "Manage Data Resources"
                              ? "#FFF"
                              : "#000",
                        }}
                      >
                        <StorageIcon
                          sx={{
                            color:
                              this.props.selectedButtonDocsManage ===
                              "Manage Data Resources"
                                ? "#FFF"
                                : "#6001FF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Manage Data Resources"
                        sx={{
                          color:
                            this.props.selectedButtonDocsManage ===
                            "Manage Data Resources"
                              ? "#FFF"
                              : "#000",
                        }}
                        disableTypography
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      // href="LLMdocs?p=3"
                      component={Link}
                        to="/LLMdocs?p=3"
                      sx={{
                        borderRadius: "10px",
                        backgroundColor:
                          this.props.selectedButtonDocsDeploy ===
                          "Deployment Settings"
                            ? "#6001FF"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            this.props.selectedButtonDocsDeploy ===
                            "Deployment Settings"
                              ? "#FFF"
                              : "#000",
                        }}
                      >
                        <SettingsSuggestIcon
                          sx={{
                            color:
                              this.props.selectedButtonDocsDeploy ===
                              "Deployment Settings"
                                ? "#FFF"
                                : "#6001FF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Deployment Settings"
                        sx={{
                          color:
                            this.props.selectedButtonDocsDeploy ===
                            "Deployment Settings"
                              ? "#FFF"
                              : "#000",
                        }}
                        disableTypography
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </CardActions>
            </Card>
          </Grid>
          {/* Employee Engagement */}
          <Grid item xs={4}>
            <Card
              elevation={0}
              sx={{ backgroundColor: "#F3F6FD", borderRadius: "20px" }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{
                    marginLeft: "3%",
                    color: "#818589",
                    fontSize: "2rem",
                  }}
                >
                  Zuno for HR
                </Typography>
              </CardContent>
              <CardActions sx={{ marginLeft: "2%", marginTop: "-10px" }}>
                <List style={{ width: "100%" }}>
                  <ListItem disablePadding>
                    <ListItemButton
                      // href="employeeEngagement?p=0"
                      component={Link}
                        to="/employeeEngagement?p=0"
                      //  onClick={this.handleLLMconfigDocsClickInNav}
                      sx={{
                        borderRadius: "10px",
                        backgroundColor:
                          this.props.selectedButtonDocsLLM ===
                          "LLM Configurations"
                            ? "#6001FF"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            this.props.selectedButtonDocsLLM ===
                            "LLM Configurations"
                              ? "#FFF"
                              : "#000",
                        }}
                      >
                        <DashboardIcon
                          sx={{
                            color:
                              this.props.selectedButtonDocsLLM ===
                              "LLM Configurations"
                                ? "#FFF"
                                : "#6001FF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="LLM Configurations"
                        sx={{
                          color:
                            this.props.selectedButtonDocsLLM ===
                            "LLM Configurations"
                              ? "#FFF"
                              : "#000",
                        }}
                        disableTypography
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      // href="employeeEngagement?p=1"
                       component={Link}
                        to="/employeeEngagement?p=1"
                      // onClick={this.handleCustomizeDocsClickInNav}
                      sx={{
                        borderRadius: "10px",
                        backgroundColor:
                          this.props.selectedButtonDocsCustom ===
                          "Customize Prompt"
                            ? "#6001FF"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            this.props.selectedButtonDocsCustom ===
                            "Customize Prompt"
                              ? "#FFF"
                              : "#000",
                        }}
                      >
                        <StickyNote2Icon
                          sx={{
                            color:
                              this.props.selectedButtonDocsCustom ===
                              "Customize Prompt"
                                ? "#FFF"
                                : "#6001FF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Customize Prompt"
                        sx={{
                          color:
                            this.props.selectedButtonDocsCustom ===
                            "Customize Prompt"
                              ? "#FFF"
                              : "#000",
                        }}
                        disableTypography
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      // href="employeeEngagement?p=2"
                       component={Link}
                        to="/employeeEngagement?p=2"
                      sx={{
                        borderRadius: "10px",
                        backgroundColor:
                          this.props.selectedButtonDocsManage ===
                          "Manage Data Resources"
                            ? "#6001FF"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            this.props.selectedButtonDocsManage ===
                            "Manage Data Resources"
                              ? "#FFF"
                              : "#000",
                        }}
                      >
                        <StorageIcon
                          sx={{
                            color:
                              this.props.selectedButtonDocsManage ===
                              "Manage Data Resources"
                                ? "#FFF"
                                : "#6001FF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Manage Data Resources"
                        sx={{
                          color:
                            this.props.selectedButtonDocsManage ===
                            "Manage Data Resources"
                              ? "#FFF"
                              : "#000",
                        }}
                        disableTypography
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      // href="employeeEngagement?p=3"
                       component={Link}
                        to="/employeeEngagement?p=3"
                      sx={{
                        borderRadius: "10px",
                        backgroundColor:
                          this.props.selectedButtonDocsDeploy ===
                          "Deployment Settings"
                            ? "#6001FF"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            this.props.selectedButtonDocsDeploy ===
                            "Deployment Settings"
                              ? "#FFF"
                              : "#000",
                        }}
                      >
                        <SettingsSuggestIcon
                          sx={{
                            color:
                              this.props.selectedButtonDocsDeploy ===
                              "Deployment Settings"
                                ? "#FFF"
                                : "#6001FF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Deployment Settings"
                        sx={{
                          color:
                            this.props.selectedButtonDocsDeploy ===
                            "Deployment Settings"
                              ? "#FFF"
                              : "#000",
                        }}
                        disableTypography
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </CardActions>
            </Card>
          </Grid>

            {/* Website */}
            <Grid item xs={4} style={{marginRight:"67%"}}>
            <Card
              elevation={0}
              sx={{ backgroundColor: "#F3F6FD",width:"100%", borderRadius: "20px", }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{
                    marginLeft: "3%",
                    color: "#818589",
                    fontSize: "2rem",
                  }}
                >
                  Zuno for Websites
                </Typography>
              </CardContent>
              <CardActions sx={{ marginLeft: "2%", marginTop: "-10px" }}>
                <List style={{ width: "100%" }}>
                  <ListItem disablePadding>
                    <ListItemButton
                      // href="website?p=0"
                       component={Link}
                        to="/website?p=0"
                      //  onClick={this.handleLLMconfigDocsClickInNav}
                      sx={{
                        borderRadius: "10px",
                        backgroundColor:
                          this.props.selectedButtonDocsLLM ===
                          "LLM Configurations"
                            ? "#6001FF"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            this.props.selectedButtonDocsLLM ===
                            "LLM Configurations"
                              ? "#FFF"
                              : "#000",
                        }}
                      >
                        <DashboardIcon
                          sx={{
                            color:
                              this.props.selectedButtonDocsLLM ===
                              "LLM Configurations"
                                ? "#FFF"
                                : "#6001FF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="LLM Configurations"
                        sx={{
                          color:
                            this.props.selectedButtonDocsLLM ===
                            "LLM Configurations"
                              ? "#FFF"
                              : "#000",
                        }}
                        disableTypography
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      // href="website?p=1"
                       component={Link}
                        to="/website?p=1"
                      // onClick={this.handleCustomizeDocsClickInNav}
                      sx={{
                        borderRadius: "10px",
                        backgroundColor:
                          this.props.selectedButtonDocsCustom ===
                          "Customize Prompt"
                            ? "#6001FF"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            this.props.selectedButtonDocsCustom ===
                            "Customize Prompt"
                              ? "#FFF"
                              : "#000",
                        }}
                      >
                        <StickyNote2Icon
                          sx={{
                            color:
                              this.props.selectedButtonDocsCustom ===
                              "Customize Prompt"
                                ? "#FFF"
                                : "#6001FF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Customize Prompt"
                        sx={{
                          color:
                            this.props.selectedButtonDocsCustom ===
                            "Customize Prompt"
                              ? "#FFF"
                              : "#000",
                        }}
                        disableTypography
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      // href="website?p=2"
                       component={Link}
                        to="/website?p=2"
                      sx={{
                        borderRadius: "10px",
                        backgroundColor:
                          this.props.selectedButtonDocsManage ===
                          "Manage Data Resources"
                            ? "#6001FF"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            this.props.selectedButtonDocsManage ===
                            "Manage Data Resources"
                              ? "#FFF"
                              : "#000",
                        }}
                      >
                        <StorageIcon
                          sx={{
                            color:
                              this.props.selectedButtonDocsManage ===
                              "Manage Data Resources"
                                ? "#FFF"
                                : "#6001FF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Manage Data Resources"
                        sx={{
                          color:
                            this.props.selectedButtonDocsManage ===
                            "Manage Data Resources"
                              ? "#FFF"
                              : "#000",
                        }}
                        disableTypography
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      // href="website?p=3"
                       component={Link}
                        to="/website?p=3"
                      sx={{
                        borderRadius: "10px",
                        backgroundColor:
                          this.props.selectedButtonDocsDeploy ===
                          "Deployment Settings"
                            ? "#6001FF"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#EFEFEF",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            this.props.selectedButtonDocsDeploy ===
                            "Deployment Settings"
                              ? "#FFF"
                              : "#000",
                        }}
                      >
                        <SettingsSuggestIcon
                          sx={{
                            color:
                              this.props.selectedButtonDocsDeploy ===
                              "Deployment Settings"
                                ? "#FFF"
                                : "#6001FF",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Deployment Settings"
                        sx={{
                          color:
                            this.props.selectedButtonDocsDeploy ===
                            "Deployment Settings"
                              ? "#FFF"
                              : "#000",
                        }}
                        disableTypography
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card
              elevation={0}
              sx={{
                height: "100%",
                backgroundColor: "#FFFFFF",
                borderRadius: "20px",
              }}
            >
              <CardContent>
                {/* <Typography gutterBottom variant="h5" component="div" style={{ marginLeft: '6%', color: "#818589" , fontSize: "2rem"}}>
                CX Co-Pilot
                </Typography> */}
              </CardContent>
              <CardActions
                sx={{ marginLeft: "5%", marginTop: "-10px" }}
              ></CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(ZunoApp);
