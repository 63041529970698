import React, { Component } from "react";
import { Card } from "@mui/material";
import { withRouter } from "./withRouter";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
// const BASE_URL = process.env.REACT_APP_BASE_URL;
class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xAxisGranularity: "year",
      yAxisMetric: "Active Users", // Default Y-axis metric
      data: null,
      departmentData: null,
      copy_of_userDetails: null,
    };
  }

  getColumns = () => {
    const { yAxisMetric } = this.props;
    switch (yAxisMetric) {
      case "Active Users":
        return [
          { id: "name", label: "Name" },
          { id: "email", label: "Email" },
          { id: "organization", label: "Organization" },
          { id: "active_days", label: "Active Days" },
        ];
      case "Queries":
        return [
          { id: "name", label: "Name" },
          { id: "email", label: "Email" },
          { id: "organization", label: "Organization" },
          { id: "total_messages", label: "Total Queries" },
        ];
      case "Tokens Consumed":
        return [
          { id: "name", label: "Name" },
          { id: "email", label: "Email" },
          { id: "organization", label: "Organization" },
          { id: "token_used", label: "Tokens Used" },
        ];
      case "Cost":
        return [
          { id: "name", label: "Name" },
          { id: "email", label: "Email" },
          { id: "organization", label: "Organization" },
          { id: "total_token_cost", label: "Cost" },
        ];
      default:
        return [];
    }
  };

  getRows = () => {
    const { yAxisMetric, top_users_data } = this.props;
    return top_users_data[yAxisMetric] || [];
  };
  render() {
    const { yAxisMetric, departmentData } = this.props;
    // console.log(top_users_data);
    // console.log(columns);
    // console.log(yAxisMetric);
    const columns = this.getColumns();
    const rows = this.getRows();
    const departmentOptions = {
      chart: {
        type: "bar",
      },
      title: {
        text: "",
        align: "left", // Align the title to the lef
      },
      xAxis: {
        categories: departmentData?.map((item) => item.department),
      },

      series: [
        {
          name: yAxisMetric,
          data: departmentData?.map((item) => item[yAxisMetric]),
          color: "#6001FF",
        },
      ],
      credits: {
        enabled: false,
      },
    };

    return (
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Card
          elevation={2}
          style={{
            borderRadius: "20px",
            backgroundColor: "#6001FF",
            width: "50%", // Adjust the width as needed
            // overflow:'hidden'
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#FFFFFF",
              fontSize: "1.5rem",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 500,
              marginLeft: "4%",
              marginTop: "1%",
              display: "flex",
              alignItems: "center",
            }}
          >
            Top Users
          </Typography>
          <Card
            elevation={2}
            style={{
              borderRadius: "20px",
              height: "400px",
              minHeight: "300px",
              backgroundColor: "#FFFFFF",
              padding: "20px",
              // boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)', */}
              overflow: "hidden",
            }}
          >
            {/* <Typography variant="h6" style={{ marginBottom: '10px', fontWeight:600 }}>
              Top Users
            </Typography> */}
            {/* Content of the first additional Paper */}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row._id}>
                      {columns.map((column) => (
                        <TableCell key={column.id}>{row[column.id]}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Card>
        <div style={{ width: "5%" }}></div> {/* Space in the middle */}
        <Card
          elevation={2}
          style={{
            borderRadius: "20px",
            backgroundColor: "#6001FF",
            width: "50%", // Adjust the width as needed
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#FFFFFF",
              fontSize: "1.5rem",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 500,
              marginLeft: "4%",
              marginTop: "1%",
              display: "flex",
              alignItems: "center",
            }}
          >
            Department-Wise Comparison
          </Typography>
          <Card
            elevation={2}
            style={{
              borderRadius: "20px",
              minHeight: "300px",
              backgroundColor: "#FFFFFF",
              padding: "20px",
              // boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
            }}
          >
            {/* Content of the second additional Paper */}
            <HighchartsReact
              highcharts={Highcharts}
              options={departmentOptions}
            />
          </Card>
        </Card>
        {/* </div> */}
      </Box>
    );
  }
}

// const formatXAxisTick = (value, granularity) => {
//   const date = new Date(value);
//   switch (granularity) {
//     case "year":
//       return date.getFullYear();
//     case "quarter":
//       const quarter = Math.floor((date.getMonth() + 3) / 3);
//       return `${date.getFullYear()} Q${quarter}`;
//     case "month":
//       return `${date.toLocaleString("default", {
//         month: "short",
//       })} ${date.getFullYear()}`;
//     case "day":
//       return `${date.toLocaleDateString()}`;
//     default:
//       return value;
//   }
// };

export default withRouter(BarChart);
