
import React, { Component } from "react";
import {
  Card,
  Paper,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import CustomMenu from "@mui/material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { withRouter } from "./withRouter";
import Utils from "../userComponents/Utils";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  // const BASE_URL = process.env.REACT_APP_BASE_URL;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 4 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const BASE_URL = process.env.REACT_APP_BASE_URL;

class PromptTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assistantName: "",
      tasks: "",
      responseLength: "",
      outputPresentation: "",
      avoidActions: "",
      avoidActions: "",
      value: 0,
      selectedCheckboxes: {
        dataAnalysis: false,
        informationExtraction: false,
        accessLLMCorpus: false,
      },
      checkboxArray: [],
      showSnackbar: false,
      snackbarSeverity: "success",
      snackbarMessage: "",
      anchorEl: null,
      email:localStorage.getItem("email")?localStorage.getItem("email"):"",

    };
  }
  componentDidMount(){
    this.fetchPromtDetails();
  }
  
  fetchPromtDetails = async () => {
    const {email}=this.state
    const token = localStorage.getItem("token");
  
    const userOrganization =
      JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
        ?.organization ?? "";
    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    try {
      const userEmail = encodeURIComponent(email);
      const response = await fetch(
        `${BASE_URL}/zn002/user/custom_user_prompts/${userOrganization}?applicationType=${encodeURIComponent(
          "zunofordocs"
        )}&email=${userEmail}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        const result = await response.json();
        if (result.message) {
          // here the user is creating the prompt for first time
          return;
        } else {
          const equipAssistant = result.equip_assistant;
          const checkboxArray = Object.keys(equipAssistant).filter(
            (key) => equipAssistant[key]
          );
          this.setState({
            profilePic: result.profilePic,
            assistantName: result.assistantName,
            tasks: result.tasks,
            outputPresentation: result.outputPresentation,
            responseLength: result.responseLength,
            avoidActions: result.avoidActions,
            selectedCheckboxes: equipAssistant,
            checkboxArray: checkboxArray,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // handleClick = () => {
  //   const {
  //     // assistantName,
  //     tasks,
  //     responseLength,
  //     outputPresentation,
  //     avoidActions,
  //   } = this.state;

  //   const promptTemplateText =
  //     "\nconsider the following criteria while generating output \nTask: " +
  //     tasks +
  //     "\nFormat: " +
  //     outputPresentation +
  //     "\navoidActions: " +
  //     avoidActions +
  //     "\nLength: " +
  //     responseLength;
  //   localStorage.setItem("promptTemplateText", promptTemplateText);

  //   // You can make API calls or perform other actions with the data here
  // };
  handleProfilePicChange = (event) => {
    // Handle profile picture change
    const file = event.target.files[0];

    if (file) {
      if (file.size > 1048576) {
        // toast.error("File size should be less than 1MB");
        this.handleSnackbarOpen("error","File size should be less than 1MB")
        return;
      }
      const reader = new FileReader();

      reader.onloadend = () => {
        this.setState({
          profilePic: reader.result,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  handleSnackbarOpen = (severity, message) => {
    this.setState({
      showSnackbar: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  handleSnackbarClose = () => {
    this.setState({ showSnackbar: false });
  };
  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleRemovePicture = () => {
    this.setState({ profilePic: null, anchorEl: null });
  };
  handleUploadPicture = () => {
    this.fileInput.click();
    this.handleMenuClose();
  };

  handleSaveChanges = async () => {
    // const {email}=this.state
    const token = localStorage.getItem("token");

    // Check if the token is not found
    if (!token) {
      console.error("Token not found in localStorage");
      // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
    const userOrganization = JSON.parse(
      localStorage.getItem("currentLoggedInUserDetails")
    )["organization"];
    const formData = {
      applicationType: "zunofordocs",
      organization: userOrganization,
      equip_assistant: this.state.selectedCheckboxes, // Corrected variable name
      profilePic: this.state.profilePic,
      assistantName: this.state.assistantName,
      tasks: this.state.tasks,
      outputPresentation: this.state.outputPresentation,
      responseLength: this.state.responseLength,
      avoidActions: this.state.avoidActions,
      email:this.state.email,
    };

    try {

      const response = await fetch(`${BASE_URL}/zn002/user/custom_user_prompts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.ok) {
        this.handleSnackbarOpen(
          "success",
          "Successfully saved prompt template"
        );
      } else {
        console.log(response);
      }
      // Optionally, you can reset the form or show a success message
    } catch (error) {
      console.error("Error saving data:", error);
      this.handleSnackbarOpen("error", error);
      // Handle errors, show an error message, etc.
    }
    
  };


  handleResetCustomizedPrompts = async() => {
    this.setState(
      {
        profilePic: null,
        assistantName: "",
        tasks: "",
        outputPresentation: "",
        responseLength: "",
        avoidActions: "",
        selectedCheckboxes: {
          dataAnalysis: false,
          informationExtraction: false,
          accessLLMCorpus: false,
        },
        checkboxArray: [],
      },
      // this.handleSaveChanges
      async ()=>{
        const token = localStorage.getItem("token");

        // Check if the token is not found
        if (!token) {
          console.error("Token not found in localStorage");
          // Optionally, you can handle this situation, e.g., redirect to login or show an error message to the user
          Utils.removeLocalStorage();
          this.props.navigate("/");
        }
        const userOrganization = JSON.parse(
          localStorage.getItem("currentLoggedInUserDetails")
        )["organization"];
        const formData = {
          applicationType: "zunofordocs",
          organization: userOrganization,
          equip_assistant: this.state.selectedCheckboxes, // Corrected variable name
          profilePic: this.state.profilePic,
          assistantName: this.state.assistantName,
          tasks: this.state.tasks,
          outputPresentation: this.state.outputPresentation,
          responseLength: this.state.responseLength,
          avoidActions: this.state.avoidActions,
          email:this.state.email,
        };
    
        try {
          const response = await fetch(`${BASE_URL}/zn002/user/custom_user_prompts`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
          });
          if (response.status === 401) {
            Utils.removeLocalStorage();
            this.props.navigate("/");
          }
          if (response.ok) {
            this.handleSnackbarOpen(
              "success",
              "Successfully updated prompt template"
            );
          } else {
            console.log(response);
          }
          // Optionally, you can reset the form or show a success message
        } catch (error) {
          console.error("Error updating data:", error);
          this.handleSnackbarOpen("error", error);
          // Handle errors, show an error message, etc.
        }
      }
    );

  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleCheckboxChange = (checkboxName) => {
    this.setState((prev) => {
      const updatedCheckboxes = {
        ...prev.selectedCheckboxes,
        [checkboxName]: !prev.selectedCheckboxes[checkboxName],
      };
      const updatedCheckboxArray = Object.keys(updatedCheckboxes).filter(
        (checkbox) => updatedCheckboxes[checkbox]
      );

      return {
        selectedCheckboxes: updatedCheckboxes,
        checkboxArray: updatedCheckboxArray,
      };
    });
  };

  render() {
    const {
      value,
      profilePic,
      assistantName,
      tasks,
      avoidActions,
      outputPresentation,
      responseLength,
      showSnackbar,
      snackbarSeverity,
      snackbarMessage,
      anchorEl,
    } = this.state;
    const open = Boolean(anchorEl);
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "1%",
          marginLeft:"60px"
        }}
      >
        <Card
          elevation={5}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "20px",
            marginTop: "4%",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              textColor="#6001FF"
              onChange={this.handleChange}
              aria-label="basic tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#6001FF",
                },
              }}
            >
              <Tab
                label="Customize Prompts"
                {...a11yProps(0)}
                sx={{
                  color: value === 0 ? "#6001FF" : "inherit",
                  "&.Mui-selected": {
                    color: "#6001FF",
                  },
                }}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Box style={{ display: "flex" }}>
              <Paper elevation={0} sx={{ width: "100%" }}>
                <Box style={{ display: "flex", alignItems: "center", }}>
                  {/* Empty Avatar */}
                  <Box
                    // onClick={() => this.fileInput.click()} // Trigger file input on avatar click
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      backgroundColor: "#E0E0E0",
                      cursor: "pointer",
                      overflow: "hidden",
                      marginRight: "0px",
                      position: "relative",
                      // border:"2px solid blue"
                    }}
                  >
                    {profilePic ? (
                      <img
                        src={profilePic}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <Box
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        Profile Picture
                      </Box>
                    )}
                    <Box
                      onClick={(event) => this.handleMenuOpen(event)}
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        backgroundColor: "white",
                        borderRadius: "50%",
                        padding: "2px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <EditIcon style={{ fontSize: "20px", color: "#000" }} />
                    </Box>
                  </Box>

                  {/* Hidden file input */}
                  <input
                    type="file"
                    accept="image/*"
                    ref={(input) => (this.fileInput = input)}
                    style={{ display: "none" }}
                    onChange={this.handleProfilePicChange}
                  />
                  <CustomMenu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={(event) => this.handleMenuClose(event)}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={() => this.handleUploadPicture()}>
                      Upload Picture
                    </MenuItem>
                    <MenuItem onClick={() => this.handleRemovePicture()}>
                      Remove Picture
                    </MenuItem>
                  </CustomMenu>

                  <Box style={{marginLeft:"17px", }}>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        flexGrow: 1,
                        fontSize: "1.2rem",
                        fontFamily: "Poppins, sans-serif",
                        // marginTop: "5%",
                      }}
                    >
                      Name your AI Assistant
                    </Typography>
                    <TextField
                      id="standard-basic"
                      label="Assistant Name"
                      name="assistantName"
                      type="assistantName"
                      value={assistantName}
                      onChange={this.handleInputChange} // Update the onChange handler
                      fullWidth
                      sx={textFieldSx}
                      style={{
                        width: "479%",
                      }}
                    />
                  </Box>
                </Box>
                {/* TextField */}

                <Box style={{ marginLeft: "10.25%" , }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      fontSize: "1.2rem",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: "5%",
                    }}
                  >
                    What should your AI Assistant do?
                  </Typography>

                  <TextField
                    id="outlined-multiline-static"
                    // label="What should your assistant do?"
                    name="tasks"
                    type="tasks"
                    value={tasks}
                    placeholder="eg: customer support"
                    onChange={this.handleInputChange} // Update the onChange handler
                    fullWidth
                    multiline
                    rows={8}
                    sx={textFieldSx}
                  />
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      fontSize: "1.2rem",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: "6%",
                    }}
                  >
                    Equip your Assistant
                  </Typography>
                </Box>
                <Box style={{ display: "flex", gap: "20px", marginLeft: "10.25%" }}>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.selectedCheckboxes.dataAnalysis}
                          onChange={() =>
                            this.handleCheckboxChange("dataAnalysis")
                          }
                          style={{
                            color: this.state.selectedCheckboxes.dataAnalysis
                              ? "#6001FF"
                              : "",
                          }}
                        />
                      }
                      label="Data Analysis"
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            this.state.selectedCheckboxes.informationExtraction
                          }
                          onChange={() =>
                            this.handleCheckboxChange("informationExtraction")
                          }
                          style={{
                            color: this.state.selectedCheckboxes
                              .informationExtraction
                              ? "#6001FF"
                              : "",
                          }}
                        />
                      }
                      label="Information Extraction"
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            this.state.selectedCheckboxes.accessLLMCorpus
                          }
                          onChange={() =>
                            this.handleCheckboxChange("accessLLMCorpus")
                          }
                          style={{
                            color: this.state.selectedCheckboxes
                              .accessLLMCorpus
                              ? "#6001FF"
                              : "",
                          }}
                        />
                      }
                      label="Access LLM Corpus"
                    />
                  </Box>
                </Box>

                <Box style={{ marginLeft: "10.25%" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      fontSize: "1.2rem",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: "5%",
                    }}
                  >
                    How do you want the output to be presented ?
                  </Typography>

                  <TextField
                    id="outlined-multiline-static"
                    name="outputPresentation"
                    type="outputPresentation"
                    value={outputPresentation}
                    placeholder="eg: bullet points"
                    onChange={this.handleInputChange} // Update the onChange handler
                    multiline
                    rows={3}
                    style={{ width: "100%" }}
                    sx={textFieldSx}
                  />
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      fontSize: "1.2rem",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: "5%",
                    }}
                  >
                    What is the preferrable response length?
                  </Typography>

                  <TextField
                    id="outlined-multiline-static"
                    // label="What is the preferable response length?"
                    name="responseLength"
                    type="responseLength"
                    value={responseLength}
                    placeholder="e.g. 1000 characters"
                    onChange={this.handleInputChange}
                    multiline
                    rows={3}
                    style={{ width: "100%" }}
                    sx={textFieldSx}
                  />
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      fontSize: "1.2rem",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: "5%",
                    }}
                  >
                    Anything the AI assistant should avoid doing?
                  </Typography>
                  <TextField
                    id="outlined-multiline-static"
                    placeholder="eg: avoid quick chat and personal questions"
                    name="avoidActions"
                    type="avoidActions"
                    value={avoidActions}
                    onChange={this.handleInputChange}
                    multiline
                    rows={3}
                    style={{ width: "100%" }}
                    sx={textFieldSx}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#6001FF",
                      color: "#fff",
                      marginLeft: "67%",
                      marginTop: "20px",
                    }}
                    onClick={this.handleResetCustomizedPrompts}
                  >
                    Reset Changes
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#6001FF",
                      color: "#fff",
                      marginLeft: "2%",
                      marginTop: "20px",
                    }}
                    onClick={this.handleSaveChanges}
                  >
                    Save Changes
                  </Button>
                </Box>
              </Paper>
            </Box>
          </CustomTabPanel>
        </Card>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
        >
          <Alert
            onClose={this.handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

export default withRouter(PromptTemplate);
