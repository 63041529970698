import React, { Component } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// import CardMedia from '@mui/material/CardMedia';
import Typography from "@mui/material/Typography";
import { withRouter } from "./withRouter";
import tenzai_logo from "../assets/images/png/tenzai-logo.png";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { CardActionArea } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import dataImage from "../assets/images/webp/Zuno for Data.webp";
import docImage from "../assets/images/webp/Zuno for Docs.webp";
import CXImage from "../assets/images/webp/CX-Assistants.webp";
import salesImage from "../assets/images/webp/Sales Co-Pilots.webp";
import RecrcImage from "../assets/images/webp/Recruitment Co-Pilot.webp";
import AutoImage from "../assets/images/webp/Automated-Insights.webp";
import EmployeeImage from "../assets/images/webp/Employee Engagement.webp";
import KnowledgeImage from "../assets/images/webp/knowledge bots.webp";
import ContractImage from "../assets/images/webp/Research Co-Pilot.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Dialog, } from "@mui/material/";
// import Backdrop from '@mui/material/Backdrop';
import User from "./Useract";
import Utils from "../userComponents/Utils";
import ManageAccount from "./ManageAccount";
const BASE_URL = process.env.REACT_APP_BASE_URL;
class Home extends Component {
  constructor(props) {
    super(props);
    const profileImage = localStorage.getItem("profileImage");
    let parsedProfileImage = null;
    
    if (profileImage && profileImage !== "undefined") {
      try {
        parsedProfileImage = JSON.parse(profileImage);
      } catch (error) {
        console.error("Error parsing profile image from localStorage", error);
      }
    }
    this.state = {
      searchQuery: "",
      imageLoaded: false,
      // assignedApplications: JSON.parse(localStorage.getItem('currentLoggedInUserDetails'))['assignedApplications'] ? JSON.parse(localStorage.getItem('currentLoggedInUserDetails'))['assignedApplications'] :[]
      assignedApplications:
        JSON.parse(localStorage.getItem("currentLoggedInUserDetails"))
          ?.assignedApplications ?? [],
      showManageAccount: false,
      profileImage: parsedProfileImage,
      email: localStorage.getItem("email") ? localStorage.getItem("email") : "",
      profileImageMethod: localStorage.getItem("profileImageMethod")
        ? JSON.parse(localStorage.getItem("profileImageMethod"))
        : null,
      tokenUsed: 0,
      tokenLimit: 10000,
    };
    this.accountRef = React.createRef();
    this.securityRef = React.createRef();
    this.updatePasswordRef = React.createRef();
    this.profileRef = React.createRef();
  }
  async componentDidMount() {
    const { profileImageMethod, } = this.state;
    await this.handleFetchTokenUsage();

    if (!profileImageMethod) {
      await this.getUserProfileImage();
    }
   
  }
  componentDidUpdate() {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
    }
  }
  getUserProfileImage = async () => {
    const { email } = this.state;
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      Utils.removeLocalStorage();
      this.props.navigate("/");
      return;
    }

    try {
      const response = await fetch(
        `${BASE_URL}/user/get_profile_image/${email}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        Utils.removeLocalStorage();
        this.props.navigate("/");
        return;
      }

      if (response.status === 200) {
        const data = await response.json();
        if (response.message) {
          this.setState({ profileImage: false });
          localStorage.setItem("profileImage", JSON.stringify(false));
          return;
        }
        this.setState({ profileImage: data.profileImage });
        localStorage.setItem("profileImage", JSON.stringify(data.profileImage));
      } else {
        console.error("Failed to fetch profile image");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    localStorage.setItem("profileImageMethod", JSON.stringify(true));
  };

  handleFetchTokenUsage = async () => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");

    try {
      const response = await fetch(
        `${BASE_URL}/user/fetch_user_token_usage/${email}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        console.error("Unauthorized: You are not logged in");
        Utils.removeLocalStorage();
        this.props.navigate("/");
      }
      if (response.status === 200) {
        const result = await response.json();
        const tokenUsage = result["token_usage"];
        const tokenLimit = result["token_limit"];
        this.setState({tokenUsed:tokenUsage,tokenLimit:tokenLimit},
          ()=>{
            localStorage.setItem("tokenUsed", tokenUsage);
            localStorage.setItem("tokenLimit", tokenLimit);
          }
        )
        
        
        // return [tokenUsage, tokenLimit];
        // this.forceUpdate();
      } else {
        throw new Error("Failed to fetch token usage");
      }
    } catch (error) {
      console.error("Error fetching token usage:", error);
    }
  };

  scrollToSection = (sectionId) => {
    switch (sectionId) {
      case "account":
        this.accountRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "security":
        this.securityRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "profile":
        this.profileRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "updatePassword":
        this.updatePasswordRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  handletryitClick = () => {
    this.props.navigate("/admin");
  };

  handletryitQAClick = () => {
    this.props.navigate("/adminQA");
  };

  handleImageLoad = () => {
    this.setState({ imageLoaded: true });
  };

  getRoute = (title) => {
    let route;
    switch (title) {
      case "Zuno for Data":
        route = "/admin";
        break;
      case "Zuno for Docs":
        route = "/adminQA";
        break;
      case "Zuno for HR":
        route = "/adminHR";
        break;
      case "Zuno for Web":
        route = "/adminWeb";
        break;
      default:
        route = "/home";
    }
    return route;
  };
  getTrueApplications = () => {
    const { assignedApplications } = this.state;
    const trueApplicationsArray = cardData.reduce((acc, item) => {
      if (assignedApplications[item.title]) {
        acc.push({
          title: item.title,
          image: item.image,
          boolValue: assignedApplications[item.title],
        });
      }
      return acc;
    }, []);

    return trueApplicationsArray;
  };

  handleManageAccount = (status) => {
    this.setState({ showManageAccount: status });
  };

  render() {
    let assignedApplications = this.getTrueApplications();
    let { searchQuery, showManageAccount } = this.state;
    const { profileImage,tokenUsed,tokenLimit } = this.state;
    // console.log("homeRender",tokenUsed,tokenLimit);
    if (searchQuery) {
      assignedApplications = assignedApplications.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.trim().toLowerCase())
      );
    }

    return (
      <React.Fragment>
    
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "5.9%",
          }}
        >
   
          <Box
            style={{
              position: "absolute",
              top: 0,
              right: "3%",
              margin: "1rem",
            }}
          >
            <User
              handleManageAccount={this.handleManageAccount}
              profileImage={profileImage}
              tokenUsed={tokenUsed}
              tokenLimit={tokenLimit}
            />

          </Box>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "1.5%",
              marginTop: "4%",
            }}
          >
            <Link to="/home">
              <img
                src={tenzai_logo}
                alt="logo"
                style={{
                  cursor: "pointer",
                  width: "175px",
                  height: "100px",
                  position: "absolute",
                  top: "10px",
                  left: "30px",
                }}
              />
            </Link>
          </Box>

          <Box
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <AppBar
              position="static"
              component="nav"
              sx={{
                width: "100%",
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow: "none",
              }}
            >
              <Toolbar>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    display: { xs: "none", sm: "block" },
                    color: "#6001FF",
                    fontSize: "2rem",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 500,
                  }}
                >
                  Zuno Gen AI Studio
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#F3F6FD",
                    borderRadius: "25px",
                    padding: "5px",
                    marginRight: "5%",
                  }}
                >
                  <SearchIcon
                    sx={{
                      color: "#6001FF",
                      marginLeft: "10px",
                      marginRight: "5px",
                    }}
                  />
                  <InputBase
                    placeholder="Search..."
                    value={this.state.searchQuery}
                    onChange={(e) =>
                      this.setState({ searchQuery: e.target.value })
                    }
                    sx={{
                      color: "#6001FF",
                      width: "400px",
                      backgroundColor: "#F3F6FD",
                      borderRadius: "25px",
                      padding: "5px",
                    }}
                  />
                </Box>
                <Tooltip title="Sort">
                  <IconButton sx={{ position: "absolute", right: "2%" }}>
                    <FilterListRoundedIcon
                      fontSize="large"
                      sx={{ color: "#6001FF" }}
                    />
                  </IconButton>
                </Tooltip>
              </Toolbar>
            </AppBar>
          </Box>

          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "2%",
            }}
          >
            <Grid container sapcing={2}>
              {/* First Card */}

              {assignedApplications.map((card, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <Link
                    to={this.getRoute(card.title)}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Card
                      elevation={5}
                      sx={{
                        width: "88.5%",
                        height: "350px",
                        backgroundColor: "#fff",
                        margin: "5%",
                        borderRadius: "10px",
                      }}
                    >
                      <CardActionArea>
                        <LazyLoadImage
                          src={card.image}
                          width="100%"
                          height={250}
                          onLoad={this.handleImageLoad}
                          style={{ objectFit: "100%" }}
                        />
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width="100%"
                          height={250}
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 1,
                            display: this.state.imageLoaded ? "none" : "block",
                          }}
                        />
                        <CardContent>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {this.state.imageLoaded ? (
                              <Typography
                                gutterBottom
                                variant="h3"
                                component="div"
                                style={{
                                  marginLeft: "9%",
                                  marginTop: "5%",
                                  color: "#6b6f73",
                                  fontSize: "1.5rem",
                                  fontFamily: "Poppins, sans-serif",
                                  fontWeight: 400,
                                }}
                              >
                                {card.title}
                              </Typography>
                            ) : (
                              <Skeleton
                                variant="h3"
                                width="100%"
                                sx={{ borderRadius: "20px" }}
                              />
                            )}
                            <Tooltip title="Info" arrow placement="right">
                              <IconButton
                                size="large"
                                sx={{
                                  marginRight: "2%",
                                  marginTop: "3%",
                                  color: "#818589",
                                }}
                              >
                                <MoreVertIcon style={{ fontSize: "2rem" }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        {showManageAccount && (
            <Dialog
              open={showManageAccount}
              onClose={() => this.handleManageAccount(false)}
              PaperProps={{
                style: {
                  width: "65%", // Set your desired width here
                  maxWidth: "none", // Ensure the dialog does not restrict the width
                },
              }}
            >
                <ManageAccount
                  accountRef={this.accountRef}
                  securityRef={this.securityRef}
                  updatePasswordRef={this.updatePasswordRef}
                  profileRef={this.profileRef}
                  scrollToSection={this.scrollToSection}
                  handleManageAccount={this.handleManageAccount}
                  profileImage={profileImage}
                  getUserProfileImage={this.getUserProfileImage}
                />
            </Dialog>
          )}
      </React.Fragment>
    );
  }
}

export default withRouter(Home);

const cardData = [
  {
    title: "Zuno for Data",
    image: dataImage,
  },
  {
    title: "Zuno for Docs",
    image: docImage,
  },
  {
    title: "Zuno for HR",
    image: EmployeeImage,
  },
  {
    title: "Zuno for Web",
    image: CXImage,
  },
  {
    title: "Analytical Bot",
    image: RecrcImage,
  },
  {
    title: "Sales Co-Pilot",
    image: salesImage,
  },
  {
    title: "Automated Insights",
    image: AutoImage,
  },
  {
    title: "Knowledge Bots",
    image: KnowledgeImage,
  },
  {
    title: "Contract Intelligence",
    image: ContractImage,
  },
];
