import React, { Component } from 'react'

 class Utils extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         
      }
    }
  static removeLocalStorage=()=>{
        for(let key in localStorage){
            localStorage.removeItem(key);
        }
    }

  render() {

    return (
        <React.Fragment>

        </React.Fragment>
    )
  }
}

export default Utils;
    
     
 