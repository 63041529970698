import React, { Component } from "react";
import { Link } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";

export class ChatRetryLangChain extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const {
      timeOutMsg,
      retryTime,
      tryCount,
      handleRetry,
      langChainIndex,
      
    } = this.props;
    return (
      <p>
        {timeOutMsg.length > 0 &&
        retryTime < 6 &&
        tryCount < 3 &&
        langChainIndex === null ? (
          <ListItemText
            secondary={`${timeOutMsg} ${retryTime}`}
            style={{
              marginLeft: "37%",
              fontSize: "1.25rem",
              color: "#818589",
              maxHeight: "1px",
            }}
            disableTypography
          />
        ) : (
          <>
            {timeOutMsg.length > 0 && tryCount < 3 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  maxHeight: "1px",

                  paddingBottom: "4px",
                }}
              >
                <Link
                  href=""
                  color="#6001FF"
                  component="button"
                  variant="body2"
                  onClick={handleRetry}
                >
                  <h3>Click here to Retry</h3>
                </Link>
              </div>
            ) : (
              <> </>
            )}
          </>
        )}
      </p>
    );
  }
}

export default ChatRetryLangChain;
