import React, { Component } from 'react'
import IconButton from '@mui/material/IconButton';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
class ScrollToTop extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        scrolledDown: false,//Add state variable to track scrolling
      }
    }
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
      }
    //after certain height, go to top button  will pop up
    handleScroll = () => {
        const scrollY = window.scrollY;
        if (scrollY > 380) { // Adjust this value as needed
          this.setState({ scrolledDown: true });
        } else {
          this.setState({ scrolledDown: false });
        }
      };
    
      //function to go to top
      scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };
  render() {
    const{scrolledDown}=this.state //destructure the state

    return (
      <>
      {this.props.children}
             {/* Conditional rendering for the scroll-to-top button */}
                {scrolledDown && (
                    <>
                        <IconButton
                            onClick={this.scrollToTop}
                            variant="contained"
                            style={{
                            position: "fixed",
                            bottom: "20px",
                            right: "5px",
                            zIndex: "1000",
                            backgroundColor:"#6001FF",
                            }}
                        >
                             <ExpandLessRoundedIcon style={{ fontSize: "1.75rem",color: "#fff" }}/>
                        </IconButton>  
                    </>     
                )
                }
      </>
    )
  }
}

export default ScrollToTop