import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

// ** Icons Imports
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import RecyclingIcon from "@mui/icons-material/Recycling";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import PhonelinkIcon from "@mui/icons-material/Phonelink";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import Utils from "../userComponents/Utils";
// import { formatIndianNumber, formatNumber } from "../userComponents/Useract";
import { Tooltip } from "@mui/material";

export const formatIndianNumber = (num) => {
  const numString = typeof num !== "string" ? num.toString() : num;
  const lastThreeDigits = numString.slice(-3);
  const otherDigits = numString.slice(0, -3);
  const formattedOtherDigits = otherDigits.replace(
    /\B(?=(\d{2})+(?!\d))/g,
    ","
  );
  return otherDigits
    ? formattedOtherDigits + "," + lastThreeDigits
    : lastThreeDigits;
};
export const formatNumber = (num) => {
  if (num >= 10000000) {
    return (num / 10000000).toFixed(2) + "Cr";
  } else if (num >= 100000) {
    return (num / 100000).toFixed(2) + "L";
  } else if (num >= 10000) {
    return (num / 10000).toFixed(2) + "K";
  }
  return formatIndianNumber(num);
};
const BASE_URL = process.env.REACT_APP_BASE_URL;

const salesData = [
  {
    title: "Total Users",
    color: "#6001FF",
    icon: <PermIdentityIcon sx={{ fontSize: "2.5rem" }} />,
  },
  {
    title: "Total Queries",
    color: "#6001FF",
    icon: <TrendingUpIcon sx={{ fontSize: "2.5rem" }} />,
  },
  {
    title: "Total Tokens Consumed",
    color: "#6001FF",
    icon: <PhonelinkIcon sx={{ fontSize: "2.5rem" }} />,
  },
  {
    title: "Total Cost",
    color: "#6001FF",
    icon: <AttachMoneyIcon sx={{ fontSize: "2.5rem" }} />,
  },
  {
    title: "Average cost per day",
    color: "#6001FF",
    icon: <EventOutlinedIcon sx={{ fontSize: "2.5rem" }} />,
  },
  {
    title: "Average cost per query",
    color: "#6001FF",
    icon: <StickyNote2OutlinedIcon sx={{ fontSize: "2.5rem" }} />,
  },
  {
    title: "Reused Cache Queries",
    color: "#6001FF",
    icon: <RecyclingIcon sx={{ fontSize: "2.5rem" }} />,
  },
  {
    title: "Amount Saved",
    color: "#6001FF",
    icon: <SavingsOutlinedIcon sx={{ fontSize: "2.5rem" }} />,
  },
];

class StatisticsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  calculateTotalTokenUsage = (usersData) => {
    let totalTokenUsage = 0;
    for (const user of usersData) {
      totalTokenUsage += user.token_used;
    }
    return totalTokenUsage;
  };

  getStatsByTitle = (title) => {
    const { user_card_analytics, organization_card_analytics } = this.props;
    let stats;
    switch (title) {
      case "Total Users":
        stats = formatNumber(user_card_analytics.total_users);
        break;
      case "Total Queries":
        // user_card_analytics.total_queries
        stats = formatNumber(user_card_analytics.total_queries);
        break;
      case "Total Tokens Consumed":
        // user_card_analytics.total_tokens_consumed

        stats = formatNumber(user_card_analytics.total_tokens_consumed);

        break;
      case "Total Cost":
        stats = "$" + user_card_analytics.total_cost.toFixed(3);
        break;
      case "Average cost per day":
        stats =
          "$" + organization_card_analytics.average_cost_per_day.toFixed(3);
        break;
      case "Average cost per query":
        stats =
          "$" + organization_card_analytics.average_cost_per_query.toFixed(3);
        break;
      case "Reused Cache Queries":
        stats = formatNumber(
          String(organization_card_analytics.reused_cache_queries)
        );
        break;
      case "Amount Saved":
        stats =
          organization_card_analytics.amount_saved !== undefined
            ? "$" + organization_card_analytics.amount_saved.toFixed(3)
            : "20";
        break;
      default:
        stats = "";
        break;
    }
    return stats;
  };

  renderStats() {
    const { user_card_analytics, organization_card_analytics } = this.props;

    return (
      <Grid container spacing={3} sx={{ paddingLeft: "5%" }}>
        {salesData.map((item, index) => (
          <Grid item xs={12} sm={3} key={index}>
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row", // Change to row to align text and avatar horizontally
                alignItems: "center",
                gap: "10px",
                // marginBottom:"20px"
              }}
            >
              <Avatar
                sx={{
                  width: 60,
                  height: 60,
                  boxShadow: 3,
                  color: "common.white",
                  backgroundColor: "#6001FF",
                  borderRadius: "10px",
                  // marginBottom:'20%',
                  // marginRight: '20px', // Add margin to create space between avatar and text
                }}
              >
                {item.icon}
              </Avatar>
              <Box>
                <Typography
                  variant="h6"
                  style={{ fontSize: "1.6rem", color: "#262626" }}
                >
                  {this.getStatsByTitle(item.title).charAt(0) !== "$" ? (
                    <Tooltip
                      title={
                        item.title === "Total Tokens Consumed"
                          ? formatIndianNumber(
                              user_card_analytics.total_tokens_consumed
                            )
                          : item.title === "Total Queries"
                          ? formatIndianNumber(
                              user_card_analytics.total_queries
                            )
                          : item.title === "Total Users"
                          ? formatIndianNumber(user_card_analytics.total_users)
                          : formatIndianNumber(
                              String(
                                organization_card_analytics.reused_cache_queries
                              )
                            )
                      }
                      placement="top-start"
                      arrow
                    >
                      <span>{this.getStatsByTitle(item.title)}</span>
                    </Tooltip>
                  ) : (
                    this.getStatsByTitle(item.title)
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  style={{
                    fontSize: "1rem",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 300,
                  }}
                >
                  {item.title}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  }
  getStatsCardData(props) {
    const { organizationAnalytics } = props;
    let totalQueries = 0;

    let totalTokensConsumed = 0;
    let totalCost = 0;

    for (let i = 0; i < organizationAnalytics.length; i++) {
      const item = organizationAnalytics[i];
      totalQueries += item.total_messages;
      totalTokensConsumed += item.total_token_usage;
      totalCost += item.total_token_cost;
    }

    let total_cost = 0;
    let total_messages = 0;
    organizationAnalytics.forEach((item) => {
      total_cost += item.total_token_cost;
      total_messages += item.total_messages;
    });
    const average_cost_per_day =
      organizationAnalytics.length > 0
        ? total_cost / organizationAnalytics.length
        : 0;

    // Calculate average cost per query
    const average_cost_per_query =
      total_messages > 0 ? total_cost / total_messages : 0;

    // const averageTokenCost = totalQueries > 0 ? totalCost / totalQueries : 0;
    this.setState({
      total_queries: totalQueries,
      total_tokens_consumed: totalTokensConsumed,
      total_cost: totalCost,
      average_cost_per_day: average_cost_per_day,
      average_cost_per_query: average_cost_per_query,
    });
  }

  render() {
    return (
      <>
        <Card elevation={2} style={{ borderRadius: "20px" }}>
          <CardContent sx={{ pt: (theme) => `${theme.spacing(3)} !important` }}>
            <Grid container>{this.renderStats()}</Grid>
          </CardContent>
        </Card>
      </>
    );
  }
}

export default StatisticsCard;
