import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};
const selectFieldSx = {
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6001ff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
  },
  "& .MuiMenuItem": {
    maxHeight: "100px", // Set the maximum height for the dropdown
    maxWidth: "100px",
  },
};
export class LogsDropDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectInfo: ["Query", "Application", "Username", "Department"],
    };
  }
  render() {
    const { selectInfo } = this.state;
    const { handleDropDown, selected } = this.props;
    return (
      <div>
        <TextField
          sx={{
            color: "#6001FF",
            width: "175px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#F3F6FD",
            paddingBottom: "2px",
            marginRight: "10px",
            ...textFieldSx,
          }}
          label="Filter based on..."
          variant="outlined"
          value={selected}
          onChange={(e) => handleDropDown(e.target.value)}
          select
          fullWidth
        >
          {selectInfo.map((item) => {
            return (
              <MenuItem sx={{ ...selectFieldSx }} value={item} key={item}>
                {item}
              </MenuItem>
            );
          })}
        </TextField>
      </div>
    );
  }
}

export default LogsDropDown;
