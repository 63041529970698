import React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import StorageIcon from "@mui/icons-material/Storage";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { withRouter } from "./withRouter";
import tenzai_logo from "../assets/images/png/tenzai-logo.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedButton: localStorage.getItem("selectedButton") || "Dashboard",
    };
  }
  // componentDidMount(){
  //   const {selectedButton,customizePromptAlert}=this.props
  //   if (selectedButton==="App Management" && customizePromptAlert) {
  //     console.log("object");
  //     this.props.onMenuClick()
  //   }
  // }
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleDashboardClickInNav = () => {
    this.props.handleDashboardClick({
      showDashboard: true,
      showUserManagement: false,
      showDataManagement: false,
      showLogs: false,
      showPrompt: false,
    });
    this.setState({ selectedButton: "Dashboard" });
  };

  handleUserManagementClickInNav = () => {
    this.props.handleUserManagementClick({
      showDashboard: false,
      showUserManagement: true,
      showDataManagement: false,
      showLogs: false,
      showPrompt: false,
    });
    this.setState({ selectedButton: "User Management" });
  };

  handleDataManagementClickInNav = () => {
    this.props.handleDataManagementClick({
      showDashboard: false,
      showUserManagement: false,
      showDataManagement: true,
      showLogs: false,
      showPrompt: false,
    });
    this.setState({ selectedButton: "Data Management" });
  };

  handleLogsClickInNav = () => {
    this.props.handleLogClick({
      showDashboard: false,
      showUserManagement: false,
      showDataManagement: false,
      showLogs: true,
      showPrompt: false,
    });
    this.setState({ selectedButton: "Query Logs" });
  };

  handlePromptClickInNav = () => {
    this.props.handlePromptClick({
      showDashboard: false,
      showUserManagement: false,
      showDataManagement: false,
      showLogs: false,
      showPrompt: true,
    });
    this.setState({ selectedButton: "App Management" });
  };

  render() {
    const { open, selectedButton } = this.state;
    const { onMenuClick, customizePromptAlert,checkAllStates } = this.props;
    return (
      <>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <IconButton
          onClick={ async () => {
            customizePromptAlert && await checkAllStates()
            const userConfirmed = customizePromptAlert && await onMenuClick();
            // console.log("Child userConfirmed", userConfirmed);
            if (
              userConfirmed &&
              this.props.selectedButton === "App Management"
            ) {
              this.handleDrawerClose();
            } else {
              this.handleDrawerOpen();
            }
          }}
          sx={{ position: "absolute", zIndex: 1, top: "5%", left: "30px" }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer anchor="left" open={open} onClose={this.handleDrawerClose}>
          <div style={{ display: "flex", marginLeft: "4%" }}>
            <img
              src={tenzai_logo}
              alt="logo"
              style={{ cursor: "pointer", width: "175px", height: "100px" }}
              onClick={() => {
                this.props.navigate("/home");
                this.handleDrawerClose();
              }}
            />
          </div>
          <List sx={{ width: "300px" }}>
            <ListItem sx={{ padding: "0px 20px 0px 0px" }}>
              <ListItemButton
                // href="dashboard"
                component={Link}
                to="/dashboard"
                // onClick={() => {
                //   this.handleDashboardClickInNav();
                //   this.handleDrawerClose();
                // }}
                sx={{
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  // backgroundColor: selectedButton === "Dashboard" ? '#6001FF' :
                  backgroundColor:
                    this.props.selectedButton === "Dashboard"
                      ? "#6001FF"
                      : "transparent",
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      this.props.selectedButton === "Dashboard"
                        ? "#FFF"
                        : "#000",
                  }}
                >
                  <DashboardIcon
                    sx={{
                      color:
                        this.props.selectedButton === "Dashboard"
                          ? "#FFF"
                          : "#6001FF",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Dashboard"
                  sx={{
                    color:
                      this.props.selectedButton === "Dashboard"
                        ? "#FFF"
                        : "#000",
                    fontSize: "1.3rem",
                  }}
                  disableTypography
                />
              </ListItemButton>
            </ListItem>

            <ListItem sx={{ padding: "0px 20px 0px 0px" }}>
              <ListItemButton
                // href="usermanagement"
                component={Link}
                to="/usermanagement"
                // onClick={() => {
                //   this.handleUserManagementClickInNav();
                //   this.handleDrawerClose();
                // }}
                sx={{
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  backgroundColor:
                    this.props.selectedButton === "User Management"
                      ? "#6001FF"
                      : "transparent",
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      this.props.selectedButton === "User Management"
                        ? "#FFF"
                        : "#000",
                  }}
                >
                  <AccountBoxIcon
                    sx={{
                      color:
                        this.props.selectedButton === "User Management"
                          ? "#FFF"
                          : "#6001FF",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="User Management"
                  sx={{
                    color:
                      this.props.selectedButton === "User Management"
                        ? "#FFF"
                        : "#000",
                    fontSize: "1.3rem",
                  }}
                  disableTypography
                />
              </ListItemButton>
            </ListItem>

            <ListItem sx={{ padding: "0px 20px 0px 0px" }}>
              <ListItemButton
                // href="datamanagement"
                component={Link}
                to="/datamanagement"
                // onClick={() => {
                //   this.handleDataManagementClickInNav();
                //   this.handleDrawerClose();
                // }}
                sx={{
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  backgroundColor:
                    this.props.selectedButton === "Data Management"
                      ? "#6001FF"
                      : "transparent",
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      this.props.selectedButton === "Data Management"
                        ? "#FFF"
                        : "#000",
                  }}
                >
                  <StorageIcon
                    sx={{
                      color:
                        this.props.selectedButton === "Data Management"
                          ? "#FFF"
                          : "#6001FF",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Data Management"
                  sx={{
                    color:
                      this.props.selectedButton === "Data Management"
                        ? "#FFF"
                        : "#000",
                    fontSize: "1.3rem",
                  }}
                  disableTypography
                />
              </ListItemButton>
            </ListItem>

            <ListItem sx={{ padding: "0px 20px 0px 0px" }}>
              <ListItemButton
                // href="querylogs"
                component={Link}
                to="/querylogs"
                // onClick={() => {
                //   this.handleLogsClickInNav();
                //   this.handleDrawerClose();
                // }}
                sx={{
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  backgroundColor:
                    this.props.selectedButton === "Query Logs"
                      ? "#6001FF"
                      : "transparent",
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      this.props.selectedButton === "Query Logs"
                        ? "#FFF"
                        : "#000",
                  }}
                >
                  <StickyNote2Icon
                    sx={{
                      color:
                        this.props.selectedButton === "Query Logs"
                          ? "#FFF"
                          : "#6001FF",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Query Logs"
                  sx={{
                    color:
                      this.props.selectedButton === "Query Logs"
                        ? "#FFF"
                        : "#000",
                    fontSize: "1.3rem",
                  }}
                  disableTypography
                />
              </ListItemButton>
            </ListItem>

            <ListItem sx={{ padding: "0px 20px 0px 0px" }}>
              <ListItemButton
                // href="appmanagement"
                component={Link}
                to="/appmanagement"
                // onClick={() => {
                //   this.handlePromptClickInNav();
                //   this.handleDrawerClose();
                // }}
                sx={{
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  backgroundColor:
                    this.props.selectedButton === "App Management"
                      ? "#6001FF"
                      : "transparent",
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      this.props.selectedButton === "App Management"
                        ? "#FFF"
                        : "#000",
                  }}
                >
                  <AppRegistrationIcon
                    sx={{
                      color:
                        this.props.selectedButton === "App Management"
                          ? "#FFF"
                          : "#6001FF",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="App Management"
                  sx={{
                    color:
                      this.props.selectedButton === "App Management"
                        ? "#FFF"
                        : "#000",
                    fontSize: "1.3rem",
                  }}
                  disableTypography
                />
              </ListItemButton>
            </ListItem>

            <ListItem sx={{ padding: "0px 20px 0px 0px" }}>
              <ListItemButton
                href="#"
                onClick={() => {
                  this.handleDrawerClose();
                }}
                sx={{
                  borderTopRightRadius: "30px",
                  borderBottomRightRadius: "30px",
                  backgroundColor:
                    selectedButton === "Scheduler" ? "#6001FF" : "transparent",
                  "&:hover": {
                    backgroundColor: "#EFEFEF",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color: selectedButton === "Scheduler" ? "#FFF" : "#000",
                  }}
                >
                  <AccessTimeFilledIcon
                    sx={{
                      color:
                        selectedButton === "Scheduler" ? "#FFF" : "#6001FF",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Scheduler"
                  sx={{
                    color: selectedButton === "Scheduler" ? "#FFF" : "#000",
                    fontSize: "1.3rem",
                  }}
                  disableTypography
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </>
    );
  }
}

export default withRouter(Menu);
