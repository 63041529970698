import React, { Component } from 'react';
import { Paper, Typography, TextField, Button, Box,Divider,IconButton,InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const textFieldSx = {
  " & .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6001ff",
    },
    "&:hover fieldset": {
      borderColor: "#6001ff", // Handle hover state
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "gray",
  },
};


class Setpassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      currentPassword: '',
      error: false,
      showPassword:false,
      isValidPassword:false,
      email:localStorage.getItem("email")?localStorage.getItem("email"):""
    };
  }
  componentDidMount(){
    this.props.scrollToSection("updatePassword")
  }
   handlePasswordChange = async(e) => {
    const password = e.target.value;
    const password_regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,}$/;
    if (!password_regex.test(password)) {
    this.setState({ password, isValidPassword:false,error:true   });
    }
    else{
      this.setState({ password,isValidPassword:true, error:false  })
    }
  };

  handleConfirmPasswordChange = (e) => {
    this.setState({ currentPassword: e.target.value },
      // ()=>{console.log("currentPassword",this.state.currentPassword)}
    );
  };

  handleSubmit = async() => {
    const {error,isValidPassword,email,password,currentPassword}=this.state
    if (error  ) {
      this.setState({ error: true });
      return;
    
    }
    else if(currentPassword.length===0){
      toast.error("current password should not be empty")
      return;
    }
    else if(!isValidPassword){
      this.setState({error:true})
      return;
    } 
    else {
      // Handle the submit logic here
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }
      const formData=new FormData();
      formData.append("userEmail",email)
      formData.append("currentPassword",currentPassword)
      formData.append("newpassword",password)
      try {
        const response = await fetch(`${BASE_URL}/auth/manage_account/reset_password`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        console.log("respone",response);
        if(response.status===401){
          toast.error("Incorrect current password")
          return;
        }
        if (response.status===403) {
          toast.error("User does not have permission to reset password")
          return;
        }
        if (response.status === 200) {
          toast.success("Reset Password Successful", {
              onClose: this.props.onCancelSetpassword,
          });
      } else {
          toast.error("Resetting user password failed", {
              onClose: this.props.onCancelSetpassword,
          });
      }
      } catch (error) {
        console.log("Error", error);
      }
    }
  };
  toggleShowPassword=()=>{
    this.setState((prevState)=>({showPassword:!prevState.showPassword}))
  }


  render() {
    const { password, error,showPassword,currentPassword} = this.state;
    const { onCancelSetpassword } = this.props;

    return (
      <React.Fragment>
         <Box sx={{display:"flex",height: "100vh",width:"100%", }}>
         <Divider orientation="vertical"sx={{ height: "105%" ,backgroundColor: "rgba(0, 0, 0, 0.2)" }}/>
        <Box ref={this.props.updatePasswordRef}>{""}</Box>
      <Paper style={{ padding: 16,marginTop:"2%" ,width:"100%"}} elevation={0} >
          <Typography variant="h5" style={{ marginBottom: 16 }} >Update Password</Typography>
          <Divider style={{ margin: '16px 0',backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
          <form noValidate autoComplete="off">
                    <TextField
              label="Current Password"
              type={showPassword?"text":"password"}
              fullWidth
              required
              value={currentPassword}
              onChange={(e)=>this.handleConfirmPasswordChange(e)}
              style={{ marginBottom: 16 }}
              sx={textFieldSx}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={()=>this.toggleShowPassword()}>
                      {showPassword ? <Visibility /> : <VisibilityOff /> }
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="New password"
              type={showPassword?"text":"password"}
              fullWidth
              required
              error={error}
              helperText={error ? "Password must be at least 10 characters and it should contain at least one uppercase letter, one lowercase letter, one digit, and one special character.": ''}
              value={password}
              onChange={(e)=>this.handlePasswordChange(e)}
              style={{ marginBottom: 36 }}
              sx={textFieldSx}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={()=>this.toggleShowPassword()}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
    
            {/* <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Sign out of all other devices"
            /> */}
            <Box display="flex" justifyContent="flex-end" style={{ marginTop: 16 ,alignItems:"center",gap: 18,}}>
              <Typography variant="outlined" onClick={onCancelSetpassword} style={{ marginRight: 8,textTransform:"none" ,cursor:"pointer"}}>
                Cancel
              </Typography>
              <Button variant="contained" color="primary" style={{
                backgroundColor: "#6001FF",
                color: "#fff",
                textTransform: "none",}} onClick={this.handleSubmit}>
                Reset Password
              </Button>
            </Box>
          </form>
        </Paper>
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={true}
        />
        </Box>
      </React.Fragment>
    );
  }
}

export default Setpassword;
